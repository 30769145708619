import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';


export default function ArgoRadioGroupHook(props) {

    const fileName = props.fileName ?? "NoFileName";
    const fieldName = props.fieldName ?? "NoFieldName";

    const index = props.index ?? ""; // If field is built in a loop index is required to know which field to match up with

    const uniqueIdentifier = fileName + "-ArgoRadioGroupHook-" + fieldName + index;

    const label = props.label ?? "";

    const fontSize = props.fontSize ?? "18px";

    const value = props.value ?? "";

    const onChange = props.onChange ?? function () {};

    //const display = props.display === undefined ? "inline" : "none";

    //const style = props.style ?? {};

    let checked = [false, false];
    if(props.checked === true) checked = [true, false];
    else if(props.checked === false) checked = [false, true];

    const radioButtonList = props.radioButtonList ?? ["Yes","No"];
    const radioButtonLabel = props.radioButtonLabel ?? "";
    const labelPlacement = props.labelPlacement ?? "start";


    const handleOnChange = (event) => {
        onChange(event, fieldName, index)
    };


    return (
        <FormControl sx={{display: "flex", flexDirection: "row", alignItems: "center", width: "100%"}}>
            {label !== "" ?
                <FormLabel
                    id={uniqueIdentifier + "-form-label"}
                    name={uniqueIdentifier + "-form-label"}
                    sx={{fontSize: fontSize, color: "black", "&.Mui-focused": {color: 'black'}}}>{label}
                </FormLabel>
                : ""
            }
            <RadioGroup
                id={uniqueIdentifier}
                name={uniqueIdentifier}
                value={value}
                row
                onChange={handleOnChange}
            >
                {
                    radioButtonList.map((row, index) => {
                        return (<FormControlLabel key={uniqueIdentifier + index} labelPlacement={labelPlacement}  value={index} label={radioButtonLabel === "" ? row : row[radioButtonLabel]} control={<Radio/>} checked={checked[index]}/>);
                    })
                }
            </RadioGroup>
        </FormControl>
    );
}
