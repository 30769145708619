import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {getIntegration} from "../../../actions/integrationActions";
import {getIntegrationTypeName} from "../../../constants/integrationConstants";
import ArgoIconButtonToggleViewFieldTextHook from "../../common/ArgoIconButtonToggleViewFieldTextHook";


class IntegrationReview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewApiKey1: false,
            viewApiKey2: false
        };
    }

    componentWillMount() {
        if (this.props.id) {
            this.props.getIntegration(this.props.id);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.id !== nextProps.id) {
            nextProps.getIntegration(nextProps.id);
        }
    }

    toggleViewApiKey = (fieldName, toggle) => {
        this.setState({[fieldName]: toggle});
    };

    render() {
        const integration = this.props.integration;
        const rootStyle = {
            padding: "32px",
            justifyContent: "space-between",
            width: "90%",
            maxHeight: "fit-content",
            height: "fit-content",
            minHeight: "fit-content",
            ...this.props.style
        };

        return (
            (integration && (typeof integration.apiKey1 !== "undefined") && (typeof integration.apiKey2 !== "undefined")) ?
                <div style={rootStyle}>

                    <div id="IntegrationReview-title-div" style={{width: "100%", display: "flex"}}>
                        <div style={{paddingBottom: "12px", fontSize: "16px", color: "silver"}}>{"Title:"}</div>
                        <div style={{width: "15px"}}></div>
                        <div style={{paddingBottom: "12px", fontSize: "16px", fontWeight: "bold"}}>{integration.name}</div>
                    </div>

                    <div id="IntegrationReview-type-div" style={{width: "100%", display: "flex"}}>
                        <div style={{paddingBottom: "12px", fontSize: "16px", color: "silver"}}>{"Type:"}</div>
                        <div style={{width: "15px"}}></div>
                        <div style={{paddingBottom: "12px", fontSize: "16px", fontWeight: "bold"}}>{getIntegrationTypeName[integration.integrationType]}</div>
                    </div>

                    <div id="IntegrationReview-url-div" style={{width: "100%", display: "flex"}}>
                        <div style={{paddingBottom: "12px", fontSize: "16px", color: "silver"}}>{"URL:"}</div>
                        <div style={{width: "15px"}}></div>
                        <div style={{paddingBottom: "12px", fontSize: "16px", fontWeight: "bold"}}>{integration.integrationUrl}</div>
                    </div>

                    <div id="IntegrationReview-api1-div" style={{width: "100%", display: "flex", alignItems: "center"}}>
                        <div style={{paddingBottom: "12px", fontSize: "16px", color: "silver"}}>{integration.integrationType === "OAUTH2" ? "Client ID" : "API Key 1:"}</div>

                        <div style={{width: "15px"}}></div>

                        <div style={{paddingBottom: "12px", fontSize: "16px", fontWeight: "bold"}}>{this.state.viewApiKey1 ? integration.apiKey1 : "*".repeat(integration.apiKey1.length)}</div>

                        <div style={{width: "15px"}}></div>

                        <ArgoIconButtonToggleViewFieldTextHook
                            fileName="integration-viewer"
                            fieldName="viewApiKey1"
                            marginTop="-15px"
                            onClick={this.toggleViewApiKey}
                        />

                    </div>

                    <div id="IntegrationReview-api2-div" style={{width: "100%", display: "flex", alignItems: "center"}}>
                        <div style={{paddingBottom: "12px", fontSize: "16px", color: "silver"}}>{integration.integrationType === "OAUTH2" ? "Secret" : "API Key 2:"}</div>

                        <div style={{width: "15px"}}></div>

                        <div style={{paddingBottom: "12px", fontSize: "16px", fontWeight: "bold"}}>{this.state.viewApiKey2 ? integration.apiKey2 : "*".repeat(integration.apiKey2.length)}</div>

                        <div style={{width: "15px"}}></div>

                        <ArgoIconButtonToggleViewFieldTextHook
                            fileName="integration-viewer"
                            fieldName="viewApiKey2"
                            marginTop="-15px"
                            onClick={this.toggleViewApiKey}
                        />
                    </div>

                    {this.props.integration.integrationType === "OAUTH2" ?
                        <div>
                            <div id="IntegrationReview-info3-div" style={{width: "100%", display: "flex"}}>
                                <div style={{paddingBottom: "12px", fontSize: "16px", color: "silver"}}>{"Token Endpoint:"}</div>
                                <div style={{width: "15px"}}></div>
                                <div style={{
                                    paddingBottom: "12px",
                                    fontSize: "16px",
                                    fontWeight: "bold"
                                }}>{integration.info3}</div>
                            </div>

                            <div id="IntegrationReview-info4-div" style={{width: "100%", display: "flex"}}>
                                <div style={{paddingBottom: "12px", fontSize: "16px", color: "silver"}}>{"Grant Type:"}</div>
                                <div style={{width: "15px"}}></div>
                                <div style={{
                                    paddingBottom: "12px",
                                    fontSize: "16px",
                                    fontWeight: "bold"
                                }}>{integration.info4}</div>
                            </div>

                            <div id="IntegrationReview-info5-div" style={{width: "100%", display: "flex"}}>
                                <div style={{paddingBottom: "12px", fontSize: "16px", color: "silver"}}>{"Scope:"}</div>
                                <div style={{width: "15px"}}></div>
                                <div style={{
                                    paddingBottom: "12px",
                                    fontSize: "16px",
                                    fontWeight: "bold"
                                }}>{integration.info5}</div>
                            </div>
                        </div> : ""
                    }

                </div>
                : ""
        );
    }
}

IntegrationReview.defaultProps = {
    integration: undefined
};

IntegrationReview.propTypes = {
    integration: PropTypes.object,
    style: PropTypes.object,
    id: PropTypes.string,
};

function mapStateToProps(state, props) {
    let mappedState = {};

    if (props.id) {
        mappedState.integration = state.integration;
    }

    return mappedState;
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({getIntegration}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationReview);
