

/**
 * Build pageable part of URL string
 * @return {string}
 */
export function FormatPageable(pageable, columnNameMapper) {

    // pagination API for components is 0 indexed

    let sort = columnNameMapper[pageable.name];

    return "&page=" + (pageable.pageNumber - 1 || "0").toString()
        + "&size=" + (pageable.pageSize || "")
        + "&sort=" + (sort || "") + "," + (pageable.direction || "");
}


/**
 * Build filter part of URL string
 * @return {string}
 */
export function FormatFilter(filter) {

    let tagsStr = "";
    for (let i = 0; i < filter.tags.length; i++) {
        if (filter.tags[i].id === undefined) {
            tagsStr = (tagsStr === "") ? filter.tags[i] : (tagsStr + "," + filter.tags[i]);
        }
        else {
            tagsStr = (tagsStr === "") ? filter.tags[i].id : (tagsStr + "," + filter.tags[i].id);
        }

    }

    return "?name=" + (filter.title || "")
    + (filter.nameQualifier !== "" ? ("&nameQualifier=" + (filter.nameQualifier || "" )) : "")
    + (filter.updatedBy !== "" ? ("&updatedBy=" + (filter.updatedBy || "" )) : "")
    + (filter.status !== "" ? ("&status=" + (filter.status || "" )) : "")
    + (filter.type !== "" ? ("&type=" + (filter.type || "" )) : "")
    + (filter.notType !== "" ? ("&type=" + (filter.notType || "" )) : "")
    + (tagsStr !== "" ? ("&tags=" + (tagsStr || "" )) : "")
    + (filter.integrationType !== "" ? ("&integrationType=" + (filter.integrationType || "" )) : "")
    + (filter.outstanding ? "&isOutstanding=true" : "");
    //    + "&currentFlag=" + (filter.currentFlag || false);  not sure needed since endpoint used determines value

}


/**
 * Build pageable and filter part of URL string from a pageable and filter object
 * @return {string}
 */
export function FormatPageableAndFilter(pageable, filter, columnNameMapper) {

    return FormatFilter(filter) + FormatPageable(pageable, columnNameMapper);
}


/**
 * Build pageable and filter part of URL string from filter object only
 * @return {string}
 */
export function FormatPageAndFilterFromFilter(filter, columnNameMapper) {

    return FormatFilter(filter) + FormatPageable(filter, columnNameMapper);
}


export function pageableObj(pageNumber, pageSize, name, direction) {

    return {pageNumber: pageNumber, pageSize: pageSize, name: name, direction: direction};
}


export function pageableObjFromFilter(filter) {

    return {pageNumber: filter.pageNumber, pageSize: filter.pageSize, name: filter.name, direction: filter.direction};
}


export function pageableObjFromFilterSetPageOne(filter) {

    return {pageNumber: 1, pageSize: filter.pageSize, name: filter.name, direction: filter.direction};
}








