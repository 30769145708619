import React, { useState } from 'react';
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";


export default function ArgoIconButtonToggleViewFieldTextHook(props) {
    const [toggle, setToggle] = useState(false);

    const fileName = props.fileName ?? "file-name";
    const fieldName = props.fieldName ?? "field-name";

    const uniqueIdentifier = fileName + "-text-field-" + fieldName;

    const marginTop = props.marginTop ?? "0px";

    const onClick = props.onClick ?? function () {};


    const handleOnClick = () => {
        setToggle(!toggle);
        onClick(props.fieldName, !toggle)
    };

    return (
        <IconButton id={uniqueIdentifier}
                    name={uniqueIdentifier}
                    onClick={handleOnClick}
                    style={{marginTop: marginTop}}>
            {toggle ? <VisibilityIcon/> : <VisibilityOffIcon/>}
        </IconButton>
    );
}
