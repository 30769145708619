import {env} from "./env";

let defaultValueFunction = function () {
	if (process.env.REACT_APP_PROFILE === "dev") {
		return "http://" + (env.REACT_APP_QD_HOST || "localhost") + ":" + (env.REACT_APP_QD_PORT || "8081");
	} else {
		return "";
	}
};


const QD_URI_PREFIX_DEFAULT = defaultValueFunction();

export const REACT_APP_PORTAL_MODE = env.REACT_APP_PORTAL_MODE;
export const QD_PORTAL = "/qd2portal";
export const QD_URI_PREFIX = env.REACT_APP_QD_URI_PREFIX || QD_URI_PREFIX_DEFAULT;
export const QD_API_AUTH_URL = QD_URI_PREFIX + "/api/authenticate";
export const QD_API_URL = QD_URI_PREFIX + "/api/v1/";
export const QD_BATCH_URL = QD_URI_PREFIX + "/batch-upload/load-files/";
export const QD_HELP_URL = QD_URI_PREFIX + "/help/webhelp/QDAdminHelp.htm";
export const QD_HELP_URL_QUESTION = QD_URI_PREFIX + "/help/csh/QD-CSH.htm#cshid=";
export const QD_URL = "/qd2admin";
export const QD_PORTAL_PATH = env.REACT_APP_QD_PORTAL_PATH;

export const STORAGE_TOKEN = "id_token";
export const STORAGE_USERNAME = "username";

export const USE_TIMER_DELAY = false;
export const USE_TIMER_DELAY_AMOUNT = 0; // 1000ms = 1 second.

