import MetadataDefinitionApi from "../api/MetadataDefinitionApi";
import * as types from "../constants/actionConstants";


export function getMetadataDefinitions(entityType) {
    return (dispatch) => {
        dispatch({type: types.METADATA_DEFINITION_LIST_GET_SUCCESS});
        MetadataDefinitionApi.getEntityMetadataDefinitions(entityType).then((data) => {
            dispatch({
                type: types.METADATA_DEFINITION_LIST_GET_SUCCESS,
                entityType: entityType,
                list: data.collection
            });
        });
    };
}
