import {styled} from "@mui/material/styles";
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import * as font from "../utilities/fontUtilities";
import * as tag from "../constants/customMetadataConstants";
import * as portalShared from "../shared/metadataConstantsAndUtilities"


export function setColor(iObj, metadataTag, defaultColor) {

    let color = defaultColor;

    color = tag.metadataValue(iObj, metadataTag, "", color); // Incase of override

    return color;
}


export function setTextColor(iObj, metadataTag, textObj) {

    let color = textObj.colorType === "select" ? textObj.color : textObj.colorEntry;

    color = tag.metadataValue(iObj, metadataTag, "", color); // Incase of override

    return color;
}


export function getColor(answer, selected, colorObj) {

    let color = colorObj.unselectedColor;

    if (answer.id === selected.id) {
        color = colorObj.selectedColor;
    }

    return color;
}


export function getOutlinedOverrides(question, textObj) {

    let outlinedOverrides = {

        textObj: textObj.fontObj,

        height: tag.metadataValue(question, portalShared.BUTTON_HEIGHT, "", portalShared.DEFAULT_BUTTON_HEIGHT),

        widthOverride: tag.metadataValue(question, portalShared.OVERRIDE_WIDTH, "", "250px"),

        unselectedTextColor: tag.metadataValue(question, portalShared.UNSELECTED_COLOR_TEXT, "", textObj.fontObj.color),
        unselectedBorderColor: tag.metadataValue(question, portalShared.UNSELECTED_COLOR_BORDER, "", portalShared.DEFAULT_UNSELECTED_BUTTON_BORDER_COLOR),
        unselectedBackgroundColor: tag.metadataValue(question, portalShared.UNSELECTED_COLOR_BACKGROUND, "", portalShared.DEFAULT_WHITE),

        selectedTextColor: tag.metadataValue(question, portalShared.SELECTED_COLOR_TEXT, "", portalShared.DEFAULT_SELECTED_BUTTON_TEXT_COLOR),
        selectedBorderColor: tag.metadataValue(question, portalShared.SELECTED_COLOR_BORDER, "", portalShared.DEFAULT_SELECTED_BUTTON_BORDER_COLOR),
        selectedBackgroundColor: tag.metadataValue(question, portalShared.SELECTED_COLOR_BACKGROUND, "", portalShared.DEFAULT_SELECTED_BUTTON_BACKGROUND_COLOR),

        borderWidth: parseInt(tag.metadataValue(question, portalShared.BUTTON_BORDER_WIDTH, "", "1px")),
        borderRadius: parseInt(tag.metadataValue(question, portalShared.BUTTON_BORDER_RADIUS, "", "10px")),
    }

    outlinedOverrides.hoverTextColor = tag.metadataValue(question, portalShared.HOVER_COLOR_TEXT, "", outlinedOverrides.unselectedTextColor);
    outlinedOverrides.hoverBorderColor = tag.metadataValue(question, portalShared.HOVER_COLOR_BORDER, "", outlinedOverrides.unselectedBorderColor);
    outlinedOverrides.hoverBackgroundColor = tag.metadataValue(question, portalShared.HOVER_COLOR_BACKGROUND, "", outlinedOverrides.unselectedBackgroundColor);

    outlinedOverrides.hoverSelectedTextColor = tag.metadataValue(question, portalShared.HOVER_SELECTED_COLOR_TEXT, "", outlinedOverrides.selectedTextColor);
    outlinedOverrides.hoverSelectedBorderColor = tag.metadataValue(question, portalShared.HOVER_SELECTED_COLOR_BORDER, "", outlinedOverrides.selectedBorderColor);
    outlinedOverrides.hoverSelectedBackgroundColor = tag.metadataValue(question, portalShared.HOVER_SELECTED_COLOR_BACKGROUND, "", outlinedOverrides.selectedBackgroundColor);

    return outlinedOverrides;
}

export function getStyledFormControlLabel(outlinedOverrides, selected, readonly) {

    return styled(FormControlLabel)({
        root: {
            marginBottom: '10px',
            width: outlinedOverrides.widthOverride,
            border: 'solid',
            borderWidth: outlinedOverrides.borderWidth,
            borderRadius: outlinedOverrides.borderRadius,
            borderColor: selected ? outlinedOverrides.selectedBorderColor : outlinedOverrides.unselectedBorderColor,
            //height: buttonOverrides.height,
            disableRipple: readonly,
            disableFocusRipple: readonly,

            // textTransform: "none",
            // fontFamily: outlinedOverrides.textObj.fontFamily,
            // fontWeight: outlinedOverrides.textObj.fontWeight,
            // fontSize: outlinedOverrides.textObj.fontSize,
            // fontStyle: outlinedOverrides.textObj.fontWeight,
            // color: selected ? outlinedOverrides.selectedTextColor : outlinedOverrides.unselectedTextColor,

            backgroundColor: selected ? outlinedOverrides.selectedBackgroundColor : outlinedOverrides.unselectedBackgroundColor,
            '&:hover': {
                border: 'solid',
                borderWidth: outlinedOverrides.borderWidth,
                borderRadius: outlinedOverrides.borderRadius,
                borderColor: selected ? outlinedOverrides.hoverSelectedBorderColor : outlinedOverrides.hoverBorderColor,
                color: selected ? outlinedOverrides.hoverSelectedTextColor : outlinedOverrides.hoverTextColor,
                backgroundColor: selected ? outlinedOverrides.hoverSelectedBackgroundColor : outlinedOverrides.hoverBackgroundColor,
            },

        }
    });

}


export function getOutlinedRadioStyle(outlinedMode, answer, savedObjs) {

    let formStyle = {}; // If not outlined mode then standard radios need no extra styling

    if (outlinedMode) {

        let borderColorObj = {
            unselectedColor: portalShared.DEFAULT_UNSELECTED_OUTLINED_RADIO_BORDER_COLOR,
            selectedColor: portalShared.DEFAULT_SELECTED_OUTLINED_RADIO_BORDER_COLOR
        }

        let bgColorObj = {
            unselectedColor: portalShared.DEFAULT_UNSELECTED_OUTLINED_RADIO_BACKGROUND_COLOR,
            selectedColor: portalShared.DEFAULT_SELECTED_OUTLINED_RADIO_BACKGROUND_COLOR
        }

        borderColorObj.unselectedColor = setColor(savedObjs.question, portalShared.UNSELECTED_COLOR_BORDER, borderColorObj.unselectedColor);
        borderColorObj.selectedColor = setColor(savedObjs.question, portalShared.SELECTED_COLOR_BORDER, borderColorObj.selectedColor);

        bgColorObj.unselectedColor = setColor(savedObjs.question, portalShared.UNSELECTED_COLOR_BACKGROUND, bgColorObj.unselectedColor);
        bgColorObj.selectedColor = setColor(savedObjs.question, portalShared.SELECTED_COLOR_BACKGROUND, bgColorObj.selectedColor);

        formStyle = {
            width: tag.metadataValue(savedObjs.question, portalShared.OVERRIDE_WIDTH, "", portalShared.DEFAULT_WIDTH),
            border: 'solid',
            borderRadius: '10px',
            borderWidth: '1px',
            borderColor: getColor(answer, savedObjs.selectedAnswer, borderColorObj),
            backgroundColor: getColor(answer, savedObjs.selectedAnswer, bgColorObj),
            marginBottom: '10px'
        };
    }

    return formStyle;
}

// ---------------------------------------------------------------------------------------------------------------------
// Get Custom Radio/Outlined Radio Style
// ---------------------------------------------------------------------------------------------------------------------
export function getRadioStyle(savedObjs, standardRadio = false) {

    let defaultUnselectedControlColor = standardRadio ? portalShared.DEFAULT_UNSELECTED_RADIO_CONTROL_COLOR : portalShared.DEFAULT_UNSELECTED_OUTLINED_RADIO_CONTROL_COLOR;
    let defaultSelectedControlColor = standardRadio ? portalShared.DEFAULT_SELECTED_RADIO_CONTROL_COLOR : portalShared.DEFAULT_SELECTED_OUTLINED_RADIO_CONTROL_COLOR;

    let unselectedColorControl = tag.metadataValue(savedObjs.activeTemplate, portalShared.UNSELECTED_COLOR_CONTROL, "", defaultUnselectedControlColor);

    let selectedColorControl = tag.metadataValue(savedObjs.activeTemplate, portalShared.SELECTED_COLOR_CONTROL, "", defaultSelectedControlColor);

    let hoverColorControl = tag.metadataValue(savedObjs.activeTemplate, portalShared.HOVER_COLOR_CONTROL, "", portalShared.DEFAULT_HOVER_RADIO_CONTROL_COLOR);

    const StyledRadio = styled(Radio)({
        color: unselectedColorControl,
        disableRipple: savedObjs.readonlyMode,
        disableFocusRipple: savedObjs.readonlyMode,
        disableTouchRipple: savedObjs.readonlyMode,
        '&.Mui-checked': {
            color: selectedColorControl,
            disableRipple: savedObjs.readonlyMode,
            disableFocusRipple: savedObjs.readonlyMode,
            disableTouchRipple: savedObjs.readonlyMode,
        },
        '&:hover': {
            color: hoverColorControl,  // Control color
            //border: 'solid',
            //borderColor: 'red', // touch border color
            //background: "green", // touch interior color
        }
    });

    return StyledRadio;
}


// ---------------------------------------------------------------------------------------------------------------------
// Get Custom DDLB Style
// ---------------------------------------------------------------------------------------------------------------------
// export function getDdlbStyle(savedObjs) {
//
//     let selectedColorControl = tag.metadataValue(savedObjs.activeTemplate, portalShared.SELECTED_COLOR_CONTROL, "" , portalShared.DEFAULT_SELECTED_COLOR);
//     let unselectedColorControl = tag.metadataValue(savedObjs.activeTemplate, portalShared.UNSELECTED_COLOR_CONTROL, "" , portalShared.DEFAULT_UNSELECTED_COLOR);
//
//     const StyledRadio = withStyles({
//         root: {
//             color: unselectedColorControl,
//             disableRipple: savedObjs.readonlyMode,
//             disableFocusRipple: savedObjs.readonlyMode,
//             disableTouchRipple: savedObjs.readonlyMode,
//             '&$checked': {
//                 color: selectedColorControl,
//                 disableRipple: savedObjs.readonlyMode,
//                 disableFocusRipple: savedObjs.readonlyMode,
//                 disableTouchRipple: savedObjs.readonlyMode,
//             },
//         },
//         checked: {},
//     })(Radio);
//
//     return StyledRadio;
// }


// ---------------------------------------------------------------------------------------------------------------------
// Get Custom Button
// ---------------------------------------------------------------------------------------------------------------------

// let buttonOverrides = {
//     customTemplateObj: tag.metadataValue(savedObjs.question, portalShared.CUSTOM_DDO_QUE_FORMAT),

//     unselectedTextColor: tag.metadataValue(savedObjs.question, portalShared.UNSELECTED_COLOR_TEXT, "", portalShared.DEFAULT_UNSELECTED_BUTTON_TEXT_COLOR),
//     unselectedBorderColor: tag.metadataValue(savedObjs.question, portalShared.UNSELECTED_COLOR_BORDER, "", portalShared.DEFAULT_UNSELECTED_BUTTON_BORDER_COLOR),
//     unselectedBackgroundColor: tag.metadataValue(savedObjs.question, portalShared.UNSELECTED_COLOR_BACKGROUND, "", portalShared.DEFAULT_UNSELECTED_BUTTON_BACKGROUND_COLOR),

//     selectedTextColor: tag.metadataValue(savedObjs.question, portalShared.SELECTED_COLOR_TEXT, "", taportalSharedg.DEFAULT_SELECTED_BUTTON_TEXT_COLOR),
//     selectedBorderColor: tag.metadataValue(savedObjs.question, portalShared.SELECTED_COLOR_BORDER, "", portalShared.DEFAULT_SELECTED_BUTTON_BORDER_COLOR),
//     selectedBackgroundColor: tag.metadataValue(savedObjs.question, portalShared.SELECTED_COLOR_BACKGROUND, "", portalShared.DEFAULT_SELECTED_BUTTON_BACKGROUND_COLOR),

//     hoverTextColor: tag.metadataValue(savedObjs.question, portalShared.HOVER_COLOR_TEXT, "", portalShared.DEFAULT_HOVER_BUTTON_TEXT_COLOR),
//     hoverBorderColor: tag.metadataValue(savedObjs.question, portalShared.HOVER_COLOR_BORDER, "", portalShared.DEFAULT_HOVER_BUTTON_BORDER_COLOR),
//     hoverBackgroundColor: tag.metadataValue(savedObjs.question, portalShared.SELECTED_COLOR_BACKGROUND, "", portalShared.DEFAULT_HOVER_BUTTON_BACKGROUND_COLOR),

//     borderWidth: tag.metadataValue(savedObjs.question, portalShared.SELECTED_COLOR_BACKGROUND, "", portalShared.DEFAULT_BUTTON_BORDER_WIDTH),
//     borderRadius: tag.metadataValue(savedObjs.question, portalShared.SELECTED_COLOR_BACKGROUND, "", portalShared.DEFAULT_BUTTON_BORDER_RADIUS),
// }

export function getButtonStyle(buttonType, savedObjs, buttonOverrides, selected) {

    //let outlinedButton = (buttonType === ssType.SINGLE_SELECT_CLEAR_BUTTON);

    let style = styled(Button)({
        width: buttonOverrides.widthOverride,
        border: 'solid',
        borderWidth: buttonOverrides.borderWidth,
        borderRadius: buttonOverrides.borderRadius,
        borderColor: selected ? buttonOverrides.selectedBorderColor : buttonOverrides.unselectedBorderColor,
        boxShadow: buttonOverrides.boxShadowOverride,
        height: buttonOverrides.height,
        padding: '0px',
        disableRipple: savedObjs.readonlyMode,
        disableFocusRipple: savedObjs.readonlyMode,
        fullWidth: true,
        opacity: "100%",
        textTransform: "none",
        fontFamily: buttonOverrides.textObj.fontFamily,
        fontWeight: "normal",
        fontSize: buttonOverrides.textObj.fontSize,  // 2em, 18pt
        fontStyle: "normal",
        color: selected ? buttonOverrides.selectedTextColor : buttonOverrides.unselectedTextColor,
        backgroundColor: selected ? buttonOverrides.selectedBackgroundColor : buttonOverrides.unselectedBackgroundColor,
        '&:hover': {
            border: 'solid',
            borderWidth: buttonOverrides.borderWidth,
            borderRadius: buttonOverrides.borderRadius,
            borderColor: selected ? buttonOverrides.hoverSelectedBorderColor : buttonOverrides.hoverBorderColor,
            fontWeight: "bold",
            color: selected ? buttonOverrides.hoverSelectedTextColor : buttonOverrides.hoverTextColor,
            backgroundColor: selected ? buttonOverrides.hoverSelectedBackgroundColor : buttonOverrides.hoverBackgroundColor,
            // '& .MuiButton-label': {
            //     color: "#fff" NOT TESTED
            //     }
        }
    });

    return style;
}

// export function getCheckboxStyle(buttonType, savedObjs, buttonOverrides, selected) {
//
//     //let outlinedButton = (buttonType === ssType.SINGLE_SELECT_CLEAR_BUTTON);
//
//     let style = withStyles({
//         root: {
//             width: buttonOverrides.widthOverride,
//             border: 'solid',
//             borderWidth: buttonOverrides.borderWidth,
//             borderRadius: buttonOverrides.borderRadius,
//             borderColor: selected ? buttonOverrides.selectedBorderColor : buttonOverrides.unselectedBorderColor,
//             boxShadow: buttonOverrides.boxShadowOverride,
//             height: buttonOverrides.height,
//             padding: '0px',
//             disableRipple: savedObjs.readonlyMode,
//             disableFocusRipple: savedObjs.readonlyMode,
//             fullWidth: true,
//             opacity: "100%",
//             textTransform: "none",
//             fontFamily: buttonOverrides.textObj.fontFamily,
//             fontWeight: "normal",
//             fontSize: buttonOverrides.textObj.fontSize,  // 2em, 18pt
//             fontStyle: "normal",
//             color: selected ? buttonOverrides.selectedTextColor : buttonOverrides.unselectedTextColor,
//             backgroundColor: selected ? buttonOverrides.selectedBackgroundColor : buttonOverrides.unselectedBackgroundColor,
//             '&:hover': {
//                 border: 'solid',
//                 borderWidth: buttonOverrides.borderWidth,
//                 borderRadius: buttonOverrides.borderRadius,
//                 borderColor: selected ? buttonOverrides.hoverSelectedBorderColor : buttonOverrides.hoverBorderColor,
//                 fontWeight: "bold",
//                 color: selected ? buttonOverrides.hoverSelectedTextColor : buttonOverrides.hoverTextColor,
//                 backgroundColor: selected ? buttonOverrides.hoverSelectedBackgroundColor : buttonOverrides.hoverBackgroundColor,
//                 // '& .MuiButton-label': {
//                 //     color: "#fff" NOT TESTED
//                 //     }
//             },
//
//         }
//     })(Checkbox);
//
//     return style;
// }

// ---------------------------------------------------------------------------------------------------------------------
// Get Custom Title Topography
// ---------------------------------------------------------------------------------------------------------------------
// export function getTitleLabelTextStyle(activeTemplate, surveyEditMode) {
//
//     let surveyEditModeFlag = (surveyEditMode === portalShared.READONLY_SURVEY)
//
//     let selectedColorControl = tag.metadataValue(activeTemplate, portalShared.SELECTED_COLOR_CONTROL, "" , "white");
//     let unselectedColorControl = tag.metadataValue(activeTemplate, portalShared.UNSELECTED_COLOR_CONTROL, "" , "grey");
//
//     const StyledRadio = withStyles({
//         root: {
//             color: unselectedColorControl,
//             disableRipple: surveyEditModeFlag,
//             disableFocusRipple: surveyEditModeFlag,
//             disableTouchRipple: surveyEditModeFlag,
//             '&$checked': {
//                 color: selectedColorControl,
//                 disableRipple: surveyEditModeFlag,
//                 disableFocusRipple: surveyEditModeFlag,
//                 disableTouchRipple: surveyEditModeFlag,
//             },
//         },
//         checked: {},
//     })(Radio);
//
//     return StyledRadio;
// }


// ---------------------------------------------------------------------------------------------------------------------
// Get Custom Title Topography
// ---------------------------------------------------------------------------------------------------------------------
// export function getQuestionTextStyle(activeTemplate, surveyEditMode) {
//
//     let surveyEditModeFlag = (surveyEditMode === portalShared.READONLY_SURVEY)
//
//     let selectedColorControl = tag.metadataValue(activeTemplate, portalShared.SELECTED_COLOR_CONTROL, "" , "white");
//     let unselectedColorControl = tag.metadataValue(activeTemplate, portalShared.UNSELECTED_COLOR_CONTROL, "" , "grey");
//
//     const StyledRadio = withStyles({
//         root: {
//             color: unselectedColorControl,
//             disableRipple: surveyEditModeFlag,
//             disableFocusRipple: surveyEditModeFlag,
//             disableTouchRipple: surveyEditModeFlag,
//             '&$checked': {
//                 color: selectedColorControl,
//                 disableRipple: surveyEditModeFlag,
//                 disableFocusRipple: surveyEditModeFlag,
//                 disableTouchRipple: surveyEditModeFlag,
//             },
//         },
//         checked: {},
//     })(Radio);
//
//     return StyledRadio;
// }

// ---------------------------------------------------------------------------------------------------------------------
// Get Custom Title Topography
// ---------------------------------------------------------------------------------------------------------------------
// export function getCustomTitleTypography(iObj, metadataTag, alignPosition) {
//
//     let tempCustomTypography = null;
//     let useCustom = false;
//     let customTextObj = "";
//
//     if (tag.metadataExists(iObj, metadataTag)) {
//         customTextObj = tag.metadataValue(iObj, metadataTag);
//
//         if (typeof customObj !== "string") {
//             useCustom = true;
//             tempCustomTypography = getCustomTextStyle(customTextObj, "", alignPosition);
//         }
//     }
//
//     return {"customTypography": tempCustomTypography, "useCustom": useCustom, "name": customTextObj.title, customTextObj: customTextObj};
// }

// ---------------------------------------------------------------------------------------------------------------------
// Get Custom Responsive label Topography
// ---------------------------------------------------------------------------------------------------------------------
// export function getResponsiveLabelTextStyle(activeTemplate, surveyEditMode) {
//
//     let surveyEditModeFlag = (surveyEditMode === portalShared.READONLY_SURVEY)
//
//     let selectedColorControl = tag.metadataValue(activeTemplate, portalShared.SELECTED_COLOR_CONTROL, "" , "white");
//     let unselectedColorControl = tag.metadataValue(activeTemplate, portalShared.UNSELECTED_COLOR_CONTROL, "" , "grey");
//
//     const StyledRadio = withStyles({
//         root: {
//             color: unselectedColorControl,
//             disableRipple: surveyEditModeFlag,
//             disableFocusRipple: surveyEditModeFlag,
//             disableTouchRipple: surveyEditModeFlag,
//             '&$checked': {
//                 color: selectedColorControl,
//                 disableRipple: surveyEditModeFlag,
//                 disableFocusRipple: surveyEditModeFlag,
//                 disableTouchRipple: surveyEditModeFlag,
//             },
//         },
//         checked: {},
//     })(Radio);
//
//     return StyledRadio;
// }


// ---------------------------------------------------------------------------------------------------------------------
// Default Totography Display
// ---------------------------------------------------------------------------------------------------------------------
export function getCustomTextStyle(customTextObj, hoverColor = "") {

    let tempfontSize = "12pt"
    let includesFontSize = false;
    if (customTextObj?.fontSize) includesFontSize = customTextObj?.fontSize.includes("pt")
    if(customTextObj?.fontSize)
    if ((customTextObj?.fontSize !== "caption") && includesFontSize) {
        tempfontSize = customTextObj?.fontSize;
    }
    else {
        tempfontSize = font.convertVariantToPointsStr(customTextObj?.fontSize);
    }

    let tempCustomTypography = null;

    let tempObj = null;

    tempObj = customTextObj;
    tempCustomTypography = styled(Typography)({
        //float: alignPosition,
        //paddingLeft: tempObj.indent !== "" ? tempObj.indent : "0px",
        fontFamily: tempObj?.fontFamily ?? font.DEFAULT_FONT_FAMILY,
        fontWeight: tempObj?.fontWeight ?? "normal",
        color:tempObj?.colorObj?.colorType === "select" ? tempObj?.colorObj?.color : tempObj?.colorObj?.colorEntry ?? "black",
        fontSize: tempObj?.fontSize ?? tempfontSize,  // 2em, 18pt
        fontStyle: tempObj?.fontStyle ??"normal",
        textAlign: tempObj?.textAlign ?? "left",  // left center right
        opacity: tempObj?.colorObj?.opacity ?? "100%",
        // marginBottom: likertIndex !== 0 && rightAndAlignedWithQuestions ? '20px' : '0px'

        "&:hover": {
            color: hoverColor,  // Define a hover color, or fallback to blue
            // cursor: "text"
        }
    });

    return tempCustomTypography;
}



