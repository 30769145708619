import TagApi from "../api/TagApi";
import * as types from "../constants/actionConstants";
import {closeDrawer} from "./drawerActions";
import {initPageAndSortAndFilterThenGetList} from "./pageAndSortActions";
import * as TEMPLATE from "../constants/templateConstants";


export function getTag(id) {
    return (dispatch) => {

        dispatch({type: types.TAG_GET_INIT, id});

        TagApi.getTagById(id).then((tag) => {
            dispatch({type: types.TAG_GET_SUCCESS, tag});
        });
    };
}

export function createTagAndCloseDrawer(tag, filter) {
    return (dispatch) => {
        TagApi.createTag(tag).then((tag) => {
            dispatch({type: types.TAG_CREATE_SUCCESS, tag});
            dispatch(closeDrawer());
            dispatch(initPageAndSortAndFilterThenGetTagList(filter));
        });
    };
}

export function saveTagAndCloseDrawer(tag, filter, onSuccess = ()=>{}) {
    return (dispatch) => {
        TagApi.updateTag(tag).then((tag) => {
            dispatch({type: types.TAG_UPDATE_SUCCESS, tag});
            onSuccess();
            dispatch(closeDrawer());
            dispatch(initPageAndSortAndFilterThenGetTagList(filter));
        });
    };
}

export function initPageAndSortAndFilterThenGetTagList(filter) {
    return (dispatch) => {
        dispatch(initPageAndSortAndFilterThenGetList("tags", filter, getTagList));
    };
}


export function getTagList(pageable, filter) {
    return (dispatch) => {
        TagApi.getTagList(pageable, filter).then((data) => {
            dispatch({
                type: types.TAG_LIST_GET_SUCCESS,
                list: data.collection,
                totalRecords: data.page.totalElements
            });
        });
    };
}


export function getTagDDLB(pageable, filter) {
    return (dispatch) => {
        TagApi.getTagList(pageable, filter).then((data) => {
            dispatch({
                type: types.TAG_DDLB_GET_SUCCESS,
                list: data.collection,
                totalRecords: data.page.totalElements,
                filterName: TEMPLATE.FILTER[TEMPLATE.MODE.ALL][TEMPLATE.TYPE.SELECT_EXISTING_TAGS]
            });
        });
    };
}
