import React, {useContext} from 'react';
import Button from "@mui/material/Button";
import UserContext from "../../UserContext"


export default function ArgoButtonHook(props) {

    const theme = useContext(UserContext); // From blueTheme.js

    const fileName = props.fileName ?? "NoFileName";
    const fieldName = props.fieldName ?? "NoFieldName";

    const uniqueIdentifier = fileName + "-ArgoButtonHook-" + fieldName;

    const canHaveErrorMessage = props.canHaveErrorMessage ?? false;
    const errorMessage = props.errorMessage ?? "";

    const variant = props.variant ?? theme.button.variant.outlined;
    const toolbar = props.toolbar ?? false;  // Toolbars have a gray background and need wome special color tweaking

    const btnTheme = toolbar ? theme.toolbarButton : theme.button;

    const label = props.label ?? "Label";

    const paddingTop = props.paddingTop ?? "0px";

    const width = props.width ?? "160px";

    const fullWidth = props.fullWidth === undefined ? ((width === 0)) : false;

    const disabled = props.disabled ?? false ;

    const autofocus = props.autofocus ?? false;

    const onClick = props.onClick ?? function () {};

    const buttonPadding = props.buttonPadding ?? undefined;

    // button variant = outlined ----------------------------------------------------------------------------------------------------------------

    const outlinedTextColor = props.outlinedTextColor ?? btnTheme.outlined.normal.textColor;
    const outlinedBgColor = props.outlinedBgColor ?? btnTheme.outlined.normal.bgColor;

    const outlinedDisabledTextColor = props.outlinedDisabledTextColor ?? btnTheme.outlined.disabled.textColor;
    const outlinedDisabledBgColor = props.outlinedDisabledBgColor ?? btnTheme.outlined.disabled.bgColor;

    const outlinedHoverTextColor = props.outlinedHoverTextColor ?? btnTheme.outlined.hover.textColor;
    const outlinedHoverBgColor = props.outlinedHoverBgColor ?? btnTheme.outlined.hover.bgColor;

    // button variant = text        ----------------------------------------------------------------------------------------------------------------

    const textTextColor = props.textTextColor ?? btnTheme.text.normal.textColor;
    const textBgColor = props.textBgColor ?? btnTheme.text.normal.bgColor;

    const textDisabledTextColor = props.textDisabledTextColor ?? btnTheme.text.disabled.textColor;
    const textDisabledBgColor = props.textDisabledBgColor ?? btnTheme.text.disabled.bgColor;

    const textHoverTextColor = props.textHoverTextColor ?? btnTheme.text.hover.textColor;
    const textdHoverBgColor = props.textdHoverBgColor ?? btnTheme.text.hover.bgColor;

    // button variant = contained ----------------------------------------------------------------------------------------------------------------

    const containedTextColor = props.containedTextColor ?? btnTheme.contained.normal.textColor;
    const containedBgColor = props.containedBgColor ?? btnTheme.contained.normal.bgColor;

    const containedDisabledTextColor = props.containedDisabledTextColor ?? btnTheme.contained.disabled.textColor;
    const containedDisabledBgColor = props.containedDisabledBgColor ?? btnTheme.contained.disabled.bgColor;

    const containedHoverTextColor = props.containedHoverTextColor ?? btnTheme.contained.hover.textColor;
    const containedHoverBgColor = props.containedHoverBgColor ?? btnTheme.contained.hover.bgColor;


    const getColor = (mode, type) => {
        let color = "black";

        switch (variant) {
            case btnTheme.variant.outlined:
                switch (mode) {
                    case "normal":
                        switch (type) {
                            case "textColor":
                                color = outlinedTextColor;
                                break;
                            case "bgColor":
                                color = outlinedBgColor;
                                break;
                            // no default
                        }
                        break;
                    case "disabled":
                        switch (type) {
                            case "textColor":
                                color = outlinedDisabledTextColor;
                                break;
                            case "bgColor":
                                color = outlinedDisabledBgColor;
                                break;
                            // no default
                        }
                        break;
                    case "hover":
                        switch (type) {
                            case "textColor":
                                color = outlinedHoverTextColor;
                                break;
                            case "bgColor":
                                color = outlinedHoverBgColor;
                                break;
                            // no default
                        }
                    break;
                    // no default
                }
                break;
            case btnTheme.variant.text:
                switch (mode) {
                    case "normal":
                        switch (type) {
                            case "textColor":
                                color = textTextColor;
                                break;
                            case "bgColor":
                                color = textBgColor;
                                break;
                            // no default
                        }
                        break;
                    case "disabled":
                        switch (type) {
                            case "textColor":
                                color = textDisabledTextColor;
                                break;
                            case "bgColor":
                                color = textDisabledBgColor;
                                break;
                            // no default
                        }
                        break;
                    case "hover":
                        switch (type) {
                            case "textColor":
                                color = textHoverTextColor;
                                break;
                            case "bgColor":
                                color = textdHoverBgColor;
                                break;
                            // no default
                        }
                        break;
                    // no default
                }
                break;
            case btnTheme.variant.contained:
                switch (mode) {
                    case "normal":
                        switch (type) {
                            case "textColor":
                                color = containedTextColor;
                                break;
                            case "bgColor":
                                color = containedBgColor;
                                break;
                            // no default
                        }
                        break;
                    case "disabled":
                        switch (type) {
                            case "textColor":
                                color = containedDisabledTextColor;
                                break;
                            case "bgColor":
                                color = containedDisabledBgColor;
                                break;
                            // no default
                        }
                        break;
                    case "hover":
                        switch (type) {
                            case "textColor":
                                color = containedHoverTextColor;
                                break;
                            case "bgColor":
                                color = containedHoverBgColor;
                                break;
                            // no default
                        }
                        break;
                    // no default
                }
                break;
            // no default
        }

        return color;
    }


    const handleOnClick = (event) => {
        onClick(event, props.fieldName)
    };

    return (
        <div style={{paddingTop: paddingTop}}>
            <Button id={uniqueIdentifier}
                    name={uniqueIdentifier}
                    variant={variant}
                    sx={{
                        color: getColor(disabled ? "disabled" : "normal", "textColor", label),
                        backgroundColor: getColor(disabled ? "disabled" : "normal", "bgColor", label),
                        fontFamily: theme.fontFamily,
                        width: width,
                        "&:hover": {
                            color: getColor("hover", "textColor", label),
                            backgroundColor: getColor("hover", "bgColor", label),
                        },
                        padding: buttonPadding
                    }}
                    onClick={handleOnClick}
                    disabled={disabled}
                    fullWidth={fullWidth}
                    autoFocus={autofocus}
            >{label}</Button>
            <div style={{paddingLeft: "15px", display: canHaveErrorMessage ? "inline" : "none", color: theme.palette.errorMessage}}>{errorMessage}</div>
        </div>
    );
}
