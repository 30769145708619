import * as types from "../constants/actionConstants";

const initialState = {};

function actionTemplateReducer(state = initialState, action) {
    switch (action.type) {
        case types.ACTION_TEMPLATE_GET_SUCCESS:
        case types.ACTION_TEMPLATE_CREATE_SUCCESS:
        case types.ACTION_TEMPLATE_UPDATE_SUCCESS:
            return {
                ...state,
                [action.actionTemplate.templateKey]: {
                    ...action.actionTemplate
                }
            };
        case types.ACTION_TEMPLATE_CLEAR:
            return {
                ...initialState
            };

        default:
            return state;
    }
}

export default actionTemplateReducer;
