import * as types from "../constants/actionConstants";


const initialState = {
    callbacks: []
};

export default function reviewTemplateReducer(state = initialState, action) {
    switch (action.type) {
        case types.REVIEW_TEMPLATE_CALLBACK_PUSH:
            let pushCallbacks = [...state.callbacks];
            pushCallbacks.push(action.payload);
            return {
                ...state,
                callbacks: pushCallbacks
            };

        case types.REVIEW_TEMPLATE_CALLBACK_POP:
            let popCallbacks = [...state.callbacks]
            popCallbacks.pop()
            return {
                ...state,
                callbacks: popCallbacks
            };

        case types.REVIEW_TEMPLATE_CALLBACK_CLEAR:
            return {
                ...state,
                callbacks: []
            }

        default:
            return state;
    }
}