import React from 'react';
import ArgoIconButtonHook from "./ArgoIconButtonHook";
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';


export default function ArgoSectionOpenCloseHook(props) {

    const fileName = props.fileName ?? "file-name";
    const fieldName = props.fieldName ?? "field-name";
    const index = props.index ?? ""; // If field is built in a loop index is required to know which field to match up with

    const uniqueIdentifier = fileName + "-section-open-close-" + fieldName + index;

    const sectionTitle = props.sectionTitle ?? "";

    const onClick = props.onClick ?? function () {};

    const open = props.open ?? false;

    const display = props.display === undefined ? "inline" : (props.display ? "inline" : "none");

    const handleOnClick = (event) => {
        onClick(event, fieldName, index);
    };

    return (
        <div id={uniqueIdentifier} style={{display: display}}>

            <div id={"argo-section-open-close-hook-div" + index} style={{display: "flex", flexDirection: "row", alignItems: "center", width: "100%"}}>

                <div id={"argo-section-open-close-hook-expand-icon-div" + index} style={{justifyContent: "flex-start"}}>
                    <ArgoIconButtonHook
                        fileName="argo-section-open-close-hook"
                        fieldName="expand-icon"
                        onClick={handleOnClick}
                        index={index}
                        icon={open ? <ExpandLessOutlinedIcon/> : <ExpandMoreOutlinedIcon/>}
                    />
                </div>

                <div id={"argo-section-open-close-hook-title-div" + index} style={{justifyContent: "flex-start", width: "200px"}}><b>{sectionTitle}</b></div>

            </div>

        </div>
    );
}

