import * as types from "../constants/actionConstants";
import TagApi from "../api/TagApi";
import {pageEmpty, selectSortDefault} from "../constants/filterConstants";


const initializeTagEditorCreate = (template = {}) => {
    return {
        type: types.TAG_EDITOR_INITIALIZE,
        payload: {
            template: {
                id: "",
                name: (template.name)? template.name : "",
                trackable: false
            },
            questionKeys: [],
            options: {
                createMode: true,
                validate: [],
                isValidating: false
            }
        }
    };
};

const initializeTagEditorEdit = (template) => {
    return {
        type: types.TAG_EDITOR_INITIALIZE,
        payload: {
            template: template,
            questionKeys: template.questionKeys || [],
            options: {
                hasScript: !!template.script,
                hasDescription: !!template.description,
                createMode: false,
                validate: [],
                isValidating: false
            }
        }
    };
};

const updateTagTemplate = (template, options) => {
    return {
        type: types.TAG_EDITOR_UPDATE,
        payload:{
            template,
            options
        }
    };
};

const clearTagEditor = () => {
    return {
        type: types.TAG_EDITOR_CLEAR
    };
};

const checkValue = (filter) => {
    return (dispatch) => {
        dispatch({type: types.TAG_EDITOR_VALUE_VALIDATING});
        dispatch(getTagList(filter))
    };
};

const checkTagExists = (name) => {
    let thunk = (dispatch) => {
        TagApi.checkTagExists(name).then((data) => {
            dispatch({type: types.TAG_EDITOR_VALUE_EXISTS, list: data.collection});
        });
    };

    thunk.meta = {
        debounce: {
            time: 1000,
            key: "__tagExistsCheck",
            leading: false,
            trailing: true
        }
    };

    return thunk;
};

const getTagList = (filter) => {
    let thunk = (dispatch) => {
        TagApi.getTagList({...pageEmpty, ...selectSortDefault}, filter).then((data) => {
            dispatch({type: types.TAG_EDITOR_VALUE_EXISTS, list: data.collection});
        });
    };

    thunk.meta = {
        debounce: {
            time: 1000,
            key: "__tagExistsCheck",
            leading: false,
            trailing: true
        }
    };

    return thunk;
};


// ---------------------------------------------------------------------------------------------------------------------
// Tags can be associated with questions, but no reorder is required
// ---------------------------------------------------------------------------------------------------------------------

const addTagQuestion = (question) => {
    return {
        type: types.TAG_EDITOR_ADD_QUESTION,
        question
    };
};

const removeTagQuestion = (index) => {
    return {
        type: types.TAG_EDITOR_REMOVE_QUESTION,
        index
    };
};


export {
    initializeTagEditorCreate,
    initializeTagEditorEdit,
    updateTagTemplate,
    clearTagEditor,
    checkValue,
    checkTagExists,
    addTagQuestion,
    removeTagQuestion,
};
