import React from "react";
import PropTypes from "prop-types";
import Snackbar from "@mui/material/Snackbar";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as notificationActions from "../../actions/notificationActions";

class ArgoAppSnackbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.closeHandler = this.closeHandler.bind(this);
    }

    closeHandler() {
        this.props.notificationActions.notificationClosed();
    }

    render() {

        return (
            <div>
                <Snackbar
                    style={{
                        zIndex: "20",
                        width: "100%",
                        justifyContent: "center",
                        alignContent: "center",
                        maxWidth: "100%",
                    }}
                    bodystyle={{
                        maxWidth: "80%",
                    }}
                    action={"Close"}
                    onActionTouchTap={this.closeHandler}
                    open={this.props.notification.open}
                    message={this.props.notification.message}
                    autoHideDuration={0}
                    onRequestClose={this.closeHandler}
                />
            </div>
        );
    }
}

ArgoAppSnackbar.propTypes = {
    notificationActions: PropTypes.object,
    notification: PropTypes.object
};

function mapDispatchToProps(dispatch) {
    return {
        notificationActions: bindActionCreators(notificationActions, dispatch)
    };
}

function mapStateToProps(state) {
    return {
        notification: state.notification
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ArgoAppSnackbar);
