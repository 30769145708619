import * as types from "../constants/actionConstants";


const initialState = {

};


function integrationReducer(state = initialState, action) {
    switch (action.type) {
        case types.INTEGRATION_GET_SUCCESS:
        case types.INTEGRATION_CREATE_SUCCESS:
        case types.INTEGRATION_UPDATE_SUCCESS:
            return action.integration;

        default:
            return state;
    }
}

export default integrationReducer;