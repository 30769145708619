
import * as types from "../constants/actionConstants";

const initialState = [];

function templateVersionsReducer(state = initialState, action) {
    switch (action.type) {

        case types.TEMPLATE_VERSIONS_LIST_CLEAR:
            return initialState;

        case types.QUESTIONNAIRE_TEMPLATE_LIST_VERSIONS_GET_SUCCESS:
        case types.CATEGORY_TEMPLATE_LIST_VERSIONS_GET_SUCCESS:
        case types.GROUP_TEMPLATE_LIST_VERSIONS_GET_SUCCESS:
        case types.QUESTION_TEMPLATE_LIST_VERSIONS_GET_SUCCESS:
        case types.ACTION_TEMPLATE_LIST_VERSIONS_GET_SUCCESS:
            return action.list;

        default:
            return state;
    }
}

export default templateVersionsReducer;