import * as types from "../constants/actionConstants";

const initialState = {};

function tagReducer(state = initialState, action) {
    switch (action.type) {
        case types.TAG_CREATE_SUCCESS:
        case types.TAG_UPDATE_SUCCESS:
        case types.TAG_GET_SUCCESS:
            return {
                ...state,
                [action.tag.id]: {
                    ...action.tag
                }
            };

        case types.TAG_GET_INIT:
            return {
                ...state,
                [action.id]: null
            };

        default:
            return state;
    }
}

export default tagReducer;
