import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from '@mui/icons-material/Check';
import {getLabel} from "../../constants/questionTypeConstants";


class ArgoScoringEntry extends Component {
    constructor(props) {
        super(props);

        this.state = {
            enterDataMode: false,
            highlightRow: false,
            startValue: "",
            entryValue: ""
        };

        this.InputRef = React.createRef();
    }

    componentDidMount() {

        document.addEventListener("keydown", this.onKeypressEvent, false);

        let value = (this.props.refObj.weight !== null) ? this.props.refObj.weight : (this.props.refObj.score !== null ? this.props.refObj.score : "");

        this.setState({startValue: value, entryValue: value });
    }


    componentDidUpdate(nextProps) {
        if (!nextProps.refObj.disabled) {
            this.InputRef.current.focus();
        }
    }

    componentWillUnmount(){
        document.removeEventListener("keydown", this.onKeypressEvent, false);
    }

    highlightRow = (e) => {
        if (!this.props.editInProcess) {
            if (!this.state.enterDataMode) {
                this.setState({highlightRow: true});
            }
        }
    };


    unHighlightRow = (e) => {
        if (!this.props.editInProcess) {
            this.setState({highlightRow: false});
        }
    };


    enterDataMode = (e) => {
        if (!this.props.editInProcess) {
            this.props.editMode(true, this.props.index);
        }
    };


    cancelEvent = (e) => {
        if (!this.props.disabled) {
            this.setState({entryValue: this.state.startValue });
            this.props.editMode(false, this.props.index, this.props.refObj.weight, this.props.refObj.score, false);
        }
    };


    saveEvent = (e) => {
        if (!this.props.disabled) {
            let weight = null;
            if (this.props.refObj.weight !== null) {
                weight = this.state.entryValue;
            }

            let score = null;
            if (this.props.refObj.score !== null) {
                score = this.state.entryValue;
            }

            this.props.editMode(false, this.props.index, weight, score, (this.state.startValue !== this.state.entryValue));
        }
    };


    inputFocus = (e) => {
        if (!this.props.disabled) {
            this.setState({entryValue: ""});
        }
    };


    handleTextFieldChange = (e, value) => {

        let regexp = /^\d+(?:\.\d{1,2})?$/;

        // Fix for the period being the last character
        let periodFlag = false;

        // Check to see if there is a period entered
        if (value.indexOf(".") !== -1) {
            // If the period was the last entered then value is OK
            if (value.indexOf(".") === (value.length-1)) {
                periodFlag = true;
            }
        }

        let emptyString = false;
        if (value === "") {
            emptyString = true;
        }

        // Test for valid decimal entry
        if (regexp.test(value) || periodFlag || emptyString) {
            this.setState({entryValue: value });
        }
    };


    onKeypressEvent = (e) => {
        if (e.key === "Enter") {
            //e.preventDefault();
            this.saveEvent(e);
        }
        if (e.key === "Escape") {
            //e.preventDefault();
            e.stopPropagation();
            this.cancelEvent(e);
        }
    };


    render() {

        const leftPad = (this.props.refObj.indent * 30) + "px";
        let rightWidth = "58%";
        let leftWidth = "";

        if (this.props.refObj.weight !== null) {
            rightWidth = "58%";
            leftWidth = "40%";
        }

        if (this.props.refObj.score !== null) {
            rightWidth = "78%";
            leftWidth = "20%";
        }

        let hoverText = "";
        switch(this.props.refObj.template) {
            case "questionnaire":
                hoverText = "Questionnaire";
                break;
            case "category":
                hoverText = "Category";
                break;
            case "group":
                hoverText = "Group" + (this.props.refObj.type === "LIKERT" ? " (Likert)" : "");
                break;
            case "question":
                hoverText = "Question (" + getLabel[this.props.refObj.type] + ")";
                break;
            case "answer":
                hoverText = "Answer" + (this.props.refObj.type === "LIKERT" ? " (Likert)" : "");
                break;
            default:
                hoverText = "";
        }

        let name = this.props.refObj.name;
        if (this.props.refObj.name.length > 90) {
            name = this.props.refObj.name.substring(0,90).trim() + "..." ;
        }

        return (

            this.props.refObj.indent < 3 ?

                <div title={hoverText} style={{display: "flex", width: "100%", backgroundColor: this.state.highlightRow ? "white" : "white", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}
                     onMouseEnter={this.highlightRow.bind(this)}
                     onMouseOut={this.unHighlightRow.bind(this)}>
                    <div style={{paddingTop: "14px", paddingLeft: leftPad, width: rightWidth, fontWeight: "bold"}}>{name}</div>
                    <div style={{width: "2%"}}></div>
                    <div style={{width: "20%"}}>{this.props.refObj.template === "questionnaire" ? <div style={{paddingLeft: "34px"}}><b>Weight</b></div> : ""}</div>
                    <div style={{width: "20%"}}>{this.props.refObj.template === "questionnaire" ? <div style={{paddingLeft: "14px"}}><b>Score</b></div> : ""}</div>
                </div>

                 :

                <div title={hoverText} style={{display: "flex", width: "100%", backgroundColor: this.state.highlightRow ? "WhiteSmoke" : "white", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}
                     onClick={this.enterDataMode.bind(this)}
                     onMouseEnter={this.highlightRow.bind(this)}
                     onMouseOut={this.unHighlightRow.bind(this)}>

                    <div style={{paddingTop: "14px", paddingLeft: leftPad, width: rightWidth, fontWeight: this.props.refObj.indent < 3 ? "bold" : "normal"}}>{name}</div>

                    <div style={{width: "2%"}}></div>

                    <div style={{display: "flex", width: leftWidth}}>

                        <form autoComplete="new-password">
                            <div style={{paddingTop: "-9px"}}>
                                <TextField id={"argo-scoring-entry" + this.props.refObj.id}
                                           name={"argo-scoring-entry" + this.props.index}
                                           variant="standard"
                                           style={{...{width: '60px', height: "36px"},...(this.state.disabled?{backgroundColor:"red"}:null)}}
                                           value={this.state.entryValue}
                                           autoComplete="off"

                                           onChange={e=>this.handleTextFieldChange(e,e.target.value)}
                                           disabled={this.props.disabled}
                                           ref={this.InputRef}
                                           onFocus={this.inputFocus}
                                           // onClick={this.inputFocus}
                                           onKeyPress={this.onKeypressEvent}
                                           inputStyle={{ textAlign: "right" }}
                                />
                            </div>
                        </form>

                        {document.activeElement.id === ("argo-scoring-entry" + this.props.refObj.id) && <><IconButton id={"ccv"} name={"ccv"}
                                    onClick={this.cancelEvent.bind(this)}
                                    iconStyle={{
                                        backgroundColor: "WhiteSmoke",
                                        borderRadius: "50%",
                                        display: this.props.disabled ? "none" : "inline"
                                    }}>
                            <CloseIcon style={{color:"red"}} tabIndex={1}/>
                        </IconButton>


                        <IconButton id={"ccv"} name={"ccv"}
                                    onClick={this.saveEvent.bind(this)}
                                    iconStyle={{
                                        backgroundColor: "WhiteSmoke",
                                        borderRadius: "50%",
                                        display: this.props.disabled ? "none" : "inline"
                                    }}>
                            <CheckIcon style={{color:"green"}}
                                   tabIndex={1}/>
                        </IconButton></>}

                    </div>

                </div>
        );
    }
}

ArgoScoringEntry.defaultProps = {
};

ArgoScoringEntry.propTypes = {
    index: PropTypes.number,
    refObj: PropTypes.object,
    editFunction: PropTypes.func,
    disabled: PropTypes.bool,
    editInProcess: PropTypes.bool,
};

function mapStateToProps(state, props) {
    return {};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ArgoScoringEntry);
