import React from 'react';
import * as color from "./colorConstants";
import {
    MdThumbUp,
    MdThumbDown,
    MdMoodBad,
    MdSentimentDissatisfied,
    MdSentimentNeutral,
    MdSentimentSatisfied,
    MdMood,
    MdStarBorder,
    MdStar
} from 'react-icons/md';
import CropDinIcon from '@mui/icons-material/CropDin';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import * as portalShared from "../portal/shared/metadataConstantsAndUtilities";
//import CropSquareIcon from '@mui/icons-material/CropSquare';
//import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

// ICON library https://material.io/resources/icons/?icon=sentiment_dissatisfied&style=baseline

// NOTE: This icon library was added because it has 5 satisfication icons
// ICON library https://react-icons.github.io/react-icons/md.html

// Constants


// import MenuItem from "@mui/material/MenuItem";
// import Select from "@mui/material/Select";
//
// import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

export const IconThumbUp = MdThumbUp;
export const IconThumbDown = MdThumbDown;
//export const IconThumbUpDown = MdThumbsUpDown; // eslint-disable-line no-unused-vars

export const IconStarEmpty = MdStarBorder;
export const IconStarFilled = MdStar;
//export const IconStarFilledHalf = MdStarHalf;

export const IconSentiment1 = MdMoodBad;
export const IconSentiment2 = MdSentimentDissatisfied;
export const IconSentiment3 = MdSentimentNeutral;
export const IconSentiment4 = MdSentimentSatisfied;
export const IconSentiment5 = MdMood;

export const ButtongroupSquare = CropDinIcon;

export const ButtongroupCircle = PanoramaFishEyeIcon;

// Metadata tag constants
export const ICON_POSITION = "icon-position";
export const ICON_TYPE = "icon-type";
export const ICON_COLOR = "icon-color";
export const ICON_COLOR_OBJ = "icon-color-obj";
export const ICON_TITLES = "icon-titles";

// Icons Non-Likert constants
export const POSITION_NO = "No";
export const POSITION_NONE = "None";
export const POSITION_BEFORE = "Before";
export const POSITION_AFTER = "After";
export const POSITION_ONLY = "Only";

export const positions =
    [
        POSITION_NONE,
        POSITION_BEFORE,
        POSITION_AFTER,
        POSITION_ONLY
    ];

// =====================================================================================================================
// Icon Likert Answers ------------------------------------------------------------------------------------------------- ICON

export const ICON_NO = "No";
export const ICON_YES = "Yes";

export const likertIcons =
    [
        {value: "0", text: ICON_NO},
        {value: "1", text: ICON_YES},
    ];


// =====================================================================================================================
// Icon Likert Modes --------------------------------------------------------------------------------------------------- MODE

// export function getIconPositionText(iconType, positionValue) {
//
//     let positionText = "";
//
//     // switch(iconType) {
//     //     case "":
//     //
//     // }
//
//     return positionText;
// }

// Position constants LIKERT (LEGACY) (No Longer Used)
export const POSITION_ICON = "Icon";
export const POSITION_ICON_NO_TITLE = "Icon (No Title)";
export const POSITION_ICON_FILL = "Icon Fill";
export const POSITION_ICON_FILL_NO_TITLE = "Icon Fill (No Title)";

export const likertModesTitlesAndPositions =
    [
        {value: "0", text: POSITION_ICON},
        {value: "1", text: POSITION_ICON_NO_TITLE},
        {value: "2", text: POSITION_ICON_FILL},
        {value: "3", text: POSITION_ICON_FILL_NO_TITLE},
    ];

export const LIKERT_ICON_MODE_NO_FILL = "No Fill"; // Stars and Faces
export const LIKERT_ICON_MODE_FILL = "Fill";

export const LIKERT_ICON_MODE_DEFAULT = "Default";  // Circles 11
//export const LIKERT_ICON_MODE_COLORS = "Multi-colored Standard"; // Circles 11
//export const LIKERT_ICON_MODE_COLORS_ALT = "Multi-colored Alternate"; // Circles 11


export const likertModesStarsAndFaces =
    [
        {value: "0", text: LIKERT_ICON_MODE_NO_FILL},
        {value: "1", text: LIKERT_ICON_MODE_FILL},
    ];

export const likertModesCircles11 =
    [
        {value: "0", text: LIKERT_ICON_MODE_DEFAULT},
        //{value: "1", text: LIKERT_ICON_MODE_COLORS},
        //{id: 2, name: LIKERT_ICON_MODE_COLORS_ALT},
    ];

export const likertModesOther =
    [
        {value: "0", text: LIKERT_ICON_MODE_DEFAULT},
    ];


// =====================================================================================================================
// Answer Text as titles above icons------------------------------------------------------------------------------------ ANSWER TEXT DISPLAY

export const LIKERT_TITLES_ALL = "As title above";  // Radios, Thumbs, Stars and Faces
export const LIKERT_TITLES_LR = "As title above (LR) only";  // Stars and Faces
export const LIKERT_TITLES_LCR = "As title above (LCR) only)";  // Stars and Faces with odd number of answers
export const LIKERT_TITLES_HIDDEN = "Hidden";  // Thumbs, Stars and Faces
export const LIKERT_TITLES_EMBEDDED = "Embedded";  // Circles and Squares Answer Text 1 or 2 characters embedded in icon

export const likertTitlesRadiosAndThumbs =
    [
        {value: "0", text: LIKERT_TITLES_ALL},
        {value: "1", text: LIKERT_TITLES_HIDDEN},
    ];

export const likertTitlesStarsEven =
    [
        {value: "0", text: LIKERT_TITLES_ALL},
        {value: "1", text: LIKERT_TITLES_LR},
        {value: "2", text: LIKERT_TITLES_HIDDEN},
    ];

export const likertTitlesALlOptions =
    [
        {value: "0", text: LIKERT_TITLES_ALL},
        {value: "1", text: LIKERT_TITLES_LR},
        {value: "2", text: LIKERT_TITLES_LCR},
        {value: "3", text: LIKERT_TITLES_HIDDEN},
    ];

export const likertTitlesCirclesAndSquares =
    [
        {value: "0", text: LIKERT_TITLES_EMBEDDED},
        {value: "1", text: LIKERT_TITLES_HIDDEN},
    ];

export const likertTitlesOther =
    [
        {value: "0", text: LIKERT_TITLES_HIDDEN}, // --/++ Circles and Circle 11 multi-color
    ];


// =====================================================================================================================
// Likert responsive labels displayed below selected answer ------------------------------------------------------------ RESPONSIVE LABELS

export const LIKERT_ICON_LABELS_NO_TEXT = "No";  // Stars, Faces, Circles, Squares
export const LIKERT_ICON_LABELS_NO_VALUE = "0";  // Stars, Faces, Circles, Squares
export const LIKERT_ICON_LABELS_YES_TEXT = "Yes";  // Stars, Faces, Circles (including --/++ and 11), Squares
export const LIKERT_ICON_LABELS_YES_VALUE = "1";  // Stars, Faces, Circles (including --/++ and 11), Squares

export const RESPONSIVE_LABELS_NO_TEXT = "No";
export const RESPONSIVE_LABELS_NO_VALUE = "0";
export const RESPONSIVE_LABELS_YES_TEXT = "Yes";
export const RESPONSIVE_LABELS_YES_VALUE = "1";


export const likertResponsiveLabels =
    [
        {value: RESPONSIVE_LABELS_NO_VALUE, text: RESPONSIVE_LABELS_NO_TEXT},
        {value: RESPONSIVE_LABELS_YES_VALUE, text: RESPONSIVE_LABELS_YES_TEXT},
    ];

export const likertResponsiveLabelsOther =
    [
        {value: RESPONSIVE_LABELS_NO_VALUE, text: RESPONSIVE_LABELS_NO_TEXT},
    ];


export const RESPONSIVE_LABEL_DEFAULT = {
    ANSWERS_1_2: {
        "N1": "Poor",
        "N2": "Good"
    },
    ANSWERS_1_3: {
        "N1": "Poor",
        "N2": "Average",
        "N3": "Good"
    },
    ANSWERS_1_4: {
        "N1": "Very Poor",
        "N2": "Poor",
        "N3": "Good",
        "N4": "Very Good"
    },
    ANSWERS_1_5: {
        "N1": "Very Poor",
        "N2": "Poor",
        "N3": "Average",
        "N4": "Good",
        "N5": "Very Good"
    },
    ANSWERS_1_6: {
        "N1": "Extremely Poor",
        "N2": "Very Poor",
        "N3": "Poor",
        "N4": "Good",
        "N5": "Very Good",
        "N6": "Extremely Good"
    },
    ANSWERS_1_7: {
        "N1": "Extremely Poor",
        "N2": "Very Poor",
        "N3": "Poor",
        "N4": "Average",
        "N5": "Good",
        "N6": "Very Good",
        "N7": "Extremely Good"
    },
    ANSWERS_1_10: {
        "N1": "Extremely Poor",
        "N2": "Very Poor",
        "N3": "Poor",
        "N4": "Semi-Poor",
        "N5": "Below Average",
        "N6": "Above Average",
        "N7": "Semi-Good",
        "N8": "Good",
        "N9": "Very Good",
        "N10": "Extremely Good"
    },
    ANSWERS_0_10: {
        "N0": "Extremely Poor",
        "N1": "Very Poor",
        "N2": "Poor",
        "N3": "Semi-Poor",
        "N4": "Below Average",
        "N5": "Average",
        "N6": "Above Average",
        "N7": "Semi-Good",
        "N8": "Good",
        "N9": "Very Good",
        "N10": "Extremely Good"
    }
};


// =====================================================================================================================


// Type constants
export const TYPE_THUMBS_UP = "Thumbs Up";
export const TYPE_THUMBS_DOWN = "Thumbs Down";
//export const TYPE_THUMBS_UP_DOWN = "Thumbs Up/Down";

//export const TYPE_STAR_FILLED = "Star Filled";
export const TYPE_RADIO = "Radio";

export const TYPE_STAR = "Star";

export const TYPE_SQUARE = "Square";  // Buttongroups

export const TYPE_CIRCLE = "Circle";  // Buttongroups

export const TYPE_SENTIMENT_VERY_DISSATISFIED = "Very Dissatisfied";
export const TYPE_SENTIMENT_DISSATISFIED = "Dissatisfied";
export const TYPE_SENTIMENT_NEUTRAL = "Neutral";
export const TYPE_SENTIMENT_SATISFIED = "Satisfied";
export const TYPE_SENTIMENT_VERY_SATISFIED = "Very Satisfied";

export const types =
    [
        {value: "0", text: "Thumbs Up", icon: IconThumbUp},
        {value: "1", text: "Thumbs Down", icon: IconThumbDown},
        {value: "2", text: "Star", icon: IconStarEmpty},
        {value: "3", text: "Very Dissatisfied", icon: IconSentiment1},
        {value: "4", text: "Dissatisfied", icon: IconSentiment2},
        {value: "5", text: "Neutral", icon: IconSentiment3},
        {value: "6", text: "Satisfied", icon: IconSentiment4},
        {value: "7", text: "Very Satisfied", icon: IconSentiment5},
        {value: "8", text: "Square", icon: <ButtongroupSquare/>},
        {value: "9", text: "Circle", icon: <ButtongroupCircle/>},
    ];


export const  iconImages = [
    <IconThumbUp size="24" style={{paddingLeft: "16px"}}/>,
    <IconThumbDown size="24" style={{paddingLeft: "16px"}} />,
    <IconStarEmpty size="32" style={{paddingLeft: "12px"}}/>,
    <IconSentiment1 size="24" style={{paddingLeft: "15px"}}/>,
    <IconSentiment2 size="24" style={{paddingLeft: "15px"}}/>,
    <IconSentiment3 size="24" style={{paddingLeft: "15px"}}/>,
    <IconSentiment4 size="24" style={{paddingLeft: "15px"}}/>,
    <IconSentiment5 size="24" style={{paddingLeft: "15px"}}/>,
    <ButtongroupSquare fontSize="medium" style={{paddingLeft: "15px"}}/>,
    <ButtongroupCircle fontSize="medium" style={{paddingLeft: "15px"}}/>,
];

export const  iconImagesMenuItems = [
    {value: "0", text: <div title="Thumb Up">{iconImages[0]}</div>},
    {value: "1", text: <div title="Thumb Down">{iconImages[1]}</div>},
    {value: "2", text: <div title="Star">{iconImages[2]}</div>},
    {value: "3", text: <div title="Very Dissatisfied">{iconImages[3]}</div>},
    {value: "4", text: <div title="Dissatisfied">{iconImages[4]}</div>},
    {value: "5", text: <div title="Neutral">{iconImages[5]}</div>},
    {value: "6", text: <div title="Satisfied">{iconImages[6]}</div>},
    {value: "7", text: <div title="Very Satisfied">{iconImages[7]}</div>},
    {value: "8", text: <div title="Square">{iconImages[8]}</div>},
    {value: "9", text: <div title="Circle">{iconImages[9]}</div>}
];


// Metadata is stored in answer and question templates
export function initIconSelectEntries(metadata, isLikertAnswer = false) {

    let iconPosition = isLikertAnswer ? POSITION_ICON : POSITION_NONE;
    let iconColorObj = portalShared.customColorObject;
    let iconImageValue = ""; // Default to standard radio (No Icon)
    let iconColorEntryType = "";
    let iconColorValue = "";
    let colorIndex = "";
    let colorEntry = "";
    let colorHex = "";
    let opacityEntry = 100;
    let opacity = opacityEntry + "%";

// Check for icon position props in the metadata
    if (metadata.hasOwnProperty(ICON_POSITION)) {

        iconPosition = metadata[ICON_POSITION];

        // See if a icon type is in the metadata
        let iconTypes = types;

        if (metadata.hasOwnProperty(ICON_TYPE)) {
            let selectedIconTypeName = metadata[ICON_TYPE];
            for (let tt = 0; tt < iconTypes.length; tt++) {
                if (iconTypes[tt].text === selectedIconTypeName) {
                    iconImageValue = iconTypes[tt].value;
                    break;
                }
            }
        }

        // See if a icon color is in the metadata
        let iconColors = color.standard;  // List of our defined standard colors

        if (metadata.hasOwnProperty(ICON_COLOR)) {
            let selectedColorHexValue = metadata[ICON_COLOR];
            for (let cc = 0; cc < iconColors.length; cc++) {
                if (iconColors[cc].hex === selectedColorHexValue) {
                    iconColorValue = iconColors[cc].value;
                    colorIndex = cc;
                    colorEntry = iconColors[cc].color;
                    colorHex = iconColors[cc].hex;
                    break;
                }
            }
        }
    }

    // Adding the use of our color component, portal will require some changes and this is a fix for supporting legacy existing surveys
    if (metadata.hasOwnProperty(ICON_COLOR_OBJ)) {
        iconColorObj = metadata[ICON_COLOR_OBJ];
    }
    else {
        iconColorObj.backgroundColor = "";
        iconColorObj.colorType = color.colorEntryTypes[1].value; // "select"
        iconColorObj.colorIndex = colorIndex;
        iconColorObj.colorEntry = colorEntry;
        iconColorObj.color = colorHex;
        iconColorObj.opacity = opacity;
        iconColorObj.opacityEntry = opacityEntry;

        if (colorIndex !== "") {
            iconColorObj.colorType = color.colorEntryTypes[1].value; // "select"
        } else if (iconColorObj.colorType === "hex") {
            iconColorObj.colorType = color.colorEntryTypes[2].value; // "hex"
        } else if (iconColorObj.colorType === "rgb") {
            iconColorObj.colorType = color.colorEntryTypes[3].value; // "rgb"
        }
        else {
            iconColorObj.colorType = color.colorEntryTypes[0].value; // "default"
        }

        iconColorEntryType = iconColorObj.colorType;

    }

    return {iconPosition: iconPosition, iconImageValue: iconImageValue, iconColorEntryType: iconColorEntryType, iconColorValue: iconColorValue, iconColorObj: iconColorObj};
}


export function formatIcon(answerOption) {

    let formated = answerOption.optionText;
    // Check for icon position props in the metadata
    if (answerOption.metadata.hasOwnProperty(ICON_POSITION)) {

        // and must have an icon selected
        if (answerOption.metadata.hasOwnProperty(ICON_TYPE)) {

                let marginAdjustment = "0px";
                switch (answerOption.metadata[ICON_TYPE]) {
                    case TYPE_THUMBS_UP:
                    case TYPE_THUMBS_DOWN:
                        marginAdjustment = "-5px";
                        break;
                    case TYPE_STAR:
                        marginAdjustment = "-10px";
                        break;
                    case TYPE_SENTIMENT_VERY_DISSATISFIED:
                    case TYPE_SENTIMENT_DISSATISFIED:
                    case TYPE_SENTIMENT_NEUTRAL:
                    case TYPE_SENTIMENT_SATISFIED:
                    case TYPE_SENTIMENT_VERY_SATISFIED:
                        marginAdjustment = "-5px";
                        break;
                    default:
                        marginAdjustment = "-10px";

                }

            switch(answerOption.metadata[ICON_POSITION]) {
                case POSITION_BEFORE:
                    formated = <div style={{display: "flex", alignItems: "center", marginTop: marginAdjustment}}>{iconType(answerOption)}<div style={{width: '10px'}}/><div style={{marginTop: '2px'}}>{formated}</div></div>;
                    break;
                case POSITION_AFTER:
                    formated = <div style={{display: "flex", alignItems: "center", marginTop: marginAdjustment}}><div>{formated}</div><div style={{width: '10px'}}/>{iconType(answerOption)}</div>;
                    break;
                case POSITION_ONLY:
                    formated = <div style={{display: "flex", alignItems: "center", marginTop: marginAdjustment}}>{iconType(answerOption)}</div>;
                    break;
                default:
                    console.log("iconConstants.js  Switch default E02"); // eslint-disable-line
            }

        }
    }

    return formated;
}

export function fillMode(metadata) {

    let fillMode = false;

    switch(metadata[ICON_POSITION]) {
        case POSITION_ICON_FILL:
        case POSITION_ICON_FILL_NO_TITLE:
            fillMode = true;
            break;
        default:
            console.log("iconConstants.js  Switch default E03"); // eslint-disable-line
    }
    return fillMode;
}


export function formatIconForLikert(metadata, index, isSelected, answers, iconsSelectedArray) {

    let formated = "";
    // Check for icon position props in the metadata
    if (metadata.hasOwnProperty(ICON_POSITION)) {

        // and must have an icon selected
        if (metadata.hasOwnProperty(ICON_TYPE)) {

            let marginAdjustment = "0px";
            let marginLeftAdjustment = "0px";
            switch (metadata[ICON_TYPE]) {
                case TYPE_THUMBS_UP:
                case TYPE_THUMBS_DOWN:
                    marginAdjustment = "-5px";
                    break;
                case TYPE_STAR:
                    marginAdjustment = "-20px";
                    break;
                case TYPE_SENTIMENT_VERY_DISSATISFIED:
                case TYPE_SENTIMENT_DISSATISFIED:
                case TYPE_SENTIMENT_NEUTRAL:
                case TYPE_SENTIMENT_SATISFIED:
                case TYPE_SENTIMENT_VERY_SATISFIED:
                    switch (index) {
                        case 0:
                            marginLeftAdjustment = "15px";
                            break;
                        case 1:
                            marginLeftAdjustment = "10px";
                            break;
                        case 2:
                            marginLeftAdjustment = "5px";
                            break;
                        case 3:
                            marginLeftAdjustment = "0px";
                            break;
                        case 4:
                            marginLeftAdjustment = "0px";
                            break;
                        default:
                            marginLeftAdjustment = "0px";
                    }
                    marginAdjustment = "-8px";
                    break;
                default:
                    marginAdjustment = "-10px";

            }

            switch(metadata[ICON_POSITION]) {
                case POSITION_ICON:
                case POSITION_ICON_NO_TITLE:
                    formated = <div style={{display: "flex", alignItems: "center", paddingLeft: marginLeftAdjustment, marginTop: marginAdjustment}}>{iconType({metadata: metadata}, 'LIKERT', isSelected, answers, false, index, iconsSelectedArray)}</div>;
                    break;
                case POSITION_ICON_FILL:
                case POSITION_ICON_FILL_NO_TITLE:
                    formated = <div style={{display: "flex", alignItems: "center", paddingLeft: marginLeftAdjustment, marginTop: marginAdjustment}}>{iconType({metadata: metadata}, 'LIKERT', isSelected, answers, true, index, iconsSelectedArray)}</div>;
                    break;
                default:
                    console.log("iconConstants.js  Switch default E04"); // eslint-disable-line
            }

        }
    }

    return formated;
}


function iconType(answerOption, mode = 'STANDARD', isSelected = false, answers, isFillMode, index, iconsSelectedArray) {

    let selectedIconName = answerOption.metadata[ICON_TYPE];
    let selectedIconColor = "#2F4F4F";

    // Get the selected icon
    let selectedIndex = index;

    // Only satisfaction icons swap out depending on selected icon during fill mode
    switch (selectedIconName) {

        case TYPE_SENTIMENT_VERY_DISSATISFIED:
        case TYPE_SENTIMENT_DISSATISFIED:
        case TYPE_SENTIMENT_NEUTRAL:
        case TYPE_SENTIMENT_SATISFIED:
        case TYPE_SENTIMENT_VERY_SATISFIED:
            if (iconsSelectedArray !== undefined) {

                if (iconsSelectedArray.length !== 0) {

                    for (let i = 0; i < iconsSelectedArray.length; i++) {
                        if (iconsSelectedArray[i]) {
                            selectedIndex = i;
                        }
                    }
                }

                // Swap out icons for fill mode

                if (isFillMode) {
                    if (index <= selectedIndex) {
                        selectedIconName = answers[selectedIndex].metadata[ICON_TYPE];
                        selectedIconColor = answers[selectedIndex].metadata.hasOwnProperty(ICON_COLOR) ? answers[selectedIndex].metadata[ICON_COLOR] : "#2F4F4F";
                    }
                }
            }
            break;
        default:
            console.log("iconConstants.js  Switch default E05"); // eslint-disable-line
    }


    let selectedIcon = "";
    let iconColor = "#2F4F4F"; // Default to black NOTE: LIKERT are always displayed as black, and any color is applied when selected (Black #000000)

    if (mode === 'STANDARD' && answerOption.metadata.hasOwnProperty(ICON_COLOR)) {
        iconColor = answerOption.metadata[ICON_COLOR];
    }

    if (mode === 'LIKERT' && isSelected && answerOption.metadata.hasOwnProperty(ICON_COLOR)) {
        iconColor = answerOption.metadata[ICON_COLOR];
    }

    if (mode === 'LIKERT' && isFillMode && isSelected && answerOption.metadata.hasOwnProperty(ICON_COLOR) && (selectedIconName !== TYPE_STAR)) {
        iconColor = selectedIconColor;
    }

    switch (selectedIconName) {

        case TYPE_THUMBS_UP:
            selectedIcon = <IconThumbUp size="28" style={{marginTop: mode==='LIKERT' ? '0px' : '0px', color: iconColor}}/>;
            break;
        case TYPE_THUMBS_DOWN:
            selectedIcon = <IconThumbDown size="28" style={{marginTop: mode==='LIKERT' ? '0px' : '0px', color: iconColor}}/>;
            break;
        case TYPE_STAR:
            // Star icons always swap to star filled, if fill mode all will swap up to selected
            if (isSelected) {
                selectedIcon = <IconStarFilled size="42" style={{marginTop: mode==='LIKERT' ? '-10px' : '0px', color: iconColor}}/>;
            }
            else {
                selectedIcon = <IconStarEmpty size="42" style={{marginTop: mode==='LIKERT' ? '-10px' : '0px', color: iconColor}}/>;
            }
            break;
        case TYPE_SENTIMENT_VERY_DISSATISFIED:
            selectedIcon = <IconSentiment1 size="32" style={{marginTop: mode==='LIKERT' ? '-3px' : '0px', color: iconColor}}/>;
            break;
        case TYPE_SENTIMENT_DISSATISFIED:
            selectedIcon = <IconSentiment2 size="32" style={{marginTop: mode==='LIKERT' ? '-3px' : '0px', color: iconColor}}/>;
            break;
        case TYPE_SENTIMENT_NEUTRAL:
            selectedIcon = <IconSentiment3 size="32" style={{marginTop: mode==='LIKERT' ? '-3px' : '0px', color: iconColor}}/>;
            break;
        case TYPE_SENTIMENT_SATISFIED:
            selectedIcon = <IconSentiment4 size="32" style={{marginTop: mode==='LIKERT' ? '-3px' : '0px', color: iconColor}}/>;
            break;
        case TYPE_SENTIMENT_VERY_SATISFIED:
            selectedIcon = <IconSentiment5 size="32" style={{marginTop: mode==='LIKERT' ? '-3px' : '0px', color: iconColor}}/>;
            break;
        case TYPE_SQUARE:
            selectedIcon = <ButtongroupSquare fontSize="32" style={{marginTop: mode==='LIKERT' ? '-3px' : '0px', color: iconColor}}/>;
            break;
        case TYPE_CIRCLE:
            selectedIcon = <ButtongroupCircle size="32" style={{marginTop: mode==='LIKERT' ? '-3px' : '0px', color: iconColor}}/>;
            break;
        default:
            console.log("iconConstants.js  Switch default E06"); // eslint-disable-line
    }

    return selectedIcon;
}


export function getLabel(pNumAnswers, pCurrIndex) {

    let label = "";
    let numAnswers = (typeof pNumAnswers === "string") ? parseInt(pNumAnswers) : pNumAnswers;
    let currIndex = (typeof pCurrIndex === "string") ? parseInt(pCurrIndex) : pCurrIndex;

    switch (numAnswers) {
        case 2:
            switch (currIndex) {
                case 0:
                    label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_2.N1;
                    break;
                case 1:
                    label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_2.N2;
                    break;
                default:
                    console.log("iconConstants.js  Switch default E09"); // eslint-disable-line
            }
            break;
        case 3:
            switch (currIndex) {
                case 0:
                    label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_3.N1;
                    break;
                case 1:
                    label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_3.N2;
                    break;
                case 2:
                    label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_3.N3;
                    break;
                default:
                    console.log("iconConstants.js  Switch default E10"); // eslint-disable-line
            }
            break;
        case 4:
            switch (currIndex) {
                case 0:
                    label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_4.N1;
                    break;
                case 1:
                    label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_4.N2;
                    break;
                case 2:
                    label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_4.N3;
                    break;
                case 3:
                    label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_4.N4;
                    break;
                default:
                    console.log("iconConstants.js  Switch default E11"); // eslint-disable-line
            }
            break;
        case 5:
            switch (currIndex) {
                case 0:
                    label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_5.N1;
                    break;
                case 1:
                    label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_5.N2;
                    break;
                case 2:
                    label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_5.N3;
                    break;
                case 3:
                    label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_5.N4;
                    break;
                case 4:
                    label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_5.N5;
                    break;
                default:
                    console.log("iconConstants.js  Switch default E12"); // eslint-disable-line
            }
            break;
        case 6:
            switch (currIndex) {
                case 0:
                    label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_6.N1;
                    break;
                case 1:
                    label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_6.N2;
                    break;
                case 2:
                    label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_6.N3;
                    break;
                case 3:
                    label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_6.N4;
                    break;
                case 4:
                    label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_6.N5;
                    break;
                case 5:
                    label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_6.N6;
                    break;
                default:
                    console.log("iconConstants.js  Switch default E13"); // eslint-disable-line
            }
            break;
        case 7:
            switch (currIndex) {
                case 0:
                    label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_7.N1;
                    break;
                case 1:
                    label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_7.N2;
                    break;
                case 2:
                    label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_7.N3;
                    break;
                case 3:
                    label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_7.N4;
                    break;
                case 4:
                    label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_7.N5;
                    break;
                case 5:
                    label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_7.N6;
                    break;
                case 6:
                    label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_7.N7;
                    break;
                default:
                    console.log("iconConstants.js  Switch default E14"); // eslint-disable-line
            }
            break;
        case 10:
            switch (currIndex) {
                case 0:
                    label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_10.N1;
                    break;
                case 1:
                    label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_10.N2;
                    break;
                case 2:
                    label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_10.N3;
                    break;
                case 3:
                    label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_10.N4;
                    break;
                case 4:
                    label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_10.N5;
                    break;
                case 5:
                    label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_10.N6;
                    break;
                case 6:
                    label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_10.N7;
                    break;
                case 7:
                    label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_10.N8;
                    break;
                case 8:
                    label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_10.N9;
                    break;
                case 9:
                    label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_10.N10;
                    break;
                default:
                    console.log("iconConstants.js  Switch default E15"); // eslint-disable-line
            }
            break;
        case 11:
            switch (currIndex) {
                case 0:
                    label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_0_10.N0;
                    break;
                case 1:
                    label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_0_10.N1;
                    break;
                case 2:
                    label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_0_10.N2;
                    break;
                case 3:
                    label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_0_10.N3;
                    break;
                case 4:
                    label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_0_10.N4;
                    break;
                case 5:
                    label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_0_10.N5;
                    break;
                case 6:
                    label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_0_10.N6;
                    break;
                case 7:
                    label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_0_10.N7;
                    break;
                case 8:
                    label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_0_10.N8;
                    break;
                case 9:
                    label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_0_10.N9;
                    break;
                case 10:
                    label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_0_10.N10;
                    break;
                default:
                    console.log("iconConstants.js  Switch default E16"); // eslint-disable-line
            }
            break;
        default:
            console.log("iconConstants.js  Switch default E17"); // eslint-disable-line
    }

    return label;
}



