import React from 'react';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {bindActionCreators} from "redux";
import {closeDrawer, scoringUpdate} from '../../actions/drawerActions';
import {getQuestionnaireGraph} from "../../actions/questionnaireTemplateActions";
import ArgoScoringEntry from "../common/ArgoScoringEntry";
import * as TEMPLATE from "../../constants/templateConstants";
import ArgoAppBar from "../common/ArgoAppBar";
import ArgoToolbarHook from "../common/ArgoToolbarHook";


const ScoringDrawerStyle = {
    container: {
        margin: 12
    },
    button: {
        margin: 12
    }
};

class QuestionnaireScoringDrawer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            scoringArray: [],
            editInProcess: false
        };

        this.editMode = this.editMode.bind(this);
    }

    componentWillMount() {
        if (this.props.row) {
            this.props.getQuestionnaireGraph(this.props.row.id);
        }
    }

    componentWillReceiveProps(nextProps) {
        //Object.keys(newProps.questionnaireTemplateGraph)

        let qnr = nextProps.questionnaireTemplateGraph;

        let scoringArray = [];

        scoringArray.push({template: "questionnaire", name: qnr.name, id: qnr.id, indent: 0, weight: null, score: null, disabled: true, modified: false, type: null, key: "", parentId: null});

        this.setCategory(scoringArray, qnr.questionCategoryTemplates, qnr.id);

        // Could be a SIMPLE questionnaire and not have categories
        if (qnr.questionCategoryTemplates.length === 0) {
            if (qnr.questionTemplates.length !== 0) {
                this.setQuestion(scoringArray, qnr.questionTemplates, 3, qnr.id);
            }
        }

        this.setState({scoringArray: scoringArray});
    }

    handleToolbarButtonClick = (event, fieldName, buttonTitle) => {
        switch (event.target.innerText) {
            case "SAVE":
                this.save();
                break;
            case "CANCEL":
                this.cancel();
                break;
            // no default
        }
    }


    setCategory(scoringArray, c, parentId) {

        for (let i = 0; i < c.length; i++) {
            scoringArray.push({template: "category", name: c[i].name, id: c[i].id, indent: 1, weight: null, score: null, disabled: true, modified: false, type: null, key: "", parentId: parentId});
            this.setGroup(scoringArray, c[i].groupTemplates, c[i].id);
        }

    }

    setGroup(scoringArray, g, parentId) {

        for (let i = 0; i < g.length; i++) {

            scoringArray.push({template: "group", name: g[i].name, id: g[i].id, indent: 2, weight: null, score: null, disabled: true, modified: false, type: g[i].questionGroupType, key: "", parentId: parentId});

            if (g[i].questionGroupType === "LIKERT") {
                this.setAnswer(scoringArray, g[i].answerOptionTemplates, "LIKERT", 3, "likert-score-value", g[i].id);
                this.setQuestion(scoringArray, g[i].questionTemplates, 4, g[i].id);
            }
            else {
                this.setQuestion(scoringArray, g[i].questionTemplates, 3, g[i].id);
            }
        }
    }

    setQuestion(scoringArray, q, indent, parentId) {

        for (let i = 0; i < q.length; i++) {

            let weight = "";
            if (!(Object.keys(q[i].metadata).length === 0 && q[i].metadata.constructor === Object)) {
                for (var key of Object.keys(q[i].metadata)) {
                    if (key === "scoring-weight") {
                        weight = q[i].metadata[key];
                    }
                }
            }
            scoringArray.push({template: "question", name: q[i].name, id: q[i].id, indent: indent, weight: weight, score: null, disabled: true, modified: false, type: q[i].questionType, key: "scoring-weight", parentId: parentId});

            this.setAnswer(scoringArray, q[i].answerOptionTemplates, q[i].questionType, indent + 1, "scoring-point", q[i].id);

        }
    }

    setAnswer(scoringArray, a, type, indent, key, parentId) {

        for (let i = 0; i < a.length; i++) {

            switch(type) {
                case "CHECKBOX":
                case "RADIO":
                case "YES_NO":
                case "LIKERT":
                case "LIKERT_RADIO":
                    let score = "";
                    if (!(Object.keys(a[i].metadata).length === 0 && a[i].metadata.constructor === Object)) {
                        for (var keyValue of Object.keys(a[i].metadata)) {
                            if (keyValue === "scoring-point" || keyValue === "likert-score-value") {
                                score = a[i].metadata[keyValue];
                            }
                        }
                    }

                    scoringArray.push({template: "answer", name: a[i].optionText, id: a[i].id, indent: indent, weight: null, score: score, disabled: true, modified: false, type: type === "LIKERT" ? "LIKERT" : null, key: key, parentId: parentId});
                    break;
                default:
                    scoringArray.push({template: "answer", name: a[i].optionText, id: a[i].id, indent: indent, weight: null, score: null, disabled: true, modified: false, type: null, key: key, parentId: parentId});
                    break;
            }

        }
    }


    editMode(mode, index, weight, score, changed) {

        let tempArray = this.state.scoringArray;

        if (changed) {
            tempArray[index].weight = weight;
            tempArray[index].score = score;
            tempArray[index].modified = changed;
        }
        tempArray[index].disabled = !mode;

        this.setState({tempArray: tempArray, editInProcess: mode});
    }


    save = (id, DTO) => {

        let tempArray = this.state.scoringArray;

        let questionsToUpdate = [];
        for (let i = 0; i < tempArray.length; i++) {
            if (tempArray[i].modified && tempArray[i].weight !== null) {
                questionsToUpdate.push({id: tempArray[i].id, name: tempArray[i].name, weight: tempArray[i].weight, key: tempArray[i].key, parentId: tempArray[i].parentId});
            }
        }

        let answersToUpdate = [];
        for (let i = 0; i < tempArray.length; i++) {
            if (tempArray[i].modified && tempArray[i].score !== null && tempArray[i].key !== "likert-score-value") {
                answersToUpdate.push({id: tempArray[i].id, name: tempArray[i].name, score: tempArray[i].score, key: tempArray[i].key, parentId: tempArray[i].parentId});
            }
        }

        let groupAnswersToUpdate = [];
        for (let i = 0; i < tempArray.length; i++) {
            if (tempArray[i].modified && tempArray[i].score !== null && tempArray[i].key === "likert-score-value") {
                groupAnswersToUpdate.push({id: tempArray[i].id, name: tempArray[i].name, score: tempArray[i].score, key: tempArray[i].key, parentId: tempArray[i].parentId});
            }
        }

        if (questionsToUpdate.length !== 0 || answersToUpdate.length !== 0 || groupAnswersToUpdate.length !== 0) {

            let id = this.props.questionnaireTemplateGraph.id;

            this.props.scoringUpdate({questionnaireId: id, questionsToUpdate: questionsToUpdate, answersToUpdate: answersToUpdate, groupAnswersToUpdate: groupAnswersToUpdate}, this.props.filter);
        }

        this.props.closeDrawer();

    };


    cancel = () => {
        this.setState({scoringArray: [], editInProcess: false}, this.props.closeDrawer());
    };


    render() {
        const {
            questionnaireTemplateGraph
        } = this.props;

        let questionnaireName = questionnaireTemplateGraph ? questionnaireTemplateGraph.name : "";
        //let questionnaireId = questionnaireTemplateGraph ? questionnaireTemplateGraph.name : null;  ZZZZZ

        return (<div>
                <ArgoAppBar
                    title={"Scoring:"}
                    titleFor={questionnaireName}
                    showMenuIconButton={false}
                    noIcon={true}
                    isDrawer={true}
                    width={questionnaireScoringDrawerProps.width}
                />

                <div style={{height: '64px'}}></div>

                <ArgoToolbarHook
                    fileName="scoring-drawer"
                    fieldName="save-or-cancel"
                    buttonTitles={["SAVE", "CANCEL"]}
                    width={questionnaireScoringDrawerProps.width}
                    onClick={e => this.handleToolbarButtonClick(e, "", "SAVE")}/>

                <div style={ScoringDrawerStyle.container}>
                    <div>
                        {this.state.scoringArray.map((row, index) => (
                            <ArgoScoringEntry key={index}
                                              index={index}
                                              refObj={row}
                                              editMode={this.editMode}
                                              disabled={row.disabled}
                                              editInProcess={this.state.editInProcess}/>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

QuestionnaireScoringDrawer.defaultProps = {
};

QuestionnaireScoringDrawer.propTypes = {
    row: PropTypes.object
};

function mapStateToProps(state) {
    return {
        questionnaireTemplateGraph: state.questionnaireTemplateGraph,
        filter: state.filter[TEMPLATE.FILTER.current.questionnaire]
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({closeDrawer, scoringUpdate, getQuestionnaireGraph}, dispatch);
}

const questionnaireScoringDrawerProps = {
    opensecondary: true,
    width: "70%",
    docked: false,
    anchor: "right"
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionnaireScoringDrawer);
export {questionnaireScoringDrawerProps};
