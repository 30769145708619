import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import * as Colors from "@mui/material/colors";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import IconButton from "@mui/material/IconButton";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {openDrawer} from "../../actions/drawerActions";
import {initGetTemplateList} from "../../actions/templateListActions";
import {getOutstandingQuestionnaireTemplateCount} from "../../actions/questionnaireTemplateActions";
import ArgoButtonHook from "./ArgoButtonHook";
import HelpDrawer, {helpProps} from "../drawers/HelpDrawer";
import pageNames from "../../utilities/helpMap";
import {QD_HELP_URL_QUESTION, QD_URL} from "../../constants/systemConstants";
import * as TEMPLATE from "../../constants/templateConstants";


export default function ArgoListTitleAndLoadingAnimationHook(props) {

    const fileName = props.fileName ?? "NoFileName";

    const uniqueIdentifier = fileName + "-ArgoListTitleAndLoadingAnimationHook";

    const listTitle = props.listTitle ?? "";

    const loading = props.loading ?? false;
    const filter = props.filter ?? false;

    const showOutstandingAlert = props.showOutstandingAlert ?? true;
    const outstandingQuestionnaireCount = useSelector(state => state.outstandingQuestionnaireCount.count);
    const questionnaireFilter = useSelector(state => state.filter["questionnaires"]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const onClickViewOutstandingQuestionnairesHelp = () => {

        let path = QD_HELP_URL_QUESTION + pageNames.unpublishedQuestionnaires;
        dispatch(openDrawer(helpProps, <HelpDrawer src={path}/>));
    };

    const onClickViewOutstandingQuestionnaires = () => {

        let tempFilter = {...questionnaireFilter}; // Shallow Clone
        tempFilter.outstanding = true;
        navigate(QD_URL + "/questionnaires");

        dispatch(initGetTemplateList(tempFilter, TEMPLATE.TYPE.QUESTIONNAIRE, TEMPLATE.MODE.CURRENT));
    };


    // Use the empty [] so useEffect will only execute when hook mounts
    useEffect(() => {

        dispatch(getOutstandingQuestionnaireTemplateCount());
    },[dispatch, loading]);

    return (
        <div id={uniqueIdentifier + "-container-div"} style={{display: "flex", alignItems: "center", width: "100%", height: "50px",}}>
            <div id={uniqueIdentifier + "-list-title-div"}>
                <h2 className="primaryText" style={{float: "left"}}>
                    {listTitle}
                </h2>
            </div>
            <div id={uniqueIdentifier + "-spacer-div"} style={{float: "left", width: "20px"}}>
                &nbsp;
            </div>
            <div id={uniqueIdentifier + "-progress-animation-div"} style={{float: "left"}}>
                {(loading || filter)? <CircularProgress size={30}/> : <div/>}
            </div>

            {showOutstandingAlert && (outstandingQuestionnaireCount > 0) ?
                <div style={{display: "flex", alignItems: "center", marginLeft: "auto"}}>
                    <ErrorOutlineIcon sx={{color: "orange", fontSize: "38px"}}/>
                    <div>&nbsp;</div>
                    <div>
                        There are outstanding unpublished versions of previously published questionnaires.
                    </div>
                    <div>
                        <IconButton id="ArgoListTitleAndLoadingAnimationHook-OutstandingQuestionnaire-Help"
                                    sx={{color: Colors.grey[500]}}
                                    onClick={onClickViewOutstandingQuestionnairesHelp}>
                            <HelpOutlineIcon/>
                        </IconButton>
                    </div>
                    <div style={{paddingTop: "3px"}}>
                        <ArgoButtonHook
                            fileName="ArgoListTitleAndLoadingAnimationHook"
                            fieldName="view"
                            variant="text"
                            label={"View " + outstandingQuestionnaireCount}
                            width="100px"
                            onClick={onClickViewOutstandingQuestionnaires}
                        />
                    </div>
                </div>
                : ""
            }
        </div>
    );
}



