import TemplateApi from "../api/TemplateApi";
import * as types from "../constants/actionConstants";


export function getActionTypesList() {
    return (dispatch) => {
        TemplateApi.getActionTypes().then((data) => {
            dispatch({
                type: types.ACTION_TYPES_GET_LIST_SUCCESS,
                list: data
            });
        });
    };
}
