
import * as system from "../constants/systemConstants";
import {axiosWrap} from "./TemplateApi";
import {FormatPageable} from "../utilities/PageableAndFilterUtilities";

const GET = "get";
const PUT = "put";
const POST = "post";
const DELETE = "delete";

// converts UI column name to database equivalent
const columnNameMapper = {
    "Name": "name",
    "Last updated": "lastModifiedDate",
    "Updated by": "lastModifiedBy",
    "Type": "integrationType",
    "Event date": "createdDate",
    "Server": "logServer",
    "Status": "success",
    "Message": "resultMessage"
};

class IntegrationApi {

    static getIntegrationById(id) {
        return axiosWrap(GET, system.QD_API_URL + "integrations/" + id)
            .then((response) => {
                return response.data;
            });
    }


    static getIntegrationByName(name) {
        return axiosWrap(GET,
            system.QD_API_URL +
            "integrations/exists?name=" +
            name)
            .then((response) => {
                return response.data;
            });
    }


    static createIntegration(integration) {

        return axiosWrap(POST, system.QD_API_URL + "integrations", integration)
            .then((response) => {
                return response.data;
            });
    }


    static saveIntegration(integration) {
        return axiosWrap(PUT, system.QD_API_URL + "integrations/" + integration.id, integration)
            .then((response) => {
                return response.data;
            });
    }


    static deleteIntegration(id) {
        return axiosWrap(DELETE, system.QD_API_URL + "integrations/" + id, {})
            .then((response) => {
                return response.data;
            });
    }


    static getIntegrationLogList(id, pageable) {
        return axiosWrap(GET, system.QD_API_URL + "integrations/" + id + "/logs?" + FormatPageable(pageable, columnNameMapper))
            .then((response) => {
                return response.data;
            });
    }


    static getIntegrationLogById(id) {
        return axiosWrap(GET, system.QD_API_URL + "integrations/logs/" + id)
            .then((response) => {
                return response.data;
            });
    }

}

export default IntegrationApi;
