import React from 'react';
import Paper from '@mui/material/Paper';

function Content(props) {
    //const { classes } = props;

    return (
        <Paper id="Content-Paper" style={{width: '100%',
            margin: 'auto',
            maxWidth: '55rem',  // NOTE: This sets the PAPER width !!!!! ZZZZZ PAGEWIDTH PAGE WIDTH
            overflow: 'hidden',
            boxShadow: '1px 1px 20px -10px',

            // We have rounded corners everywhere (due to shape.borderRadius set in the theme), but
            // we don't need to have rounded corners for the progress since we want that to be rectangular.
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            }}
        >
            {props.children}
        </Paper>
    );
}

export default (Content);