import React from 'react';
import IconButton from "@mui/material/IconButton";


export default function ArgoIconButtonHook(props) {

    const fileName = props.fileName ?? "file-name";
    const fieldName = props.fieldName ?? "field-name";

    const index = props.index ?? ""; // If field is built in a loop index is required to know which field to match up with
    const depth = props.depth ?? 0; // Question Depth

    const uniqueIdentifier = fileName + "-icon-button-" + fieldName + index + depth;

    const size = props.size ?? "medium";

    const disabled = props.disabled ?? false;


    const icon = props.icon;

    const color = props.color ?? "black";
    const marginTop = props.marginTop ?? "0px";

    const onClick = props.onClick ?? function () {};


    const handleOnClick = (event) => {
        onClick(event, props.fieldName, index, depth);
    };

    return (
        <IconButton id={uniqueIdentifier}
                    name={uniqueIdentifier}
                    size={size}
                    onClick={handleOnClick}
                    disabled={disabled}
                    style={{marginTop: marginTop, color: color}}>
            {icon}
        </IconButton>
    );
}
