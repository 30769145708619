import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import InfoIcon from "@mui/icons-material/Info";
import {getActionTemplate} from "../../../actions/actionTemplateActions";
import Typography from "@mui/material/Typography";


class ActionReview extends Component {

    componentDidMount() {
        if (this.props.templateKey || this.props.templateId) {
            this.props.getActionTemplate(this.props.templateKey, this.props.templateId);
        }
    }

    componentWillReceiveProps(nextProps) {
        if ((nextProps.templateKey && nextProps.templateKey !== this.props.templateKey)
            || (nextProps.templateId && nextProps.templateId !== this.props.templateId)
        ) {
            nextProps.getActionTemplate(nextProps.templateKey, nextProps.templateId);
        }
    }

    render() {
        const template = this.props.template;
        const rootStyle = {
            justifyContent: "space-between",
            width: "100%",
            maxHeight: "fit-content",
            height: "fit-content",
            minHeight: "fit-content",
            ...this.props.style
        };

        let templateIsLoaded = template && (!this.props.templateId || (template.id && template.id.toString() === this.props.templateId));
        if (!templateIsLoaded) {
            return null;
        }

        return (
            template ?
                <div style={rootStyle}>
                    <div style={{width: "40%", padding: "32px"}}>

                        <Typography variant="h6" component="div">
                            Action Information:
                        </Typography>

                        <div>&nbsp;</div>

                        <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                            <div style={{color: "lightgray"}}>Name:</div>
                            <div>&nbsp;&nbsp;</div>
                            <div style={{fontSize: "16px"}}>{template.name}</div>
                        </div>

                        <div style={{height: "20px"}}>&nbsp;</div>

                        <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                            <div style={{color: "lightgray"}}>Description:</div>
                            {template.description ? <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                    <InfoIcon style={{minWidth: "48px"}}/>
                                    <div style={{fontSize: "16px"}}>{template.description}</div>
                            </div> : ""}
                        </div>

                        <div style={{height: "20px"}}>&nbsp;</div>

                        <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                            <div style={{color: "lightgray"}}>Type:</div>
                            <div>&nbsp;&nbsp;</div>
                            <div style={{fontSize: "16px"}}>{template.type}</div>
                        </div>

                    </div>
                </div>
            : ""
        );
    }
}

ActionReview.propTypes = {
    template: PropTypes.object,
    templateKey: PropTypes.string,
};

function mapStateToProps(state, props) {
    return {template: state.actionTemplate.hasOwnProperty(props.templateKey) ? state.actionTemplate[props.templateKey] : {}};
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({getActionTemplate}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionReview);
