import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import * as actionTemplateActions from "../../../actions/actionTemplateActions";
import ActionReview from "./ActionReview";
import ActionEditor from "./ActionEditor";


class ActionContainer extends Component {

    constructor(props) {
        super(props);

        this.state = {
            template: {},
            resolved: false,
            error: false,
            templateKey: ""
        };

        this.save = this.save.bind(this);
    }
    //TODO this doesnt seem correct manipulating the the props via state doesnt make much sense.
    componentDidMount() {
        let templateKey = "";
        if (!this.props.templateKey) {
            templateKey = this.props.match.params.templateKey;
        } else {
            templateKey = this.props.templateKey;
        }
        this.setState({templateKey: templateKey});
        this.props.actionTemplateActions.getActionTemplate(templateKey);

    }

    componentWillReceiveProps(nextProps) {
        if (this.state.templateKey !== "") {
            this.setState({
                template: nextProps.actionTemplate[this.state.templateKey],
                resolved: true
            });
        }
    }

    save(saveObject) {
        this.props.actionTemplateActions.saveActionTemplate(saveObject);
    }

    getActionPreview() {
        let html;

        if (this.state.resolved) {
            html = (
                <div style={{paddingTop: "20px"}}>
                    <Card>
                        <CardHeader
                            title={"Action: " + this.state.template.templateKey}
                            titleTypographyProps={{color: "blue"}}
                            actAsExpander
                        >
                            <ActionReview name={this.state.template.name} />
                        </CardHeader>
                        <CardContent expandable>
                            <ActionEditor save={this.save} width="90%"/>
                        </CardContent>
                    </Card>
                </div>
            );
        } else {
            html = <div />;
        }
        return html;
    }

    render() {
        return this.getActionPreview();
    }

}

ActionContainer.propTypes = {
    actionTemplateActions: PropTypes.func,
    actionTemplate: PropTypes.node,
    templateKey: PropTypes.object,
    match: PropTypes.object
};


function mapDispatchToProps(dispatch) {
    return {
        actionTemplateActions: bindActionCreators(actionTemplateActions, dispatch)
    };
}

function mapStateToProps(state) {
    return {
        actionTemplate: state.actionTemplate
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionContainer);
