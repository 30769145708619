import TemplateApi from "../api/TemplateApi";
import * as types from "../constants/actionConstants";
import * as TEMPLATE from "../constants/templateConstants";
import {sortDefault, filterDefault} from "../constants/filterConstants";
import {selectPageable, initialFilterObject} from "./pageAndSortActions";
import {pageableObjFromFilter} from "../utilities/PageableAndFilterUtilities";


const localSelectPagable = selectPageable;
const localInitialFilterObject = initialFilterObject;

// Const does not work here.
// listTypes was used by leagacy code for lists that pulled all records current and versions
let listClearTypes = {
    [TEMPLATE.TYPE.TAG]: types.TAG_LIST_CLEAR,
    [TEMPLATE.TYPE.INTEGRATION]: types.INTEGRATION_LIST_CLEAR,
    [TEMPLATE.TYPE.INTEGRATION_LOG]: types.INTEGRATION_LOGS_LIST_CLEAR,
    [TEMPLATE.TYPE.SELECT_EXISTING_CATEGORY]: types.CLEAR_SELECT_EXISTING_DDLB_LIST,
    [TEMPLATE.TYPE.SELECT_EXISTING_GROUP]: types.CLEAR_SELECT_EXISTING_DDLB_LIST,
    [TEMPLATE.TYPE.SELECT_EXISTING_QUESTION]: types.CLEAR_SELECT_EXISTING_DDLB_LIST,
};

let listTypes = {
    [TEMPLATE.TYPE.QUESTIONNAIRE]: types.QUESTIONNAIRE_TEMPLATE_LIST_GET_SUCCESS,
    [TEMPLATE.TYPE.CATEGORY]: types.CATEGORY_TEMPLATE_LIST_GET_SUCCESS,
    [TEMPLATE.TYPE.GROUP]: types.GROUP_TEMPLATE_LIST_GET_SUCCESS,
    [TEMPLATE.TYPE.QUESTION]: types.QUESTION_TEMPLATE_LIST_GET_SUCCESS,
    [TEMPLATE.TYPE.ACTION]: types.ACTION_TEMPLATE_LIST_GET_SUCCESS,
    [TEMPLATE.TYPE.TAG]: types.TAG_LIST_GET_SUCCESS,
    [TEMPLATE.TYPE.INTEGRATION]: types.INTEGRATION_LIST_GET_SUCCESS,
    [TEMPLATE.TYPE.INTEGRATION_LOG]: types.INTEGRATION_LOGS_LIST_GET_SUCCESS,
    [TEMPLATE.TYPE.SELECT_EXISTING_CATEGORY]: types.GET_SELECT_EXISTING_DDLB_LIST,
    [TEMPLATE.TYPE.SELECT_EXISTING_GROUP]: types.GET_SELECT_EXISTING_DDLB_LIST,
    [TEMPLATE.TYPE.SELECT_EXISTING_QUESTION]: types.GET_SELECT_EXISTING_DDLB_LIST,
};

let listCurrentTypes = {
    [TEMPLATE.TYPE.QUESTIONNAIRE]: types.QUESTIONNAIRE_TEMPLATE_LIST_CURRENT_GET_SUCCESS,
    [TEMPLATE.TYPE.CATEGORY]: types.CATEGORY_TEMPLATE_LIST_CURRENT_GET_SUCCESS,
    [TEMPLATE.TYPE.GROUP]: types.GROUP_TEMPLATE_LIST_CURRENT_GET_SUCCESS,
    [TEMPLATE.TYPE.QUESTION]: types.QUESTION_TEMPLATE_LIST_CURRENT_GET_SUCCESS,
    [TEMPLATE.TYPE.ACTION]: types.ACTION_TEMPLATE_LIST_CURRENT_GET_SUCCESS,
    [TEMPLATE.TYPE.SELECT_EXISTING_CATEGORY]: types.GET_SELECT_EXISTING_DDLB_LIST,
    [TEMPLATE.TYPE.SELECT_EXISTING_GROUP]: types.GET_SELECT_EXISTING_DDLB_LIST,
    [TEMPLATE.TYPE.SELECT_EXISTING_QUESTION]: types.GET_SELECT_EXISTING_DDLB_LIST,
};

let listVersionsTypes = {
    [TEMPLATE.TYPE.QUESTIONNAIRE]: types.QUESTIONNAIRE_TEMPLATE_LIST_VERSIONS_GET_SUCCESS,
    [TEMPLATE.TYPE.CATEGORY]: types.CATEGORY_TEMPLATE_LIST_VERSIONS_GET_SUCCESS,
    [TEMPLATE.TYPE.GROUP]: types.GROUP_TEMPLATE_LIST_VERSIONS_GET_SUCCESS,
    [TEMPLATE.TYPE.QUESTION]: types.QUESTION_TEMPLATE_LIST_VERSIONS_GET_SUCCESS,
    [TEMPLATE.TYPE.ACTION]: types.ACTION_TEMPLATE_LIST_VERSIONS_GET_SUCCESS,
};

const action = {
    [TEMPLATE.MODE.ALL]: getTemplateListAll,
    [TEMPLATE.MODE.CURRENT]: getTemplateListCurrent,
    [TEMPLATE.MODE.VERSIONS]: getTemplateListVersions,
};


// ---------------------------------------------------------------------------------------------------------------------
// init and Get Template List (Currrent and Versions)
// ---------------------------------------------------------------------------------------------------------------------

// Clear filter, set page to 1 and reload.  Page Size is remains the same
export function initGetTemplateList(filter, templateType, templateMode, key, name, nameQualifier, defaultActions) {

    let initFilter = {
        'pageNumber': 1,
        'pageSize': filter.pageSize,
        ...sortDefault,
        ...filterDefault
    };

    initFilter.outstanding = filter.outstanding;

    initFilter.templateKey = (key !== undefined) ? key : filter.templateKey;
    initFilter.templateName = (name !== undefined) ? name : filter.templateName;
    initFilter.templateNameQualifier = (nameQualifier !== undefined) ? nameQualifier : filter.templateNameQualifier;
    initFilter.defaultActions = (defaultActions !== undefined) ? defaultActions : filter.defaultActions; //Array of actions used by versions

    return (dispatch) => {

        // When a List Page loads if there is no sort name then set to default sort by Last updated and clear pageNumber and Filters
        dispatch({type: types.UPDATE_PAGE_SORT_AND_FILTER, filterName: TEMPLATE.FILTER[templateMode][templateType], filter: initFilter});

        // Action Mode determines which action to call
        dispatch(action[templateMode](pageableObjFromFilter(initFilter), initFilter, templateType, key, name, nameQualifier, defaultActions));
    };
}

// Used when filter is to be persistent and stay the same
export function getTemplateList(filter, templateType, templateMode, key, name, defaultActions) {

    let tempFilter = {...filter};
    if (filter.pageSize === undefined) {
        tempFilter.pageSize = 10;
    }

    return (dispatch)=>{
        dispatch(action[templateMode](pageableObjFromFilter(tempFilter), tempFilter, templateType, key, name, defaultActions));
    };
}

// This is used by setFilter
export function getList(pageable, filter, templateType, templateMode, key, name, defaultActions) {
    let tempFilter = {...filter};
    if (filter.pageSize === undefined) {
        tempFilter.pageSize = 10;
    }
    return (dispatch)=>{
        dispatch(action[templateMode](pageable, tempFilter, templateType, key, name, defaultActions));
    };
}


// ---------------------------------------------------------------------------------------------------------------------
// Get Template List (Currrent aka Latest) However do NOT use pageable, instead use a default large amount so DDLB is not limited to page size
// ---------------------------------------------------------------------------------------------------------------------

export function getTemplateListForSelectFilter(templateType, templateMode, filter) {

    let newFilter = {...localInitialFilterObject};

    // Curently only Actions are filtered.  this will set the stage for DDLB load to use a filter.
    if (filter !== undefined) {
        newFilter.type = filter.type;  // Action type for now
    }

    return (dispatch)=>{
        action[templateMode](localSelectPagable, newFilter, templateType)(dispatch);
    };
}

// ---------------------------------------------------------------------------------------------------------------------
// update page Get Template List (Currrent and Versions)
// ---------------------------------------------------------------------------------------------------------------------

export function updatePageAndGetList(pageNumber, pageSize, filter, templateType, templateMode, event) {
    return (dispatch) => {
        // Set the new page details in the filter, page: is converted from template mode and type

        let tempPageSize = pageSize;
        if (tempPageSize === undefined) {
            tempPageSize = 10;
        }

        let changeAllPageSizes = event === undefined ? false : (event.altKey && event.ctrlKey);

        if (changeAllPageSizes) {
            dispatch({
                type: types.PAGE_CHANGE_SIZE_ALL_LISTS,
                filterName: TEMPLATE.FILTER[templateMode][templateType],
                filter: {pageNumber, pageSize}
            });
        }
        else
        {
            dispatch({type: types.PAGE_CHANGE, filterName: TEMPLATE.FILTER[templateMode][templateType], filter:{ pageNumber, pageSize}});
        }

        // Action Mode determines which action to call
        dispatch(action[templateMode]({ pageNumber: pageNumber, pageSize: pageSize, name: filter.name, direction: filter.direction}, filter, templateType));
    };
}

// ---------------------------------------------------------------------------------------------------------------------
// update page Get Template List (Currrent and Versions)
// ---------------------------------------------------------------------------------------------------------------------

export function updateSortAndGetList(name, direction, filter, templateType, templateMode) {

    let tempFilter = {...filter};
    if (tempFilter.pageSize === undefined) {
        tempFilter.pageSize = 10;
    }

    return (dispatch) => {
        // Set the new sort details in the filter, page: is converted from template mode and type
        dispatch({type: types.SORT_CHANGE, filterName: TEMPLATE.FILTER[templateMode][templateType], filter: {name, direction}});
        // Action Mode determines which action to call
        dispatch(action[templateMode]({ pageNumber: filter.pageNumber, pageSize: tempFilter.pageSize, name: name, direction: direction}, filter, templateType));
    };
}

// ---------------------------------------------------------------------------------------------------------------------
// Get Template List (Original, Currrent and Versions)
// ---------------------------------------------------------------------------------------------------------------------

// Get templates by filter (NOTE: This was the original action before adding current flag to templates, ,ay still be needed for DDLB population)
export function getTemplateListAll(pageable, filter, templateType) {

    return (dispatch)=>{
        TemplateApi.getTemplateList(pageable, filter, templateType).then((data) =>{
            // Issues with integrations list clearing so FORCING the issue here!
            dispatch({
                type: listClearTypes[templateType], // Convert templateType to a action type
            });
            dispatch({
                type: listTypes[templateType], // Convert templateType to a action type
                list: data.collection,
                totalRecords: data.page.totalElements,
                filterName: TEMPLATE.FILTER[TEMPLATE.MODE.ALL][templateType]
            });
        });
    };
}

// Get categories (current aka latest) by filter
export function getTemplateListCurrent(pageable, filter, templateType) {
    return (dispatch)=>{
        TemplateApi.getTemplateListCurrent(pageable, filter, templateType).then((data) =>{
            dispatch({
                type: listCurrentTypes[templateType], // Convert templateType to a action type
                list: data.collection,
                mode: filter.mode,
                totalRecords: data.page.totalElements,
                filterName: TEMPLATE.FILTER[TEMPLATE.MODE.CURRENT][templateType]
            });
        });
    };
}


// Get template (versions aka history) by filter (ie.. all  templates with matching templateKey)
export function getTemplateListVersions(pageable, filter, templateType, key, name, nameQualifier, defaultActions) {
    return (dispatch)=>{
        TemplateApi.getTemplateListVersions(pageable, filter, templateType, key).then((data) =>{
            dispatch({
                type: types.TEMPLATE_VERSIONS_LIST_CLEAR, // Convert templateType to a action type
            });
            dispatch({
                type: listVersionsTypes[templateType],  // Convert templateType to a action type
                list: data.collection,
                totalRecords: data.page.totalElements,
                templateKey: key === undefined ? filter.templateKey : key,
                templateName: name === undefined ? filter.templateName : name,
                templateNameQualifier: nameQualifier === undefined ? filter.templateNameQualifier : nameQualifier,
                defaultActions: defaultActions === undefined ? filter.defaultActions : defaultActions,
                filterName: TEMPLATE.FILTER[TEMPLATE.MODE.VERSIONS][templateType]
            });
        });
    };
}

// Get template (questionnaire references) for Categories, Groups and Questions
export function getTemplateReferences(templateType, id) {
    return (dispatch) => {
        TemplateApi.getTemplateReferences(templateType, id).then((references) => {
            dispatch({
                type: types.REFERENCE_LOAD_SUCCESS,
                references
            });
        });
    };
}


export function clearTemplateReferences() {
    return {
        type: types.REFERENCE_CLEAR
    };
}


