import React, {Component} from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import { DropzoneArea } from "mui-file-dropzone";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import CircularProgress from "@mui/material/CircularProgress";
import {uploadDocumentRequest, uploadClear} from '../../../actions/uploadFileActions';
import {notificationOpen} from "../../../actions/notificationActions";
import ArgoButtonHook from "../../common/ArgoButtonHook";
import * as Colors from "@mui/material/colors";


const QuestionnaireImportStyles = {
    root:{
        width: 500,
        height: 220,
        border: "2px solid #039BE5",
        alignItems: "center",
        justifyContent: "center",
        paddingLeft: '5px',
        paddingTop: '5px'
    },
    active: {
        border: "2px dashed #039BE5"
    },
    rejected: { // will only display in Chrome browser
        border: "2px dashed #E53935"
    },
    accepted: {
        border: "2px dashed #7CB342"
    }
};

const batchResultsStyles = {
    wrapperStyle: {
        display:"flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%"
    },
    list: {
        padding: 0
    },
    messageList: {
        listStyleType: "none"
    }
};

class QuestionnaireImport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            uploading: false
        };
    }

    componentWillReceiveProps (newProps) {
        if (this.props.uploadedFiles !== newProps.uploadedFiles){
            this.setState({
                uploading: false
            });
        }
    }

    onDrop = (files) => {
        this.setState((prevState) => {
            let newFiles = prevState.files.slice();

            for (let i = 0; i < files.length; i++){
                if (newFiles.length >= 10) {
                    this.props.notificationOpen("Questionnaire upload is limited to ten files at a time. Please limit the number of files you are uploading to ten or less.");
                    break;
                }
                newFiles.push(files[i]);
            }

            return {files: newFiles};
        });
    };

    onButtonClickImportFile = (event, fieldName) => {
        const data = new FormData();

        this.state.files.forEach(file=>{
            data.append('files', file);
        });
        this.props.uploadDocumentRequest(data);
        this.setState({
            files: [],
            uploading: true
        });
    };

    onButtonClickClear = (event, fieldName) => {
        this.setState({files: []});
        this.props.uploadClear();
    };

    onDeleteChip = (deleteFile) => {
        this.setState((prevState) => {
            let remainingFiles = prevState.files.filter(file => file.name !== deleteFile.name)
            return {files: remainingFiles}
        });
    }

    render() {

        let uploadedFilesReversed = this.props.uploadedFiles.slice(0).reverse();  // Reverse to put newest upload on top of list

        // https://www.npmjs.com/package/react-mui-dropzone

        return (
            <div id="QuestionnaireImport-container-div" style={{height: "100%", padding: "15px", marginTop: '64px'}}>

                <h2 className="primaryText">Import Questionnaire</h2>

                {/*// ===========================================================================================================*/}
                {/*// DROPZONE */}
                {/*// ===========================================================================================================*/}

                {this.state.uploading ? <div><CircularProgress size={250}/></div> :
                    <div id="QuestionnaireImport-DropzoneArea-container-div">
                        <DropzoneArea
                            id="QuestionnaireImport-dropzone"
                            style={QuestionnaireImportStyles.root}
                            fileObjects={this.state.files}
                            accept="text/xml"
                            onDrop={this.onDrop}
                            acceptedFiles={['text/xml']}
                            showFileNames={true}
                            filesLimit={10}
                            showAlerts={['error']}
                            showPreviewsInDropzone={true}
                            useChipsForPreview
                            showFileNamesInPreview
                            onDelete={this.onDeleteChip}
                            previewChipProps={{size: "small", variant: "outlined", sx: {marginRight: 100}}}
                        />
                        <div>
                            {({isDragActive, isDragReject, acceptedFiles, rejectedFiles}) => {
                                if (isDragReject)
                                    return "This file is not authorized";
                                if (isDragActive)
                                    return "This file is authorized";
                                return acceptedFiles.length || rejectedFiles.length ?
                                    <div style={{background: "blue"}}>
                                        {`Accepted ${this.state.files.length}, rejected ${rejectedFiles.length} files`}
                                        <ul>{this.state.files.map(file => (
                                            <li key={file.name}>
                                                {file.name}
                                            </li>))}
                                        </ul>
                                    </div>
                                    : "Please drop files here, or click to browse and select files to upload!";
                            }}
                        </div>
                    </div>
                }

                <div style={{height: "20px"}}>&nbsp;</div>

                {/*// ===========================================================================================================*/}
                {/*// BUTTONS */}
                {/*// ===========================================================================================================*/}

                <ArgoButtonHook
                    fileName="QuestionnaireImport"
                    fieldName="import"
                    variant="contained"
                    label="IMPORT"
                    width={140}
                    containedTextColor={'white'}
                    containedBgColor={Colors.blue[500]}
                    containedHoverTextColor={'#010203'}
                    containedHoverBgColor={Colors.blue[500]}
                    onClick={this.onButtonClickImportFile}
                    disabled={!this.state.files.length}
                />

                <div style={{height: "20px"}}>&nbsp;</div>

                {/*// ===========================================================================================================*/}
                {/*// TABLE OF LOADED QUESTIONNAIRES */}
                {/*// ===========================================================================================================*/}

                <TableContainer id="QuestionnaireImport-TableContainer" component={Paper}>
                    <Table id="QuestionnaireImport-Table" sx={{minWidth: 650}} aria-label="simple table" size="small">
                        <TableHead id="QuestionnaireImport-TableHead">
                            <TableRow>
                                <TableCell>Filename</TableCell>
                                <TableCell>Processed</TableCell>
                                <TableCell colSpan={2}>Message</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {uploadedFilesReversed.map((result, index) => {
                                    let fileName, successful, messages;
                                    for (let key in result) {
                                        if (result.hasOwnProperty(key)) {
                                            let obj = result[key];
                                            fileName = key === "processedFileName" ? obj : fileName;
                                            successful = key === "successfulRun" ? obj : successful;
                                            messages = key === "messages" ? obj : messages;

                                        }
                                    }

                                    return (
                                        <TableRow height={3} key={index}
                                                  sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                            <TableCell>{fileName}</TableCell>
                                            <TableCell>{successful === true ? "Yes" : "No"}</TableCell>
                                            <TableCell colSpan="2">

                                                <ul style={{...batchResultsStyles.list, height: 25, padding: 0, margin: 0}}>
                                                    {messages.map((value, index) => {
                                                        return (
                                                            <li key={index} style={batchResultsStyles.messageList}>
                                                                {value}
                                                            </li>
                                                        );
                                                    })}
                                                </ul>

                                            </TableCell>
                                        </TableRow>);
                                }
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                <div>&nbsp;</div>

                <div style={{float: "right", paddingBottom: "20px"}}>
                    <ArgoButtonHook
                        fileName="QuestionnaireImport"
                        fieldName="clear"
                        variant="contained"
                        label="CLEAR"
                        width={140}
                        containedTextColor={'white'}
                        containedBgColor={Colors.blue[500]}
                        containedHoverTextColor={'#010203'}
                        containedHoverBgColor={Colors.blue[500]}
                        onClick={this.onButtonClickClear}
                        disabled={(this.state.uploading) || (this.props.uploadedFiles.length === 0)}
                    />
                </div>

            </div>
        );
    }

}

QuestionnaireImport.propTypes = {

};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({uploadDocumentRequest, uploadClear, notificationOpen}, dispatch);
}

function mapStateToProps(state) {
    return {
        uploadedFiles: state.uploadedFiles.items
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionnaireImport);
