
// Check for screen size

export const displayOneQuestionPerPage = () => {

    let onePerPage = false;

    let screenHeight = window.screen.height;
    let screenWidth = window.screen.width;

    if ((screenHeight < 800) || (screenWidth < 450)) {
        onePerPage = true;
    }

    return onePerPage;
};

// Possible fix for phone change direction and refresh NPS (Net Promoter Score) from 11 strait to pyramid/honeycomb
// https://stackoverflow.com/questions/62472029/react-native-detect-screen-rotation-change-using-portrait-mode
