import {axiosWrap, GET, PUT} from "./axiosWrapper";
import {systemConstants} from "../constants/systemConstants";


// Send reference key to QD Server, will return instance or create and return if one does not exist.
// Example: http://localhost:8081/qd2/api/v1/questionnaire-reference?qid=cUm1tzvWA9XvYjY83oI9

export function getQuestionnaireInstanceIdFromRefKeyApi(refKey, firstAnswerIndex) {
    if (firstAnswerIndex !== null) {
        return axiosWrap(GET, systemConstants.QD_API_URL + "questionnaire-reference?qid=" + refKey + "&firstAnswerIndex=" + firstAnswerIndex)
            .then((data) => {
                return data;
            });
    }
    else {
        return axiosWrap(GET, systemConstants.QD_API_URL + "questionnaire-reference?qid=" + refKey)
            .then((data) => {
                return data;
            });
    }
}

export function getNewTokenIdAfterExpiration(refKey) {
    return axiosWrap(GET, systemConstants.QD_API_URL + "questionnaire-reference?qid=" + refKey)
        .then((data) => {
            return data.id_token;
        });
}

export function getQuestionnaireReadonlyInstanceIdFromRefKeyApi(refKey) {
    return axiosWrap(GET, systemConstants.QD_API_URL + "questionnaire-reference/readonly?qid=" + refKey)
        .then((data) => {
            return data;
        });
}


export function getQuestionnaireInstanceApi(id) {
    return axiosWrap(GET, systemConstants.QD_API_URL + "questionnaires/" + id)
        .then((data) => {
            return data;
        });
}


export function answerQuestionAnswerOptionsApi(id, answerOptionKey, answerOptionDTO) {
    return axiosWrap(PUT, systemConstants.QD_API_URL + `questions/${id}/answer-options/${answerOptionKey}`, answerOptionDTO).then((response) => {
        return response;
    });
}


export function answerQuestionTextApi(id, questionDTO) {
    return axiosWrap(PUT, systemConstants.QD_API_URL + `questions/${id}`, questionDTO).then((response) => {
        return response;
    });
}


export function getQuestionnaireFullApi(id) {
    return axiosWrap(GET, systemConstants.QD_API_URL + `questionnaires/${id}`).then((response) => {
        return response;
    });
}


export function startQuestionnaireApi(id) {
    return axiosWrap(PUT, systemConstants.QD_API_URL + `questionnaires/${id}`, {
        started: true
    }).then((response) => {
        return response;
    });
}


export function completeQuestionnaireApi(id) {
    return axiosWrap(PUT, systemConstants.QD_API_URL + `questionnaires/${id}`, {
        complete: true
    }).then((response) => {
        return response;
    });
}
