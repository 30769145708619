import * as types from "../constants/actionConstants";
import {pageEmpty, sortDefault, filterDefault} from "../constants/filterConstants";


// Select DDLBs default to unlimited size until we develop a pagaable/filter select pattern
export const selectPageable = Object.freeze({
    ...pageEmpty,
    ...sortDefault    // selectSortDefault will change DDLB to sort by Title by default (PLACE HOLDER FOR FUTURE ENHANCEMENT)
});

export const initialFilterObject = Object.freeze({
    ...filterDefault
});

export function pageInitGetList(page, getListAction, filter) {

    return (dispatch) => {

        // When a List Page loads if there is no sort name then set to default sort by Last updated and clear pageNumber and Filters
        if (filter.name === "") {

            let initFilter = {
                'pageNumber': 1,
                'pageSize': filter.pageSize,
                ...sortDefault,
                ...filterDefault
            };

            dispatch({type: types.UPDATE_PAGE_SORT_AND_FILTER, filterName: page, filter: initFilter});

            getListAction({
                pageNumber: initFilter.pageNumber,
                pageSize: initFilter.pageSize,
                name: initFilter.name,
                direction: initFilter.direction
            }, initFilter);
        }
        else {
            getListAction({
                pageNumber: filter.pageNumber,
                pageSize: filter.pageSize,
                name: filter.name,
                direction: filter.direction
            }, filter);
        }
    };
}

export function updatePage(filterName, pageNumber, pageSize, event) {

    let changeAllPageSizes = event === undefined ? false : (event.altKey && event.ctrlKey);

    return (dispatch) => {
        if (changeAllPageSizes) {
            dispatch({
                type: types.PAGE_CHANGE_SIZE_ALL_LISTS,
                filterName: filterName,
                filter: {pageNumber, pageSize}
            });
        }
        else {
            dispatch({type: types.PAGE_CHANGE, filterName, filter: {pageNumber, pageSize}});
        }
    }
}

export function updateSort(filterName, name, direction) {
    return {type: types.SORT_CHANGE, filterName, filter: {name, direction}};
}

export function updatePageAndGetList(filterName, pageNumber, pageSize, getListAction, filter) {
    return (dispatch) => {
        dispatch({type: types.SORT_CHANGE, filterName, filter:{ pageNumber, pageSize}});
        getListAction({ pageNumber: pageNumber, pageSize: pageSize, name: filter.name, direction: filter.direction}, filter);
    };
}

export function updateSortAndGetList(filterName, name, direction, getListAction, filter) {
    return (dispatch) => {
        dispatch({type: types.SORT_CHANGE, filterName, filter: {name, direction}});
        getListAction({ pageNumber: filter.pageNumber, pageSize: filter.pageSize, name: name, direction: direction}, filter);
    };
}

export function initPageAndSortAndFilterThenGetList(filterName, filter, getListAction) {
    // filter is passed in for pageSize.  We should never reset users selected page size.
    let initFilter = {
        'pageNumber': 1,
        'pageSize': filter.pageSize,
        ...sortDefault,
        ...filterDefault
    };

    return (dispatch) => {
        dispatch({type: types.UPDATE_PAGE_SORT_AND_FILTER, filterName: filterName, filter: initFilter});
        getListAction({ pageNumber: initFilter.pageNumber, pageSize: initFilter.pageSize, name: initFilter.name, direction: initFilter.direction}, initFilter)(dispatch);
    };
}

// export function initPageAndSortAndFilterThenGetListForOutstanding(filterName, filter, getListAction) {
//     // filter is passed in for pageSize.  We should never reset users selected page size.
//     let initFilter = {
//         'pageNumber': 1,
//         'pageSize': filter.pageSize,
//         ...sortDefault,
//         ...filterDefault
//     };
//
//     initFilter.outstanding = true;
//
//     return (dispatch) => {
//         dispatch({type: types.UPDATE_PAGE_SORT_AND_FILTER, filterName: filterName, filter: initFilter});
//         getListAction({ pageNumber: initFilter.pageNumber, pageSize: initFilter.pageSize, name: initFilter.name, direction: initFilter.direction}, initFilter, true)(dispatch);
//     };
// }




