import * as types from "../constants/actionConstants";

const initialState = {
    list: [],
    id: "",
    name: ""
};

function integrationLogsListReducer(state = initialState, action) {
    switch (action.type) {
        case types.INTEGRATION_LOGS_LIST_GET_SUCCESS:
            return {
                list: action.list,
                id: action.id,
                name: action.name
            };

        case types.INTEGRATION_LOGS_LIST_CLEAR:
            return initialState;

        default:
            return state;
    }
}

export default integrationLogsListReducer;
