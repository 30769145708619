import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {closeDrawer} from "../../actions/drawerActions";
import ActionEditor from "../qd/actions/ActionEditor";
import {createActionTemplateAndCloseDrawer, saveActionTemplateAndCloseDrawer} from "../../actions/actionTemplateActions";
import {QD_URL} from "../../constants/systemConstants";
import * as TEMPLATE from "../../constants/templateConstants";
import ArgoAppBar from "../common/ArgoAppBar";
import {withRouter} from "../../utilities/withRouter";


class ActionCreateEditDrawer extends Component {

    closeDrawer = () => {
        this.props.closeDrawer();
    };

    save = async (actionTemplate) => {
        if (this.props.createMode) {
            this.props.createActionTemplateAndCloseDrawer(actionTemplate, this.props.filter, this.onCreateSuccess);
        }
        else {
            await this.props.saveActionTemplateAndCloseDrawer(actionTemplate, this.props.filter, this.props.versionsFilter, this.props.templateMode);
        }

    };

    onCreateSuccess = () => {
        <withRouter navigate={QD_URL + "/actions"}/>;
    };

    render() {
        
        return (
            <div style={{height: "100%"}}>
                <ArgoAppBar
                    title={this.props.createMode ? "Create Action" : "Edit Action"}
                    showMenuIconButton={false}
                    noIcon={true}
                    isDrawer={true}
                    width={actionCreateEditDrawerProps.width}
                />

                <div style={{height: '64px'}}></div>

                <ActionEditor
                    createMode={this.props.createMode}
                    cancel={this.closeDrawer}
                    save={this.save}
                    templateKey={this.props.templateKey}
                    templateId={this.props.templateId}
                    templateMode={this.props.templateMode}
                    width={actionCreateEditDrawerProps.width}
                />
            </div>
        );
    }
}

ActionCreateEditDrawer.defaultProps = {
    createMode: false,
    templateKey: "",
    templateId: null,
    templateMode: ""
};

ActionCreateEditDrawer.propTypes = {
    filter: PropTypes.func,
    templateKey: PropTypes.string,
    templateId: PropTypes.number,
    templateMode: PropTypes.string,  // mode can be VERSIONS
    createMode: PropTypes.bool,
};


function mapDispatchToProps(dispatch) {
    return bindActionCreators({closeDrawer, createActionTemplateAndCloseDrawer, saveActionTemplateAndCloseDrawer}, dispatch);
}

function mapStateToProps(state) {
    return {
        filter: state.filter[TEMPLATE.FILTER.current.action]
    };
}

const actionCreateEditDrawerProps = {
    opensecondary: true,
    width: "90%",
    docked: false,
    anchor: "right"
};

export default connect(mapStateToProps, mapDispatchToProps) (ActionCreateEditDrawer);
export {actionCreateEditDrawerProps};
