import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {closeDrawer} from "../../actions/drawerActions";
import CategoryReview from "../qd/categories/CategoryReview";
import ArgoAppBar from "../common/ArgoAppBar";
import ClickAwayListener from '@mui/material/ClickAwayListener';


class CategoryReviewDrawer extends Component {

    render() {

        return (
            <ClickAwayListener onClickAway={(event) => {
                // Checks is target is BODY first because portal preview DDLBs cause target to be body and we shouldn't close on that
                if (event.target.tagName !== 'BODY') this.props.closeDrawer()
            }}>
                <div id="CategoryReviewDrawer-container-div" style={{height: "100%"}}>
                    <ArgoAppBar
                        title="Review Category"
                        showMenuArrowButton="LEFT"
                        onLeftIconButtonTouchTap={this.props.closeDrawer}
                        isDrawer={true}
                        width={categoryReviewDrawerProps.width}
                    />

                    <div style={{height: '64px'}}></div>

                    <div id="CategoryReviewDrawer-CategoryReview-container-div">
                        <CategoryReview
                            templateKey={this.props.templateKey}
                            templateId={this.props.templateId}
                            scrollTo="CategoryReviewDrawer-CategoryReview-container-div"
                            width={categoryReviewDrawerProps.width}
                        />
                    </div>
                </div>
            </ClickAwayListener>
        );
    }
}

CategoryReviewDrawer.propTypes = {
    templateKey: PropTypes.string,
    templateId: PropTypes.string
};


function mapDispatchToProps(dispatch) {
    return {close: bindActionCreators(closeDrawer, dispatch)};
}

const categoryReviewDrawerProps = {
    opensecondary: true,
    width: "90%",
    docked: false,
    anchor: "right"
};

export default connect(null, mapDispatchToProps)(CategoryReviewDrawer);
export {categoryReviewDrawerProps};
