import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as Colors from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import AnswerEditor from "../answers/AnswerEditor";
import TagSelectOrCreate from "../tags/TagSelectOrCreate";
import ArgoMetadataEditor from "../../common/ArgoMetadataEditor";
import ActionEditor from "../actions/ActionEditor";
import GroupEditor from "../groups/GroupEditor";
import {
    initializeQuestionEditor,
    updateQuestionTemplate,
    clearQuestionEditor,
    validateQuestionNameAndQualifierUnique,
    checkQuestionReference,
    checkQuestionVersion
} from "../../../actions/questionEditorActions";
import {getQuestionTemplate} from "../../../actions/questionTemplateActions";
import {addAnswer, removeAnswer} from "../../../actions/answerEditorActions";
import {createActionTemplate} from "../../../actions/actionTemplateActions";
import {createGroupTemplate} from "../../../actions/groupTemplateActions";
import {getMetadataDefinitions} from "../../../actions/metadataDefinitionActions";
import {STATE_LIST} from "../../../constants/dataConstants";
import ArgoQuestionFormatDefinition from "../../common/ArgoQuestionFormatDefinition";
import ArgoQuestionFormatCustom from "../../common/ArgoQuestionFormatCustom";
import * as icon from "../../../constants/iconConstants";
import * as questionType from "../../../constants/questionTypeConstants";
import ArgoCheckboxHook from "../../common/ArgoCheckboxHook";
import ArgoToolbarHook from "../../common/ArgoToolbarHook";
import ArgoTextFieldSelectHook from "../../common/ArgoTextFieldSelectHook";
import ArgoTextFieldHook from "../../common/ArgoTextFieldHook";
import ArgoButtonHook from "../../common/ArgoButtonHook";
import ArgoLikertAnswerGenerator from "../../common/ArgoLikertAnswerGenerator";
import ArgoTextEntryWithCustomOverride from "../../common/ArgoTextEntryWithCustomOverride";
import ArgoPortalPreview from "../../common/ArgoPortalPreview";
import * as validate from "../../../utilities/validate";
import * as metadataUtils from "../../../utilities/metadata";
import * as metadata from "../../../utilities/metadata";
import * as questionTypes from "../../../constants/questionTypeConstants";
import * as TEMPLATE from "../../../constants/templateConstants";
import * as metadataTemplateTypes from "../../../constants/metadataTemplateTypes";
import * as portalShared from "../../../portal/shared/metadataConstantsAndUtilities"


const QUESTION_TEMPLATE_MODE = false;


class QuestionEditor extends Component {

    constructor(props) {
        super(props);

        this.state = {
            pageInit: false,
            nameErrorText: " ",
            answerErrorText: " ",
            metadataErrorText: "",
            metadataErrorFlag: false,
            isLoading: false,
            toolbarErrorText: "",
            answerIndex: null,
            headerInitializer: "",
            yesNoMode: false,
            displayAsButtonGroup: false,
            displayAsLikert: false,
            useLikertAnswerShowGroup: false,
            useLikertAnswerActions: false,
            addAnswersFromTemplate: false,
            addAnswerTemplate: "1",
            typeDisplay: metadata.defaultCustomMetadataObj,
            labelDisplay: metadata.defaultCustomMetadataObj,
            nameDisplay: metadata.defaultCustomMetadataObj,
            descDisplay: metadata.defaultCustomMetadataObj,
            questionSubFormat: '',
            keyMetricCategorys: [],
            keyMetricCategorySelectedIndex: 0,
            likertAddAnswerTemplate: "2",
            likertAddAnswerTemplateFormat: "radios",
            likertMode: icon.LIKERT_ICON_MODE_DEFAULT,
            likertAnswerTextDisplay: icon.LIKERT_TITLES_ALL,
            likertResponsiveLabels: icon.LIKERT_ICON_LABELS_NO_TEXT,
            likertTitles: "none",
            likertTitleLeft: "",
            likertTitleCenter: "",
            likertTitleRight: "",
            alignAnswersRight: false,
            showActionEditor: false,
            showGroupEditor: false,
            metadataSet: false,
            initialScroll: false
        };

    }

    componentDidMount() {

        // Get any preset metadata definitions for questions. ie REPORTING CATEGORIES (KEY METRICS)
        this.props.getMetadataDefinitions(metadataTemplateTypes.QuestionTemplate);

        if (this.props.templateOriginal) {
            this.props.initializeQuestionEditor(this.props.templateOriginal, this.props.questionDepth);

            this.props.checkQuestionVersion(this.props.templateOriginal.name, this.props.questionDepth); // Get version to determine if question type can be changed

            if (this.props.templateOriginal.questionType === questionTypes.LIKERT_RADIO) {
                // We get references for LIKERTS so if they belong to a group, then question type can NOT be changed
                this.props.checkQuestionReference(this.props.templateOriginal.id, this.props.questionDepth);
            }

            this.pageInit(this.props);
        } else {

            // In case's where the question is edited from the group screen, group_template.questionKeys only has keys
            // So every group or that uses this question will reference the question_key/current
            if (this.props.templateKey || this.props.templateId) {
                this.setState({isLoading: true});
                this.props.getQuestionTemplate(this.props.templateKey, this.props.templateId);
            }
            else {
                this.props.initializeQuestionEditor(this.props.createInitialTemplate, this.props.questionDepth);
            }
        }

    }

    componentWillReceiveProps(nextProps) {

        if (!this.state.initialScroll) {
            let scrollTo = (this.props.scrollTo === "") ? "QuestionEditor-height-div" : this.props.scrollTo;
            let page = document.getElementById(scrollTo);
            if (page) {
                page.scrollIntoView(false);
                this.setState({initialScroll: true});
                return;
            }
        }

        // if templateOriginal is loaded and the templateKey matches the current templateKey and is different from the loaded template (check id here?)
        let isTemplateLoaded = (nextProps.templateOriginal && nextProps.templateOriginal.templateKey === nextProps.templateKey && nextProps.template.templateKey !== nextProps.templateKey);

        // The loaded template is stale and the correct template is loaded
        let editTemplateReady = nextProps.template.id !== nextProps.templateId && (nextProps.templateOriginal && nextProps.templateOriginal.id === nextProps.templateId);

        if (!this.state.metadataSet){
            if (isTemplateLoaded && editTemplateReady) {

                this.setState({isLoading: false});

                nextProps.initializeQuestionEditor(nextProps.templateOriginal, nextProps.questionDepth);

                nextProps.checkQuestionVersion(nextProps.templateOriginal.name, nextProps.questionDepth);

                if (nextProps.templateOriginal.questionType === questionTypes.LIKERT_RADIO)
                    nextProps.checkQuestionReference(nextProps.templateOriginal.id, nextProps.questionDepth);

                this.pageInit(nextProps);
            }

            // NOTE: Before metadataDefinition is populated it is an empty collections array.
            if ((!nextProps.metadataDefinition.hasOwnProperty("collection")) && (this.state.keyMetricCategorys.length === 0)) {

                let tempKeyMetricCategorys = [];
                tempKeyMetricCategorys.push("None");
                for (let i = 0; i < nextProps.metadataDefinition.length; i++) {
                    if (nextProps.metadataDefinition[i].name === portalShared.KEY_METRIC_CATEGORY_QUESTION) {
                        for (let j = 0; j < nextProps.metadataDefinition[i].listValues.length; j++) {
                            tempKeyMetricCategorys.push(nextProps.metadataDefinition[i].listValues[j]);
                        }
                    }
                }

                this.setState({keyMetricCategorys: tempKeyMetricCategorys}, () => this.pageInit(nextProps));
            }

        }

        this.checkForErrorsOnScreen(nextProps);
    }

    componentWillUnmount() {
        this.props.clearQuestionEditor(this.props.questionDepth);
    }


    // Allow child components to set errors on this page.
    setStateCallback = (stateObj) => {

        let objKeyArray = Object.keys(stateObj);

        if (objKeyArray !== "null") {
            this.setState(stateObj);
        }

        // , this.checkForErrorsOnPage(this.props)
    }


    pageInit = (currProps) => {

        let tempDisplayAsButtonGroup = false;
        let tempDisplayAsLikert = false;
        let tempUseLikertAnswerShowGroup = false;
        let tempUseLikertAnswerActions = false;

        let metadataObj = currProps.templateOriginal?.metadata ? currProps.templateOriginal?.metadata : {};

        let tempTypeDisplayObj = metadata.setTextObjSelectedAndValueAndValueObj(metadataObj, portalShared.CUSTOM_DDO_QUE_FORMAT);
        let tempLabelDisplayObj = metadata.setTextObjSelectedAndValueAndValueObj(metadataObj, portalShared.CUSTOM_DDO_QUE_LABEL);

        let tempNameDisplayObj = metadata.setTextObjSelectedAndValueAndValueObj(metadataObj, portalShared.CUSTOM_DDO_NAME);
        let tempDescDisplayObj = metadata.setTextObjSelectedAndValueAndValueObj(metadataObj, portalShared.CUSTOM_DDO_DESC);

        let tempKeyMetricCategorySelectedIndex = 0;
        let tempLikertTitles = "none";
        let tempLikertTitleLeft = "";
        let tempLikertTitleCenter = "";
        let tempLikertTitleRight = "";


        if (metadataObj.hasOwnProperty(portalShared.DISPLAY_AS)) {
            tempDisplayAsButtonGroup = true;
        }
        // if (metadataObj.hasOwnProperty("display-def-as-likert")) {
        //     tempDisplayAsLikert = true;
        // }
        if (metadataObj.hasOwnProperty("display-def-use-show-group")) {
            tempUseLikertAnswerShowGroup = true;
        }

        if (metadataObj.hasOwnProperty("display-def-use-actions")) {
            tempUseLikertAnswerActions = true;
        }

        if (metadataObj.hasOwnProperty(portalShared.CUSTOM_DDO_QUE_FORMAT)) {
            tempTypeDisplayObj.selected = "custom";
            tempTypeDisplayObj.metadataValue = metadataObj[portalShared.CUSTOM_DDO_QUE_FORMAT];
            tempTypeDisplayObj.metadataValueObj = JSON.parse(tempTypeDisplayObj.metadataValue);

            tempDisplayAsLikert = (tempTypeDisplayObj.metadataValueObj.displayAs === "likert");
        }

        if (metadataObj.hasOwnProperty(portalShared.CUSTOM_DDO_QUE_LABEL)) {
            tempLabelDisplayObj.selected = "custom";
            tempLabelDisplayObj.metadataValue = metadataObj[portalShared.CUSTOM_DDO_QUE_LABEL];
            tempLabelDisplayObj.metadataValueObj = JSON.parse(tempLabelDisplayObj.metadataValue);
        }

        tempNameDisplayObj = metadata.setTextObjSelectedAndValueAndValueObj(metadataObj, portalShared.CUSTOM_DDO_NAME);

        tempDescDisplayObj = metadata.setTextObjSelectedAndValueAndValueObj(metadataObj, portalShared.CUSTOM_DDO_DESC);

        if (metadataObj.hasOwnProperty(portalShared.KEY_METRIC_CATEGORY_QUESTION)) {
            tempKeyMetricCategorySelectedIndex = this.state.keyMetricCategorys.indexOf(metadataObj[portalShared.KEY_METRIC_CATEGORY_QUESTION]);
        }

        if (metadataObj.hasOwnProperty(portalShared.DISPLAY_DEF_SHOW_QUESTION_GRAYBAR)) {
            tempLikertTitles = "graybar"
        }

        if (metadataObj.hasOwnProperty(portalShared.DISPLAY_DEF_LIKERT_TITLE_LEFT)) {
            tempLikertTitleLeft = metadataObj[portalShared.DISPLAY_DEF_LIKERT_TITLE_LEFT];
            if ( tempLikertTitles !== "graybar") {
                tempLikertTitles = "standard";
            }
        }
        if (metadataObj.hasOwnProperty(portalShared.DISPLAY_DEF_LIKERT_TITLE_CENTER)) {
            tempLikertTitleCenter = metadataObj[portalShared.DISPLAY_DEF_LIKERT_TITLE_CENTER];
            if ( tempLikertTitles !== "graybar") {
                tempLikertTitles = "standard";
            }
        }
        if (metadataObj.hasOwnProperty(portalShared.DISPLAY_DEF_LIKERT_TITLE_RIGHT)) {
            tempLikertTitleRight = metadataObj[portalShared.DISPLAY_DEF_LIKERT_TITLE_RIGHT];
            if ( tempLikertTitles !== "graybar") {
                tempLikertTitles = "standard";
            }
        }

        this.setState({
            pageInit: true,
            displayAsButtonGroup: tempDisplayAsButtonGroup,
            displayAsLikert: tempDisplayAsLikert,
            useLikertAnswerShowGroup: tempUseLikertAnswerShowGroup,
            useLikertAnswerActions: tempUseLikertAnswerActions,
            typeDisplay: metadata.setCustomMetadataObj(tempTypeDisplayObj),
            labelDisplay: metadata.setCustomMetadataObj(tempLabelDisplayObj),
            nameDisplay: metadata.setCustomMetadataObj(tempNameDisplayObj),
            descDisplay: metadata.setCustomMetadataObj(tempDescDisplayObj),
            keyMetricCategorySelectedIndex: tempKeyMetricCategorySelectedIndex,
            likertTitles: tempLikertTitles,
            likertTitleLeft: tempLikertTitleLeft,
            likertTitleCenter: tempLikertTitleCenter,
            likertTitleRight: tempLikertTitleRight,
            alignAnswersRight: metadataObj.hasOwnProperty(portalShared.DISPLAY_DEF_ALIGN_QUESTION_ANSWER_RIGHT),
        });

    };


    handleToolbarButtonClick = (event, fieldName, buttonTitle) => {
        switch (buttonTitle) {
            case "CANCEL":
                this.props.cancel();
                break;
            case "SAVE":

                let template = {
                    ...this.props.template,
                    answerOptionTemplates: this.props.answerOptionTemplates
                };

                // If edit is being called from question expander, after groupEditor state is set it calls saveQuestion
                if (this.props.questionExpanderEdit) {
                    this.props.questionExpanderSetStateCallback({questionTemplate: template}, true, TEMPLATE.TYPE.QUESTION);
                    return;
                }

                this.props.save(template); // Save will close edit drawer and reviewViewer drawer
                break;
            // no default
        }

        event.preventDefault(); // Fix for the enter key propagating to the next focusable element
    }


    onTextChange = (event, fieldName) => {

        let value = event.target.value;

        if ((fieldName === "nameQualifier") && (value==="")) {
            value = null;
        }

        this.handleInputChangeAndUpdateTemplate(event, fieldName, value);
    };

    onSelectChange = (event, fieldName) => {

        let selected = event.target.value;

        let tempLikertMode = "";
        let tempLikertAnswerTextDisplay = "";
        //let tempLikertResponsiveLabels = "";

        switch (fieldName) {
            case "questionType":
                this.handleInputChangeAndUpdateTemplate(event, fieldName, selected);
                this.setState({[fieldName]: selected})
                break;
            case "keyMetricCategory":
                let index = event.target.value;
                selected = this.state.keyMetricCategorys[index];  // Note: "None: has been added to thee metadata list this.state.keyMetricCategorys, so metadata needs to adjust

                this.setState({keyMetricCategorySelectedIndex: index}, () => metadataUtils.setCustomMetadata(null, this.props.template, this.props.updateQuestionTemplate, this.setStateCallback,fieldName, selected, portalShared.KEY_METRIC_CATEGORY_QUESTION, selected, this.props.questionDepth));
                break;
            case "likertAddAnswerTemplate":
                this.setState({[fieldName]: selected, likertAddAnswerTemplateFormat: "radios", likertMode: icon.LIKERT_ICON_MODE_DEFAULT, likertAnswerTextDisplay: icon.LIKERT_TITLES_ALL, likertResponsiveLabels: icon.LIKERT_ICON_LABELS_NO_TEXT});
                break;

            case "likertAddAnswerTemplateFormat":

                if (selected === "stars" || selected === "facesA" || selected === "facesD") {
                    tempLikertMode = icon.LIKERT_ICON_MODE_NO_FILL;
                } else {
                    tempLikertMode = icon.LIKERT_ICON_MODE_DEFAULT;
                }

                if (selected === "radios" || selected === "thumbs") {

                    tempLikertAnswerTextDisplay = icon.LIKERT_TITLES_ALL;

                } else if (selected === "stars") {

                    if (this.state.likertAddAnswerTemplate === "4" || this.state.likertAddAnswerTemplate === "6") {
                        tempLikertAnswerTextDisplay = icon.LIKERT_TITLES_ALL;
                    } else {
                        tempLikertAnswerTextDisplay = icon.LIKERT_TITLES_ALL;
                    }

                } else if (selected === "facesA" || selected === "facesD") {

                    tempLikertAnswerTextDisplay = icon.LIKERT_TITLES_ALL;

                // } else if (selected === "circles" && this.state.likertAddAnswerTemplate === "11" && (this.state.likertMode === icon.LIKERT_ICON_MODE_COLORS || this.state.likertMode === icon.LIKERT_ICON_MODE_COLORS_ALT)) {
                //
                //     tempLikertAnswerTextDisplay = icon.LIKERT_TITLES_HIDDEN;

                } else if (selected === "circles" || selected === "squares") {

                    tempLikertAnswerTextDisplay = icon.LIKERT_TITLES_EMBEDDED;

                } else {
                    tempLikertAnswerTextDisplay = icon.LIKERT_TITLES_HIDDEN;
                }

                this.setState({[fieldName]: selected, likertMode: tempLikertMode, likertAnswerTextDisplay: tempLikertAnswerTextDisplay, likertResponsiveLabels: icon.LIKERT_ICON_LABELS_NO_TEXT});
                break;

            case "likertMode":

                if (this.state.likertAddAnswerTemplateFormat === "radios" || this.state.likertAddAnswerTemplateFormat === "thumbs") {

                    tempLikertAnswerTextDisplay = icon.LIKERT_TITLES_ALL;

                } else if (this.state.likertAddAnswerTemplateFormat === "stars") {

                    if (this.state.likertAddAnswerTemplate === "4" || this.state.likertAddAnswerTemplate === "6") {
                        tempLikertAnswerTextDisplay = icon.LIKERT_TITLES_ALL;
                    } else {
                        tempLikertAnswerTextDisplay = icon.LIKERT_TITLES_ALL;
                    }

                } else if (this.state.likertAddAnswerTemplateFormat === "facesA" || this.state.likertAddAnswerTemplateFormat === "facesD") {

                    tempLikertAnswerTextDisplay = icon.LIKERT_TITLES_ALL;

                // } else if (this.state.likertAddAnswerTemplateFormat === "circles" && this.state.likertAddAnswerTemplate === "11" && (selected === icon.LIKERT_ICON_MODE_COLORS || selected === icon.LIKERT_ICON_MODE_COLORS_ALT)) {
                //
                //     tempLikertAnswerTextDisplay = icon.LIKERT_TITLES_HIDDEN;

                } else if (this.state.likertAddAnswerTemplateFormat === "circles" || this.state.likertAddAnswerTemplateFormat === "squares") {

                    tempLikertAnswerTextDisplay = icon.LIKERT_TITLES_EMBEDDED;

                } else {
                    tempLikertAnswerTextDisplay = icon.LIKERT_TITLES_HIDDEN;
                }

                this.setState({[fieldName]: selected, likertAnswerTextDisplay: tempLikertAnswerTextDisplay, likertResponsiveLabels: icon.LIKERT_ICON_LABELS_NO_TEXT});
                break;

            case "likertAnswerTextDisplay":
                this.setState({[fieldName]: selected, likertResponsiveLabels: icon.LIKERT_ICON_LABELS_NO_TEXT});
                break;

            default:
                this.setState({[fieldName]: selected});
        }
    };

    onCheckBoxChange = (event, fieldName) => {

        let value = event.target.checked;

        let updatedTemplate = {...this.props.template};

        updatedTemplate[fieldName] = !value;

        this.props.updateQuestionTemplate(updatedTemplate, this.props.questionDepth);
    };


    // Handle change will work for any UI input, autocomplete, text, select, checkbox...
    handleInputChangeAndUpdateTemplate = (event, fieldName, key) => {

        var value = event.target.value; // let declaration was not working so switched to var

        let updatedTemplate = {...this.props.template};

        updatedTemplate[fieldName] = value;

        switch (fieldName) {
            case "questionType":
                // Question type is changed so delete all answers prev. added
                for (let aa = this.props.answerOptionTemplates.length - 1; aa >= 0; aa--) {
                    this.props.removeAnswer(QUESTION_TEMPLATE_MODE, aa);
                }

                // Question type is changed so delete all metadata  added
                let tempMetadata = {};

                for (key in updatedTemplate.metadata) {
                    if (key === portalShared.CUSTOM_DDO_NAME || key === portalShared.CUSTOM_DDO_DESC || key === portalShared.KEY_METRIC_CATEGORY_QUESTION) {
                        tempMetadata[key] = updatedTemplate.metadata[key];
                    }
                }
                updatedTemplate.metadata = tempMetadata; // Remove all metadata except those above


                let yesNoMode = false;

                switch (value) {
                    case questionTypes.YES_NO:
                        // Add Yes/No Answers
                        yesNoMode = true;
                        this.props.addAnswer(QUESTION_TEMPLATE_MODE, {optionText: "Yes", metadata: {[icon.ICON_POSITION]: icon.POSITION_NONE}, index: 0}, this.props.questionDepth);
                        this.props.addAnswer(QUESTION_TEMPLATE_MODE, {optionText: "No", metadata: {[icon.ICON_POSITION]: icon.POSITION_NONE}, index: 1}, this.props.questionDepth);
                        break;
                    case questionTypes.ADDRESS_FULL:
                    case questionTypes.STATE_ONLY:
                        // Add state answers
                        STATE_LIST.forEach((item, index) => {
                            this.props.addAnswer(QUESTION_TEMPLATE_MODE, {optionText: item, index: index}, this.props.questionDepth);
                        });
                        break;
                    // no default
                }

                this.setState({yesNoMode: yesNoMode,
                    clearMetadata: true,
                    typeDisplay: metadata.defaultCustomMetadataObj}
                );

                break;
            case "name":
            case "nameQualifier":
                // Check that name/qualifier is not a duplicate
                this.props.validateQuestionNameAndQualifierUnique(updatedTemplate.name, updatedTemplate.nameQualifier, this.props.questionDepth);  //this.props.template.nameQualifier
                break;
            // no default
        }

        this.props.updateQuestionTemplate(updatedTemplate, this.props.questionDepth);
    };


    onButtonClickAddAnswer = () => {

        let addCount = parseInt(this.state.addAnswerTemplate);

        for (let i = 0; i < addCount; i++) {
            // Per Cameron, answers added to pre-existing questions, then answer titles should be blank.  NOTE: Answer titles are required to save.
            if (this.props.template.questionType === questionType.RADIO) {
                 if(this.props.answerOptionTemplates.length > 0)
                    this.props.addAnswer(QUESTION_TEMPLATE_MODE, {optionText: "", metadata: {[icon.ICON_POSITION]: icon.POSITION_NONE}, index: this.props.answerOptionTemplates.length + i}, this.props.questionDepth);
                else
                    this.props.addAnswer(QUESTION_TEMPLATE_MODE, {optionText: (this.props.answerOptionTemplates.length + i+1).toString(), metadata: {[icon.ICON_POSITION]: icon.POSITION_NONE}, index: this.props.answerOptionTemplates.length + i}, this.props.questionDepth);
            }
            else {
                if (this.props.answerOptionTemplates.length > 0)
                    this.props.addAnswer(QUESTION_TEMPLATE_MODE, {optionText: "", index: this.props.answerOptionTemplates.length + i}, this.props.questionDepth);
                else
                    this.props.addAnswer(QUESTION_TEMPLATE_MODE, {optionText: (this.props.answerOptionTemplates.length + i+1).toString(), index: this.props.answerOptionTemplates.length + i}, this.props.questionDepth);
            }

        }
    };

    isStateOrAddressQuestionType = () => {
        let type = this.props.template.questionType;
        return (type === questionTypes.STATE_ONLY || type === questionTypes.ADDRESS_FULL);
    }

    isYesNoQuestionType = () => {
        let type = this.props.template.questionType;
        return (type === questionTypes.YES_NO);
    }


    answerOptionEditor = (answerOptionTemplates, propsObj) => {

        let isLikert = this.state.displayAsLikert;

        if (this.props.template.metadata.hasOwnProperty(portalShared.CUSTOM_DDO_QUE_FORMAT)) {
            let metadataValue = JSON.parse(this.props.template.metadata[portalShared.CUSTOM_DDO_QUE_FORMAT]);

            if (metadataValue.hasOwnProperty("displayAs")) {
                isLikert = (metadataValue.displayAs === "likert")
            }
        }

        // NOTE: Must pass in answerOptionTemplates from render so this function gets called if this.props.answerOptionTemplates changes
        // If NO-Answers, Then which answer generator to use, Non-LIKERT or LIKERT, If LIKERT and there are answers then [REMOVE ANSWERS] button will display

        // If valid question type for answers and non-LIKERT display answers and [ADD ANSWERS] button, also NOTE: Yes/No questions have their answers automatically generated
        if (this.questionTypeHasAnswerOptions(this.props.template.questionType) && !isLikert) {

            return (
                <div id="QuestionEditor-non-likert-answer-generator-container-div"  style={{width: "100%", minHeight: "min-content", paddingTop: "4px"}}>
                    <div>
                        <div id="QuestionEditor-answer-editor-container-div">

                            <AnswerEditor
                                disableRemoveIcon={this.isStateOrAddressQuestionType() || this.isYesNoQuestionType()}
                                displayAsLikert={isLikert}
                                isLikertAnswer={false}
                                setMetadataErrorText={this.setMetadataErrorText}
                                hasAnswers={this.questionTypeHasAnswerOptions(this.props.template.questionType)}
                                template={this.props.template}
                                answerOptionTemplates={answerOptionTemplates}
                                questionDepth={this.props.questionDepth}
                                setStateCallback={this.setStateCallback}
                                groupTemplateMode={this.props.groupTemplateMode}
                            />

                        </div>
                        <div>
                            {/* Note radio entries have an answer generator section, EXCEPT Yes/No, address or state which are automatically generated */}

                            {(this.isStateOrAddressQuestionType() || this.isYesNoQuestionType()) ? "" :

                                <div id="QuestionEditor-non-likert-answer-generator-container-div" style={{display: "flex", justifyContent: "flex-end", alignItems: 'center', marginBottom: "16px", marginTop: "16px"}}>

                                    <div id="QuestionEditor-generate-answers-button" style={{alignItems: "left", paddingTop: '14px'}}>

                                        <ArgoButtonHook
                                            fileName="QuestionEditor"
                                            fieldName="add-answers"
                                            variant="contained"
                                            label={this.state.addAnswerTemplate === '1' ? "Add an answer" : "Add Answers"}
                                            paddingTop="0px"
                                            width={150}
                                            containedTextColor={'white'}
                                            containedBgColor={Colors.blue[500]}
                                            containedHoverTextColor={'#010203'}
                                            containedHoverBgColor={Colors.blue[500]}
                                            onClick={this.onButtonClickAddAnswer}
                                        />
                                        <span className="notranslate">​</span>

                                    </div>

                                    <div style={{alignItems: "left", width: "20px"}}></div>

                                    <div id="QuestionEditor-select-number-of-answers-to-generate" style={{alignItems: "left"}}>

                                        <ArgoTextFieldSelectHook
                                            fileName="QuestionEditor"
                                            fieldName="addAnswerTemplate"
                                            label="Template"
                                            value={this.state.addAnswerTemplate}
                                            onChange={this.onSelectChange}
                                            width="200px"
                                            menuItems={[
                                                {value: "1", text: "Single Answer"},
                                                {value: "2", text: "2 Answers"},
                                                {value: "3", text: "3 Answers"},
                                                {value: "4", text: "4 Answers"},
                                                {value: "5", text: "5 Answers"},
                                                {value: "6", text: "6 Answers"},
                                                {value: "7", text: "7 Answers"},
                                                {value: "8", text: "8 Answers"}
                                            ]}
                                        />

                                    </div>

                                </div>
                            }
                        </div>
                    </div>
                </div>
            );
        }
        else {

            return (
                <div id="QuestionEditor-ArgoLikertAnswerGenerator-container-div">

                    {isLikert ?
                        <ArgoLikertAnswerGenerator
                            fileName="QuestionEditor"
                            componentMode="likert-answers"
                            templateType={TEMPLATE.TYPE.QUESTION}
                            template={this.props.template}
                            isLikert={true}
                            displayAsLikert={isLikert}
                            answerOptionTemplates={answerOptionTemplates}
                            answerErrorCallback={this.answerErrorCallback}
                            templateUpdateFunction={this.props.updateQuestionTemplate}
                            setMetadataErrorText={this.setMetadataErrorText}
                            questionDepth={this.props.questionDepth}
                            setStateCallback={this.setStateCallback}
                            customMetadataKey={portalShared.CUSTOM_DDO_QUE_FORMAT}
                            propsObj={propsObj}
                        /> : ""
                    }
                </div>
            );
        }

    };


    questionTypeHasAnswerOptions = (questionType) => {
        switch (questionType) {
            case questionTypes.YES_NO:
            case questionTypes.RADIO:
            case questionTypes.CHECKBOX:
            case questionTypes.ADDRESS_FULL:
            case questionTypes.STATE_ONLY:
                return true;
            default:
                return false;
        }
    };


    // listTags = () => {
    //
    //     let tags = this.props.template.searchTags || []; // if searchTags is undefined then use empty array
    //
    //     return (
    //         <div id="QuestionEditor-assigned-tags-container-div" style={{display: "flex", flexDirection: "row", alignItems: "center", backgroundColor: "yellow"}}>
    //             {tags.map((tag, index) => {
    //                 <ArgoChipHook
    //                     fileName="QuestionEditor"
    //                     fieldName={tag.id.toString()}
    //                     index={index}
    //                     label={tag.name}
    //                     onDelete={function () {}}
    //                 />
    //             })}
    //         </div>
    //     );
    // };

    // answerErrorCallback = () => {
    //     //// ZZZZZ TODO capture answer errors
    // }

    checkForErrorsOnScreen = (props) => {
        // Questions editor drawer can have 5 types of errors.
        // name is blank or duplicate name/nameQualifier
        // question type requires answers and there are none
        // note: answers can have blank optionText or metadata with no key,  The answer editor will do those checks and use setStateCallback to set error on this drawer
        // note: answer editor will also display error text on the answers with blank optionText or metadata with bank keys
        // metadata key is blank
        // note: metadata editor will display an error message on metadata entry with the missing key

        let nameErrorText = validate.templateNameAndQualifierUnique(props, "Question Text");
        let metadataErrorText = this.state.metadataErrorText;
        let toolbarErrorText = "";
        let duplicateAnswersErrorText = "";
        let blankAnswerErrorText = "";

        if (nameErrorText !== " ") {
            toolbarErrorText = nameErrorText;
        }

        if (metadataErrorText !== "") {
            toolbarErrorText = toolbarErrorText === "" ? this.state.metadataErrorText : toolbarErrorText + ",  " + this.state.metadataErrorText;
        }

        // Note: Specific answer errors i.e. missing answer optionText or answer metadata errors are checked by answerEditor
        // Note: Requires a callback to answerEditor to disable review button and set toolbar error message
        if (this.questionTypeHasAnswerOptions(props.template.questionType) && props.answerOptionTemplates.length === 0) {
            let needAnswersErrorText = "Answers are required for this question type!"
            toolbarErrorText = toolbarErrorText === "" ? needAnswersErrorText : toolbarErrorText + ",  " + needAnswersErrorText;  // Specific error message for toolbar
        }

        if (props.answerOptionTemplates.reduce((isFilled, option) => {
            if (!isFilled) isFilled = option.optionText.length === 0
            return isFilled
        }, false)) blankAnswerErrorText = "One or more answers is blank"

        if (blankAnswerErrorText !== "") {
            toolbarErrorText = toolbarErrorText === "" ? blankAnswerErrorText : toolbarErrorText + ",  " + blankAnswerErrorText;  // Specific error message for toolbar
        }

        for (let i = 0; i < props.answerOptionTemplates.length; i++) {
            const answerOption = props.answerOptionTemplates[i].optionText;
            for (let j = 0; j < props.answerOptionTemplates.length; j++) {
                const compareTo = props.answerOptionTemplates[j].optionText;
                if (answerOption === compareTo && i !== j) {
                    duplicateAnswersErrorText = "Duplicate answer names!"
                }
            }
        }

        if (duplicateAnswersErrorText !== "") {
            toolbarErrorText = toolbarErrorText === "" ? duplicateAnswersErrorText : toolbarErrorText + ",  " + duplicateAnswersErrorText;  // Specific error message for toolbar
        }

        this.setState({nameErrorText: nameErrorText, metadataErrorText: metadataErrorText, toolbarErrorText: toolbarErrorText})
    };

    setMetadataErrorText = (metadataErrorText) => {
        // NOTE: setState callback is not working, state is NOT being set before call, so added fix
        this.setState({metadataErrorText: metadataErrorText, metadataSet: false}, () => this.checkForErrorsOnScreen(this.props));
    }

    metadataChanged = (updatedTemplate, depth) => {
        // Note: Timing issues was causing componentWillReceiveProps to trigger and a setState was canceling another setState
        this.setState({metadataSet: true}, () => this.props.updateQuestionTemplate(updatedTemplate, depth));
    }

    // -----------------------------------------------------------------------------------------------------------------
    // ACTION EDITOR ---------------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    saveAction = (template) => {
        template.answerIndex = this.state.answerIndex;
        this.props.createActionTemplate(template);
        this.setState({showActionEditor: false, answerIndex: null, headerInitializer: ""});
    };

    closeActionEditor = () => {
        this.setState({showActionEditor: false, answerIndex: null, headerInitializer: ""});
    };

    actionEditor = () => {
        let template = {
            name: this.state.headerInitializer
        };

        return (
            <div className="layout vertical" style={{height: "100%"}}>
                <div className="layout vertical" style={{height: "100%"}}>
                    <ActionEditor
                        save={this.saveAction}
                        cancel={this.closeActionEditor}
                        createInitialTemplate={template}
                        showCreateToggle={this.isCreateActionSelector}
                        createMode={true}
                        width={this.props.width}
                    />
                </div>
            </div>
        );
    };

    // -----------------------------------------------------------------------------------------------------------------
    // SHOW GROUP EDITOR -----------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    closeShowGroupEditor = () => {
        this.setState({showGroupEditor: false});
    };

    saveShowGroup = (groupTemplate) => {
        this.props.createGroupTemplate(groupTemplate);
        this.setState({showGroupEditor: false});
    };

    groupEditor = () => {

        return (
            <div className="layout vertical" style={{height: "100%"}}>
                <div className="layout vertical" style={{height: "100%"}}>
                    <GroupEditor
                        createMode={true}
                        save={this.saveShowGroup}
                        cancel={this.closeShowGroupEditor}
                        templateMode={"Create Group"}
                        width={this.props.width}
                        scrollTo="QuestionEditor-container-outer-div"
                    />
                </div>
            </div>
        );
    };

    // -----------------------------------------------------------------------------------------------------------------
    // RENDER ----------------------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    render() {

        if (this.state.isLoading) {
            return null;
        }

        if (this.state.showActionEditor) {
            return this.actionEditor();
        }

        if (this.state.showGroupEditor) {
            return this.groupEditor();
        }

        // Check to see if question type can be changed ----------------------------------------------------------------
        let questionTypeChangeAllowed = false;
        if (this.props.createMode) {
            questionTypeChangeAllowed = true;
        }
        else {
            if (this.props.isLikert) {
                questionTypeChangeAllowed = false;  // If being created from group LIKERT then question type can only be LIKERT
            } else {
                if (this.props.options.hasVersion !== null) {
                    questionTypeChangeAllowed = this.props.options.hasVersion.length === 1 && this.props.options.hasVersion[0].publishedDate == null;
                }
                if (this.props.template.questionType === questionTypes.LIKERT_RADIO) {
                    questionTypeChangeAllowed = questionTypeChangeAllowed && this.props.options.hasReference && this.props.options.hasReference.length === 0
                }
            }
        }

        //let showDisplayAsButtonGroupOption = ((this.props.template.questionType === questionTypes.LIKERT_RADIO) || (this.props.template.questionType === questionTypes.RADIO) || (this.props.template.questionType === questionTypes.YES_NO));

        //let isLikert = (this.props.template.questionType === questionTypes.LIKERT_RADIO);

        // let iconType = "";


        // if (this.props.answerOptionTemplates !== undefined && this.props.answerOptionTemplates.length > 0) {
        //     if (this.props.answerOptionTemplates[0].metadata.hasOwnProperty(icon.ICON_TYPE)) {
        //         iconType = this.props.answerOptionTemplates[0].metadata[icon.ICON_TYPE];
        //     }
        // }

        let propsObj = {
            fileName: "QuestionEditor",
            onTextChange: this.onTextChange,
            templateType: TEMPLATE.TYPE.QUESTION,
            template: this.props.template,
            answerOptionTemplates: this.props.answerOptionTemplates,
            templateUpdateFunction: this.props.updateQuestionTemplate,
            setStateCallbackFunction: this.setStateCallback,
            setCustomMetadataFunction: metadataUtils.setCustomMetadata,
            depth: this.props.questionDepth
        }
        
        return (
            this.state.pageInit || this.props.createMode ?
                <div id="QuestionEditor-container-outer-div" style={{height: "100%"}}>

                    <ArgoToolbarHook
                        fileName="QuestionEditor"
                        fieldName="save-or-cancel"
                        toolbarErrorText={this.state.toolbarErrorText}
                        width={this.props.width}
                        onClick={this.handleToolbarButtonClick}
                    />
                    <div id="QuestionEditor-height-div" style={{height: "50px"}}></div>

                    <div id="QuestionEditor-page-div" style={{height: "100%"}}>

                        <div id="QuestionEditor-container-div"
                             style={{flexGrow: "2", paddingLeft: "32px", paddingRight: "32px"}}>

                            <div>
                                {this.props.title === "" ? "" :
                                    <div>
                                        <div>&nbsp;</div>
                                        <Typography style={{fontWeight: "bold"}}>{this.props.title}</Typography>
                                    </div>
                                }
                            </div>

                            <div>&nbsp;</div>

                            <div style={{display: "flex", alignItems: "left", width: '100%'}}>

                                <ArgoTextFieldSelectHook
                                    fileName="QuestionEditor"
                                    fieldName="questionType"
                                    label="Question Type"
                                    value={this.props.template.questionType}
                                    onChange={this.onSelectChange}
                                    menuItems={questionTypes.questionTypesList}
                                    disabled={!questionTypeChangeAllowed}
                                />

                                <ArgoQuestionFormatDefinition
                                    questionType={this.props.template.questionType}
                                    fieldName="typeDisplay"
                                    fieldTextObjName="typeDisplay"
                                    initTo={this.state.typeDisplay.selected}
                                    metadataDetails
                                    metadataPropsObj={Object.assign({metadataTag: portalShared.CUSTOM_DDO_QUE_FORMAT}, propsObj)}
                                />

                            </div>

                            <div>&nbsp;</div>

                            {(this.state.typeDisplay.selected === 'custom') ?
                                <ArgoQuestionFormatCustom
                                    questionType={this.props.template.questionType}
                                    fieldName="typeDisplay"
                                    fieldTextObjName="typeDisplay"
                                    initToObj={this.state.typeDisplay.metadataValueObj}
                                    answerCount={this.props.answerOptionTemplates.length}
                                    labelFieldName="labelDisplayDefinition"
                                    labelInitTo={this.state.labelDisplayDefinition}
                                    labelInitToObj={this.state.labelDisplayMetadataValueObj}
                                    labelMetadataTag={portalShared.CUSTOM_DDO_QUE_LABEL}
                                    answers={this.props.answerOptionTemplates}
                                    metadataPropsObj={Object.assign({metadataTag: portalShared.CUSTOM_DDO_QUE_FORMAT}, propsObj)}
                                />
                                :
                                <div></div>
                            }

                            <div>&nbsp;</div>

                            <ArgoTextEntryWithCustomOverride
                                templateType={TEMPLATE.TYPE.QUESTION}
                                textEntryType={TEMPLATE.TEXT_TYPE.QUESTION.NAME}
                                fieldName="name"
                                fieldTextObjName="nameDisplay"
                                index={100}
                                label="Question Text"
                                labelCustom="Display (Text)"
                                value={this.props.template.name}
                                maxLength={2000}
                                errorText={this.state.nameErrorText}
                                initTo={this.state.nameDisplay.selected}
                                initToObj={this.state.nameDisplay.metadataValueObj}
                                propsObj={Object.assign({metadataTag: portalShared.CUSTOM_DDO_NAME}, propsObj)}
                            />

                            <div>&nbsp;</div>

                            <div id="QuestionEditor-qualifier-container-div"
                                 style={{display: "flex", alignItems: "left", width: '100%'}}>

                                <ArgoTextFieldHook
                                    fileName="QuestionEditor"
                                    fieldName="nameQualifier"
                                    label="Name Qualifier"
                                    value={this.props.template.nameQualifier}
                                    maxLength={255}
                                    multiline={true}
                                    onChange={this.onTextChange}
                                    width="96%"
                                    divContainerHeight="52px"
                                />

                                <div style={{width: "83px"}}>&nbsp;</div>

                            </div>

                            <div>&nbsp;</div>

                            <ArgoTextEntryWithCustomOverride
                                templateType={TEMPLATE.TYPE.QUESTION}
                                textEntryType={TEMPLATE.TEXT_TYPE.QUESTION.DESC}
                                fieldName="description"
                                fieldTextObjName="descDisplay"
                                index={200}
                                label="Description/Instructions"
                                labelCustom="Display (Description)"
                                value={this.props.template.description}
                                maxLength={2000}
                                initTo={this.state.descDisplay.selected}
                                initToObj={this.state.descDisplay.metadataValueObj}
                                propsObj={Object.assign({metadataTag: portalShared.CUSTOM_DDO_DESC}, propsObj)}
                            />

                            <ArgoTextFieldSelectHook
                                fileName="QuestionEditor"
                                fieldName="keyMetricCategory"
                                label="Key Metric Category"
                                value={this.state.keyMetricCategorySelectedIndex}
                                onChange={this.onSelectChange}
                                width="300px"
                                menuItems={this.state.keyMetricCategorys}
                            />

                            <div>&nbsp;</div>

                            {/*// ===========================================================================================================*/}
                            {/*// OPTIONAL QUESTION */}
                            {/*// ===========================================================================================================*/}

                            <div>&nbsp;</div>

                            <ArgoCheckboxHook
                                fileName="QuestionEditor"
                                fieldName="optional"
                                label={"Required Question"}
                                labelColor={this.props.template.optional ? "black" : "red"}
                                onChange={this.onCheckBoxChange}
                                checked={!this.props.template.optional}
                                paddingTop="-2px"
                                bold={true}
                                width="200px"
                            />

                            <div>&nbsp;</div>

                            {/*// ===========================================================================================================*/}
                            {/*// TAGS */}
                            {/*// ===========================================================================================================*/}

                            <div>&nbsp;</div>

                            <div style={{fontSize: "large", fontWeight: "bold", paddingBottom: "24px"}}>Tags</div>

                            <TagSelectOrCreate questionDepth={this.props.questionDepth}/>

                            {/*// ===========================================================================================================*/}
                            {/*// METADATA EDITOR */}
                            {/*// ===========================================================================================================*/}

                            <div>&nbsp;</div>

                            <div style={{width: "98%", paddingBottom: "40px", marginLeft: "24px"}}>
                                <ArgoMetadataEditor
                                    entityType={metadataTemplateTypes.QuestionTemplate}
                                    template={this.props.template}
                                    // onChange={this.props.updateQuestionTemplate}
                                    onChangeCallback={this.metadataChanged}
                                    depth={this.props.depth}
                                    metadata={this.props.template.metadata}
                                    errorText={this.state.metadataErrorText}
                                    setMetadataErrorText={this.setMetadataErrorText}
                                />
                            </div>

                            {/*// ===========================================================================================================*/}
                            {/*// ASSIGNED ANSWERS LIST */}
                            {/*// ===========================================================================================================*/}

                            {this.answerOptionEditor(this.props.answerOptionTemplates, propsObj)}

                            {/*// ===========================================================================================================*/}
                            {/*// PORTAL PREVIEW */}
                            {/*// ===========================================================================================================*/}

                            <div id="QuestionEditor-space-above-portal-preview" style={{height: "20px"}}>&nbsp;</div>

                            <ArgoPortalPreview
                                calledBy="QuestionEditor"
                                createMode={this.props.createMode}
                                templateType={TEMPLATE.TYPE.QUESTION}
                                template={this.props.template}
                                answerOptionTemplates={this.props.answerOptionTemplates}
                                hidePortalPreview={this.props.template.questionType === questionType.LIKERT_RADIO}
                            />

                        </div>
                    </div>
                </div> : <div id="QuestionEditor-container-outer-div"/>
        );
    }
}


QuestionEditor.defaultProps = {
    createMode: false,
    title: "",
    template: undefined,
    cancel: () => {},
    showMetaData: false,
    isLikert: false,
    row: null,
    questionExpanderEdit: false,
    questionExpanderSetStateCallback: function () {},
    questionDepth: 0,
    scrollTo: "",
    width: "100%"
};

QuestionEditor.propTypes = {
    scrollTo: PropTypes.string,
    createMode: PropTypes.bool,
    title: PropTypes.string,
    templateKey: PropTypes.string,
    templateId: PropTypes.number,
    row: PropTypes.object,
    cancel: PropTypes.func,
    save: PropTypes.func,
    showMetaData: PropTypes.bool,
    groupTemplateList: PropTypes.array,
    createInitialTemplate: PropTypes.object,
    questionDepth: PropTypes.number,
    isLikert: PropTypes.bool,
    questionExpanderEdit: PropTypes.bool,
    questionExpanderSetStateCallback: PropTypes.func,
    width: PropTypes.string
};

function mapStateToProps(state, props) {
    const {templateKey} = props;

    if (!state.questionEditor) {
        return {};
    }

    return {
        template: state.questionEditor[props.questionDepth].template,
        templateOriginal: state.questionTemplate[templateKey],
        options: state.questionEditor[props.questionDepth].options,
        answerOptionTemplates: state.questionEditor[props.questionDepth].answerOptionTemplates,
        metadataDefinition: state.metadataDefinition[metadataTemplateTypes.QuestionTemplate],
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        initializeQuestionEditor,
        updateQuestionTemplate,
        clearQuestionEditor,
        validateQuestionNameAndQualifierUnique,
        checkQuestionReference,
        checkQuestionVersion,
        getQuestionTemplate,
        createGroupTemplate,
        createActionTemplate,
        getMetadataDefinitions,
        addAnswer,
        removeAnswer
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionEditor);
