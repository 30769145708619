import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "../../utilities/withRouter";
import {bindActionCreators} from "redux";
import {closeDrawer} from "../../actions/drawerActions";
import {createTagAndCloseDrawer, saveTagAndCloseDrawer} from "../../actions/tagActions";
import TagEditor from "../qd/tags/TagEditor";
import ArgoAppBar from "../common/ArgoAppBar";
import {QD_URL} from "../../constants/systemConstants";


class TagCreateEditDrawer extends Component {

    closeDrawer = () => {
        this.props.closeDrawer();
    };

    createTagAndCloseDrawer = (tag) => {
        if (typeof this.props.setLoadingState === "function") {
            this.props.setLoadingState(function () {});
        }

        this.props.createTagAndCloseDrawer(tag, this.props.filter, this.onCreateSuccess);
    };

    onCreateSuccess = () => {
        <withRouter navigate={QD_URL + "/search-tags"}/>;
    };

    saveTagAndCloseDrawer = (tag) => {
        if (typeof this.props.setLoadingState === "function") {
            this.props.setLoadingState(function () {});
        }

        this.props.saveTagAndCloseDrawer(tag, this.props.filter);
    };

    // onSaveRequest = (tag) => {
    //     if (typeof this.props.setLoadingState === "function") {
    //         this.props.setLoadingState(this.saveTag, tag);
    //     }
    //     else {
    //         this.saveTag(tag);
    //     }
    // };

    render() {
        return (
            <div style={{height: "100%"}}>
                <ArgoAppBar
                    title={this.props.createMode ? "Create Tag" : "Edit Tag"}
                    showMenuIconButton={false}
                    noIcon={true}
                    isDrawer={true}
                    width={tagCreateEditDrawerProps.width}
                />

                <div style={{height: '64px'}}></div>

                <TagEditor
                    id={this.props.createMode ? null : this.props.id}
                    createMode={this.props.createMode}
                    save={this.props.createMode ? this.createTagAndCloseDrawer.bind(this) : this.saveTagAndCloseDrawer.bind(this)}
                    cancel={this.closeDrawer}
                    width={tagCreateEditDrawerProps.width}
                />
            </div>
        );
    }
}

TagCreateEditDrawer.defaultProps = {
    createMode: false,
    id: null,
    filter: {},
};

TagCreateEditDrawer.propTypes = {
    createMode: PropTypes.bool,
    id: PropTypes.number,
    filter: PropTypes.object,
    setLoadingState: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({closeDrawer, createTagAndCloseDrawer, saveTagAndCloseDrawer}, dispatch);
}

function mapStateToProps(state) {
    return {
        filter: state.filter["tags"] || {}
    };
}

const tagCreateEditDrawerProps = {
    opensecondary: true,
    width: "90%",
    docked: false,
    anchor: "right"
};

export default connect(mapStateToProps, mapDispatchToProps)(TagCreateEditDrawer);
export {tagCreateEditDrawerProps};
