import React from 'react';
import PropTypes from "prop-types";
import FirstPage from "@mui/icons-material/FirstPage";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import LastPage from "@mui/icons-material/LastPage";
import ArgoTextFieldSelectHook from "./ArgoTextFieldSelectHook";
import ArgoIconButtonHook from "./ArgoIconButtonHook";


const ArgoListPagingControlsHook = (props) => {

    let hidePageSize = props.hidePageSize ?? true;
    let pageSize = hidePageSize ? 10 : (props.pageState.pageSize ?? 10);
    let totalPages = 1;
    let currentPage = props.pageState.pageNumber;
    let totalRecords = props.pageState.totalRecords;
    let currentStartRecord = totalRecords !== 0 ? (currentPage - 1) * (pageSize) + 1 : 0;
    let currentEndRecord = Math.min(((currentStartRecord - 1) + pageSize), totalRecords);

    if (pageSize !== 0) {
        totalPages = Math.ceil(totalRecords / pageSize);
    }

    let handleFirstClick = function () {
        if (currentPage !== 1 && totalPages !== 0) {
            props.onPageChange(1, pageSize);
        }
    };

    let handleBackClick = function () {
        if (currentPage !== 1 && totalPages !== 0) {
            props.onPageChange(currentPage - 1, pageSize);
        }
    };

    let handleNextClick = function () {
        if (currentPage !== totalPages && totalPages !== 0) {
            props.onPageChange(currentPage + 1, pageSize, props.setloadingState);
        }
    };

    let handleLastClick = function () {
        if (currentPage !== totalPages && totalPages !== 0) {
            props.onPageChange(totalPages, pageSize);
        }
    };

    let onSelectChangePageSize = function (event) {
        props.onPageChange(1, event.target.value, event);
    };

    return (

        <div id="ArgoListPagingControlsHook-container-div" style={{width: '100%', height: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "right"}}>

            {props.hidePageSize ? "" :
                <div style={{minWidth: "20px", fontSize: '12px', color: "black"}}>Records per page:</div>
            }

            {props.hidePageSize ? "" :
                <div style={{float: 'left', width: '10px'}}>
                    <span>&nbsp;</span>
                </div>
            }

            {props.hidePageSize ? "" :
                    <ArgoTextFieldSelectHook
                        fileName="ArgoListPagingControlsHook"
                        fieldName="recordsPerPage"
                        value={pageSize}
                        onChange={onSelectChangePageSize}
                        fullWidth={false}
                        width="60px"
                        paddingTop="14px"
                        menuItems={[{value: 5, text: "5"},{value: 10, text: "10"},{value: 20, text: "20"},{value: 50, text: "50"}]}
                    />
            }

            <ArgoIconButtonHook
                fileName="ArgoListPagingControlsHook"
                fieldName="firstPage"
                size="small"
                onClick={handleFirstClick}
                icon={<FirstPage/>}
            />

            <ArgoIconButtonHook
                fileName="ArgoListPagingControlsHook"
                fieldName="prevPage"
                onClick={handleBackClick}
                icon={<ChevronLeft/>}
            />

            <div style={{paddingTop: "2px"}}>
                <span style={{fontSize: '14px', color: "black"}}>{currentStartRecord} - {currentEndRecord} of {totalRecords}</span>
            </div>

            <ArgoIconButtonHook
                fileName="ArgoListPagingControlsHook"
                fieldName="nextPage"
                onClick={handleNextClick}
                icon={<ChevronRight/>}
            />

            <ArgoIconButtonHook
                fileName="ArgoListPagingControlsHook"
                fieldName="lastPage"
                onClick={handleLastClick}
                icon={<LastPage/>}
            />

        </div>
    );
};

ArgoListPagingControlsHook.contextTypes = {
    muiTheme: PropTypes.object
};

ArgoListPagingControlsHook.defaultProps = {
    hidePageSize: false
};

ArgoListPagingControlsHook.propTypes = {
    hidePageSize: PropTypes.bool
};

export default ArgoListPagingControlsHook;
