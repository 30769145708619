import ArgoIconButtonHook from "./ArgoIconButtonHook";
import ArrowCircleUpOutlinedIcon from '@mui/icons-material/ArrowCircleUpOutlined';
import ArrowCircleDownOutlinedIcon from '@mui/icons-material/ArrowCircleDownOutlined';

export default function ArgoReorderArrowsHook(props) {

    const fileName = props.fileName ?? "NoFileName";
    const fieldName = props.fieldName ?? "";

    const index = props.index ?? ""; // If field is built in a loop index is required to know which field to match up with

    const uniqueIdentifier = fileName + "-ArgoReorderArrowsHook-" + index;

    const arrayLength = props.arrayLength;
    const isFirst = props.isFirst;
    const isLast = props.isLast;

    const depth = props.depth ?? 0;

    const onClick = props.onClick ?? function () {};

    const handleOnClick = (direction, event) => {
        onClick(event, fieldName, index, depth, direction);
    };

    return (
        (isFirst && isLast) ? "" :
            <div id={uniqueIdentifier} style={{display: "flex"}}>
                <div style={{display: "flex"}}>
                    {isFirst ? <div style={{width: "40px"}}>&nbsp;</div> :
                        <ArgoIconButtonHook
                            fileName="argo-reorder-arrows-hook"
                            fieldName="arrow-up"
                            index={index}
                            disabled={(isFirst)}
                            onClick={handleOnClick.bind(this, "up")}
                            depth={depth}
                            color="black"
                            icon={<ArrowCircleUpOutlinedIcon/>}
                        />
                    }
                </div>

                <div style={{display: "flex"}}>
                    {(isLast || arrayLength === 1) ? <div style={{width: "40px"}}>&nbsp;</div> :
                        <ArgoIconButtonHook
                            fileName="argo-reorder-arrows-hook"
                            fieldName="arrow-down"
                            index={index}
                            disabled={isLast}
                            onClick={handleOnClick.bind(this, "down")}
                            depth={depth}
                            color="black"
                            icon={<ArrowCircleDownOutlinedIcon/>}
                        />
                    }
                </div>
            </div>
    );
}


