import * as types from "../constants/actionConstants";

const pushCallBack = (func) => {
    return {
        type: types.REVIEW_TEMPLATE_CALLBACK_PUSH,
        payload: func
    };
};

const popCallBack = () => {
    return {
        type: types.REVIEW_TEMPLATE_CALLBACK_POP,
    };
}

const clearCallBack = () => {
    return {
        type: types.REVIEW_TEMPLATE_CALLBACK_CLEAR
    }
}

export {
    pushCallBack,
    popCallBack,
    clearCallBack
};
