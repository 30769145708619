import React from 'react';
import Chip from "@mui/material/Chip";
import FlashOnIcon from "@mui/icons-material/FlashOn";


export default function ArgoTextFieldHook(props) {

    const fileName = props.fileName ?? "file-name";
    const fieldName = props.fieldName ?? "field-name";
    const index = props.index ?? ""; // If field is built in a loop index is required to know which field to match up with

    const uniqueIdentifier = fileName + "-chip-" + fieldName + index;

    const variant = props.variant ?? "filled"; //NOTE: use "outlined" for chip with white fill
    const label = props.label ?? "";
    const useTrackableAvatar = props.useTrackableAvatar ?? false;
    const title = props.title ?? "";

    const onDelete = props.onDelete ?? function () {};

    const handelOnDelete = (event) => {
        onDelete(event, label, index);
    };

    return (
        <div title={title} style={{paddingRight: "10px"}}>
            <Chip   key={uniqueIdentifier}
                    id={uniqueIdentifier}
                    name={uniqueIdentifier}
                    variant={variant}
                    label={label}
                    onDelete={handelOnDelete}
                    avatar={useTrackableAvatar ? <FlashOnIcon/> : ""}
            />
        </div>
    );
}



