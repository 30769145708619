import * as React from 'react';
import PropTypes from "prop-types";
import AppBar from '@mui/material/AppBar';
import {connect} from "react-redux";
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import UserContext from "../../UserContext";
import {QD_URL} from "../../constants/systemConstants";

class ArgoAppBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url: "",
            childPage: false,
            showMenuIconButton: true,
            showMenuArrowButton: "LEFT",
            prevPage: ""
        };
    }

    static contextType = UserContext;  // From blueTheme.js

    onLeftIconButtonTouchTap = () => {
        this.props.onLeftIconButtonTouchTap();
    };

    onRightIconButtonTouchTap = () => {
        this.props.onRightIconButtonTouchTap();
    };

    returnToPrevURL = () => {

        // if (this.props.title === "Integration Log Details") {
        //     this.props.closeDrawer();
        // }
        // else {
        this.setState({childPage: false, showMenuIconButton: true, showMenuArrowButton: "LEFT"})
        let path = QD_URL + "/" + this.state.prevPage;
        this.props.navHook(path, {replace: true});
        //}
    };

    render() {

        let currURL = window.location.href;

        if ( (!this.props.isDrawer) && (!this.state.childPage) && (currURL.includes("-versions") || currURL.includes("integration-logs")) ) {

            let prevPage = "";

            if (currURL.includes("integration-logs")) {
                prevPage = "integrations";
            }
            else {
                let urlObj = new URL(currURL);
                let urlPathnameParsedArray = urlObj.pathname.split('/'); // parse pathname part of URL, [2] contains the piece we need

                prevPage = urlPathnameParsedArray[2].replace("-versions", "") +"s"; // Remove "-versions" part of path ie.. group-versions and returns groups for prevPage

                prevPage = (prevPage === "categorys") ? "categories" : prevPage;
            }

            this.setState({url: currURL, childPage: true, showMenuIconButton: false, showMenuArrowButton: "LEFT", prevPage: prevPage})

        } else {
            if (this.props.isDrawer && this.state.showMenuArrowButton !== "LEFT") {
                this.setState({showMenuArrowButton: "LEFT"});
            }
        }

        let hyphenatedTitle = this.props.title.replaceAll(" ","-");
        let uniqueRef = "ArgoAppBar-" + hyphenatedTitle.toLowerCase();
        let appBarId = uniqueRef;
        let appBarName = uniqueRef;
        const {palette} = this.context
        return (
            <Box>
                <AppBar
                    position={this.props.static ? "static" : "fixed"}
                    id={appBarId}
                    name={appBarName}
                    style={{backgroundColor: palette.accent3Color, width: this.props.width === undefined ? "100%" : this.props.width}}
                >
                    <Toolbar>
                        {(this.props.showMenuIconButton && !this.props.isDrawer) ?
                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                sx={{mr: 2}}
                                onClick={this.onLeftIconButtonTouchTap}
                            >
                                <MenuIcon/>
                            </IconButton>
                            :
                            (!this.props.noIcon && this.props.showMenuArrowButton)  ?
                                <IconButton
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menuArrow"
                                    sx={{mr: 2}}
                                    onClick={this.onLeftIconButtonTouchTap}
                                >
                                    {this.state.showMenuArrowButton === "LEFT" ? <ArrowBackIcon/> : <ArrowForwardIcon/>}
                                </IconButton> : ""
                        }
                        <Typography variant="h5" component="div" sx={{opacity: this.props.titleFor === "" ? "100%": "85%"}}>
                            {this.props.title}
                        </Typography>
                        <div>&nbsp;&nbsp;</div>
                        <Typography variant="h5" component="div" stlye={{float: "left"}} >
                            {this.props.titleFor}
                        </Typography>
                        <div>&nbsp;&nbsp;</div>
                        <Typography variant="h7" component="div" stlye={{float: "left"}} >
                            {(this.props.qualifierFor !== "" && this.props.qualifierFor !== undefined) ? " -  " + this.props.qualifierFor : ""}
                        </Typography>
                        {this.props.showHelpIconButton ?
                            <IconButton
                                size="large"
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                sx={{mr: 2}}
                                onClick={this.onRightIconButtonTouchTap}
                            >
                                <HelpOutlineIcon/>
                            </IconButton>
                            :
                            ""
                        }
                    </Toolbar>
                </AppBar>
            </Box>
        );
    }
}

ArgoAppBar.defaultProps = {
    title: "QD Administration",
    titleFor: "",
    QualifierFor: "",
    noIcon: false,
    showMenuIconButton: false,
    showMenuArrowButton: "RIGHT",
    showHelpIconButton: false,
    onLeftIconButtonTouchTap: function () {},
    onRightIconButtonTouchTap: function () {},
    isDrawer: false,
    static: false,
};

ArgoAppBar.propTypes = {
    title: PropTypes.string,
    titleFor: PropTypes.string,
    QualifierFor: PropTypes.string,
    noIcon: PropTypes.bool,
    showMenuIconButton: PropTypes.bool,
    showMenuArrowButton: PropTypes.string,
    showHelpIconButton: PropTypes.bool,
    onLeftIconButtonTouchTap: PropTypes.func,
    onRightIconButtonTouchTap: PropTypes.func,
    isDrawer: PropTypes.bool,
    width: PropTypes.string,
    static: PropTypes.bool
};

function mapStateToProps(state) {
    return {};
}

// function mapDispatchToProps(dispatch) {
//     return bindActionCreators({closeDrawer},dispatch);
// }

// function myParams(Component,props) {
//     return props => <Component {...props} navHook={useNavigate()} />;
// }

//export default connect(mapStateToProps, mapDispatchToProps)(myParams(ArgoAppBar));
export default connect(mapStateToProps)(ArgoAppBar);

