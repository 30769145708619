//import {AiOutlineSmallDash} from "react-icons/all";
import {getLabelWidth} from "./getLabelWidth";
import * as tag from "../constants/customMetadataConstants";
import * as portalShared from "../shared/metadataConstantsAndUtilities"
//import * as responsiveLabel  from "../utilities/responsiveLabelUtilities";


export const CIRCLE_LIKERT_02_07  = "CIRCLE_LIKERT_02_07"; // Circles 2 - 7 inputs
export const CIRCLE_LIKERT_10_11  = "CIRCLE_LIKERT_10_11"; //
export const CIRCLE_LIKERT_PYRAMID  = "CIRCLE_LIKERT_PYRAMID"; // Circles 10 - 11 inputs
export const CIRCLE_INPUT_FORMAT  = "CIRCLE_INPUT_FORMAT";

export const SINGLE_SELECT_ARRANGE_ANSWERS_SIDE_BY_SIDE  = "SINGLE_SELECT_ARRANGE_ANSWERS_SIDE_BY_SIDE";
export const SINGLE_SELECT_ARRANGE_ANSWERS_BY_ROW  = "SINGLE_SELECT_ARRANGE_ANSWERS_BY_ROW";
//export const SINGLE_SELECT_ARRANGE_ANSWERS_WRAP  = "SINGLE_SELECT_ARRANGE_ANSWERS_WRAP"; // does not appear to be used


export function singleSelectAnswerArangement(savedObjs) {

    let arrangeAnswers = SINGLE_SELECT_ARRANGE_ANSWERS_BY_ROW; // Default to one answer per row

    if (savedObjs.answers.length === 2) {
        arrangeAnswers = SINGLE_SELECT_ARRANGE_ANSWERS_SIDE_BY_SIDE; // If only 2 answers change default to side by side
    } else {
        arrangeAnswers = SINGLE_SELECT_ARRANGE_ANSWERS_BY_ROW;
    }

    // Default is 1 answer per row
    if (tag.metadataExists(savedObjs.question, portalShared.DISPLAY_SINGLE_SELECT_AS_SIDE_BY_SIDE)) {
        arrangeAnswers = SINGLE_SELECT_ARRANGE_ANSWERS_SIDE_BY_SIDE;
    }
    else if (tag.metadataExists(savedObjs.question, portalShared.DISPLAY_SINGLE_SELECT_AS_ONE_PER_ROW)) {
        arrangeAnswers = SINGLE_SELECT_ARRANGE_ANSWERS_BY_ROW;
    }

    return arrangeAnswers;
}

// Single Select and Pyramid set colors APPEARS NOT TO BE USED
export function getCircleColors(answer, isBackground, isSelected, isDefault) {

    let opacity = 1;

    if(isDefault && !isSelected) {
        return null
    }

    if(isBackground === true) {
        if(isSelected === false) {
            opacity = .25;
        }
    }

    switch(answer.optionText) {
        case "0":
            return 'rgba(198, 40, 40, ' + opacity + ')';
        case "1":
            return 'rgba(230, 74, 24, ' + opacity + ')';
        case "2":
            return 'rgba(245, 124, 0, ' + opacity + ')';
        case "3":
            return 'rgba(255, 152, 0, ' + opacity + ')';
        case "4":
            return 'rgba(255, 193, 8, ' + opacity + ')';
        case "5":
            return 'rgba(255, 214, 1, ' + opacity + ')';
        case "6":
            return 'rgba(205, 220, 57, ' + opacity + ')';
        case "7":
            return 'rgba(139, 195, 75, ' + opacity + ')';
        case "8":
            return 'rgba(77, 175, 80, ' + opacity + ')';
        case "9":
            return 'rgba(67, 160, 71, ' + opacity + ')';
        case "10":
            return 'rgba(46, 125, 50, ' + opacity + ')';
        default:
            return "lightGrey";
    }
}

// Single Select and Pyramid set colors
export function setCircleDefaultMultiColors(answer, selected) {
// ZZZZZ
    let opacity = (selected) ? 1 : .25;

    switch(answer.optionText) {
        case "0":
            return 'rgba(198, 40, 40, ' + opacity + ')';
        case "1":
            return 'rgba(230, 74, 24, ' + opacity + ')';
        case "2":
            return 'rgba(245, 124, 0, ' + opacity + ')';
        case "3":
            return 'rgba(255, 152, 0, ' + opacity + ')';
        case "4":
            return 'rgba(255, 193, 8, ' + opacity + ')';
        case "5":
            return 'rgba(255, 214, 1, ' + opacity + ')';
        case "6":
            return 'rgba(205, 220, 57, ' + opacity + ')';
        case "7":
            return 'rgba(139, 195, 75, ' + opacity + ')';
        case "8":
            return 'rgba(77, 175, 80, ' + opacity + ')';
        case "9":
            return 'rgba(67, 160, 71, ' + opacity + ')';
        case "10":
            return 'rgba(46, 125, 50, ' + opacity + ')';
        default:
            return "lightGrey";
    }
}

// -------------------------------------------------------------------------------------------------------------------------------------+
// PARAMETERS:                                                                                                                          |
// boxOrCircle     - "box" or "circle" dictates the appearance of the likert scale                                                      |
// desktopOrMobile - "desktop" or "mobile" dictates responsive appearance (mobile <600px)                                               |
// position        - "left", "center", or "right" dictates label position                                                               |
// isZeroToTen     - true or false to determine if using the 0-10 likert scale (calculations are different due to smaller circle sizes) |
// -------------------------------------------------------------------------------------------------------------------------------------+
export function calculateMarginSpaceForLabel(boxOrCircle, desktopOrMobile, position, isZeroToTen, propsObj) {

    let leftTitleWidth   = getLabelWidth(propsObj.leftTitle);
    let rightTitleWidth  = getLabelWidth(propsObj.rightTitle);
    let centerTitleWidth = getLabelWidth(propsObj.centerTitle);

    let   circleBoxWidth = 50;

    // box labels will align the left and right titles with the buttongroup edges, and the center title will align in the middle of the buttongroup
    if(boxOrCircle === "box") {
        if (desktopOrMobile === "desktop") {

            if(position === "left") {

                // calculates the half-way position of the likert scale (this is where the middle label will be centered if one exists)
                let halfLengthOfGroup = ((propsObj.answers.length) * propsObj.desktopButtonWidth) / 2;

                // calculates the width of half the center title (we want the middle of the center label and the middle of the button group to align)
                let centerTitleHalfWidth = centerTitleWidth / 2;

                // number of buttons spanned by either just the right, or both the right and center labels if a center label exists
                let numOfButtons = (rightTitleWidth + centerTitleWidth) / propsObj.desktopButtonWidth;

                // if there is no center label, calculate the margin space for the left label:
                // first, calculate the amount of space to align the right side of the left label with the left side of the buttongroup
                // next, subtract the width of the label to align the left side of the label with the left side of the buttongroup
                // finally, account for pixel offset (1px for every 1 button)
                let marginWidth = ((propsObj.answers.length - numOfButtons) * propsObj.desktopButtonWidth) - getLabelWidth(propsObj.leftTitle) - propsObj.answers.length;

                // if there is a center label, calculate the margin space needed for the left label from the center label position
                // first, align the right side of the label with the center of the buttongroup
                // second, add half the length of the buttongroup to align the right side of the label with the left side of the buttongroup
                // third, reduce the length by the width of the label to align the left side of the label and buttongroup
                // finally, account for pixel offset (1px for every 1 button - half the total number of buttons since we are calculating from the middle)
                let marginWidthWithCenter = -centerTitleHalfWidth + halfLengthOfGroup - leftTitleWidth - Math.floor(propsObj.answers.length / 2);

                return (propsObj.centerTitle ? marginWidthWithCenter : marginWidth) + "px";

            } else if(propsObj.position === "center") {

                // Since we want to align the center of the label with the center of the buttongroup, calculate half of a buttons width
                let halfButonWidth = (propsObj.desktopButtonWidth / 2);

                // find the midpoint of the buttongroup
                let halfway = (propsObj.desktopButtonWidth * (Math.floor(propsObj.answers.length / 2)));

                // pixel offset = 1px for every 1 button (calculating half-way so divide total number by 2)
                let pixelOffset = Math.floor(propsObj.answers.length / 2);

                // first, position center label right-aligned with buttongroup
                // second, position the label to be centered over the last button in the group
                // finally, add the length of half the buttongroup and account for pixel offset
                return -rightTitleWidth - ((centerTitleWidth / 2) - halfButonWidth) + halfway - pixelOffset;

            } else if(propsObj.position === "right") {
                // right-align label with buttongroup
                return 30;
            } else {
                // error
                return 0;
            }

        } else {
            // mobile
            if(propsObj.position === "center") {

                let centerOfGroup = ((propsObj.answers.length) * propsObj.mobileButtonWidth) / 2;
                let halfCenterTitle = getLabelWidth(propsObj.centerTitle) / 2;

                // first, place center label in position of left label add half the length of the buttongroup
                // next, subtract half the length of the center label to align the middle of the label and buttongroup
                return - propsObj.leftTitle + centerOfGroup - halfCenterTitle + "px";

            } else if(propsObj.position === "right") {

                let lengthOfGroup = (propsObj.answers.length) * propsObj.mobileButtonWidth;

                // align the right side of the buttongroup with the left side of the label,
                // then subtract the length of the label and account for pixel offset
                return (lengthOfGroup - rightTitleWidth) - propsObj.answers.length + "px";
            }

        }
    } else {
        //Circles
        let centerOrLeftTitle = "";
        let centerOrRightTitle = "";

        if(propsObj.isZeroToTen) {
            circleBoxWidth = 50;
        }

        if(propsObj.desktopOrMobile === "desktop") {

            if(propsObj.position === "center") {
                centerOrLeftTitle = propsObj.centerTitle;
                centerOrRightTitle = propsObj.rightTitle;
            } else if (propsObj.position === "left") {
                centerOrLeftTitle = propsObj.leftTitle;
                centerOrRightTitle = propsObj.centerTitle;
            } else {
                // position === "right"
                if(!propsObj.isZeroToTen) {
                    // when using 0-10 scale, add right margin of 30 then align middle of circle with middle of title
                    return 30 + (circleBoxWidth / 2) - (getLabelWidth(propsObj.rightTitle) / 2)
                } else {
                    // in non 0-10 scale, align middle of circle with middle of title
                    return circleBoxWidth - (getLabelWidth(propsObj.rightTitle) / 2)
                }
            }

        } else {
            circleBoxWidth = 53;
            if(propsObj.position === "center") {
                centerOrRightTitle = propsObj.centerTitle;
                centerOrLeftTitle = propsObj.leftTitle;
            } else {
                centerOrRightTitle = propsObj.rightTitle;
                centerOrLeftTitle = propsObj.centerTitle;
            }
        }

        // align the middle of the labels with each other
        let alignMiddles = -(getLabelWidth(centerOrRightTitle) / 2) - (getLabelWidth(centerOrLeftTitle) / 2);

        // get the middle circle location for odd number and middle for even number of answer choices
        let circleLocation = circleBoxWidth * (Math.floor(propsObj.answers.length / 2));

        return alignMiddles + circleLocation;
    }
}

// THIS DOES NOT APPEAR TO BE USED
export function getSingleSelectCustomStyle(whichStyle, propsObj) {

    let tempStyle = {};

    switch (whichStyle) {
        case CIRCLE_INPUT_FORMAT:

            tempStyle = {
                questionTextRow: {
                    width: '100%',
                },
                descriptionTextRow: {
                    width: '100%',
                },
                inputRow: {
                    textGroup: {
                        width: '100%',
                        float: 'left',
                        display: 'flex',
                        alignItems: 'center',
                        //justifyContent: 'center',
                    },
                    circleInputGroup: {
                        placeContent: "space-between",
                        width: ((propsObj.answers.length === 10) ? "530px" : ((propsObj.answers.length === 11) ? "565px" : "365px")),
                        minWidth: ((propsObj.answers.length === 10) ? "530px" : ((propsObj.answers.length === 11) ? "565px" : "365px")),
                        float: propsObj.isLeftAligned ? 'left' : 'right',
                        //marginLeft: propsObj.isLeftAligned ? '0px' : '10px',
                        //marginTop: (propsObj.questionLevelAlignment) ? '0px' : '20px',
                    },
                    buttonGroup: {
                        placeContent: "space-between",
                        width: ((propsObj.answers.length === 10) ? "530px" : ((propsObj.answers.length === 11) ? "565px" : "365px")),
                        minWidth: ((propsObj.answers.length === 10) ? "530px" : ((propsObj.answers.length === 11) ? "565px" : "365px")),
                        float: propsObj.isLeftAligned ? 'left' :  'right',
                        //marginLeft: propsObj.isLeftAligned ? '0px' : '10px',
                        //marginTop: (propsObj.questionLevelAlignment) ? '0px' : '20px',
                        //backgroundColor: 'blue'
                    },
                    buttonContainer: {
                        borderRadius: '50%',
                        minWidth: '40px',
                        width: '40px',
                        height: '40px',
                        marginBottom: '10px',
                        //backgroundColor: 'cyan'
                    },
                    button: {
                        border: 'solid',
                        borderWidth: '2px',
                        borderRadius: '50%',
                        minWidth: '40px',
                        width: '40px',
                        height: '40px',
                        boxShadow: '0 1px 10px #e0e0e0',
                        //backgroundColor: 'pink'
                    },
                    responsiveLabel: {
                        textAlign: 'center',
                        whiteSpace: 'nowrap',
                        justifyContent: 'center'
                    }
                }
            }
            break;

        case CIRCLE_LIKERT_02_07:

            tempStyle = {
                root: {
                    //marginBottom: propsObj.rightAndAlignedWithQuestions ? '-40px' : '20px',
                    marginBottom: propsObj.rightAndAlignedWithQuestions ? ((propsObj.answers.length < 10) ? '10px' : '-40px') : '20px',
                    display: propsObj.rightAndAlignedWithQuestions ? 'flex' : 'unset',
                    //alignItems: propsObj.rightAndAlignedWithQuestions ? 'center' : 'unset',
                    alignItems: propsObj.rightAndAlignedWithQuestions ? ((propsObj.answers.length < 10) ? 'center' : 'top') : 'unset',
                    justifyContent: propsObj.rightAndAlignedWithQuestions ? 'space-between' : 'unset',
                    backgroundColor: 'yellow'
                },
                subheader: {
                    float: 'left',
                    width: '100%!',
                    marginBottom: propsObj.rightAndAlignedWithQuestions ? ((propsObj.answers.length < 10) ? '10px' : '-10px') : '0px',
                    marginLeft: '10px',
                    paddingLeft: propsObj.indent,
                    marginTop: propsObj.questionLevelAlignment && !propsObj.isLeftAligned && propsObj.hasTitle ? '30px' : '0px',
                    backgroundColor: 'purple'
                },
                desktop: {
                    root: {
                        display: 'flex',
                        flexDirection: 'column',
                        float: propsObj.isLeftAligned ? 'left' : 'right',
                        width: '365px',
                        marginTop: (propsObj.questionLevelAlignment && !propsObj.isLeftAligned) ? (propsObj.hasTitle ? '-110px' : '0px') : '0px',
                        backgroundColor: 'pink'
                    },
                    labelsRoot: {
                        float: 'right',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        display: 'flex',
                        flexDirection: 'row-reverse',
                        marginLeft: '10px'
                    },
                    leftTitle: {
                        width: getLabelWidth(propsObj.leftTitle),
                        float: 'right',
                        textAlign: 'right',
                        marginRight: calculateMarginSpaceForLabel("circle", "desktop", "left", false, propsObj)
                    },
                    rightTitle: {
                        width: getLabelWidth(propsObj.rightTitle),
                        float: 'right',
                        marginRight: calculateMarginSpaceForLabel("circle", "desktop", "right", false, propsObj)
                    },
                    centerTitle: {
                        width: getLabelWidth(propsObj.centerTitle),
                        float: 'right',
                        textAlign: 'center',
                        marginRight: calculateMarginSpaceForLabel("circle", "desktop", "center", false, propsObj)
                    },
                    textGroup: {
                        width: '100%',
                        float: 'left',
                        display: 'flex',
                        alignItems: 'center',
                        //justifyContent: 'center',
                        backgroundColor: 'yellow'
                    },
                    buttonGroup: {
                        placeContent: "space-between",
                        width: ((propsObj.answers.length === 10) ? "530px" : ((propsObj.answers.length === 11) ? "565px" : "365px")),
                        minWidth: ((propsObj.answers.length === 10) ? "530px" : ((propsObj.answers.length === 11) ? "565px" : "365px")),
                        float: propsObj.isLeftAligned ? 'left' :  'right',
                        //marginLeft: propsObj.isLeftAligned ? '0px' : '10px',
                        //marginTop: (propsObj.questionLevelAlignment) ? '0px' : '20px',
                        backgroundColor: 'blue'
                    },
                    buttonContainer: {
                        borderRadius: '50%',
                        minWidth: '40px',
                        width: '40px',
                        height: '40px',
                        marginBottom: '10px',
                        backgroundColor: 'red'
                    },
                    button: {
                        border: 'solid',
                        borderWidth: '2px',
                        borderRadius: '50%',
                        minWidth: '40px',
                        width: '40px',
                        height: '40px',
                        boxShadow: '0 1px 10px #e0e0e0',
                        backgroundColor: 'green'
                    },
                    responsiveLabel: {
                        textAlign: 'center',
                        whiteSpace: 'nowrap',
                        justifyContent: 'center'
                    }
                },
                mobile: {
                    root: {
                        display: 'flex',
                        flexDirection: 'column',
                        float: 'left',
                        width: '100%'
                    },
                    labels: {
                        display: 'flex',
                        width: '360px',
                        height: '17px',
                        marginTop: '10px',
                        whiteSpace: 'nowrap',
                        alignItems: 'center',
                    },
                    buttonContainer: {
                        borderRadius: '50%',
                        minWidth: '53px',
                        width:    '53px',
                        height:   '50px',
                        marginBottom: '40px',
                        textAlign: 'center',
                        fontSize: '12px',
                    },
                    button: {
                        border: 'solid',
                        borderWidth: '3px',
                        borderRadius: '50%',
                        minWidth: '45px',
                        width:    '45px',
                        height:   '45px',
                        boxShadow: '0 1px 10px #e0e0e0',
                    }
                }
            };

            break;

        case CIRCLE_LIKERT_10_11:
// ZZZZZ
            tempStyle = {
                buttonStyle: {
                    // backgroundColor: ""
                },
                root: {
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'row-reverse',
                    backgroundColor: 'cyan'
                },
                leftTitle: {
                    width: getLabelWidth(propsObj.leftTitle),
                    float: 'right',
                    textAlign: 'right',
                    marginRight: calculateMarginSpaceForLabel("circle", "desktop", "left", true, propsObj)
                },
                centerTitle: {
                    width: getLabelWidth(propsObj.centerTitle),
                    float: 'right',
                    textAlign: 'center',
                    marginRight: calculateMarginSpaceForLabel("circle", "desktop", "center", true, propsObj)
                },
                rightTitle: {
                    width: getLabelWidth(propsObj.rightTitle),
                    float: 'right',
                    marginRight: calculateMarginSpaceForLabel("circle", "desktop", "right", true, propsObj)
                },
                buttonGroup: {
                    borderRadius: '50%',
                    minWidth: '40px',
                    width: '40px',
                    height: '70px',
                    marginRight: '5px',
                    marginLeft:  '5px',
                    marginBottom: '10px',
                    textAlign: 'center',
                    backgroundColor: 'yellow'
                },
                button: {
                    border: 'solid',
                    borderWidth: '2px',
                    borderRadius: '50%',
                    minWidth: '40px',
                    width: '40px',
                    height: '40px',
                    boxShadow: '0 1px 10px #e0e0e0',
                    backgroundColor: 'green'
                },
                responsiveLabel: {
                    display: 'flex',
                    textAlign: 'center',
                    whiteSpace: 'nowrap',
                    justifyContent: 'center'
                },
                singleLine: {
                    display: "inline-block",
                    flexDirection: "column",
                    float: propsObj.isLeftAligned ? "left" : "right",
                    marginLeft: propsObj.isLeftAligned ? '0px' : '0px',
                    marginRight: propsObj.isLeftAligned ? '0px' : '5px',
                    marginBottom: propsObj.rightAndAlignedWithQuestions ? '0px' : '0px', // ZZZZZZ '40px',
                    marginTop: propsObj.questionLevelAlignment && !propsObj.isLeftAligned && propsObj.hasTitle ? '50px' : '0px',
                    backgroundColor: 'orange'
                },
            };

            break;

        case CIRCLE_LIKERT_PYRAMID:
// ZZZZZ
            tempStyle = {
                pyramid: {
                    root: {
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        float: 'left'
                    },
                    leftTitle: {
                        marginBottom: '10px',
                        marginLeft: '15px',
                        marginRight: 'auto',
                    }
                },
                singleLine: {
                    display: "inline-block",
                    flexDirection: "column",
                    float: propsObj.isLeftAligned ? "left" : "right",
                    marginLeft: propsObj.isLeftAligned ? '0px' : '0px',
                    marginRight: propsObj.isLeftAligned ? '0px' : '5px'
                }
            };

            break;

        // no default

    }

    return tempStyle;
}


export const DISPLAY_CIRCLES_NO  = "DISPLAY_CIRCLES_NO"; //
export const DISPLAY_CIRCLES_PLAIN  = "DISPLAY_CIRCLES_PLAIN"; //
export const DISPLAY_CIRCLES_COLORS  = "DISPLAY_CIRCLES_COLORS"; //

export function displayAsCircles(group, question) {

    let displayAsCircles = DISPLAY_CIRCLES_NO;

    if (tag.metadataExists(question, portalShared.DISPLAY_BUTTONS_AS_CIRCLES) || tag.metadataExists(group, portalShared.DISPLAY_BUTTONS_AS_CIRCLES)) {
        displayAsCircles = DISPLAY_CIRCLES_PLAIN;
    }

    // Having a color metadata will override plain metadata
    // NOTE: Pyramid/Honeycomb is NOT a override, it auto-translates an 11 input circle if metadata exists
    // NOTE: Alt Colors Circles I removes because I no longer believe it exists.

    if (tag.metadataExists(question, portalShared.DISPLAY_BUTTONS_AS_CIRCLES_COLORS) || tag.metadataExists(group, portalShared.DISPLAY_BUTTONS_AS_CIRCLES_COLORS)) {
        displayAsCircles = DISPLAY_CIRCLES_COLORS;
    }

    return displayAsCircles;
}


// tag.metadataExists(question, portalShared.DISPLAY_BUTTONS_AS_CIRCLES_PYRAMID) ||
// tag.metadataExists(question, portalShared.DISPLAY_BUTTONS_AS_CIRCLES_PYRAMID_COLORS) ||
// tag.metadataExists(group, portalShared.DISPLAY_BUTTONS_AS_CIRCLES_PYRAMID_COLORS) ||
// tag.metadataExists(question, portalShared.DISPLAY_BUTTONS_AS_CIRCLES_COLORS_ALT) ||
// tag.metadataExists(group, portalShared.DISPLAY_BUTTONS_AS_CIRCLES_COLORS_ALT) ||
// tag.metadataExists(question, portalShared.DISPLAY_BUTTONS_AS_CIRCLES_PYRAMID_COLORS_ALT) ||
// tag.metadataExists(group, portalShared.DISPLAY_BUTTONS_AS_CIRCLES_PYRAMID_COLORS_ALT)
