import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArgoReorderArrowsHook from "../../common/ArgoReorderArrowsHook";
import Typography from "@mui/material/Typography";
import ArgoIconButtonHook from "../../common/ArgoIconButtonHook";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AccordionDetails from "@mui/material/AccordionDetails";
import {removeCategoryGroup, reorderCategoryGroup} from "../../../actions/categoryEditorActions";
import {getGroupTemplate} from "../../../actions/groupTemplateActions";
import {getQuestionTemplate} from "../../../actions/questionTemplateActions";
import {expanderUpdate} from "../../../actions/expanderActions";
import {openDrawer} from "../../../actions/drawerActions";
import QuestionViewerExpandable from "../questions/QuestionViewerExpandable";
import ArgoTemplateListDividerHook from "../../common/ArgoTemplateListDividerHook";
import ArgoExpandCollapseButtonHook from "../../common/ArgoExpandCollapseButtonHook";
import * as expander from "../../../utilities/expander";
import statusFormatter from "../../../utilities/StatusFormatter";
import * as screenChangeSize from "../../../utilities/screenChangeSize";
import * as groupType from "../../../constants/groupTypeLikertOrDefault";
import * as TEMPLATE from "../../../constants/templateConstants";


class GroupViewerExpandable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expander: {},
            expandAll: true,
            protalPreviewRendered: false,
            groupInfoDrawerOpen: false,
            questionsLoaded: true,
            widthChange: false
        };
    }

    // static contextType = UserContext;  // From blueTheme.js

    componentDidMount() {
        // Not sure why but this component will re-mount after review screen back button
        // So putting in a fix to set expander to look at this.props.expander
        for (let i = 0; i < this.props.groupKeys?.length; i++) {
            this.props.getGroupTemplate(this.props.groupKeys[i], null);
        }
        this.setExpander(this.props);
    }

    componentWillReceiveProps(nextProps) {
        // All groups and question loaded
        for (let i = 0; i < nextProps.groupKeys?.length; i++) {

            // Is group loaded
            if (nextProps.groupTemplates.hasOwnProperty(nextProps.groupKeys[i])) {

                let questionKeys = nextProps.groupTemplates[nextProps.groupKeys[i]].questionKeys;

                // Load all the questions for current group
                for (let ii = 0; ii < questionKeys.length; ii++) {

                    // If question is NOT loaded then get question
                    if (!nextProps.questionTemplates.hasOwnProperty(questionKeys[ii])) {
                        this.props.getQuestionTemplate(questionKeys[ii], null);
                    }
                }
            }
        }

        if (!nextProps.expander.expanderSet) {
            this.setExpander(nextProps);
        }
    }

    setExpander = (props) => {
        // Not sure why but this component will re-mount after review screen back button
        // So putting in a fix to set expander to look at this.props.expander
        if (this.allRequiredTemplatesLoaded(props)) {
            this.setState({expander: expander.presetExpander(props.groupKeys, props.groupTemplates, props)});
            props.setStateCallback({groupsLoaded: true, groupTemplates: props.groupTemplates});
        }
    }


    setStateCallback = (stateObj, templateKey) => {
        this.setState(stateObj);

        // If the expander is being updated, then update the global redux store
        if (stateObj && stateObj.hasOwnProperty("expander")) {
            this.props.expanderUpdate(this.props.parentName, TEMPLATE.TYPE.GROUP, stateObj, templateKey);
        }
    }

    allRequiredTemplatesLoaded = (props) => {

        let allRequiredTemplatesLoaded = true;

        for (let i = 0; i < props.groupKeys.length; i++) {
            if (!props.groupTemplates.hasOwnProperty(props.groupKeys[i])) {
                allRequiredTemplatesLoaded = false;
                break;
            }
            else {
                let questionKeys = props.groupTemplates[props.groupKeys[i]].questionKeys;
                for (let i = 0; i < questionKeys.length; i++) {
                    if (!props.questionTemplates.hasOwnProperty(questionKeys[i])) {
                        allRequiredTemplatesLoaded = false;
                    }
                }
            }
        }

        return allRequiredTemplatesLoaded;
    };

    // -----------------------------------------------------------------------------------------------------------------
    // EXPANDER FUNCTIONS ----------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    onClickIconButtonReorder = (event, fieldName, index, depth, direction) => {
        event.stopPropagation(); // Keep accordion from expand/collapse
        this.props.reorderCategoryGroup((direction === "up"), index);
    };

    onClickIconButtonEdit = (groupTemplate, event) => {
        event.stopPropagation(); // Keep accordion from expand/collapse
        this.props.setStateCallback({openGroupEditor: true, groupTemplate: groupTemplate});
    };

    onClickIconButtonRemove = (index, event) => {
        event.stopPropagation(); // Keep accordion from expand/collapse
        this.props.removeCategoryGroup(index);
    };

    onClickIconButtonInfo = (groupTemplateKey, event) => {
        event.stopPropagation(); // Keep accordion from expand/collapse
        this.props.setStateCallback({openGroupReview: true, selectedGroupInfoKey: groupTemplateKey, hidePortalPreview: true, showCategoryBackButton: false});
        //this.props.openDrawer(groupReviewDrawerProps, <GroupReviewDrawer templateKey={this.props.templateKey} templateId={this.props.template.id}/>);
    };

    onExpandChange = (groupTemplateKey, event, open) => {
        expander.expandCollapseSelected(this.state.expander, groupTemplateKey, this.setStateCallback);
    };

    // -----------------------------------------------------------------------------------------------------------------
    // RENDER ----------------------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    render() {
        //changes state of widthChange so it re-renders the width everytime
        window.addEventListener('resize', this.setStateCallback.bind(this, {widthChange: !this.state.widthChange}))

        //const {palette} = this.context;  // From blueTheme.js
        const template = this.props.categoryTemplate;
        // let templateIsLoaded = template && (!this.props.templateId || (template.id && template.id.toString() === this.props.templateId));
        // if (!templateIsLoaded) {
        //     return null;
        // }

        return (
            this.allRequiredTemplatesLoaded(this.props) ?
                <div key={"GroupViewerExpandable-container-div-categoryId-" + template.id} id={"GroupViewerExpandable-container-div-categoryId-" + template.id} style={{width: "100%"}}>

                    <div>&nbsp;</div>

                    {/*// ===========================================================================================================*/}
                    {/*// GROUP LIST DIVIDER */}
                    {/*// ===========================================================================================================*/}

                    <ArgoTemplateListDividerHook
                        fileName="GroupViewerExpandable"
                        parentsLabel="Category"
                        childLabel={["group","groups"]}
                        arrayLength={this.props.groupKeys.length}
                    />

                    {/*// ===========================================================================================================*/}
                    {/*// GROUP EXPAND/COLLAPSE BUTTON  - NOTE: No expand/collapse button if group is Likert ie.. has answers  */}
                    {/*// ===========================================================================================================*/}

                    {(this.props.groupKeys.length > 1) ?
                        <div style={{float: "right", width: 140}}>
                            <ArgoExpandCollapseButtonHook
                                fileName="GroupViewerExpandable"
                                expandAll={this.props.expander.expandAll}
                                onClick={expander.expandCollapseAll.bind(this, TEMPLATE.TYPE.GROUP, this.state.expander, this.props.expander.expandAll, this.props.groupKeys, this.props.hidePortalPreview, this.setStateCallback)}
                            />

                            <div>&nbsp;</div>
                        </div>
                        : ""
                    }

                    {/*// ===========================================================================================================*/}
                    {/*// ASSIGNED GROUPS LIST */}
                    {/*// ===========================================================================================================*/}

                    <div id="GroupViewerExpandable-question-list-container-div" style={{display: "flex", alignSelf: "flex-start", flexDirection: "column", width: "100%", minHeight: "min-content"}}>
                        {this.props.groupKeys.map((groupTemplateKey, index) => {
                            return (
                                <div key={groupTemplateKey + index} id={"GroupViewerExpandable-question-container-div-" + groupTemplateKey + index} style={{display: "flex", flexDirection: "row", alignItems: "center"}}>

                                    <Accordion key={"Accordion-" + groupTemplateKey}
                                               id={"GroupViewerExpandable-Accordion-" + groupTemplateKey}
                                               variant="outlined"
                                               disableGutters={true}
                                               onChange={this.onExpandChange.bind(this, groupTemplateKey)}
                                               expanded={this.props.expander.expander[groupTemplateKey]?.open ? this.props.expander.expander[groupTemplateKey]?.open : false}
                                               sx={{width: "100%", minWidth: 940}}>
                                        <AccordionSummary key={"AccordionSumary-" + groupTemplateKey}
                                                          id={"GroupViewerExpandable-AccordionSummary-" + groupTemplateKey}
                                                          expandIcon={<ExpandMoreIcon style={{color: expander.ICON_COLOR}}/>}
                                                          sx={{height: "30px", backgroundColor: expander.ACCORDION_BG_COLOR, width: "100%", '&:hover': {bgcolor: expander.ACCORDION_BG_COLOR_HOVER}}}
                                        >
                                            <div style={{display: "flex", flexDirection: "row", alignItems: "center", width: "100%"}}>

                                                {this.props.showIconButtonReorder ?
                                                    <ArgoReorderArrowsHook
                                                        fileName="GroupViewerExpandable"
                                                        fieldName="container-div"
                                                        onClick={this.onClickIconButtonReorder}
                                                        index={index}
                                                        arrayLength={this.props.groupKeys.length}
                                                        isFirst={index === 0}
                                                        isLast={index === (this.props.groupKeys.length -1)}
                                                        upArrowColor={index === 0 ? expander.ICON_COLOR_HIDDEN : expander.ICON_COLOR}
                                                        downArrowColor={this.props.isLast ? expander.ICON_COLOR_HIDDEN : expander.ICON_COLOR}
                                                    /> : ""
                                                }

                                                <Typography>
                                                    {statusFormatter(this.props.groupTemplates[groupTemplateKey], 0, 0)}
                                                </Typography>

                                                <Typography variant="h6" component="div" sx={{color: expander.TEXT_COLOR_SECONDARY}}>
                                                    {"Group:"}
                                                </Typography>

                                                <Typography variant="h6" component="div"
                                                            sx={{paddingLeft: "5px",
                                                                color: expander.TEXT_COLOR_PRIMARY,
                                                                whiteSpace: 'nowrap',
                                                                wordWrap: 'break-word',
                                                                overflow: "hidden",
                                                                textOverflow: 'ellipsis',
                                                                minWidth: 50,
                                                                width: screenChangeSize.setWidth.call(this, 1020, [this.props.groupTemplates[groupTemplateKey].nameQualifier, this.props.showIconButtonReorder, this.props.showIconButtonEdit, this.props.showIconButtonRemove, this.props.showIconButtonInfo], this.setStateCallback),
                                                            }}>
                                                    {this.props.groupTemplates[groupTemplateKey].name}
                                                </Typography>

                                                {this.props.groupTemplates[groupTemplateKey].nameQualifier ?
                                                    <div style={{display: "flex"}}>
                                                        <Typography variant="subtitle1" component="div" sx={{paddingLeft: "20px", paddingTop: "3px", color: expander.TEXT_COLOR_SECONDARY}}>
                                                            {(this.props.groupTemplates[groupTemplateKey].nameQualifier ? "Qualifier:" : "")}
                                                        </Typography>

                                                        <Typography variant="subtitle1" component="div"
                                                                    sx={{paddingLeft: "5px",
                                                                        paddingTop: "3px",
                                                                        color: expander.TEXT_COLOR_PRIMARY,
                                                                        whiteSpace: 'nowrap',
                                                                        wordWrap: 'break-word',
                                                                        overflow: "hidden",
                                                                        textOverflow: 'ellipsis',
                                                                        maxWidth: 120,
                                                                        minWidth: 120
                                                                    }}>
                                                            {this.props.groupTemplates[groupTemplateKey].nameQualifier ? this.props.groupTemplates[groupTemplateKey].nameQualifier : ""}
                                                        </Typography>
                                                    </div> : ''
                                                }

                                                <Typography variant="subtitle1" component="div" sx={{paddingLeft: "20px", paddingTop: "3px", color: expander.TEXT_COLOR_PRIMARY, minWidth: 52}}>
                                                    {this.props.groupTemplates[groupTemplateKey].questionGroupType === groupType.GROUP_TYPE_LIKERT ? " LIKERT" : ""}
                                                </Typography>

                                                <Typography variant="subtitle1" component="div" sx={{paddingLeft: "20px", paddingTop: "3px", color: expander.TEXT_COLOR_SECONDARY, minWidth: 163}}>
                                                    {"(Questions Assigned: " + (this.props.groupTemplates[groupTemplateKey].questionKeys ? this.props.groupTemplates[groupTemplateKey].questionKeys.length : "0") + ")"}
                                                </Typography>

                                                <div id={"GroupViewerExpandable-icon-button-container-div" + index} style={{display: "flex", marginLeft: "auto"}}>

                                                    {this.props.showIconButtonEdit ?
                                                        <ArgoIconButtonHook
                                                            fileName="GroupViewerExpandable"
                                                            fieldName="edit"
                                                            onClick={this.onClickIconButtonEdit.bind(this, this.props.groupTemplates[groupTemplateKey])}
                                                            index={this.props.index}
                                                            icon={<ModeEditIcon style={{color: expander.ICON_COLOR}}/>}
                                                            display={this.props.showEditIconButton}
                                                        />  : ""
                                                    }

                                                    <div>&nbsp;</div>

                                                    {this.props.showIconButtonRemove ?
                                                        <ArgoIconButtonHook
                                                            fileName="GroupViewerExpandable"
                                                            fieldName="remove"
                                                            onClick={this.onClickIconButtonRemove.bind(this, index)}
                                                            index={this.props.index}
                                                            icon={<HighlightOffOutlinedIcon style={{color: expander.ICON_COLOR}}/>}
                                                            display={this.props.showIconButtonRemove}
                                                        />  : ""
                                                    }

                                                    <div>&nbsp;</div>

                                                    {this.props.showIconButtonInfo ?
                                                        <ArgoIconButtonHook
                                                            fileName="GroupViewerExpandable"
                                                            fieldName="info"
                                                            onClick={this.onClickIconButtonInfo.bind(this, groupTemplateKey)}
                                                            index={this.props.index}
                                                            icon={<InfoOutlinedIcon style={{color: expander.ICON_COLOR}}/>}
                                                        /> : ""
                                                    }

                                                    <div>&nbsp;</div>

                                                </div>

                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails key={"GroupViewerExpandable-AccordionDetails-" + groupTemplateKey}>

                                            {/*// ===========================================================================================================*/}
                                            {/*// ASSIGNED QUESTIONS LIST */}
                                            {/*// ===========================================================================================================*/}

                                            <QuestionViewerExpandable
                                                parentName={this.props.parentName}
                                                parentType={TEMPLATE.TYPE.GROUP}
                                                parentTemplate={this.props.groupTemplates[groupTemplateKey]}
                                                questionKeys={this.props.groupTemplates[groupTemplateKey].questionKeys}
                                                showIconButtonReorder={false}
                                                showIconButtonEdit={this.props.showIconButtonEdit}
                                                showIconButtonRemove={false}
                                                setStateCallback={this.props.setStateCallback}
                                                hidePortalPreview={this.props.groupTemplates[groupTemplateKey].answerOptionTemplates.length !== 0}
                                            />

                                            <div>&nbsp;</div>

                                        </AccordionDetails>
                                    </Accordion>

                                    {(this.state.expander[groupTemplateKey]?.open) ? <div id={"spacer-div-" + index}>&nbsp;</div> : ""}

                                </div>
                            );
                        })}
                    </div>

                </div>
                : ""
        );
    }
}

GroupViewerExpandable.defaultProps = {
    parentName: "",
    categoryTemplate: {},
    defaultExpanded: false,
    showIconButtonReorder: true,
    showIconButtonEdit: true,
    showIconButtonRemove: true,
    showIconButtonInfo: true,
    hidePortalPreview: false
};

GroupViewerExpandable.propTypes = {
    parentName: PropTypes.string,
    categoryTemplate: PropTypes.object,
    groupKeys: PropTypes.array,
    showIconButtonReorder: PropTypes.bool,
    showIconButtonEdit: PropTypes.bool,
    showIconButtonRemove: PropTypes.bool,
    showIconButtonInfo: PropTypes.bool,
    setStateCallback: PropTypes.func,
    hidePortalPreview: PropTypes.bool,
    questionTemplate: PropTypes.object // Added to force re-render after edit
};

function mapStateToProps(state, props) {

    let tempExpander = {expanderSet: false, expandAll: true, expander:{}};
    let expanderExists = false;
    if (state.expander.hasOwnProperty(props.parentName)) {
        if (state.expander[props.parentName].hasOwnProperty(TEMPLATE.TYPE.GROUP)) {
            expanderExists = true;
        }
    }

    return {
        groupTemplates: state.groupTemplate,
        questionTemplates: state.questionTemplate,
        questionsFilter: state.filter[TEMPLATE.FILTER.current.group],
        expander: expanderExists ? state.expander[props.parentName][TEMPLATE.TYPE.GROUP] : tempExpander
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({getGroupTemplate, removeCategoryGroup, reorderCategoryGroup, getQuestionTemplate, expanderUpdate, openDrawer}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupViewerExpandable);
