import React, {Component} from "react";
import PropTypes from "prop-types";
import ArgoTextFieldSelectHook from "./ArgoTextFieldSelectHook";
import * as metadataUtils from "../../utilities/metadata";
import * as portalShared from "../../portal/shared/metadataConstantsAndUtilities"


class ArgoSelectPortalTextDisplayType extends Component {
    constructor(props) {
        super(props);

        this.state = {
            textDisplay: props.initTo
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({textDisplay: nextProps.initTo});
    }

    onSelectChange = (event, fieldName) => {

        let selected = event.target.value;

        let metadataValue = "";

        switch(selected) {
            case "hide":
                metadataValue = selected;
                break;
            case "default":
            case "custom":
                metadataValue =  JSON.stringify(portalShared.getCustomTextObj(this.props.templateType, this.props.textEntryType));
                break;
            default:
                console.log("ArgoSelectPortalTextDisplayType.js Switch default E01"); // eslint-disable-line
        }

        let mpo = this.props.metadataPropsObj;

        this.setState({[fieldName]: selected}, () => {metadataUtils.setCustomMetadata(this.props.fieldTextObjName, mpo.template, mpo.templateUpdateFunction, mpo.setStateCallbackFunction, this.props.fieldName, selected, mpo.metadataTag, (metadataValue !== "hide" ? JSON.parse(metadataValue) : "hide"), mpo.depth)});
    };


    render() {

        let textDisplayItems = [];

        if (this.props.hideOption) {
            textDisplayItems.push({value: "hide", text: "Hide"});
        }

        // If NOT hide only options add Default and Custom to Portal Custom DDLB
        if (!this.props.hideOnly) {
            textDisplayItems.push({value: "default", text: "Default"});
            textDisplayItems.push({value: "custom", text: "Custom"});
        }

        return (

            <React.Fragment>

                <div style={{width: "15px"}}></div>

                <ArgoTextFieldSelectHook
                    fileName="ArgoSelectPortalTextDisplayType"
                    fieldName="textDisplay"
                    index={this.props.index}
                    label={this.props.labelText}
                    value={this.state.textDisplay}
                    onChange={this.onSelectChange}
                    fullWidth={false}
                    width="130px"
                    menuItems={textDisplayItems}
                />

            </React.Fragment>
        );
    }
}

ArgoSelectPortalTextDisplayType.defaultProps = {
    labelText: "Portal Display",
    hideOption: true,
    hideOnly: false,
    index: 0
};

ArgoSelectPortalTextDisplayType.propTypes = {
    templateType: PropTypes.string,
    textEntryType: PropTypes.string,
    fieldName: PropTypes.string,
    fieldTextObjName: PropTypes.string,
    labelText: PropTypes.string,
    hideOption: PropTypes.bool,
    hideOnly: PropTypes.bool,
    initTo: PropTypes.string,
    metadataPropsObj: PropTypes.object,
    index: PropTypes.number,
};

export default ArgoSelectPortalTextDisplayType;
