import * as types from "../constants/actionConstants";
import * as questionTypes from "../constants/questionTypeConstants";
import * as portalShared from "../shared/metadataConstantsAndUtilities";


const initialState ={
    refKey: null,
    metadata: null,
    questionnaireInstanceId: null,
    questionnaireInstance: null,
    questionnaireTemplateId: null,
    questionnaireTemplate: null,
    templateObj: null, // PortalPreview
    currPageIndex: 0,
    portalCount: 0
};

function portalReducer(state = initialState, action) {

    let tempQnr = null;

    switch (action.type) {
        case types.QUESTIONNAIRE_START:
            return {
                ...state,
                currPageIndex: 0,
                questionnaireInstance: action.data
            };
        case types.QUESTIONNAIRE_GET_FULL_SUCCESS:
            return {
                ...state,
                [action.questionnaire.id]: action.questionnaire
            };
        case types.SET_QUESTIONNAIRE_INSTANCE_NULL:
            return {
                ...state,
                refKey: null,
                questionnaireInstanceId: null,
                currPageIndex: 0,
                questionnaireInstance: {}
            };
        case types.SET_QUESTIONNAIRE_TEMPLATE_SUCCESS:
            return {
                ...state,
                questionnaireTemplateId: action.data.id,
                questionnaireTemplate: action.data.questionnaireTemplate
            };
        case types.SET_QUESTIONNAIRE_TEMPLATE_NULL:
            return {
                ...state,
                refKey: null,
                questionnaireTemplateId: null,
                currPageIndex: 0,
                questionnaireTemplate: {}
            };
        case types.GET_QUESTIONNAIRE_INSTANCE_ID_FROM_REF_KEY_SUCCESS:
            return {
                ...state,
                refKey: action.data.refKey,
                metadata: action.data.metadata,
                firstAnswerIndex: null,
                questionnaireInstanceId: action.data.questionnaireId
            };
        case types.GET_QUESTIONNAIRE_READONLY_INSTANCE_ID_FROM_REF_KEY_SUCCESS:
            return {
                ...state,
                refKey: action.data.refKey,
                metadata: action.data.metadata,
                questionnaireInstanceId: action.data.questionnaireId
            };
        case types.GET_QUESTIONNAIRE_INSTANCE_SUCCESS:
            return {
                ...state,
                currPageIndex: 0,
                questionnaireInstance: action.data
            };
        case types.SET_TEMPLATE_OBJ:
            return {
                ...state,
                templateObj: action.data.templateObj
            };
        case types.PORTAL_CURRENT_PAGE:
            return {
                ...state,
                currPageIndex: action.data
            };
        case types.PORTAL_NEXT_PAGE:
            return {
                ...state,
                currPageIndex: ++action.data
            };
        case types.PORTAL_PREV_PAGE:
            return {
                ...state,
                currPageIndex: --action.data
            };
        case types.QUESTION_ANSWER_SUCCESS:

            tempQnr = portalShared.cloneObj(state.questionnaireInstance); // Clone template instance

            for (let i = 0; i < tempQnr.questions.length; i++) {

                // Find the Question ID
                if (tempQnr.questions[i].id === action.data.id) {

                    // If the answer key is null, then must be a question type w/o answer objects
                    if (action.data.key === null) {

                        if (action.data.responseObj.addressAnswerDTO) {
                            tempQnr.questions[i].addressAnswerDTO = action.data.responseObj.addressAnswerDTO;
                        }
                        else
                        {
                            tempQnr.questions[i].answerText = action.data.responseObj.answerText;
                        }
                    }
                    else {

                        let answers = tempQnr.questions[i].answerOptions;

                        for (let j = 0; j < answers.length; j++) {

                            // Find the Answer Key for answered question and deselect any possible previous answers.
                            if (answers[j].key === action.data.key) {

                                answers[j].selected = action.data.responseObj.selected;
                            }
                            else
                            {
                                switch (tempQnr.questions[i].questionType) {
                                    case questionTypes.RADIO:
                                    case questionTypes.YES_NO:
                                    case questionTypes.LIKERT_RADIO:
                                    case questionTypes.STATE_ONLY:
                                    case questionTypes.ADDRESS_FULL:
                                        answers[j].selected = false;  // Single select, deselect other options
                                        break;
                                    default:
                                        break
                                }
                            }
                        }
                    }

                    break; // Question Answer updated so break out of question loop
                }
            }

            return {
                ...state,
                questionnaireInstance: tempQnr
            };

        case types.QUESTION_TEMPLATE_ANSWER_SUCCESS:

            tempQnr = portalShared.cloneObj(state.questionnaireTemplate);

            let category = null;
            let group = null;
            let question = null;
            let answers = null;
            let answer = null;

            for (let c = 0; c < tempQnr.questionCategoryTemplates.length; c++) {

                category = tempQnr.questionCategoryTemplates[c];

                for (let g = 0; g < category.groupTemplates.length; g++) {

                    group = category.groupTemplates[g];

                    for (let i = 0; i < group.questionTemplates.length; i++) {

                        question = group.questionTemplates[i];

                        // Find the Question ID
                        if (question.id === action.data.id) {

                            // If the answer key is null, then must be a question type w/o answer objects
                            if (action.data.key === null) {

                                if (action.data.responseObj.addressAnswer !== undefined) {
                                    question.addressAnswer = action.data.responseObj.addressAnswer;
                                }
                                else
                                {
                                    question.answerText = action.data.responseObj.answerText;
                                }
                            }
                            else {

                                answers = question.answerOptionTemplates;

                                for (let j = 0; j < answers.length; j++) {

                                    answer = answers[j];
                                    // Find the Answer Key for answered question and deselect any possible previous answers.
                                    // NOTE key here is actually the answer ID
                                    if (answer.id === action.data.responseObj.id) {

                                        answer.selected = action.data.responseObj.selected;
                                    } else {
                                        switch (question.questionType) {
                                            case questionTypes.RADIO:
                                            case questionTypes.YES_NO:
                                            case questionTypes.LIKERT_RADIO:
                                            case questionTypes.STATE_ONLY:
                                                answer.selected = false;  // Single select, deselect other options
                                                break;
                                            default:
                                                break
                                        }
                                    }
                                }
                            }

                            break; // Question Answer updated so break out of question loop
                        }
                    }
                }
            }

            return {
                ...state,
                questionnaireTemplate: tempQnr
            };
        case types.INCREMENT_PORTAL_COUNT:
            return {
                ...state,
                portalCount: state.portalCount + 1
            };
        default:
            return state;
    }
}

export default portalReducer;
