import React, { useState, useEffect } from 'react';
import TextField from "@mui/material/TextField";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';


export default function ArgoAutocompleteHook(props) {
    const [error, setError] = useState(false);
    const [value, setValue] = useState(props.value || "");

    const fileName = props.fileName ?? "NoFileName";
    const fieldName = props.fieldName ?? "NoFieldName";

    const index = props.index ?? ""; // Index is needed of this input is created in a loop

    const uniqueIdentifier = fileName + "-ArgoAutocompleteHook-" + fieldName + index;

    const variant = props.variant ?? "standard";

    const label = props.label ?? "Label";

    const maxLength = props.maxLength ?? 255;

    const width = props.width ?? "0px";
    //const fullWidth = props.fullWidth === undefined ? ((width !== 0) ? false : true) : false;

    const paddingTop = props.paddingTop ?? "0px" ;

    const onChange = props.onChange ?? function () {};

    const multiline = props.multiline !== undefined;

    const openOnFocus = props.openOnFocus !== undefined;
    const errorText = props.errorText ?? " ";

    const menuItems = props.menuItems ?? [];
    const menuItemsIsStrings = menuItems.length > 0 ? (typeof menuItems[0] === "string") : false; // if not array of strings, must be objects {value: "", text: ""}

    const valueProperty = props.valueProperty ?? "value";
    const textProperty = props.textProperty ?? "text";

    const validationType = ""; // This is required by metadata editor

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    // const handleClick = (event) => {
    //
    //     setAnchorEl(event.currentTarget);
    // };

    const handleClose = (selectedValue) => {

        setAnchorEl(null);

        if (typeof selectedValue == "string"){

            triggerInput(uniqueIdentifier, (openOnFocus ? selectedValue : value + selectedValue + '}'))
        }
    };

    const triggerInput = (enteredName, enteredValue) => {

        const input = document.getElementById(enteredName);

        const lastValue = input.value;

        input.value = enteredValue;

        const event = new Event("input", { bubbles: true });

        const tracker = input._valueTracker;

        if (tracker) {
            tracker.setValue(lastValue);
        }

        input.dispatchEvent(event);
    }

    const handleKeyDown = (event) => {
        if (event.key === '{' && !openOnFocus){
            setAnchorEl(event.currentTarget);

            triggerInput(uniqueIdentifier,value+'{')
        }
    }

    useEffect(() => {
        setError((errorText !== " "));
    }, [value, errorText, error])

    return (
        <div id={uniqueIdentifier + "-container-div"} style={{paddingTop: paddingTop, width: width}}>
            <TextField
                id={uniqueIdentifier}
                name={uniqueIdentifier}
                variant={variant}
                label={label}
                value={props.value}
                multiline={multiline}
                onKeyDown={handleKeyDown}
                onClick = {(event)=>{if (openOnFocus )setAnchorEl(event.currentTarget)}}
                onChange={event => {setValue(event.target.value); onChange(event, fieldName, index, validationType, uniqueIdentifier)}}
                inputProps={{autoComplete: "off", maxLength: maxLength}}
                fullWidth
                error={error}
                helperText={errorText}
            />
                <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}

                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    {menuItems.map((item, index) => {
                        return (<MenuItem
                            key={uniqueIdentifier + index}
                            value={menuItemsIsStrings ? index : item[valueProperty]}
                            onClick={()=>handleClose(menuItemsIsStrings ? item : item[textProperty])}
                        >
                            {menuItemsIsStrings ? item : item[textProperty]}
                        </MenuItem>);
                    })}
                </Menu>
        </div>
    );
}
