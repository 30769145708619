import TemplateApi from "../api/TemplateApi";
import * as types from "../constants/actionConstants";


export function getSystemSetting() {
    return (dispatch) => {
        TemplateApi.getSettings().then((settings) => {
            dispatch({
                type: types.SET_SYSTEM_SETTING,
                payload: {...settings}
            });
        });
    };
}
