import React from 'react';
import TableCell from "@mui/material/TableCell";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import {toLocale} from "./dateFormats";
import gridPropIdFormatter from "./GridPropIdFormatter";


export default function statusFormatter(template, index, colnum, showStatusText = false) {

    let status;

    if (template) {

        let statusName = ""; // ZZZZZZ
        let tooltip = ""; // ZZZZZZ
        let statusIcon; // ZZZZZZ
        let statusToolTip; // ZZZZZZ

        if (template.retiredDate) {
            //statusName = "Retired";
            statusToolTip = "Retired";
            tooltip = toLocale(template.retiredDate);
            statusIcon = <DoNotDisturbIcon sx={{color: 'rgba(0, 0, 0, 0.87)'}}/>;

        }
        else if (template.publishedDate) {
            //statusName = "Published";
            tooltip = template.publishedDate; // toLocale(template.publishedDate); ZZZZZ

            // Questionnaires have a isActive flag, other templates will never be published inactive status
            if ((template.isActive === undefined) || (template.isActive === true)) {
                statusIcon = <CheckCircleIcon sx={{color: '#4caf50'}}/>;
                statusToolTip = "Published active";
            } else {
                statusIcon = <RemoveCircleOutlineIcon/>;
                statusToolTip = "Published inactive";
            }
        } else {
            //statusName = "Draft";
            statusToolTip = "Draft";
            tooltip = toLocale(template.createdDate);
            statusIcon = <PanoramaFishEyeIcon/>;

        }

        status = (<div style={{borderBottom: '0'}}>TableRow</div>);

        status = (
            <TableCell id={gridPropIdFormatter(index, colnum)} name={gridPropIdFormatter(index, colnum)} className="layout horizontal center" colSpan="1" style={{borderBottom: '0', padding: '5px'}}>
                <div style={{display: 'flex', height: "20px"}}>
                    <div title={statusToolTip} style={{marginRight: "10px"}}>{statusIcon}</div>
                    {showStatusText ?
                        <div title={tooltip} style={{color: 'rgba(0, 0, 0, 0.87)', fontSize: 'medium', fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif", paddingTop: '1px'}}>
                            {statusToolTip}
                        </div> : ""}
                </div>
            </TableCell> );
    }
    else {
        status = <TableCell />;
    }

    return status;
}


//
//{/*<div title={tooltip}> {statusName}</div>  // Removed for space reasons.*/}
//

//  QUESTIONNAIRE Active vs Inactive Diagram
//
//  Creation date             Publish date    !Start date                     !End date        Retire date
//  |                         |               !                               !               |                         |
//  |                         |               !                               !               |                         |
//  |                         |               !                               !               |                         |
//  |                         |               !                               !               |                         |
//  |                         |               !                               !               |                         |
//  |-------------------------|---------------!-------------------------------!---------------|-------------------------|
//  |        DRAFT            |                          PUBLISHED                            |         RETIRED         |                         |
//  |-------------------------|---------------!-------------------------------!---------------|-------------------------|
//                                            !                               !
//                    INACTIVE                !            ACTIVE             !                INACTIVE
//                                            !                               !



