import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Typography from "@mui/material/Typography";
import ArgoReviewTemplate from "../../common/ArgoReviewTemplate";
import {getQuestionnaireTemplate, getQuestionnaireGraph, clearQuestionnaireGraph} from "../../../actions/questionnaireTemplateActions";
import {expanderClear} from "../../../actions/expanderActions";
import CategoryViewerExpandable from "../categories/CategoryViewerExpandable";
import ArgoDividerWithLabelHook from "../../common/ArgoDividerWithLabelHook";
import ArgoPortalPreview from "../../common/ArgoPortalPreview";
import * as metadata from "../../../utilities/metadata";
import * as metadataUtils from "../../../utilities/metadata";
import statusFormatter from "../../../utilities/StatusFormatter";
import * as TEMPLATE from "../../../constants/templateConstants";
import * as portalShared from "../../../portal/shared/metadataConstantsAndUtilities";



class QuestionnaireReview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openCategoryReview: false,
            selectedCategoryInfoKey: "",
            openGroupReview: false,
            selectedGroupInfoKey: "",
            openQuestionReview: false,
            selectedQuestionInfoKey: "",
            categoriesLoaded: false,
            categoryTemplates: null,
            showCategoryBackButton: true
        };
    }

    componentDidMount() {

        if (this.props.templateKey && this.props.templateId) {
            this.props.getQuestionnaireTemplate(this.props.templateKey, this.props.templateId);
            this.props.getQuestionnaireGraph(this.props.templateId);
        }
    }

    componentWillUnmount() {
        this.props.clearQuestionnaireGraph();
        this.props.expanderClear("QuestionnaireReview");
    }

    handleToolbarButtonClick = (event, fieldName, buttonTitle) => {
        if (buttonTitle === 'BACK') this.setState({openCategoryReview: false, openGroupReview: false, openQuestionReview: false})
    }

    setStateCallback = (stateObj) => {
        this.setState(stateObj);
    }

    render() {
        let template = this.props.template;

        if (this.state.openCategoryReview) {
            return <ArgoReviewTemplate
                templateType={TEMPLATE.TYPE.CATEGORY}
                templateKey={this.state.selectedCategoryInfoKey}
                handleToolbarButtonClickFunction={this.handleToolbarButtonClick}
                width={this.props.width}
            />;
        }

        if (this.state.openGroupReview) {
            return <ArgoReviewTemplate
                templateType={TEMPLATE.TYPE.GROUP}
                templateKey={this.state.selectedGroupInfoKey}
                handleToolbarButtonClickFunction={this.handleToolbarButtonClick}
                width={this.props.width}
            />;
        }

        if (this.state.openQuestionReview) {
            return <ArgoReviewTemplate
                templateType={TEMPLATE.TYPE.QUESTION}
                templateKey={this.state.selectedQuestionInfoKey}
                handleToolbarButtonClickFunction={this.handleToolbarButtonClick}
                width={this.props.width}
            />;
        }

        let templateGraphClone = portalShared.cloneObj(this.props.questionnaireTemplateGraph);

        return (
            (template && templateGraphClone?.id) ?
                <div id="QuestionnaireReview-container-div" style={{flexGrow: "2", padding: "32px"}}>
                    <div style={{minHeight: "min-content"}}>

                        <Typography variant="h6" component="div">
                            Questionnaire Information:
                        </Typography>

                        <div>&nbsp;</div>

                        <div id="QuestionnaireReview-complex-question-type-container-div" style={{width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center", overflowWrap: "break-word"}}>
                            <div style={{color: "darkgray"}}>Status:</div>
                            <div>&nbsp;</div>
                            <div>{statusFormatter(this.props.template, 0, 0, true)}</div>
                        </div>

                        <div>&nbsp;</div>

                        <div id="QuestionnaireReview-complex-question-name-container-div" style={{width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center", overflowWrap: "break-word"}}>
                            <div style={{color: "darkgray"}}>Questionnaire:</div>
                            <div>&nbsp;</div>
                            <div>{template.name}</div>
                        </div>

                        <div>&nbsp;</div>

                        <div id="QuestionnaireReview-complex-question-qualifier-container-div" style={{width: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center", overflowWrap: "break-word"}}>
                            <div style={{color: "darkgray"}}>Qualifier:</div>
                            <div>&nbsp;</div>
                            <div>{template.nameQualifier}</div>
                        </div>

                        <div>&nbsp;</div>

                        <div style={{display: 'flex'}}>
                            <div style={{color: "darkgray"}}>Key Metric Category:</div>
                            <div>&nbsp;</div>
                            <div style={{paddingBottom: "12px"}}>{!template.metadata.hasOwnProperty(portalShared.KEY_METRIC_CATEGORY_QUESTIONNAIRE) ? 'None' : template.metadata[portalShared.KEY_METRIC_CATEGORY_QUESTIONNAIRE]}</div>
                        </div>

                        <div>&nbsp;</div>

                        <div>&nbsp;</div>

                        <div>
                            {metadataUtils.listMetadata(this.props.template, "Questionnaire")}
                        </div>
                    </div>

                    {/*// ===========================================================================================================*/}
                    {/*// ASSIGNED CATEGORIES LIST */}
                    {/*// ===========================================================================================================*/}

                    <CategoryViewerExpandable
                        parentName="QuestionnaireReview"
                        questionnaireTemplate={template}
                        categoryKeys={template.categoryKeys}
                        showIconButtonReorder={false}
                        showIconButtonEdit={false}
                        showIconButtonRemove={false}
                        hidePortalPreview={true}
                        setStateCallback={this.setStateCallback}
                    />

                    <div id="QuestionnaireReview-space-1" style={{height: "20px"}}>&nbsp;</div>

                    {/*// ===========================================================================================================*/}
                    {/*// PORTAL PREVIEW DIVIDER */}
                    {/*// ===========================================================================================================*/}

                    <ArgoDividerWithLabelHook
                        fileName="QuestionnaireReview"
                        label="Portal Preview"
                    />

                    {/*// ===========================================================================================================*/}
                    {/*// QUESTIONNAIRE PORTAL PREVIEW */}
                    {/*// ===========================================================================================================*/}

                    <div id="QuestionnaireReview-space-2" style={{height: "10px"}}>&nbsp;</div>

                    <div id="QuestionnaireReview-div">
                        <ArgoPortalPreview
                            portalPreview={true}
                            templateType={TEMPLATE.TYPE.QUESTIONNAIRE_GRAPH}
                            template={templateGraphClone}
                            portalCount={this.props.portalCount}
                        />
                    </div>

                </div>
                : ""
        );
    }
}

QuestionnaireReview.defaultProps = {
    templateKey: null,
    templateId: null,
    width: "100%"
};

QuestionnaireReview.propTypes = {
    templateKey: PropTypes.string,
    templateId: PropTypes.number,
    width: PropTypes.string
};

function mapStateToProps(state, props) {
    return {
        template: state.questionnaireTemplate.hasOwnProperty(props.templateKey) ? state.questionnaireTemplate[props.templateKey] : null,
        questionnaireTemplateGraph: state.questionnaireTemplateGraph,
        portalCount: state.portal.portalCount
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({getQuestionnaireTemplate, getQuestionnaireGraph, clearQuestionnaireGraph, expanderClear}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionnaireReview);
