import * as types from "../constants/actionConstants";
import * as portalTypes from "../portal/constants/actionConstants";


export function addAnswer (isGroupMode, answer, depth = 0) {

    let answerOption = {
        templateKey: "",
        optionText: "",
        importActionKeys: [],
        importCategoryKeys: [],
        importGroupKeys: [],
        metadata: {},
        ...answer
    };

    return (dispatch) => {
        dispatch({type: isGroupMode ? types.ANSWER_EDITOR_ADD_GROUP_ANSWER : types.ANSWER_EDITOR_ADD_QUESTION_ANSWER,
            answer: answerOption,
            depth: depth
        });
        dispatch({type: portalTypes.INCREMENT_PORTAL_COUNT})
    };
}

export function updateAnswer (isGroupMode, answer, index, depth = 0) {
    return (dispatch) => {
        dispatch({type: isGroupMode ? types.ANSWER_EDITOR_UPDATE_GROUP_ANSWER : types.ANSWER_EDITOR_UPDATE_QUESTION_ANSWER,
            answer: answer,
            index: index,
            depth: depth
        });
        dispatch({type: portalTypes.INCREMENT_PORTAL_COUNT});
    };
}

export function removeAnswer (isGroupMode, index, depth = 0) {
    return (dispatch) => {
        dispatch({type: isGroupMode ? types.ANSWER_EDITOR_REMOVE_GROUP_ANSWER : types.ANSWER_EDITOR_REMOVE_QUESTION_ANSWER,
            index: index,
            depth: depth
        });
        dispatch({type: portalTypes.INCREMENT_PORTAL_COUNT});
    };
};

export function reorderAnswer (isGroupMode, isUp, currentIndex, depth = 0) {
    return (dispatch) => {
        dispatch({type: isGroupMode ? types.ANSWER_EDITOR_REORDER_GROUP_ANSWER : types.ANSWER_EDITOR_REORDER_QUESTION_ANSWER,
            isUp: isUp,
            currentIndex: currentIndex,
            depth: depth
        });
        dispatch({type: portalTypes.INCREMENT_PORTAL_COUNT});
    };
};

export function setShowGroup (newShowGroupAdded, answerIndex) {
    return {
        type: types.ANSWER_SHOW_GROUP_SUCCESS,
        newShowGroupAdded,
        answerIndex,
    };
};

export function clearShowGroup (newShowGroupAdded, answerIndex) {
    return {
        type: types.ANSWER_SHOW_GROUP_CLEAR,
        newShowGroupAdded,
        answerIndex,
    };
}
