export const YES_NO = "YES_NO";
export const CHECKBOX = "CHECKBOX";
export const RADIO = "RADIO";
export const LIKERT_RADIO = "LIKERT_RADIO";
export const NUMBER = "NUMBER";
export const CURRENCY = "CURRENCY";
export const PHONE = "PHONE";
export const DATE = "DATE";
export const DATE_RANGE = "DATE_RANGE";
export const EMAIL = "EMAIL";
export const FREE_TEXT = "FREE_TEXT";
export const ADDRESS_FULL = "ADDRESS_FULL";
export const ZIPPOSTAL_ONLY = "ZIPPOSTAL_ONLY";
export const STATE_ONLY = "STATE_ONLY";


export const getLabel = {
    [YES_NO]: "Yes/No",
    [CHECKBOX]: "Checkbox",
    [RADIO]: "Single Select",
    [LIKERT_RADIO]: "Likert",
    [NUMBER]: "Number",
    [CURRENCY]: "Currency",
    [PHONE]: "Phone",
    [DATE]: "Date",
    [DATE_RANGE]: "Date Range",
    [EMAIL]: "Email",
    [FREE_TEXT]: "Free Text",
    [ADDRESS_FULL]: "Address",
    [ZIPPOSTAL_ONLY]: "ZIP",
    [STATE_ONLY]: "State",
};


export const supported = {
    FREE_TEXT,
    YES_NO,
    RADIO,
    CHECKBOX,
    LIKERT_RADIO,
    DATE,
    DATE_RANGE,
    EMAIL,
    PHONE,
    CURRENCY,
    NUMBER,
    ADDRESS_FULL,
    ZIPPOSTAL_ONLY,
    STATE_ONLY,
};


export const questionTypesList = [
    {value: "FREE_TEXT", text: "Free Text"},
    {value: "YES_NO", text: "Yes/No"},
    {value: "RADIO", text: "Single Select"},
    {value: "CHECKBOX", text: "Checkbox"},
    {value: "LIKERT_RADIO", text: "Likert"},
    {value: "DATE", text: "Date"},
    {value: "DATE_RANGE", text: "Date Range"},
    {value: "EMAIL", text: "Email"},
    {value: "PHONE", text: "Phone"},
    {value: "CURRENCY", text: "Currency"},
    {value: "NUMBER", text: "Number"},
    {value: "ADDRESS_FULL", text: "Address"},
    {value: "ZIPPOSTAL_ONLY", text: "ZIP"},
    {value: "STATE_ONLY", text: "State"}
];
