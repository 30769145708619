
export const CUSTOMER_JOURNEY_TYPE = "CUSTOMER_JOURNEY"; //Pre fred still sending CUSTOMER JOURNEY to backend since it's hard to change
export const CUSTOMER_JOURNEY_NAME= "API Key";
export const OAUTH2_TYPE = "OAUTH2";
export const OAUTH2_NAME = "OAuth2"

export const SALES_AND_SERVICE_TYPE = "SALES_AND_SERVICE";
export const SALES_AND_SERVICE_NAME = "Sales & Service";


export const getIntegrationTypeName = {
    [CUSTOMER_JOURNEY_TYPE]: [CUSTOMER_JOURNEY_NAME],
    [OAUTH2_TYPE]: [OAUTH2_TYPE]
    // [SALES_AND_SERVICE_TYPE]: [SALES_AND_SERVICE_NAME]
};

export const IntegrationTypes = [
    {value: CUSTOMER_JOURNEY_TYPE, text: CUSTOMER_JOURNEY_NAME},
    {value: OAUTH2_TYPE, text: OAUTH2_NAME}
    // {value: SALES_AND_SERVICE_TYPE, text: SALES_AND_SERVICE_NAME}
];

export const IntegrationTypesFilter = [
    {type: "ALL", name: "All"},
    {type: CUSTOMER_JOURNEY_TYPE, name: CUSTOMER_JOURNEY_NAME},
    {value: OAUTH2_TYPE, text: OAUTH2_NAME}
    // {type: SALES_AND_SERVICE_TYPE, name: SALES_AND_SERVICE_NAME}
];

export const getIntegrationLogStatus = ["Failed", "Success"];


