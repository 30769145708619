import React, {useEffect, useState} from 'react';
import TextField from "@mui/material/TextField";
import MenuItem from '@mui/material/MenuItem';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';

// This fixes the DDLB transparency issue
const theme = createTheme({
    backgroundColor: "white"
});


export default function ArgoTextFieldSelectHook(props) {
    const [error, setError] = useState(null);

    const fileName = props.fileName ?? "NoFileName";
    const fieldName = props.fieldName ?? "NoFieldName";

    const index = props.index ?? ""; // If field is built in a loop index is required to know which field to match up with

    const uniqueIdentifier = fileName + "-ArgoTextFieldSelectHook-" + fieldName + "-" + index;

    const variant = props.variant ?? "standard";
    const label = props.label ?? "";
    const value = props.value ?? ""; // Value is the index of the list select menuitem
    const width = props.width ?? "";
    const paddingBottom = props.paddingBottom ?? "0px";
    const paddingTop = props.paddingTop ?? "0px";

    const fullWidth = props.fullWidth === undefined ? (width === "") : false;
    //const autoFocus = props.autoFocus === undefined ? false : false;
    const onChange = props.onChange ?? function () {};
    const disabled = props.disabled ?? false;
    //const display = props.display === undefined ? "inline" : "none";
    const addAll = props.addAll !== undefined;

    const errorText = props.errorText ?? " ";

    const menuItems = props.menuItems === undefined ? [] : (addAll ? props.menuItems.unshift("All") : props.menuItems);

    const menuItemsIsStrings = menuItems.length > 0 ? (typeof menuItems[0] === "string") : false; // if not array of strings, must be objects {value: "", text: ""}
    const useTextForValue = props.useTextForValue !== undefined;
    const valueProperty = props.valueProperty ?? "value";
    const textProperty = props.textProperty ?? "text";

    //const multiple = props.multiple === undefined ? false : props.multiple;

    const handleChange = (event) => {

        let menuItemText = "";
        if (menuItemsIsStrings) {
            if (useTextForValue) {
                menuItemText = event.target.value;
            }
            else {
                menuItemText = menuItems[event.target.value]; // NOTE: event.target.value on a select is the menuItem value
            }
        }
        else {
            if (menuItems[0].hasOwnProperty("value")) {
                menuItemText = menuItems.find(x => x[valueProperty]  === event.target.value)[textProperty];
            }
            else {
                menuItemText = menuItems.find(x => x[valueProperty] === event.target.value[valueProperty])[textProperty];
            }
        }

        onChange(event, fieldName, menuItemText, index);
    };

    useEffect(() => {
        setError(errorText !== " ");
    }, [value, errorText, error])

    return (menuItems.length > 0 ?
            <div style={{width: fullWidth ? "100%" : width}}>
                <ThemeProvider theme={theme}>
                    <TextField
                        id={uniqueIdentifier}
                        name={uniqueIdentifier}
                        select
                        variant={variant}
                        label={label}
                        value={value}
                        disabled={disabled}
                        onChange={handleChange}
                        fullWidth={fullWidth}
                        error={error}
                        helperText={errorText}
                        sx={{width: fullWidth ? "100%" : width, paddingBottom: paddingBottom, paddingTop: paddingTop}}
                    >
                        {menuItems.map((item, index) => {
                            return (<MenuItem key={uniqueIdentifier + index} value={(menuItemsIsStrings && !useTextForValue) ? index : (useTextForValue ? item : item[valueProperty])}>{menuItemsIsStrings ? item : item[textProperty]}</MenuItem>);
                        })}
                    </TextField>
                </ThemeProvider>
            </div>
            :
            <div></div>
    );
}


// Loop thru objects with map
// {Object.keys(this.props.types).map(type =>
//     <MenuItem key={type} value={type} primaryText={self.props.types[type]}/>
// )}
