import React from "react";
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from "@mui/material/OutlinedInput";
import {createTheme} from '@mui/material/styles';
import {ThemeProvider} from '@mui/material/styles';
import {getLikertTitleObj}  from "../utilities/getLikertTitles";
import * as mode from "../utilities/displayOneQuestionPerPage";
import * as ssUtil from '../utilities/singleSelectUtilities';
import * as style  from "../utilities/getStyle";
import * as responsiveLabel  from "../utilities/responsiveLabelUtilities";
import * as font from "../utilities/fontUtilities";
import {RESPONSIVE_LABEL_DEFAULT} from "../utilities/responsiveLabelUtilities";
import * as icon from "../constants/iconConstants";
import * as tag from "../constants/customMetadataConstants";
import * as portalShared from "../shared/metadataConstantsAndUtilities"
import * as ddo from "../constants/customObjConstants";
import * as ssType from "../constants/singleSelectTypes";
import * as TEMPLATE from "../../constants/templateConstants";
import {metadataValueObj} from "../constants/customMetadataConstants";



// Single Select Questions come in many types, this save services all of them...
export function saveAnswerTypeSingleSelect(callerRef, savedObjs, event, answerId = "") {

    if (!savedObjs.readonlyMode) {

        let tempAnswerId = answerId.props === undefined ? answerId : answerId.props.value;  // DDLB pass the ID differently

        let answerIdInt = tempAnswerId !== null ? parseInt(tempAnswerId) : event.target.value; // Radio buttons pass the answer id (as a string) after the event.

        let answerObj = savedObjs.answers.find(answer => {
            return answer.id === answerIdInt;
        });

        // Instant MODE or Template Mode?
        if (savedObjs.displayMode === portalShared.INSTANT_MODE) {
            savedObjs.answerQuestionOptions(savedObjs.question.id, answerObj.key, {selected: !answerObj.selected});
        } else {
            // Must be in some form template mode ie.. questionnaire, category, group or question
            let tempAnswerObj = {...answerObj};
            tempAnswerObj.selected = !tempAnswerObj.selected;  // Toggle selected flag
            if (savedObjs !== null) {
                savedObjs.selectedAnswer = tempAnswerObj;
            }
            savedObjs.answerQuestionTemplateOptions(savedObjs.question.id, answerObj.key, tempAnswerObj);
        }
    }

}


// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------
// Single Select (QUESTION) !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! <editor-fold>
// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------


export function singleSelectQuestion(singleSelectType, tempProps, itemObj, isAddress, likertInputType = "", likertIndex = 0) {

    const self = this;
    const {questionnaire, group, question, questionNumber, surveyEditMode, displayMode, answerQuestionOptions, answerQuestionTemplateOptions} = tempProps;

    let activeTemplate = (group !== undefined) ? group : question;
    let activeTemplateType = (group !== undefined) ? "group" : "question";

    let textObj = null;
    if (activeTemplateType === "question") {
        textObj = ddo.getQuestionNameText(question, questionNumber);
    }

    let answers = ddo.getAnswerOptions(question);
    let selectedAnswer = answers.find((answerOption) => {
        return answerOption.selected === true;
    });

    if (!selectedAnswer) {
        selectedAnswer = {
            id: ""
        };
    }

    let isGroupLikert = ((group !== undefined) && (question !== undefined) && ((group.groupType === "LIKERT")||(group.questionGroupType === "LIKERT")));


    let isFirstQuestionOfGroupLikert = false;
    if (isGroupLikert) {
        isFirstQuestionOfGroupLikert = (group.questionTemplates === undefined) ? (group.questionIds[0] === question.id) : (group.questionTemplates[0].templateKey === question.templateKey);
    }
    if (tag.metadataExists(question, portalShared.DISPLAY_SINGLE_SELECT_AS_LIKERT)) {
        isFirstQuestionOfGroupLikert = true;
    }


    let savedObjs = {
        customTemplateObj: null,
        props:  tempProps,
        qnr: tempProps.questionnaire,
        showGroupKey: tempProps.showGroupKey,
        itemObj: itemObj,
        singleSelectType: singleSelectType,
        likertInputType: likertInputType,
        inputModifier: "",
        likertIndex: likertIndex,
        activeTemplate: activeTemplate,
        activeTemplateType: activeTemplateType,
        questionnaire: questionnaire,
        group: group,
        isGroupLikert: isGroupLikert,
        isFirstQuestionOfGroupLikert:  isFirstQuestionOfGroupLikert,
        question: question,
        questionNameHidden: (tag.metadataValue(question, portalShared.CUSTOM_DDO_NAME) === "hide"),
        questionNumber: questionNumber === "0" ? "" : questionNumber,
        questionTextObj: textObj,
        questionTextRendered: false,
        answers: answers,
        displayMode: displayMode,
        surveyEditMode: surveyEditMode,
        readonlyMode: (surveyEditMode === portalShared.READONLY_SURVEY),
        selectedAnswer: selectedAnswer,
        rightAlignLikertAnswers: false,
        alignQuestionsAndAnswers: false,
        indent: tag.metadataValue(activeTemplate, portalShared.INDENT_NAME, "", "0px"),
        answerQuestionOptions: answerQuestionOptions,
        answerQuestionTemplateOptions: answerQuestionTemplateOptions,
        onClickFunction: saveAnswerTypeSingleSelect
    }

    if (tag.metadataExists(question, portalShared.CUSTOM_DDO_QUE_FORMAT)) {
        savedObjs.customTemplateObj = tag.metadataValueObj(question, portalShared.CUSTOM_DDO_QUE_FORMAT);
    }

    let htmlObj = "";

    switch(singleSelectType) {
        case ssType.SINGLE_SELECT_RADIO:
            htmlObj = singleSelectRadios(savedObjs, ssType.SINGLE_SELECT_RADIO);
            break;
        case ssType.SINGLE_SELECT_OUTLINED_RADIO:
            htmlObj = singleSelectRadios(savedObjs, ssType.SINGLE_SELECT_OUTLINED_RADIO);
            break;
        case ssType.SINGLE_SELECT_STANDARD_BUTTON:
            htmlObj = singleSelectButtons(savedObjs, ssType.SINGLE_SELECT_STANDARD_BUTTON);
            break;
        case ssType.SINGLE_SELECT_CLEAR_BUTTON:
            htmlObj = singleSelectButtons(savedObjs, ssType.SINGLE_SELECT_CLEAR_BUTTON);
            break;
        case ssType.SINGLE_SELECT_TEXT_BUTTON:
            htmlObj = singleSelectButtons(savedObjs, ssType.SINGLE_SELECT_TEXT_BUTTON);
            break;
        case ssType.SINGLE_SELECT_DDLB:
        case ssType.SINGLE_SELECT_DDLB_STATES:
            htmlObj = singleSelectDropDown(savedObjs);
            break;
        // case ssType.SINGLE_SELECT_DDLB_STATES:
        //     htmlObj = singleSelectDropDownStates(savedObjs, isAddress);
        //     break;
        case ssType.SINGLE_SELECT_LIKERT:
// ZZZZZ
            if (likertInputType === icon.TYPE_CIRCLE) {
                //savedObjs.inputModifier = (tag.metadataExists(savedObjs.activeTemplate, portalShared.DISPLAY_BUTTONS_AS_CIRCLES_COLORS)) ? icon.TYPE_CIRCLE_MODIFIER_COLORS : "";
                savedObjs.inputModifier = "";
            }

            htmlObj = singleSelectLikert(self, savedObjs);  // Must be LIKERT
            break;

        // no default
    }

    return htmlObj;
}


// ---------------------------------------------------------------------------------------------------------------------
// Single Select (Radio Group) !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! <editor-fold>
// ---------------------------------------------------------------------------------------------------------------------


function singleSelectRadios(savedObjs, radioType = ssType.SINGLE_SELECT_RADIO) {
    const self = this;

    let textObj = font.getDefaultOrCustomTextObj(savedObjs.question, ddo.OBJ_TYPE.ANSWER, ddo.OBJ_TEXT_TYPE.NAME);

    let outlinedRadio = (radioType === ssType.SINGLE_SELECT_OUTLINED_RADIO);

    let CustomTopographyStyle = style.getCustomTextStyle(textObj.fontObj);

    let arrangeAnswers = ssUtil.singleSelectAnswerArangement(savedObjs);

    let uniqueKey = "radiogroup-" + savedObjs.question.id;

    // Check for possible text color dependence on selected or not.
    let textColorObj = {
        unselectedColor: style.setTextColor(savedObjs.question, portalShared.UNSELECTED_COLOR_TEXT, textObj.fontObj),
        selectedColor: style.setTextColor(savedObjs.question, portalShared.SELECTED_COLOR_TEXT, textObj.fontObj)
    }

    // For outlined radios change the default selected text color
    textObj.fontObj.color = outlinedRadio ? portalShared.DEFAULT_SELECTED_OUTLINED_RADIO_TEXT_COLOR : textObj.fontObj.color;
    textColorObj.selectedColor = style.setTextColor(savedObjs.question, portalShared.SELECTED_COLOR_TEXT, textObj.fontObj);

    let CustomStyle = style.getRadioStyle(savedObjs, !outlinedRadio);  // Returns default style unless metadata overrides

    let answersAlignment = tag.metadataExists(savedObjs.question, portalShared.CENTER_ANSWERS) ? "center" : "left";

    let arrangeRowByRow = arrangeAnswers === ssUtil.SINGLE_SELECT_ARRANGE_ANSWERS_BY_ROW;

    let divStyle = null;
    // If screen size is small or override to one per row, otherwise default is side-by-side
    if ((mode.displayOneQuestionPerPage()) || (arrangeRowByRow)) {
        divStyle = {width: "100%"};
    }
    else {
        // By default, no width is set for standard radios
        let widthOverride = tag.metadataValue(savedObjs.question, portalShared.OVERRIDE_WIDTH, "", "")
        if (widthOverride === "") {
            divStyle = {float: "left",marginRight: "10px"};
        }
        else {
            divStyle = {float: "left", marginRight: "10px", width: widthOverride};
        }
    }

    let labelPlacement = "end"; // TODO implement "bottom","end","start","top"

    return <div style={{paddingLeft: "20px"}}>
        <RadioGroup id={uniqueKey}
                    name={uniqueKey}
                    label={savedObjs.questionTextObj.text}
                    value={savedObjs.selectedAnswer.id !== undefined ? savedObjs.selectedAnswer.id.toString() : null}
                    onChange={saveAnswerTypeSingleSelect.bind(self, "singleSelectRadio", savedObjs)}
                    disableripple={savedObjs.readonlyMode.toString()}
                    disablefocusripple={savedObjs.readonlyMode.toString()}>
            <div style={arrangeRowByRow ? {} : {display: "flex", flexWrap: "wrap", justifyContent: answersAlignment}}>
                {savedObjs.answers.map((answer) => {
                    return (<div key={"Answer-"  + answer.id.toString()} style={{...divStyle}} className="flex-item">
                        <FormControlLabel value={answer.id.toString()}
                                          control={<CustomStyle/>}
                                          label={<div style={{display: "flex", alignItems: "center"}}>{<CustomTopographyStyle style={{color: style.getColor(answer, savedObjs.selectedAnswer, textColorObj)}}>{answer.optionText}</CustomTopographyStyle>}</div>}
                                          key={answer.id}
                                          labelPlacement={labelPlacement}
                                          style={{...style.getOutlinedRadioStyle(outlinedRadio, answer, savedObjs)}}
                        />
                    </div>);
                })}
            </div>
        </RadioGroup>
    </div>;

}


// ---------------------------------------------------------------------------------------------------------------------
// singleSelectButtons !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! <editor-fold>
// ---------------------------------------------------------------------------------------------------------------------


function singleSelectButtons(savedObjs, buttonType = ssType.SINGLE_SELECT_OUTLINED_RADIO) {
    const self = this;

    let textObj = font.getDefaultOrCustomTextObj(savedObjs.question, ddo.OBJ_TYPE.BUTTON, ddo.OBJ_TEXT_TYPE.NAME);

    // -----------------------------------------------------------------------------------------------------------------

    let arrangeAnswers = ssUtil.singleSelectAnswerArangement(savedObjs);

    let uniqueKey = "button-" + savedObjs.question.id + "-answer-";

    let answersAlignment = tag.metadataExists(savedObjs.question, portalShared.CENTER_ANSWERS) ? "center" : "left";

    let arrangeRowByRow = arrangeAnswers === ssUtil.SINGLE_SELECT_ARRANGE_ANSWERS_BY_ROW;

    let divStyle = null;
    let divSpacerStyle = null;
    // If screen size is small or override to one per row, otherwise default is side-by-side
    if ((mode.displayOneQuestionPerPage()) || (arrangeRowByRow)) {
        if (answersAlignment === "center") {
            divStyle = {display: "flex", flexDirection: "row", justifyContent: answersAlignment, marginBottom: "10px"};
            divSpacerStyle = {display: "flex", flexDirection: "row", justifyContent: answersAlignment};
        }
        else {
            divStyle = {width: "100%"};
            divSpacerStyle = {width: "100%", height: "10px"};
        }
    }
    else {
        let separatorWidth = tag.metadataValue(savedObjs.question, portalShared.DISPLAY_SINGLE_SELECT_SIDE_BY_SIDE_SEPARATOR, "", "10px", true);
        divStyle = {float: "left"};
        divSpacerStyle = {float: "left", width: separatorWidth};
    }

    let StyledButton = null;

    //let test = tag.metadataValue(savedObjs.question, portalShared.CUSTOM_DDO_QUE_FORMAT);

    let outlinedButton = (buttonType === ssType.SINGLE_SELECT_CLEAR_BUTTON);

    // Check button overrides at the question level not in the answers loop
    let buttonOverrides = {

        textObj: textObj.fontObj,

        height: tag.metadataValue(savedObjs.question, portalShared.BUTTON_HEIGHT, "", portalShared.DEFAULT_BUTTON_HEIGHT),

        widthOverride: tag.metadataValue(savedObjs.question, portalShared.OVERRIDE_WIDTH, "", "250px"),

        customTemplateObj: tag.metadataValue(savedObjs.question, portalShared.CUSTOM_DDO_QUE_FORMAT),

        unselectedTextColor: tag.metadataValue(savedObjs.question, portalShared.UNSELECTED_COLOR_TEXT, "", outlinedButton ? portalShared.DEFAULT_UNSELECTED_BUTTON_TEXT_COLOR : portalShared.DEFAULT_WHITE),
        unselectedBorderColor: tag.metadataValue(savedObjs.question, portalShared.UNSELECTED_COLOR_BORDER, "", outlinedButton ? portalShared.DEFAULT_UNSELECTED_BUTTON_BORDER_COLOR : portalShared.DEFAULT_UNSELECTED_BUTTON_BORDER_COLOR),
        unselectedBackgroundColor: tag.metadataValue(savedObjs.question, portalShared.UNSELECTED_COLOR_BACKGROUND, "", outlinedButton ? portalShared.DEFAULT_WHITE : portalShared.DEFAULT_UNSELECTED_BUTTON_BACKGROUND_COLOR),

        selectedTextColor: tag.metadataValue(savedObjs.question, portalShared.SELECTED_COLOR_TEXT, "", portalShared.DEFAULT_SELECTED_BUTTON_TEXT_COLOR),
        selectedBorderColor: tag.metadataValue(savedObjs.question, portalShared.SELECTED_COLOR_BORDER, "", portalShared.DEFAULT_SELECTED_BUTTON_BORDER_COLOR),
        selectedBackgroundColor: tag.metadataValue(savedObjs.question, portalShared.SELECTED_COLOR_BACKGROUND, "", portalShared.DEFAULT_SELECTED_BUTTON_BACKGROUND_COLOR),

        hoverTextColor: tag.metadataValue(savedObjs.question, portalShared.HOVER_COLOR_TEXT, "", outlinedButton ? portalShared.DEFAULT_HOVER_BUTTON_TEXT_COLOR : portalShared.DEFAULT_HOVER_BUTTON_TEXT_COLOR),
        hoverBorderColor: tag.metadataValue(savedObjs.question, portalShared.HOVER_COLOR_BORDER, "", outlinedButton ? portalShared.DEFAULT_HOVER_BUTTON_BORDER_COLOR: portalShared.DEFAULT_HOVER_BUTTON_BORDER_COLOR),
        hoverBackgroundColor: tag.metadataValue(savedObjs.question, portalShared.HOVER_COLOR_BACKGROUND, "", outlinedButton ? portalShared.DEFAULT_HOVER_BUTTON_BACKGROUND_COLOR: portalShared.DEFAULT_WHITE),

        hoverSelectedTextColor: tag.metadataValue(savedObjs.question, portalShared.HOVER_SELECTED_COLOR_TEXT, "", outlinedButton ? portalShared.DEFAULT_HOVER_SELECTED_BUTTON_TEXT_COLOR : portalShared.DEFAULT_HOVER_SELECTED_BUTTON_TEXT_COLOR),
        hoverSelectedBorderColor: tag.metadataValue(savedObjs.question, portalShared.HOVER_SELECTED_COLOR_BORDER, "", outlinedButton ? portalShared.DEFAULT_HOVER_SELECTED_BUTTON_BORDER_COLOR: portalShared.DEFAULT_HOVER_SELECTED_BUTTON_BORDER_COLOR),
        hoverSelectedBackgroundColor: tag.metadataValue(savedObjs.question, portalShared.HOVER_SELECTED_COLOR_BACKGROUND, "", outlinedButton ? portalShared.DEFAULT_HOVER_SELECTED_BUTTON_BACKGROUND_COLOR: portalShared.DEFAULT_HOVER_SELECTED_BUTTON_BACKGROUND_COLOR),

        borderWidth: parseInt(tag.metadataValue(savedObjs.question, portalShared.BUTTON_BORDER_WIDTH, "", portalShared.DEFAULT_BUTTON_BORDER_WIDTH)),
        borderRadius: parseInt(tag.metadataValue(savedObjs.question, portalShared.BUTTON_BORDER_RADIUS, "", portalShared.DEFAULT_BUTTON_BORDER_RADIUS)),
        boxShadowOverride: tag.metadataValue(savedObjs.question, portalShared.BUTTON_BOX_SHADOW, "", portalShared.DEFAULT_BUTTON_BOX_SHADOW)
    }

    return  <div style={arrangeRowByRow ? {width: "100%"} : {display: "flex", flexWrap: "wrap", justifyContent: answersAlignment}}>
        {savedObjs.answers.map((answer) => {

            StyledButton = style.getButtonStyle(buttonType, savedObjs, buttonOverrides, (answer.id === savedObjs.selectedAnswer.id));

            return <div key={"StyledButton-" + uniqueKey + answer.id} style={{...divStyle}} className="flex-item">
                <StyledButton
                    id={uniqueKey + answer.id}
                    name={uniqueKey + answer.id}
                    onClick={saveAnswerTypeSingleSelect.bind(self, "singleSelectButton", savedObjs, null, answer.id)}
                    variant = "outlined"
                >
                    {answer.optionText}
                </StyledButton>
                <div style={{...divSpacerStyle}}></div>
            </div>
        })}
    </div>

}


// ---------------------------------------------------------------------------------------------------------------------
//singleSelectDropDown !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! <editor-fold>
// ---------------------------------------------------------------------------------------------------------------------

export function singleSelectDropDown(savedObjs) {
    const self = this;

    let question = savedObjs.question;

    let textObj = font.getDefaultOrCustomTextObj(savedObjs.question, ddo.OBJ_TYPE.DDLB, ddo.OBJ_TEXT_TYPE.NAME);

    let tempQuestionNumber = savedObjs.questionNumber === "0" ? "" : savedObjs.questionNumber;
    // if (tag.metadataExists(question, portalShared.DISPLAY_DEF_SELECT_NO_QUESTION_NUMBER)) {
    //     tempQuestionNumber = "";
    // }
    // let ddlbObj = {
    //     labelText: tag.metadataValue(question, portalShared.DISPLAY_DEF_SELECT_LABEL_TEXT, "", ((tempQuestionNumber === "") ? question.name : (tempQuestionNumber + " " + question.name))),
    //     labelTextDDLB: tag.metadataValue(question, portalShared.DISPLAY_DEF_SELECT_DDLB_LABEL, "", ""),
    //
    //     floatingtextColor: tag.metadataValue(question, portalShared.DISPLAY_DEF_SELECT_FLOATED_TEXT_COLOR, "", portalShared.DEFAULT_UNSELECTED_COLOR),
    //     textSelectedColorOverride: tag.metadataExists(question, portalShared.DISPLAY_DEF_SELECT_TEXT_COLOR),
    //     textSelectedColor: tag.metadataValue(question, portalShared.DISPLAY_DEF_SELECT_TEXT_COLOR, "", portalShared.DEFAULT_SELECTED_COLOR),
    //
    //     borderColor: tag.metadataValue(question, portalShared.DISPLAY_DEF_SELECT_BASE_BORDER, "", portalShared.DEFAULT_UNSELECTED_COLOR),
    //     borderColorHover: tag.metadataValue(question, portalShared.DISPLAY_DEF_SELECT_HOVER_BORDER, "", portalShared.DEFAULT_SELECTED_COLOR),
    //     borderColorFocused: tag.metadataValue(question, portalShared.DISPLAY_DEF_SELECT_FOCUS_BORDER, "", portalShared.DEFAULT_SELECTED_COLOR),
    //     borderColorAnswered: tag.metadataValue(question, portalShared.DISPLAY_DEF_SELECT_ANSWERED_BORDER, "", portalShared.DEFAULT_SELECTED_COLOR),
    //
    //     // width: tag.metadataValue(question, portalShared.DISPLAY_DEF_SELECT_WIDTH, "", "100%"),
    //
    //     // noFloat: tag.metadataExists(question, portalShared.DISPLAY_DEF_SELECT_NO_FLOAT),
    // }


    let ddlbObj = {
        labelText: savedObjs?.customTemplateObj?.floatingLabel?.text ?? "", // LEGACY FIX Clarifying Intent Short
        // labelTextDDLB: tag.metadataValue(question, portalShared.DISPLAY_DEF_SELECT_DDLB_LABEL, "", ""),

        floatingTextColor: savedObjs?.customTemplateObj?.floatingLabel?.colorType !== "default" ?
            savedObjs?.customTemplateObj?.floatingLabel?.color : undefined,
        textSelectedColorOverride: savedObjs?.customTemplateObj?.selectedText?.color !== "#000000",
        textSelectedColor: savedObjs?.customTemplateObj?.selectedText?.colorType !== "default" ?
            savedObjs?.customTemplateObj?.selectedText?.color : undefined,

        borderColor: savedObjs?.customTemplateObj?.unselectedBorder?.colorType !== "default" ?
            savedObjs?.customTemplateObj?.unselectedBorder?.color : undefined,
        borderColorHover: savedObjs?.customTemplateObj?.hoverBorder?.colorType !== "default" ?
            savedObjs?.customTemplateObj?.hoverBorder?.color : undefined,
        borderColorFocused: savedObjs?.customTemplateObj?.selectedBorder?.colorType !== "default" ?
            savedObjs?.customTemplateObj?.selectedBorder?.color : undefined,
        borderColorAnswered: savedObjs?.customTemplateObj?.ddlbBorderColorAfterSelection?.colorType !== "default" ?
            savedObjs?.customTemplateObj?.ddlbBorderColorAfterSelection?.color : undefined,

        width: savedObjs?.customTemplateObj?.width

    }

    // -----------------------------------------------------------------------------------------------------------------

    const customTheme = createTheme({
        components: {
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        // borderRadius: newRadius, // Change border radius for outlined inputs
                        " .MuiOutlinedInput-notchedOutline": {
                            borderColor: savedObjs?.selectedAnswer?.id === "" || savedObjs?.selectedAnswer?.id === undefined ? ddlbObj.borderColor : ddlbObj.borderColorAnswered,
                            //width: "400px", // width of select
                        },
                        "&:hover": {
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: ddlbObj.borderColorHover,
                                borderWidth: '1px',
                            }
                        },
                        "&.Mui-focused": {
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: ddlbObj.borderColorFocused,
                                borderWidth: '2px',
                            }
                        }
                    }
                }
            }
        }
    });

    let selectedId = "";
    for (let i = 0; i < savedObjs.answers.length; i++) {
        if (savedObjs.answers[i].selected) {
            selectedId = savedObjs.answers[i].id.toString();
        }
    }

    // ddlbObj.labelText = ddlbObj.labelTextDDLB !== "" ? ddlbObj.labelTextDDLB : ddlbObj.labelText === "address" ? "state" : ddlbObj.labelText;
    return (
        <Grid container>
            <Grid item xs={12}>
                <FormControl variant="outlined" style={{width: '100%'}} size="small">
                    <InputLabel style={{backgroundColor: "white", color: ddlbObj.floatingTextColor}}>{ddlbObj.labelText === "" ? "" : portalShared.NON_BREAKING_SPACE + ddlbObj.labelText + portalShared.NON_BREAKING_SPACE2}</InputLabel>
                    {
                        <ThemeProvider theme={customTheme}>
                            <Select
                                labelId={"singleSelectDropDownId-" + question.id.toString()}
                                id={question.id.toString()}
                                value={selectedId}
                                onChange={saveAnswerTypeSingleSelect.bind(self, "singleSelectDropDown-" + question.id.toString(), savedObjs)}
                                disabled={savedObjs.readonlyMode}
                                sx={{
                                    width: ddlbObj.width,
                                    color: ddlbObj.textSelectedColorOverride ? ddlbObj.textSelectedColor : (ddlbObj.textColorOverride ? ddlbObj.textColor : textObj.fontObj.color),
                                    fontFamily: textObj.fontObj.fontFamily,
                                    fontStyle: textObj.fontObj.fontStyle,
                                    fontWeight: textObj.fontObj.fontWeight,
                                    fontSize: textObj.fontObj.fontSize,
                                    '& .MuiInputBase-root': {
                                        height: "54px"
                                    }
                                }}
                                input={
                                    <OutlinedInput
                                        //labelWidth={ddlbObj.noFloat ? "0px" : getLabelWidth(labelText)}
                                        name={ddlbObj.labelText}
                                        id={"DDLBCustomOutlinedInput-" + question.id.toString()}
                                    />
                                }
                            >
                                {savedObjs.answers.map((answerOption) => {
                                    return (
                                        <MenuItem
                                            value={answerOption.id.toString()}
                                            key={answerOption.id.toString()}>{answerOption.optionText}
                                        </MenuItem>);
                                })}
                            </Select>
                        </ThemeProvider>
                    }
                </FormControl>
            </Grid>
        </Grid>
    );

}

//</editor-fold>
// ---------------------------------------------------------------------------------------------------------------------



// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------
// Single Select (LIKERT) !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! <editor-fold>
// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!


function singleSelectLikert(self, savedObjs) {
    let pyramidMode = mode.displayOneQuestionPerPage() && (savedObjs.answers.length === 11);

    // LEGACY FIX
    if (savedObjs.isGroupLikert && !(tag.metadataExistsObj(savedObjs.activeTemplate, portalShared.CUSTOM_DDO_GRP_FORMAT))) {
        if (savedObjs.activeTemplate.hasOwnProperty("metadata")) {
            if (savedObjs.activeTemplate.metadata.hasOwnProperty("metadataItems")) {
                savedObjs.activeTemplate.metadata.metadataItems[portalShared.CUSTOM_DDO_GRP_FORMAT] = JSON.stringify(portalShared.getCustomGroupObj());
            } else {
                savedObjs.activeTemplate.metadata[portalShared.CUSTOM_DDO_GRP_FORMAT] = JSON.stringify(portalShared.getCustomGroupObj());
            }
        }
        savedObjs.activeTemplate.metadata[portalShared.CUSTOM_DDO_GRP_FORMAT] = JSON.stringify(portalShared.getCustomGroupObj());
    }

    // Likert would require a custom group or question object to exist
    savedObjs.customTemplateObj = tag.metadataValueObj(savedObjs.activeTemplate, savedObjs.isGroupLikert ? portalShared.CUSTOM_DDO_GRP_FORMAT : portalShared.CUSTOM_DDO_QUE_FORMAT);


    if (savedObjs.customTemplateObj.hasOwnProperty("likertObj")) {
        //savedObjs.rightAlignLikertAnswers = tag.metadataExists(savedObjs.activeTemplate, portalShared.DISPLAY_DEF_ALIGN_QUESTION_ANSWER_RIGHT); // Check for answers right aligned
        savedObjs.rightAlignLikertAnswers = savedObjs.customTemplateObj.likertObj.alignAnswersRight;

        //savedObjs.alignQuestionsAndAnswers = tag.metadataExists(savedObjs.activeTemplate, portalShared.DISPLAY_DEF_ALIGN_ANSWERS_WITH_QUESTION);  // Check for questions aligned with answers
        savedObjs.alignQuestionsAndAnswers = savedObjs.customTemplateObj.likertObj.alignQuestionsAndAnswers;
    } else {
        // LEGACY FIX
        savedObjs.rightAlignLikertAnswers = savedObjs.customTemplateObj.alignAnswersRight;
        savedObjs.alignQuestionsAndAnswers = savedObjs.customTemplateObj.alignQuestionsAndAnswers;
    }


    // If aligned right then answers need to be reversed
    let orderedAnswers = savedObjs.answers;
    let floatDirection = "left";

    // let alignAnswersRight = false;
    //
    // if (savedObjs.activeTemplateType === TEMPLATE.TYPE.GROUP) {
    //     let groupCustomMetadata = null;
    //     if (tag.metadataExists(savedObjs.group, portalShared.CUSTOM_DDO_GRP_FORMAT)) {
    //         groupCustomMetadata = JSON.parse(savedObjs.group.template[portalShared.CUSTOM_DDO_GRP_FORMAT]);
    //         alignAnswersRight = groupCustomMetadata.likertObj.alignAnswersRight;
    //     }
    // } else {
    //     if (savedObjs?.customTemplateObj) {
    //         alignAnswersRight = portalShared.legacyFixLikertObj(savedObjs.customTemplateObj, "alignAnswersRight"); // LEGACY FIX
    //     }
    // }

    if (savedObjs.rightAlignLikertAnswers) {
        orderedAnswers = savedObjs.answers.map(answer => answer).reverse();  // Reverse answers so fill from right to left for right aligned answers options
        floatDirection = "right";
    }

    // -----------------------------------------------------------------------------------------------------------------

    // If pyramid mode then only show left title
    let titleObj = getLikertTitleObj(savedObjs, orderedAnswers, floatDirection, pyramidMode);  // Check for LCR titles
    //let titleObjPyramid = pyramidMode ? getLikertTitleObj(savedObjs, orderedAnswers, floatDirection, false, true) : "";  // Check for pyramid bottom right title


    let textObj = ddo.getQuestionNameText(savedObjs.question, savedObjs.questionNumber);  // Check for custom question text

    //let RadioCustomStyle = style.getRadioStyle(savedObjs);

    //let answerObj = getLikertAnswerInputObj("radio", savedObjs);

    // -----------------------------------------------------------------------------------------------------------------

    let responsiveLabelsObj = getResponsiveLabelObj(savedObjs.group, savedObjs.question, orderedAnswers, savedObjs.selectedAnswer);  // Check for custom responsive labels

    // -----------------------------------------------------------------------------------------------------------------

    //Pyramid mode responsive label setup
    let divLeftWidth = 0;
    let savedIndex = 0;

    if (responsiveLabelsObj.useResponsiveLabelsFlag && pyramidMode) {
        for (let i = 0; i < responsiveLabelsObj.answerArray.length; i++) {
            if (responsiveLabelsObj.answerArray[i].id === savedObjs.selectedAnswer.id) {
                savedIndex = i;
                break;
            }
        }
    }

    if (savedIndex < 6) {
        divLeftWidth = ((savedIndex === 0 ? 0 : -40) + ((savedIndex) * 55)).toString() + "px";
    }
    else {
        divLeftWidth = (0 + ((savedIndex - 6) * 52)).toString() + "px";
    }

    // -----------------------------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    let titlebarAfterspace = tag.metadataValue(savedObjs.activeTemplate, portalShared.DISPLAY_LIKERT_TITLEBAR_AFTERSPACE_HEIGHT, "", "0px", true);

    let questionDivWidth = (830 - ((orderedAnswers.length + 2) * titleObj.defaultWidthNum)) + "px";

    let questionSpaceAfter = tag.metadataValue(savedObjs.activeTemplate, portalShared.LIKERT_QUESTION_SPACE_AFTER, "", "15px");
    let answersSpaceAfter = tag.metadataValue(savedObjs.activeTemplate, portalShared.LIKERT_ANSWERS_SPACE_AFTER, "", "0px");

    // const circleClass = {
    //     position: "absolute",
    //     top: "50%",
    //     left: "50%",
    //     transform: "translate(-50%, -50%)",
    //     width: "3rem",
    //     height: "3rem",
    //     borderRadius: "50%",
    //     backgroundColor: "white"
    // }

    // For group likert the titles come first, then the question text for a question likert the question comes before the title
    // style={{display: ddo.showGroup(qnr, showGroupKey) ? "inline" : "none"}}


    let divName = "LIKERT-" + savedObjs.activeTemplateType + "-" + savedObjs.activeTemplate.id + "-" + savedObjs.likertIndex;

    let addSpacerLine = !(savedObjs.likertInputType === "Radio" || savedObjs.likertInputType === "Star"); // No Spacer if control is radios or stars

    let answers = savedObjs.answers[0].metadata.hasOwnProperty("metadataItems") ? savedObjs.answers[0].metadata.metadataItems : savedObjs.answers[0].metadata; // Instance mode or template mode

    let hasResponsiveLabels= answers.hasOwnProperty(portalShared.USE_LIKERT_RESPONSIVE_LABEL); // No spacer if responsive labels

    addSpacerLine = addSpacerLine && !hasResponsiveLabels;

    return (
        <div id={divName} style={{width: '100%', display: ddo.showGroup(savedObjs.qnr, savedObjs.showGroupKey) ? "inline" : "none"}}>

            {savedObjs.activeTemplateType === TEMPLATE.TYPE.GROUP ?
                <div id={divName+"-inner-div"}>
                    {(titleObj.htmlTitleDiv !== "" && !pyramidMode) ?
                        <div style={{width: "100%"}}>
                            {titleObj.htmlTitleDiv}
                        </div> : ""
                    }

                    <div id="single-select-clear-both1" style={{clear: "both"}}></div>

                    {(titleObj.useGraybarHeader) ?
                        <div id={divName+"-use-graybar-header1-true"} style={{clear: floatDirection, width: "100%", height: titlebarAfterspace}}>&nbsp;</div>
                        :
                        <div id={divName+"-use-graybar-header1-false"} style={{clear: floatDirection, width: "100%", height: titlebarAfterspace}}></div>
                    }

                    <div id="single-select-clear-both2" style={{clear: "both"}}></div>

                    {getQuestionName(textObj, savedObjs, questionDivWidth, "ABOVE")}

                </div>
                :
                <div>
                    {getQuestionName(textObj, savedObjs, questionDivWidth, "ABOVE")}

                    <div id="single-select-clear-both3" style={{clear: "both", height: questionSpaceAfter}}>&nbsp;</div>

                    {(titleObj.htmlTitleDiv !== "" && !pyramidMode) ?
                        <div style={{width: "100%"}}>
                            {titleObj.htmlTitleDiv}
                        </div> : ""
                    }

                    <div id="single-select-clear-both4" style={{clear: "both"}}></div>

                    {(titleObj.useGraybarHeader) ?
                        <div id={divName+"-use-graybar-header2-true"} style={{clear: floatDirection, width: "100%", height: titlebarAfterspace}}>&nbsp;</div>
                        :
                        <div id={divName+"-use-graybar-header2-false"} style={{clear: floatDirection, width: "100%", height: titlebarAfterspace}}></div>
                    }
                </div>
            }

            <div id={divName+"outer-div-space-above-question"} style={{width: '100%', backgroundColor: "red"}}>

                {getQuestionName(textObj, savedObjs, questionDivWidth, "ALIGNED")}

                {/*<div id={divName+"-space-after-1"} style={{height: questionSpaceAfter, backgroundColor: "blue"}}></div>*/}

                <div id={divName+"-float-direction-1"} style={{float: floatDirection}}>
                    <div id={divName+"-float-direction-inner-1"} style={{float: floatDirection, width: titleObj.defaultWidth1xStr}}>&nbsp;</div>
                </div>

                <div>
                    {pyramidMode ?
                        getPyramid(self, savedObjs, orderedAnswers, floatDirection)
                        :
                        orderedAnswers.map((answer, index) =>
                            <div key={"OrderedAnswers1-" + index + "-" + answer.id} style={{float: floatDirection}}>
                                <div key={"OrderedAnswers2-" + index + "-" + answer.id}
                                     style={{float: floatDirection, width: titleObj.defaultWidth1xStr}}>

                                    {singleSelectAnswerInputObject(self, savedObjs, answer, index)}
                                    {addSpacerLine ? <div>&nbsp;</div> : <div/>}
                                </div>
                            </div>)
                    }
                </div>

                <div id={divName+"-float-direction-2"} style={{float: floatDirection}}>
                    <div id={divName+"-float-direction-inner-2"} style={{float: floatDirection, width: titleObj.defaultWidth1xStr}}>&nbsp;</div>
                </div>
            </div>

            {pyramidMode ? "" :
                <div id={divName+"-clear-float-direction-1"} style={{clear: floatDirection}}></div>}

            {/*{(titleObjPyramid.htmlTitleDiv !== "") ?*/}
            {/*    titleObjPyramid.htmlTitleDiv : ""*/}
            {/*}*/}

            {pyramidMode ? "" :
                <div id={divName+"-not-pyramid-mode-space-after-1"} style={{height: answersSpaceAfter}}></div>}

            <div id="single-select-clear-both5" style={{clear: "both"}}></div>

            {responsiveLabelsObj.useResponsiveLabelsFlag ?
                (pyramidMode && (responsiveLabelsObj.answerResponsiveLabel !== "")) ?
                    <div style={{width: '100%'}}>
                        {divLeftWidth !== "0px" ? <div key={"RL-left-pyramid"} style={{float: "left", width: divLeftWidth}}>&nbsp;</div> : ""}
                        <div key={"RL-center-pyramid"} style={{float: "left", width: "150px", textAlign: "center"}}>
                            <Typography style={responsiveLabelsObj.responsiveLabelStyle}>
                                {responsiveLabelsObj.answerResponsiveLabel}
                            </Typography>
                        </div>
                    </div>
                    :
                    <div style={{width: '100%'}}>
                        {responsiveLabelsObj.answerArray.map((answer) =>
                            <div key={"RLdiv" + answer.id.toString()} style={{float: floatDirection}}>
                                {(answer.id !== savedObjs.selectedAnswer.id) ?
                                    <div key={"RLselected" + answer.id.toString()} style={{float: floatDirection}}>
                                        <div style={{float: floatDirection, width: titleObj.defaultWidth1xStr}}>&nbsp;</div>
                                    </div>
                                    :
                                    <div key={"RL" + answer.id.toString()} style={{float: floatDirection, width: titleObj.defaultWidth3xStr, paddingTop: "5px"}}>
                                        <Typography style={responsiveLabelsObj.responsiveLabelStyle}>
                                            {responsiveLabelsObj.answerResponsiveLabel}
                                        </Typography>
                                    </div>
                                }
                            </div>
                        )}
                    </div> : ""}
        </div>
    );
}


function getQuestionName(textObj, savedObjs, questionDivWidth, nameLocation) {

    let htmlObj = "";

    // If show question numbers then add number prefix
    let questionName = savedObjs.questionNameHidden ? "" : savedObjs.question.name;
    if ((savedObjs.itemObj.showNumber !== "")&&(savedObjs.itemObj.showNumber !== "0")) {
        questionName = savedObjs.itemObj.showNumber + " " + savedObjs.question.name;
    }

    let divId = "question-id-" + savedObjs.activeTemplate.id;

    if (textObj.customObj !== "hide") {

        if (nameLocation === "ABOVE") {

            if (!savedObjs.alignQuestionsAndAnswers) {

                htmlObj =
                    <div>
                        <div id={divId} style={{float: "left"}}>
                            {textObj.CustomTopographyStyle === null ?
                                <Typography variant={textObj.variant} style={{textAlign: "left"}}>
                                    {questionName}
                                </Typography>
                                :
                                <textObj.CustomTopographyStyle>
                                    {questionName}
                                </textObj.CustomTopographyStyle>
                            }
                        </div>

                        <div id="single-select-clear-both6" style={{clear: "both"}}></div>
                    </div>

                }
            }
        else
        {

            if (savedObjs.rightAlignLikertAnswers) {

                htmlObj = <div id={divId} style={{
                                float: "left",
                                width: questionDivWidth,
                                wordWrap: "normal",
                                height: "42px",
                                position: "relative",
                                display: 'flex',
                                alignItems: "center",
                                paddingLeft: savedObjs.indent
                            }}>
                                {textObj.CustomTopographyStyle === null ?
                                    <Typography variant={textObj.variant} style={{textAlign: "left"}}>
                                        {savedObjs.alignQuestionsAndAnswers ? questionName : ""}
                                    </Typography>
                                    :
                                    <textObj.CustomTopographyStyle>
                                        {savedObjs.alignQuestionsAndAnswers ? questionName : ""}
                                    </textObj.CustomTopographyStyle>
                                }
                            </div>


            }

        }
    }

    return htmlObj;
}


// function getLikertAnswerInputObj(answerType, savedObjs, answer) {
//
//     let answerObj = {
//         divWidth: "",
//         AnswerCustomStyle: null
//     }
//
//     answerObj.AnswerCustomStyle = style.getRadioStyle(savedObjs);
//
//
//     return answerObj;
// }


function getDisplayColors(savedObjs, answer) {

    let colorObj = {
        textFont: font.DEFAULT_FONT_FAMILY,
        defaultBorder: icon.DEFAULT_ICON_COLOR_NOT_SELECTED,
        defaultBorderOpacity: 1,
        defaultText: icon.DEFAULT_ICON_COLOR_NOT_SELECTED,
        defaultTextOpacity: 1,
        defaultBackground: "white",
        defaultBackgroundOpacity: 1,
        selectedBorder: icon.DEFAULT_ICON_COLOR_SELECTED,
        selectedBorderOpacity: 1,
        selectedText: "white",
        selectedTextOpacity: 1,
        selectedBackground: icon.DEFAULT_ICON_COLOR_SELECTED,
        selectedBackgroundOpacity: 1
    }

    // If multi-color NPS (Net Promoter Score)
    // if (savedObjs.inputModifier === icon.TYPE_CIRCLE_MODIFIER_COLORS) {
    //     colorObj.defaultBorder = ssUtil.setCircleDefaultMultiColors(answer, true);
    //     colorObj.defaultText = "black";
    //     colorObj.defaultBackground = ssUtil.setCircleDefaultMultiColors(answer, false);
    //     colorObj.selectedBackground = ssUtil.setCircleDefaultMultiColors(answer, true);
    //     colorObj.selectedText = "black";
    // }
    // else {
        colorObj.defaultBorder = tag.metadataValue(savedObjs.activeTemplate, portalShared.OVERRIDE_ICON_UNSELECTED_COLOR, "", colorObj.defaultBorder);
        colorObj.defaultBorderOpacity = tag.metadataValue(savedObjs.activeTemplate, portalShared.OVERRIDE_ICON_UNSELECTED_OPACITY, "", colorObj.defaultBorderOpacity);

        colorObj.defaultText = tag.metadataValue(savedObjs.activeTemplate, portalShared.OVERRIDE_ICON_UNSELECTED_TEXT_COLOR, "", colorObj.defaultText);
        colorObj.defaultTextOpacity = tag.metadataValue(savedObjs.activeTemplate, portalShared.OVERRIDE_ICON_UNSELECTED_TEXT_OPACITY, "", colorObj.defaultTextOpacity);

        colorObj.defaultBackground = tag.metadataValue(savedObjs.activeTemplate, portalShared.OVERRIDE_ICON_UNSELECTED_BACKGROUND_COLOR, "", colorObj.defaultBackground);
        colorObj.defaultBackgroundOpacity = tag.metadataValue(savedObjs.activeTemplate, portalShared.OVERRIDE_ICON_UNSELECTED_BACKGROUND_OPACITY, "", colorObj.defaultBackgroundOpacity);

        colorObj.selectedBackground = tag.metadataValue(savedObjs.activeTemplate, portalShared.OVERRIDE_ICON_SELECTED_COLOR, "", colorObj.selectedBackground);
        colorObj.selectedBackgroundOpacity = tag.metadataValue(savedObjs.activeTemplate, portalShared.OVERRIDE_ICON_SELECTED_OPACITY, "", colorObj.selectedBackgroundOpacity);

        colorObj.selectedBorder = tag.metadataValue(savedObjs.activeTemplate, portalShared.OVERRIDE_ICON_SELECTED_BORDER_COLOR, "", colorObj.selectedBackground);
        colorObj.selectedBorderOpacity = tag.metadataValue(savedObjs.activeTemplate, portalShared.OVERRIDE_ICON_SELECTED_BORDER_OPACITY, "", colorObj.selectedBackgroundOpacity);

        colorObj.selectedText = tag.metadataValue(savedObjs.activeTemplate, portalShared.OVERRIDE_ICON_SELECTED_TEXT_COLOR, "", colorObj.selectedText);
        colorObj.selectedTextOpacity = tag.metadataValue(savedObjs.activeTemplate, portalShared.OVERRIDE_ICON_SELECTED_TEXT_OPACITY, "", colorObj.selectedTextOpacity);

        colorObj.textFont = tag.metadataValue(savedObjs.activeTemplate, portalShared.OVERRIDE_ICON_TEXT_FONT, "", colorObj.textFont);
    //}

    return colorObj;
}


function singleSelectAnswerInputObject (self, savedObjs, answer, index) {

    let htmlObj = "";

    let answerObj = {
        divWidth: "",
        AnswerCustomStyle: null
    }

    //let activeTemplate = (group !== undefined) ? group : question;

    let objId = "question-" + savedObjs.question.id + "-answer-" + answer.id.toString() + "-" + index.toString();

    let colorObj = getDisplayColors(savedObjs, answer, index);
    let selectedFlag = (answer.id === savedObjs.selectedAnswer.id);

    switch(savedObjs.likertInputType) {
        case icon.TYPE_RADIO:

            answerObj.AnswerCustomStyle = style.getRadioStyle(savedObjs, true);

            htmlObj = <answerObj.AnswerCustomStyle
                id={objId}
                checked={answer.id === savedObjs.selectedAnswer.id}
                onClick={saveAnswerTypeSingleSelect.bind(self, "singleSelectLikert-radio-" + answer.id.toString(), savedObjs,  null, answer.id)}
            />
            break;
        case icon.TYPE_SQUARE:

            htmlObj = <Button id={objId}
                              value={answer.id.toString()}
                              style={{
                                  fontFamily: "Comic Sans MS, Comic Sans MS5, cursive",
                                  font: "Comic Sans MS, Comic Sans MS5, cursive",
                                  height: '40px',
                                  width: '40px',
                                  minWidth: '40px',
                                  border: 'solid',
                                  borderWidth: tag.metadataValue(savedObjs.activeTemplate, portalShared.OVERRIDE_ICON_SQUARE_BORDER_WIDTH, "", "1px", true),
                                  borderColor: (selectedFlag ? colorObj.selectedBorder : colorObj.defaultBorder),
                                  color: (selectedFlag ? colorObj.selectedText : colorObj.defaultText),
                                  backgroundColor: (selectedFlag ? colorObj.selectedBackground : colorObj.defaultBackground),
                              }}
                //color={answer.id === savedObjs.selectedAnswer.id ? "primary" : "default"}
                              onClick={saveAnswerTypeSingleSelect.bind(self, "singleSelectLikert-square-" + answer.id.toString(), savedObjs, null, answer.id)}
                              disableripple={savedObjs.readonlyMode.toString()}
                              disablefocusripple={savedObjs.readonlyMode.toString()}
            >
                <Typography id={"innertext-" + objId} style={{color: (selectedFlag ? colorObj.selectedText : colorObj.defaultText)}}>
                    {tag.metadataExists(answer, portalShared.HIDE_BUTTONGROUP_NAME) ? "\u00a0" : answer.optionText}
                </Typography>
            </Button>
            break;
        case icon.TYPE_CIRCLE:

            htmlObj = <Button id={objId}
                              value={answer.id.toString()}
                              style = {{
                                  border: 'solid',
                                  borderWidth: '2px',
                                  borderRadius: '50%',
                                  minWidth: '40px',
                                  width: '40px',
                                  height: '40px',
                                  boxShadow: '0 1px 10px #e0e0e0',
                                  borderColor: (selectedFlag ? colorObj.selectedBorder : colorObj.defaultBorder),
                                  color: (selectedFlag ? colorObj.selectedText : colorObj.defaultText),
                                  backgroundColor: (selectedFlag ? colorObj.selectedBackground : colorObj.defaultBackground),
                              }}
                // color={answer.id === savedObjs.selectedAnswer.id ? "primary" : "default"}
                              onClick={saveAnswerTypeSingleSelect.bind(self, "singleSelectLikert-circle-" + answer.id.toString(), savedObjs, null, answer.id)}
                              disableripple={savedObjs.readonlyMode.toString()}
                              disablefocusripple={savedObjs.readonlyMode.toString()}
                // aria-label={(index) + " out of " + (answers.length-1) + " - " + (answer.id === selectedAnswer.id ? "selected" : "unselected")}
            >
                <Typography id={"innertext-" + objId} style={{color: (selectedFlag ? colorObj.selectedText : colorObj.defaultText)}}>
                    {tag.metadataExists(answer, portalShared.HIDE_BUTTONGROUP_NAME) ? "\u00a0" : answer.optionText}
                </Typography>
            </Button>
            break;
        default:

            htmlObj = icon.getLikertIconButton(savedObjs, answer, objId, saveAnswerTypeSingleSelect.bind(self, "singleSelectLikert-icon-" + answer.id.toString(), savedObjs, null, answer.id));
            break;
    }

    return htmlObj;
}


// ---------------------------------------------------------------------------------------------------------------------
// Responsive Labels Utlilities !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! <editor-fold>
// ---------------------------------------------------------------------------------------------------------------------


function getResponsiveLabelObj(group, question, orderedAnswerArray, selectedAnswer) {

    let responsiveLabelObj = {
        useResponsiveLabelsFlag: false,
        answerArray: [],
        answerIndex: 0,
        answerResponsiveLabel: "",
        responsiveLabelStyle: null
    };


    // Priority if group or question level use of defaults declared in metadata
    responsiveLabelObj.useResponsiveLabelsFlag = (tag.metadataExists(question, portalShared.USE_LIKERT_DEFAULT_RESPONSIVE_LABEL) || tag.metadataExists((group !== undefined) ? group : question, portalShared.USE_LIKERT_DEFAULT_RESPONSIVE_LABEL));

    if (!responsiveLabelObj.useResponsiveLabelsFlag) {

        // If not at group or question level check all the answers for a declared responsive label
        // If any answer has metadata for responsive level
        for (var j = 0; j < orderedAnswerArray.length; j++) {
            if (tag.metadataExists(orderedAnswerArray[j], portalShared.USE_LIKERT_RESPONSIVE_LABEL) || tag.metadataExists(orderedAnswerArray[j], portalShared.USE_LIKERT_DEFAULT_RESPONSIVE_LABEL)) {
                responsiveLabelObj.useResponsiveLabelsFlag = true;
                break;
            }
        }
    }

    let customTemplateTag = (group !== undefined) ? portalShared.CUSTOM_DDO_GRP_FORMAT : portalShared.CUSTOM_DDO_QUE_FORMAT;

    if (responsiveLabelObj.useResponsiveLabelsFlag) {
        for (var i = 0; i < orderedAnswerArray.length; i++) {
            responsiveLabelObj.answerArray.push(orderedAnswerArray[i]);
            if (orderedAnswerArray[i].id === selectedAnswer.id) {
                responsiveLabelObj.answerIndex = i;
                responsiveLabelObj.answerResponsiveLabel = getResponsiveLabel(((group !== undefined) ? group : question), question, selectedAnswer);
                responsiveLabelObj.responsiveLabelStyle = responsiveLabel.getResponsiveLabelProperties(customTemplateTag, ((group !== undefined) ? group : question), question, orderedAnswerArray[i]);
                break;
            }
        }
    }

    return responsiveLabelObj;
}


function getResponsiveLabel(group, question, selectedAnswerOption, type = "") {

    let label = "";
    let answerFound = false;
    let selectedAnswerIndex = 0;

    // Use default responsive label
    if (tag.metadataExists(group, portalShared.USE_LIKERT_DEFAULT_RESPONSIVE_LABEL) || tag.metadataExists(question, portalShared.USE_LIKERT_DEFAULT_RESPONSIVE_LABEL)) {

        let answers = ddo.getAnswerOptions(question);
        for (let i = 0; i < answers.length; i++) {
            if (answers[i].optionText === selectedAnswerOption.optionText) {
                answerFound = true;
                selectedAnswerIndex = i;
            }
        }

        if (answerFound) {
            switch(answers.length) {
                case 2:
                    switch(selectedAnswerIndex) {
                        case 0:
                            label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_2.N1;
                            break;
                        case 1:
                            label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_2.N2;
                            break;
                        // no default
                    }
                    break;
                case 3:
                    switch(selectedAnswerIndex) {
                        case 0:
                            label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_3.N1;
                            break;
                        case 1:
                            label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_3.N2;
                            break;
                        case 2:
                            label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_3.N3;
                            break;
                        // no default
                    }
                    break;
                case 4:
                    switch(selectedAnswerIndex) {
                        case 0:
                            label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_4.N1;
                            break;
                        case 1:
                            label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_4.N2;
                            break;
                        case 2:
                            label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_4.N3;
                            break;
                        case 3:
                            label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_4.N4;
                            break;
                        // no default
                    }
                    break;
                case 5:
                    switch(selectedAnswerIndex) {
                        case 0:
                            label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_5.N1;
                            break;
                        case 1:
                            label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_5.N2;
                            break;
                        case 2:
                            label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_5.N3;
                            break;
                        case 3:
                            label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_5.N4;
                            break;
                        case 4:
                            label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_5.N5;
                            break;
                        // no default
                    }
                    break;
                case 6:
                    switch(selectedAnswerIndex) {
                        case 0:
                            label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_6.N1;
                            break;
                        case 1:
                            label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_6.N2;
                            break;
                        case 2:
                            label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_6.N3;
                            break;
                        case 3:
                            label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_6.N4;
                            break;
                        case 4:
                            label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_6.N5;
                            break;
                        case 5:
                            label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_6.N6;
                            break;
                        // no default
                    }
                    break;
                case 7:
                    switch(selectedAnswerIndex) {
                        case 0:
                            label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_7.N1;
                            break;
                        case 1:
                            label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_7.N2;
                            break;
                        case 2:
                            label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_7.N3;
                            break;
                        case 3:
                            label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_7.N4;
                            break;
                        case 4:
                            label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_7.N5;
                            break;
                        case 5:
                            label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_7.N6;
                            break;
                        case 6:
                            label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_7.N7;
                            break;
                        // no default
                    }
                    break;
                case 10:
                    switch(selectedAnswerIndex) {
                        case 0:
                            label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_10.N1;
                            break;
                        case 1:
                            label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_10.N2;
                            break;
                        case 2:
                            label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_10.N3;
                            break;
                        case 3:
                            label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_10.N4;
                            break;
                        case 4:
                            label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_10.N5;
                            break;
                        case 5:
                            label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_10.N6;
                            break;
                        case 6:
                            label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_10.N7;
                            break;
                        case 7:
                            label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_10.N8;
                            break;
                        case 8:
                            label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_10.N9;
                            break;
                        case 9:
                            label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_1_10.N10;
                            break;
                        // no default
                    }
                    break;
                case 11:
                    if (type === "squares") {
                        switch(selectedAnswerIndex) {
                            case 0:
                                label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_0_10.N0;
                                break;
                            case 1:
                                label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_0_10.N1;
                                break;
                            case 2:
                                label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_0_10.N2;
                                break;
                            case 3:
                                label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_0_10.N3;
                                break;
                            case 4:
                                label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_0_10.N4;
                                break;
                            case 5:
                                label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_0_10.N5;
                                break;
                            case 6:
                                label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_0_10.N6;
                                break;
                            case 7:
                                label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_0_10.N7;
                                break;
                            case 8:
                                label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_0_10.N8;
                                break;
                            case 9:
                                label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_0_10.N9;
                                break;
                            case 10:
                                label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_0_10.N10;
                                break;
                            // no default
                        }
                    }
                    else {
                        switch(selectedAnswerIndex) {
                            case 0:
                                label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_0_10.N0;
                                break;
                            case 1:
                                label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_0_10.N1;
                                break;
                            case 2:
                                label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_0_10.N2;
                                break;
                            case 3:
                                label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_0_10.N3;
                                break;
                            case 4:
                                label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_0_10.N4;
                                break;
                            case 5:
                                label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_0_10.N5;
                                break;
                            case 6:
                                label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_0_10.N6;
                                break;
                            case 7:
                                label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_0_10.N7;
                                break;
                            case 8:
                                label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_0_10.N8;
                                break;
                            case 9:
                                label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_0_10.N9;
                                break;
                            case 10:
                                label = RESPONSIVE_LABEL_DEFAULT.ANSWERS_0_10.N10;
                                break;
                            // no default
                        }
                    }
                    break;
                // no default
            }
        }

    }
    else {
        // Question does not have the use default responsive label metadata so check answer for a specific label
        if (tag.metadataExists(selectedAnswerOption, portalShared.USE_LIKERT_RESPONSIVE_LABEL)) {
            label = tag.metadataValue(selectedAnswerOption, portalShared.USE_LIKERT_RESPONSIVE_LABEL);
        }
    }

    return label;

}


//</editor-fold>
// ---------------------------------------------------------------------------------------------------------------------

function getPyramid(self, savedObjs, orderedAnswers, floatDirection) {

    // In pyramid mode only show right title

    //let useColorsNPS = (savedObjs.inputModifier === icon.TYPE_CIRCLE_MODIFIER_COLORS); // "Colors" or use default dodger blue unless overridden
    let useColorsNPS = false; // "Colors" or use default dodger blue unless overridden

    let colorObj = getDisplayColors(savedObjs, {}, 0);
    let objIds = [];

    for (let i = 0; i < savedObjs.answers.length; i++) {
        objIds.push("question-" + savedObjs.question.id + "-answer-" + savedObjs.answers[i].id.toString() + "-" + i.toString());
    }
    let objNames = objIds;


    let titleObjLeft = getLikertTitleObj(savedObjs, orderedAnswers, floatDirection, true, true, false);
    let titleObjRight = getLikertTitleObj(savedObjs, orderedAnswers, floatDirection, true, false, true);


    let pyramidStyles = {
        buttonStyle: {
            backgroundColor: ""
        },
        buttonGroup: {
            width: '350px',
            flex: '1',
            flexFlow: 'wrap',
            justifyContent: 'center'
        },
        button: {
            border: 'solid',
            borderWidth: '2px',
            borderRadius: '50%',
            minWidth: '45px',
            width: '45px',
            height: '45px',
            marginLeft: '5px',
            marginRight: '5px',
            marginBottom: '5px',
            boxShadow: useColorsNPS ? '0 1px 10px #e0e0e0' : 'none',
        }
    }

    return (
        <>
            <div>
                <div style={{paddingLeft: "-15"}}>
                    {(titleObjLeft.htmlTitleDiv !== "") ?
                        <div style={{width: "100%"}}>
                            {titleObjLeft.htmlTitleDiv}
                        </div> : <div>&nbsp;</div>
                    }
                </div>
                <ButtonGroup
                    color="primary"
                    aria-label="answer button group"
                    style={pyramidStyles.buttonGroup}
                >
                    {savedObjs.answers.map((answer, index) => {

                        let textStyles = {
                            color: ""
                        };

                        if (answer.id === savedObjs.selectedAnswer.id) {
                            textStyles.fontWeight = "bold";
                            textStyles.color = useColorsNPS ? 'black' : 'white';
                            // ZZZZZ Commented out to see if the cause of mobile crashes
                            //pyramidStyles.button.backgroundColor = self.props.theme.theme.palette.secondary.main;
                        }

                        if (answer.metadata.metadataItems["icon-type"] === "Square") {
                            pyramidStyles.button.borderRadius = 0;
                        }

                        return (
                            <div>
                                <Button id={objIds[index]}
                                        name={objNames[index]}
                                        value={answer.id.toString()}
                                        style={{
                                            ...pyramidStyles.button,
                                            backgroundColor: useColorsNPS ? ssUtil.getCircleColors(answer, true, answer.id === savedObjs.selectedAnswer.id, useColorsNPS) : answer.id === savedObjs.selectedAnswer.id ? colorObj.selectedBackground : colorObj.defaultBackground,
                                            borderColor: useColorsNPS ? ssUtil.getCircleColors(answer, false) : answer.id === savedObjs.selectedAnswer.id ? colorObj.selectedBorder : colorObj.defaultBorder,
                                        }}
                                        onClick={saveAnswerTypeSingleSelect.bind(self,  "singleSelectLikertWithButtonGroup-2", savedObjs, null, answer.id)}
                                        disableRipple={savedObjs.readonlyMode}
                                        disableFocusRipple={savedObjs.readonlyMode}
                                >
                                    <Typography id={"innertext-" + objIds[index]} name={"innertext-" + objNames[index]} style={{color: (answer.id === savedObjs.selectedAnswer.id ? colorObj.selectedText : colorObj.defaultText)}}>
                                        {tag.metadataExists(answer, portalShared.HIDE_BUTTONGROUP_NAME) ? "\u00a0" : answer.optionText}
                                    </Typography>
                                </Button>
                            </div>
                        );
                    })}
                </ButtonGroup>
                <div>
                    {(titleObjRight.htmlTitleDiv !== "") ?
                        <div style={{width: "100%"}}>
                            {titleObjRight.htmlTitleDiv}
                        </div> : <div>&nbsp;</div>
                    }
                </div>
            </div>
        </>
    );
}


// <Typography className={classes.title} style={textStyles}>
