import * as types from "../constants/actionConstants";


const initialState = {

};


function questionTemplateReducer(state = initialState, action) {
    switch (action.type) {
        case types.QUESTION_TEMPLATE_GET_SUCCESS:
        case types.QUESTION_TEMPLATE_CREATE_SUCCESS:
        case types.QUESTION_TEMPLATE_UPDATE_SUCCESS:
        case types.QUESTION_TEMPLATE_TAGS_UPDATE_SUCCESS:
            return {
                ...state,
                [action.questionTemplate.templateKey]: {
                    ...action.questionTemplate
                }
            };

        case types.QUESTION_TEMPLATE_GET_INIT:
            return {
                ...state,
                [action.templateKey]: null
            };

        default:
            return state;
    }
}

export default questionTemplateReducer;
