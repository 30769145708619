import {useEffect} from 'react';

export default function ChangeAppTitleToPortalHook(props) {

    const mode = props.mode;

    useEffect(() => {
        // Update the document title using the browser API
        if (mode === "yes") {
            document.title = "QD Portal Questionnaire";
        }
    });
}
