import * as types from "../constants/actionConstants";

export function notificationOpen(message = "An error has occurred.", error) {
    let fullMessage = message + ((error.response && error.response.status) ? (" (Error code: " + error.response.status + ")") : "");

    return (dispatch) => {
        dispatch({type: types.NOTIFICATION_OPEN, message: fullMessage});
    };
}

export function notificationClosed() {
    return (dispatch) => {
        dispatch({type: types.NOTIFICATION_CLOSED});
    };
}
