import React from 'react';
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";


export default function ArgoCheckboxHook(props) {

    const fileName = props.fileName ?? "NoFileName";
    const fieldName = props.fieldName ?? "NoFieldName";

    const uniqueIdentifier = fileName + "-ArgoCheckboxHook-" + fieldName;

    const label = props.label ?? "Label";
    const labelColor = props.labelColor ?? "black";
    const checked = props.checked;
    const onChange = props.onChange ?? function () {};
    const display = props.display === undefined ? "inline" : "none";
    const disabled = props.disabled ?? false;
    const paddingTop = props.paddingTop ?? "3px";
    const bold = props.bold ?? false;
    const width = props.width ?? "180px";

    const handleOnChange = (event) => {
        onChange(event, props.fieldName)
    };

    return (
        <div id={uniqueIdentifier + "-containder-div"} style={{paddingTop: paddingTop, width: width}}>
            <FormControlLabel
                id={uniqueIdentifier}
                name={uniqueIdentifier}
                label={bold ? <b>{label}</b> : label}
                disabled={disabled}
                sx={{paddingTop: paddingTop, display: display, color: labelColor}}
                control={<Checkbox sx={{paddingTop: "1px"}} checked={checked} onChange={handleOnChange} />}
            />
        </div>
    );
}


