import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {closeDrawer} from "../../actions/drawerActions";
import QuestionnaireReview from "../qd/questionnaires/QuestionnaireReview";
import ArgoAppBar from "../common/ArgoAppBar";
import ClickAwayListener from '@mui/material/ClickAwayListener';


class QuestionnaireReviewDrawer extends Component {

    render() {

        return (
            <ClickAwayListener onClickAway={(event) => {
                // Checks is target is BODY first because portal preview DDLBs cause target to be body and we shouldn't close on that
                if (event.target.tagName !== 'BODY') this.props.closeDrawer()
            }}>
                <div style={{height: "100%"}}>
                    <ArgoAppBar
                        title="Review Questionnaire"
                        showMenuArrowButton="LEFT"
                        onLeftIconButtonTouchTap={this.props.closeDrawer}
                        isDrawer={true}
                        width={questionnaireReviewDrawerProps.width}
                    />

                    <div style={{height: '64px'}}></div>

                    <QuestionnaireReview
                        templateKey={this.props.templateKey}
                        templateId={this.props.templateId}
                        width={questionnaireReviewDrawerProps.width}
                    />
                </div>
            </ClickAwayListener>
        );
    }
}

QuestionnaireReviewDrawer.propTypes = {
    templateKey: PropTypes.string,
    templateId: PropTypes.number,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({closeDrawer}, dispatch);
}

const questionnaireReviewDrawerProps = {
    opensecondary: true,
    width: "90%",
    docked: false,
    anchor: "right"
};

export default connect(null, mapDispatchToProps)(QuestionnaireReviewDrawer);
export {questionnaireReviewDrawerProps};
