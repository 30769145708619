import * as types from "../constants/actionConstants";

const initialState = {
    template: {
        templateKey: "",
        name: "",
        description: "",
        type: "",
        metadata: {}
    },
    options: {
        hasScript: false,
        hasDescription: false,
        validate: [],
        isValidating: false
    }
};

export default function actionEditorReducer(state = initialState, action) {
    switch (action.type) {
        case types.ACTION_EDITOR_INITIALIZE:
            return {
                ...state,
                ...action.payload
            };

        case types.ACTION_EDITOR_UPDATE:
            return {
                ...state,
                template: {
                    ...state.template,
                    ...action.payload.template
                },
                options: {
                    ...state.options,
                    ...action.payload.options
                }
            };

        case types.ACTION_EDITOR_CLEAR:
            return {
                ...initialState
            };

        case types.ACTION_EDITOR_VALUE_EXISTS:
            return {
                ...state,
                options: {
                    ...state.options,
                    validate: action.list,
                    isValidating: false
                }
            };

        case types.ACTION_EDITOR_VALUE_VALIDATING:
            return {
                ...state,
                options: {
                    ...state.options,
                    isValidating: true
                }
            };

        default:
            return state;
    }
}