import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";


export default function ArgoSelectWithRenderValueHook(props) {

    const fileName = props.fileName ?? "NoFileName";
    const fieldName = props.fieldName ?? "NoFieldName";

    const index = props.index ?? ""; // If field is built in a loop index is required to know which field to match up with

    const uniqueIdentifier = fileName + "-ArgoSelectWithRenderValueHook-" + fieldName + index;
    const uniqueIdentifierLabel = uniqueIdentifier + "-label";

    const variant = props.variant ?? "standard";
    const label = props.label ?? "";

    const width = props.width ?? "";

    const paddingBottom = props.paddingBottom ?? "0px";

    const onChange = props.onChange ?? function () {};

    const disabled = props.disabled ?? false;

    const menuItems = props.menuItems?? [];

    const valueProperty = props.valueProperty ?? "value";
    const textProperty = props.textProperty ?? "text";

    const defaultValue = props.defaultValue ?? ""; // Default to first item in menuitem list menuItems[0][valueProperty] ????


    const handleChange = (event) => {

        onChange(event, fieldName, menuItems[event.target.value][textProperty].props.title, index);
    };

    return (menuItems.length > 0 ?
        <FormControl variant={variant} sx={{width: width, paddingBottom: defaultValue === "" ? "7px" : paddingBottom}} disabled={disabled}>
            <InputLabel id={uniqueIdentifierLabel}>{label}</InputLabel>
            <Select
                id={uniqueIdentifier}
                name={uniqueIdentifier}
                labelId={uniqueIdentifierLabel}
                onChange={handleChange}
                defaultValue={defaultValue}
                renderValue={(selected) => (menuItems[selected][textProperty])}
            >
                {menuItems.map((item, index) => {
                    return (<MenuItem key={uniqueIdentifier + index} value={item[valueProperty]}>{item[textProperty]}</MenuItem>);
                })}
            </Select>
            <FormHelperText sx={{width: "14px"}}>&nbsp;</FormHelperText>
        </FormControl>
        :
        <div></div>
    );
}

// select property displayEmpty={true} may be needed
