import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {getQuestionnaireTemplate} from "../../../actions/questionnaireTemplateActions";
import {
    initializeQuestionnaireEditorUpdate,
    initializeQuestionnaireEditorCreate,
    clearQuestionnaireEditor,
    updateQuestionnaireTemplate,
    checkQuestionnaireValue,
    checkNameQualifier,
    validateQuestionnaireNameAndQualifierUnique,
    addQuestionnaireCategory,
    incrementPortalCount
} from "../../../actions/questionnaireEditorActions";
import {createCategoryTemplate, getCategoryTemplate, saveCategoryTemplate} from "../../../actions/categoryTemplateActions";
import {saveGroupTemplate} from "../../../actions/groupTemplateActions";
import {saveQuestionTemplate} from "../../../actions/questionTemplateActions";
import {expanderClear} from "../../../actions/expanderActions";
import CategoryViewerExpandable from "../categories/CategoryViewerExpandable";
import ArgoReviewTemplate from "../../common/ArgoReviewTemplate";
import CategoryEditor from "../categories/CategoryEditor";
import GroupEditor from "../groups/GroupEditor";
import QuestionEditor from "../questions/QuestionEditor";
import ArgoMetadataEditor from "../../common/ArgoMetadataEditor";
import QuestionnaireReview from "./QuestionnaireReview";
import ArgoSelectPortalTextDisplayType from "../../common/ArgoSelectPortalTextDisplayType";
import ArgoTextDisplayCustom from "../../common/ArgoTextDisplayCustom";
import ArgoTextFieldHook from "../../common/ArgoTextFieldHook";
import ArgoTextFieldSelectHook from "../../common/ArgoTextFieldSelectHook";
import ArgoTextEntryWithCustomOverride from "../../common/ArgoTextEntryWithCustomOverride";
import * as validate from "../../../utilities/validate";
import * as metadata from "../../../utilities/metadata";
import * as expander from "../../../utilities/expander";
import * as metadataUtils from "../../../utilities/metadata";
import {getMetadataDefinitions} from "../../../actions/metadataDefinitionActions";
import ArgoSelectExisting from "../../common/ArgoSelectExisting";
import ArgoRadioGroupHook from "../../common/ArgoRadioGroupHook";
import ArgoToolbarHook from "../../common/ArgoToolbarHook";
import ArgoPortalPreview from "../../common/ArgoPortalPreview";
import * as metadataTemplateTypes from "../../../constants/metadataTemplateTypes"
import * as TEMPLATE from "../../../constants/templateConstants";
import * as portalShared from "../../../portal/shared/metadataConstantsAndUtilities"
import ArgoCheckboxHook from "../../common/ArgoCheckboxHook";
import {getCustomQuestionnaireSystemObj} from "../../../portal/shared/metadataConstantsAndUtilities";


const arrayNoOrYes = [
    {value: "no", text: "No"},
    {value: "yes", text: "Yes"}
];

const arrayDefaultOrCustom = [
    {value: "default", text: "Default"},
    {value: "custom", text: "Custom"}
];

const arrayNoneOrTextOrImage = [
    {value: "none", text: "None"},
    {value: "text", text: "Text"},
    {value: "image", text: "Image"}
];

const arrayQuestionSubNumberFormats = [
    {value: "none", text: "None"},
    {value: "a", text: "a"},
    {value: "a.", text: "a."},
    {value: "a)", text: "a)"},
    {value: "(a)", text: "(a)"},
    {value: "#a", text: "#a"},
    {value: "#a.", text: "#a."},
    {value: "#a)", text: "#a)"},
    {value: "#(a)", text: "#(a)"},
    {value: "A", text: "A"},
    {value: "A.", text: "A."},
    {value: "A)", text: "A)"},
    {value: "(A)", text: "(A)"},
    {value: "#A", text: "#A"},
    {value: "#A.", text: "#A."},
    {value: "#A)", text: "#A)"},
    {value: "#(A)", text: "#(A)"},
    {value: "#.#", text: "#.#"}
];

const tabLabels = {
    appHeader: ["HEADER (No)", "HEADER (Yes)"],
    startPage: ["START PAGE (No)", "START PAGE (Yes)"],
    mainPages: ["MAIN PAGES (Default)", "MAIN PAGES (Custom)"],
    closePage: ["CLOSE PAGE (No)", "CLOSE PAGE (Yes)"],
    system: ["SYSTEM (Default)", "SYSTEM (Custom)"],
}

const TITLE_TYPE_STATE_OBJ = 0;
const TITLE_TYPE_TEXT_ENTRY_TYPE = 1;
const TITLE_TYPE_METADATA_OBJ_TAG = 2;
const TITLE_TYPE_METADATA_IMAGE_TAG = 3;

const textEntryTypeAppHeader = TEMPLATE.TEXT_TYPE.QUESTIONNAIRE.APP_HEADER;
const textEntryTypePageHeader = TEMPLATE.TEXT_TYPE.QUESTIONNAIRE.APP_HEADER;
const textEntryTypePageFooter1 = TEMPLATE.TEXT_TYPE.QUESTIONNAIRE.APP_HEADER;
const textEntryTypePageFooter2 = TEMPLATE.TEXT_TYPE.QUESTIONNAIRE.APP_HEADER;

const appHeaderTag = portalShared.CUSTOM_QNR_APP_HEADER_OBJ;
const mainPagesTag = portalShared.CUSTOM_QNR_MAIN_PAGES_OBJ;

const titleType = {
    appHeaderLeft: ["appHeaderObj", textEntryTypeAppHeader, appHeaderTag, portalShared.HEADER_APPBAR_IMAGE_LEFT],
    appHeaderCenter: ["appHeaderObj", textEntryTypeAppHeader, appHeaderTag, portalShared.HEADER_APPBAR_IMAGE_CENTER],
    appHeaderRight: ["appHeaderObj", textEntryTypeAppHeader, appHeaderTag, portalShared.HEADER_APPBAR_IMAGE_RIGHT],
    pageHeaderLeft: ["mainPagesObj", textEntryTypePageHeader, mainPagesTag, portalShared.PAGE_HEADER_IMAGE_LEFT],
    pageHeaderCenter: ["mainPagesObj", textEntryTypePageHeader, mainPagesTag, portalShared.PAGE_HEADER_IMAGE_CENTER],
    pageHeaderRight: ["mainPagesObj", textEntryTypePageHeader, mainPagesTag, portalShared.PAGE_HEADER_IMAGE_RIGHT],
    pageFooter1Left: ["mainPagesObj", textEntryTypePageFooter1, mainPagesTag, portalShared.PAGE_FOOTER1_IMAGE_LEFT],
    pageFooter1Center: ["mainPagesObj", textEntryTypePageFooter1, mainPagesTag, portalShared.PAGE_FOOTER1_IMAGE_CENTER],
    pageFooter1Right: ["mainPagesObj", textEntryTypePageFooter1, mainPagesTag, portalShared.PAGE_FOOTER1_IMAGE_RIGHT],
    pageFooter2Left: ["mainPagesObj", textEntryTypePageFooter2, mainPagesTag, portalShared.PAGE_FOOTER2_IMAGE_LEFT],
    pageFooter2Center: ["mainPagesObj", textEntryTypePageFooter2, mainPagesTag, portalShared.PAGE_FOOTER2_IMAGE_CENTER],
    pageFooter2Right: ["mainPagesObj", textEntryTypePageFooter2, mainPagesTag, portalShared.PAGE_FOOTER2_IMAGE_RIGHT],
}

const QUESTION_DEPTH = 0;

// NOTE: LEGACY there used to be 3 types of questionnaires, SIMPLE, COMPLEX and DYNAMIC
// SIMPLE allowed questions to be added directly to a questionnaire without categories and groups
// DYNAMIC was never implemented
// COMPLEX is now the only supported format

class QuestionnaireEditor extends Component {

    constructor(props) {
        super(props);

        this.state = {
            expander: {},
            expandAll: true,
            isLoading: false,
            toolbarErrorText: "",
            isThisAnExistingCategory: "0",
            categoryTemplateSearch: "",
            openCategoryReview: false,
            showCategoryBackButton: true,
            selectedCategoryInfoKey: "",
            openGroupReview: false,
            showGroupBackButton: true,
            selectedGroupInfoKey: "",
            openQuestionReview: false,
            showQuestionBackButton: true,
            selectedQuestionInfoKey: "",
            openCategoryEditor: false,
            categoryTemplate: {},
            openGroupEditor: false,
            groupTemplate: {},
            openGroupEditorComplete: false,
            openQuestionEditor: false,
            questionTemplate: {},
            nameErrorText: " ",
            metadataSet: false,
            metadataErrorText: "",
            nameDisplay: metadata.getDefaultMetadataObj(),
            descDisplay: metadata.getDefaultMetadataObj(),
            portalTabTitle: "",
            keyMetricCategories: [],
            keyMetricCategory: 0,
            selectedTabNumber: 0,

            tab0: tabLabels.appHeader[0],
            appHeader: "no",
            appHeaderObj: null,

            tab1: tabLabels.startPage[0],
            startPage: "no",
            hideNameOnStartPage: false,
            startPageObj: null,
            startPageScript: "",
            startPageScriptDisplay: metadata.getDefaultMetadataObj(), // Scripts are stored as metadata and can contain TOKENS
            startPageText: portalShared.customQuestionnaireStartPageObj.defaultText,
            startPageTextDisplay: metadata.getDefaultMetadataObj(),

            tab2: tabLabels.mainPages[0],
            mainPages: "default",
            mainPagesObj: null,

            tab3: tabLabels.closePage[1], // Close page defaults to yes
            closePage: "yes",
            noGreenCheck: false,
            closePageObj: portalShared.getCustomQuestionnaireClosePageObj(),
            closePageScript: "",
            closePageScriptDisplay: metadata.getDefaultMetadataObj(), // Scripts are stored as metadata and can contain TOKENS
            closePageText: portalShared.customQuestionnaireClosePageObj.defaultText,
            closePageTextDisplay: metadata.getDefaultMetadataObj(),

            tab4: tabLabels.system[0],
            system: "default",
            systemObj: null,

            dummyDisplay: metadata.getDefaultMetadataObj(), // Needed for setState to easily wahp out when needed
        };

    }

    componentDidMount() {

        //let currURL = window.location.href;

        this.props.getMetadataDefinitions(metadataTemplateTypes.QuestionnaireTemplate);

        if (this.props.templateKey || this.props.templateId) {
            if (this.props.questionnaireTemplate[this.props.templateKey] &&
                (this.props.questionnaireTemplate[this.props.templateKey].id === this.props.templateId)) {

                this.props.initializeQuestionnaireEditorUpdate(this.props.questionnaireTemplate[this.props.templateKey]);
            }
            else if (this.props.templateKey) {
                this.setState({isLoading: true});
                this.props.getQuestionnaireTemplate(this.props.templateKey, this.props.templateId);
            }

            this.pageInit(this.props);
        }
        else {
            this.props.initializeQuestionnaireEditorCreate();
        }

        if (this.props.templateOriginal) {
            this.pageInit(this.props);
        }
    }

    componentWillReceiveProps(nextProps) {

        let tempKeyMetricCategories = [];
        if (!this.state.metadataSet) {
            if (this.state.keyMetricCategories.length === 0) {

                if (nextProps.metadataDefinition !== undefined) {

                    // Create the Key Metrics DDLB Items, add None as first entry
                    tempKeyMetricCategories.push("None");
                    for (let i = 0; i < nextProps.metadataDefinition.length; i++) {
                        if (nextProps.metadataDefinition[i].name === portalShared.KEY_METRIC_CATEGORY_QUESTIONNAIRE) {
                            for (let j = 0; j < nextProps.metadataDefinition[i].listValues.length; j++) {
                                tempKeyMetricCategories.push(nextProps.metadataDefinition[i].listValues[j]);
                            }
                        }
                    }
                    this.setState({keyMetricCategories: tempKeyMetricCategories});

                }
            }
            else {
                tempKeyMetricCategories = this.state.keyMetricCategories;
            }
        }

        // if templateOriginal is loaded and the templateKey matches the current templateKey and is different from the loaded template (check id here?)
        let isTemplateLoaded = (nextProps.templateOriginal && nextProps.templateOriginal.templateKey === nextProps.templateKey
            && nextProps.template.templateKey !== nextProps.templateKey);

        // The loaded template is stale and the correct template is loaded
        let editTemplateReady = nextProps.template.id !== nextProps.templateId &&
            (nextProps.questionnaireTemplate[nextProps.templateKey] && nextProps.questionnaireTemplate[nextProps.templateKey].id === nextProps.templateId);

        if (nextProps.cloneMode) {
            this.props.validateQuestionnaireNameAndQualifierUnique(nextProps.template.name, nextProps.template.nameQualifier);
        }

        if (isTemplateLoaded && editTemplateReady && (tempKeyMetricCategories.length > 0)) {

            this.props.initializeQuestionnaireEditorUpdate(nextProps.templateOriginal);

            this.pageInit(nextProps, tempKeyMetricCategories);

            return;
        }

        if (!this.state.metadataSet) {
            this.setState({expander: expander.presetExpander(nextProps.categoryKeys, this.state.expander), keyMetricCategories: tempKeyMetricCategories, isLoading: (isTemplateLoaded && editTemplateReady)});
        }

        this.checkForErrorsOnScreen(nextProps);

        nextProps.incrementPortalCount(); // For some reason questionnaires don't update Portal Preview the same, so FORCE issue!!!
    }

    componentWillUnmount() {
        this.props.expanderClear("QuestionnaireEditor");
        this.props.clearQuestionnaireEditor();
    }

    handleToolbarButtonClick = (event, fieldName, buttonTitle) => {
        switch (buttonTitle) {
            case "BACK":
                this.setState({openCategoryReview: false, openGroupReview: false, openQuestionReview: false});
                break;
            // no default
        }
    }

    // Allow child components to set errors on this page.
    setStateCallback = (stateObj, saveAfter = false, templateType) => {

        if ((templateType === TEMPLATE.TYPE.CATEGORY) && saveAfter) {
            this.setState(stateObj, () => this.categoryEditorSave());
        }
        else if ((templateType === TEMPLATE.TYPE.GROUP) && saveAfter) {
            this.setState(stateObj, () => this.groupEditorSave());
        }
        else if ((templateType === TEMPLATE.TYPE.QUESTION) && saveAfter) {
                this.setState(stateObj, () => this.questionEditorSave());
        } else {
            this.setState(stateObj);
        }
    }

    pageInit = (currProps, keyMetricCategories) => {

        let metadataObj = currProps.templateOriginal.metadata;

        let tempNameDisplay = metadata.setTextObjSelectedAndValueAndValueObj(metadataObj, portalShared.CUSTOM_DDO_NAME);
        let tempDescDisplay = metadata.setTextObjSelectedAndValueAndValueObj(metadataObj, portalShared.CUSTOM_DDO_DESC);

        // -------------------------------------------------------------------------------------------------------------
        // ---------- PORTAL TAB TITLE
        // -------------------------------------------------------------------------------------------------------------

        let tempPortalTabTitle = "";

        if (metadataObj.hasOwnProperty(portalShared.PORTAL_TAB_TITLE)) {
            tempPortalTabTitle = metadataObj[portalShared.PORTAL_TAB_TITLE];
        }

        // -------------------------------------------------------------------------------------------------------------
        // ---------- KEY METRIC CATEGORY AKA REPORTING CATEGORY
        // -------------------------------------------------------------------------------------------------------------

        let tempKeyMetricCategory = "0";

        // LEGACY was named REPORTING CATEGORY
        if (metadataObj.hasOwnProperty(portalShared.KEY_METRIC_CATEGORY_QUESTIONNAIRE)) {

            let tempKeyMetricCategoryText = metadataObj[portalShared.KEY_METRIC_CATEGORY_QUESTIONNAIRE];
            for (let idx = 0; idx < keyMetricCategories.length; idx++) {
                if (keyMetricCategories[idx] === tempKeyMetricCategoryText) {
                    tempKeyMetricCategory = idx;
                    break;
                }
            }
        }

        // -------------------------------------------------------------------------------------------------------------
        // ---------- APP BAR HEADER INIT
        // -------------------------------------------------------------------------------------------------------------

        let tempTab0 = tabLabels.appHeader[0];
        let tempAppHeader = "no";
        let tempAppHeaderObj = null;

        if (metadataObj.hasOwnProperty(portalShared.CUSTOM_QNR_APP_HEADER_OBJ)) {
            tempAppHeader = "yes";
            tempTab0 = tabLabels.appHeader[1];
            tempAppHeaderObj = JSON.parse(metadataObj[portalShared.CUSTOM_QNR_APP_HEADER_OBJ]); // PORTAL will never display appBar
        }

        // -------------------------------------------------------------------------------------------------------------
        // ---------- START PAGE INIT
        // -------------------------------------------------------------------------------------------------------------

        let tempTab1 = tabLabels.startPage[0];
        let tempStartPage = "no";
        let tempStartPageObj = metadata.setTextObjSelectedAndValueAndValueObj(metadataObj, portalShared.CUSTOM_QNR_START_PAGE_OBJ);
        let tempStartPageScript = "";
        let tempStartPageScriptDisplay = metadata.getDefaultMetadataObj();
        let tempStartPageText = portalShared.customQuestionnaireStartPageObj.defaultText;
        let tempStartPageTextDisplay = metadata.getDefaultMetadataObj();

        // LEGACY user-script is used as custom text on the Start Page
        let tempStartPageScriptDisplayObj = metadata.setTextObjSelectedAndValueAndValueObj(metadataObj, portalShared.QNR_START_PAGE_SCRIPT_FORMAT_OBJ);

        if (metadataObj.hasOwnProperty(portalShared.CUSTOM_QNR_START_PAGE_OBJ)) {
            tempStartPage = "yes";
            tempTab1 = tabLabels.startPage[1];

            let startPageObj = JSON.parse(metadataObj[portalShared.CUSTOM_QNR_START_PAGE_OBJ]);

            tempStartPageText = startPageObj.defaultText;

            tempStartPageTextDisplay.selected = startPageObj.defaultTextFormat;
            tempStartPageTextDisplay.metadataValue = JSON.stringify(startPageObj.defaultTextCustomObj);
            tempStartPageTextDisplay.metadataValueObj = startPageObj.defaultTextCustomObj;
        }

        if (metadataObj.hasOwnProperty(portalShared.QNR_START_PAGE_SCRIPT)) {
            tempStartPageScript = metadataObj[portalShared.QNR_START_PAGE_SCRIPT];
        }

        if (metadataObj.hasOwnProperty(portalShared.QNR_START_PAGE_SCRIPT_FORMAT_OBJ)) {
            tempStartPageScriptDisplay.selected = "custom";
            tempStartPageScriptDisplay.metadataValue = metadataObj[portalShared.QNR_START_PAGE_SCRIPT_FORMAT_OBJ];
            tempStartPageScriptDisplay.metadataValueObj = JSON.parse(tempStartPageScriptDisplay.metadataValue);
        }

        // -------------------------------------------------------------------------------------------------------------
        // ---------- MAIN PAGES INIT
        // -------------------------------------------------------------------------------------------------------------

        let tempTab2 = tabLabels.mainPages[0];
        let tempMainPages = "default";
        let tempMainPagesObj = null;

        if (metadataObj.hasOwnProperty(portalShared.CUSTOM_QNR_MAIN_PAGES_OBJ)) {
            tempMainPages = "custom";
            tempTab2 = tabLabels.mainPages[1];
            tempMainPagesObj = JSON.parse(metadataObj[portalShared.CUSTOM_QNR_MAIN_PAGES_OBJ]);
        }

        // -------------------------------------------------------------------------------------------------------------
        // ---------- CLOSE PAGE INIT
        // -------------------------------------------------------------------------------------------------------------

        let tempTab3 = tabLabels.closePage[0];
        let tempClosePage = "no";
        let tempClosePageObj = metadata.setTextObjSelectedAndValueAndValueObj(metadataObj, portalShared.CUSTOM_QNR_CLOSE_PAGE_OBJ);
        let tempClosePageScript = "";
        let tempClosePageScriptDisplay = metadata.getDefaultMetadataObj();
        let tempClosePageText = portalShared.customQuestionnaireClosePageObj.defaultText;
        let tempClosePageTextDisplay = metadata.getDefaultMetadataObj();

        if (metadataObj.hasOwnProperty(portalShared.CUSTOM_QNR_CLOSE_PAGE_OBJ)) {
            tempClosePage = "yes";
            tempTab3 = tabLabels.closePage[1];

            let closePageObj = JSON.parse(metadataObj[portalShared.CUSTOM_QNR_CLOSE_PAGE_OBJ]);

            tempClosePageText = closePageObj.defaultText;

            tempClosePageTextDisplay.selected = closePageObj.defaultTextFormat;
            tempClosePageTextDisplay.metadataValue = JSON.stringify(closePageObj.defaultTextCustomObj);
            tempClosePageTextDisplay.metadataValueObj = closePageObj.defaultTextCustomObj;
        }

        // LEGACY user-script-bottom is used as custom text on the Close Page
        if (metadataObj.hasOwnProperty(portalShared.QNR_CLOSE_PAGE_SCRIPT)) {
            tempClosePageScript = metadataObj[portalShared.QNR_CLOSE_PAGE_SCRIPT];
        }

        // -------------------------------------------------------------------------------------------------------------
        // ---------- SYSTEM INIT
        // -------------------------------------------------------------------------------------------------------------

        let tempTab4 = tabLabels.system[0];
        let tempSystem = "default";
        let tempSystemObj = null;

        if (metadataObj.hasOwnProperty(portalShared.CUSTOM_QNR_SYSTEM_OBJ)) {
            tempSystem = "custom";
            tempTab4 = tabLabels.system[1];
            tempSystemObj = JSON.parse(metadataObj[portalShared.CUSTOM_QNR_SYSTEM_OBJ]);
        }

        // -------------------------------------------------------------------------------------------------------------
        // ---------- SET STATE
        // -------------------------------------------------------------------------------------------------------------

        this.setState({
            nameDisplay: tempNameDisplay,
            descDisplay: tempDescDisplay,
            portalTabTitle: tempPortalTabTitle,
            keyMetricCategory: tempKeyMetricCategory,

            tab0: tempTab0,
            appHeader: tempAppHeader,
            appHeaderObj: tempAppHeaderObj,

            tab1: tempTab1,
            startPage: tempStartPage,
            startPageObj: tempStartPageObj,
            startPageScript: tempStartPageScript,
            startPageScriptDisplay: tempStartPageScriptDisplay,
            startPageText: tempStartPageText,
            startPageTextDisplay: tempStartPageTextDisplay,

            tab2: tempTab2,
            mainPages: tempMainPages,
            mainPagesObj: tempMainPagesObj,

            tab3: tempTab3,
            closePage: tempClosePage,
            closePageObj: tempClosePageObj,
            closePageScript: tempClosePageScript,
            closePageScriptDisplay: tempClosePageScriptDisplay,
            closePageText: tempClosePageText,
            closePageTextDisplay: tempClosePageTextDisplay,

            tab4: tempTab4,
            system: tempSystem,
            systemObj: tempSystemObj,
        });

    };


    checkForErrorsOnScreen = (props) => {
        let nameErrorText = validate.templateNameAndQualifierUnique(props, "Questionnaire Name");
        let metadataErrorText = this.state.metadataErrorText;
        let toolbarErrorText = "";

        if (nameErrorText !== " ") {
            toolbarErrorText = nameErrorText;
        }

        if (metadataErrorText !== "") {
            toolbarErrorText = toolbarErrorText === "" ? this.state.metadataErrorText : toolbarErrorText + ",  " + this.state.metadataErrorText;
        }

        this.setState({nameErrorText: nameErrorText, metadataErrorText: metadataErrorText, toolbarErrorText: toolbarErrorText});
    };


    onTextChangePortalTabTitle = (event) => {
        this.setState({"portalTabTitle": event.target.value}, () => this.handleChangeMetadataValue(portalShared.PORTAL_TAB_TITLE, event.target.value));
    }

    onSelectChangeKeyMetricCategory = (event, fieldName) => {
        let index = event.target.value;
        let selected = this.state.keyMetricCategories[index];  // Note: "None: has been added to the metadata list this.state.keyMetricCategories, so metadata needs to adjust

        this.setState({[fieldName]: index}, () => this.handleChangeMetadataValue(portalShared.KEY_METRIC_CATEGORY_QUESTIONNAIRE, selected));
    };


    handleChangeMetadataValue = (fieldName, value, removeMetadataTab = null) => {

        let updatedTemplate = {...this.props.template};  // Copy template
        let metadata = {...updatedTemplate.metadata};  // Copy metadata from template
        metadata[fieldName] = value; // Assign new value to metadata

        // In some cases when a value changes a related metadata entry needs to be removed
        // If an array of values is passed then remove all related metadata entries
        if (removeMetadataTab !== null) {
            if (Array.isArray(removeMetadataTab)) {
                for (let i = 0; i < removeMetadataTab.length; i++) {
                    if (metadata.hasOwnProperty(removeMetadataTab[i])) {
                        delete metadata[removeMetadataTab[i]];
                    }
                }
            }
            else if (metadata.hasOwnProperty(removeMetadataTab)) {
                delete metadata[removeMetadataTab];
            }
        }

        updatedTemplate.metadata = metadata; // Update template with new metadata
        this.props.updateQuestionnaireTemplate(updatedTemplate); // Update questionnaire with new metadata
    };

    handleMetadataChange = (metadata) => {
        let updatedTemplate = {...this.props.template, metadata: metadata};
        this.checkForErrorsOnScreen(this.props);
        this.props.updateQuestionnaireTemplate(updatedTemplate);
    };

    setQuestionnaireCustomMetadata = (fieldNameObj, fieldName, selected, metadataTag, metadataValueObj, deleteAdditionalTagsArray = []) => {

        let updatedTemplate = {...this.props.template};
        let tempMetadata = {...updatedTemplate.metadata};

        let metadataValueStr = (typeof metadataValueObj === "string") ? metadataValueObj : JSON.stringify(metadataValueObj);


        if ((selected === "default") || (selected === "no") || (selected === "None")) {
            if (tempMetadata.hasOwnProperty(metadataTag)) {
                delete tempMetadata[metadataTag];
            }
        } else {
            tempMetadata[metadataTag] = metadataValueStr;
        }

        for (let i = 0; i < deleteAdditionalTagsArray.length; i++) {
            delete tempMetadata[deleteAdditionalTagsArray[i]];
        }

        let metadataSaveName = metadataTag;
        let metadataSaveObjName = "";
        let setStateFlag = true;
        switch(fieldName) {
            case "keyMetricCategory":
                setStateFlag = false;
                break;
            // no default
        }

        this.handleMetadataChange(tempMetadata);
    };


    setCustomMetadataLayered = (template, updateTemplateFunction, setStateCallbackFunction, fieldName, selected, metadataTag, metadataValueObj, depth) => {

        let updatedTemplate = {...this.props.template};
        let tempMetadata = {...updatedTemplate.metadata};

        let tempValueObj = JSON.parse(tempMetadata[metadataTag]);
        tempValueObj[fieldName] = metadataValueObj;
        tempMetadata[metadataTag] = JSON.stringify(tempValueObj);
        //let tempName = "";

        // switch(fieldName) {
        //     case "textLeftCustomObj":
        //     case "textCenterCustomObj":
        //     case "textRightCustomObj":
        //
        //         // tempName = "appHeaderObj." + fieldName;
        //         break;
        //
        //     case "pageHeaderLeftTextObj":
        //     case "pageHeaderCenterTextObj":
        //     case "pageHeaderRightTextObj":
        //     case "pageFooter1LeftTextObj":
        //     case "pageFooter1CenterTextObj":
        //     case "pageFooter1RightTextObj":
        //     case "pageFooter2LeftTextObj":
        //     case "pageFooter2CenterTextObj":
        //     case "pageFooter2RightTextObj":
        //
        //         // tempName = "mainPagesObj." + fieldName;
        //         break;
        //
        //     // no default
        // }

        this.setState({tempName: metadataValueObj});

        this.handleMetadataChange(tempMetadata);
    };

    setImageMetadata = (metadataTag, metadataValueStr) => {

        let updatedTemplate = {...this.props.template};
        let tempMetadata = {...updatedTemplate.metadata};


        tempMetadata[metadataTag] = metadataValueStr;


        this.handleMetadataChange(tempMetadata);
    };

    deleteMetadata = (metadataTag) => {

        let updatedTemplate = {...this.props.template};
        let tempMetadata = {...updatedTemplate.metadata};

        if (tempMetadata.hasOwnProperty(metadataTag)) {
            delete tempMetadata[metadataTag];
        }

        this.handleMetadataChange(tempMetadata);
    };



    setMetadataErrorText = (metadataErrorText) => {
        this.setState({metadataErrorText: metadataErrorText, metadataSet: false}, () => this.checkForErrorsOnScreen(this.props));
    }

    metadataChanged = (updatedTemplate, depth) => {
        // Note: Timing issues was causing componentWillReceiveProps to trigger and a setState was canceling another setState
        this.setState({metadataSet: true}, () => this.props.updateQuestionnaireTemplate(updatedTemplate, depth));
    }

    onTextChange = (event, fieldName) => {
        let value = event.target.value;

        if ((fieldName === "nameQualifier") && (value==="")) {
            value = null;
        }

        let updatedTemplate = {...this.props.template};
        updatedTemplate[fieldName] = value;

        this.props.updateQuestionnaireTemplate(updatedTemplate);

        if (fieldName === "name" || fieldName === "nameQualifier")
        {
            this.props.validateQuestionnaireNameAndQualifierUnique(updatedTemplate.name, updatedTemplate.nameQualifier);
        }
    };


    onChangeArgoAutocompleteHook = (event, fieldName, index, validationType) => {

        let value = event.target.value;

        switch(fieldName) {
            case "name":
            case "description":

                let updatedTemplate = {...this.props.template};

                updatedTemplate[fieldName] = value;

                this.checkForErrorsOnScreen(this.props);

                this.props.updateQuestionnaireTemplate(updatedTemplate);

                if (this.props.cloneMode) {
                    this.props.checkNameQualifier(updatedTemplate.nameQualifier, updatedTemplate.id);
                }
                if (fieldName === "name")
                {
                    this.props.validateQuestionnaireNameAndQualifierUnique(updatedTemplate.name, updatedTemplate.nameQualifier);
                }
                else {
                    this.props.checkQuestionnaireValue({name: updatedTemplate.name, templateKey: updatedTemplate.templateKey});
                }

                break;
            case portalShared.QNR_START_PAGE_SCRIPT:
            case portalShared.QNR_CLOSE_PAGE_SCRIPT: // Just added this 08/06/2024
                // NOTE: These are LEGACY text fields designed for Conversation Preview and The Foundation Application
                // The Foundation Application was the predecessor to Portal Application for displaying surveys
                // However it could only display one question per page
                // Conversation Preview was a module in Admin to simulate The Foundation App displaying a survey
                // "user-script" was text displayed on the start page
                // "user-script-bottom" was text displayed on the close page
                // These fields are stored in metadata user-script is before
                this.handleChangeMetadataValue(fieldName, value);
                break;
            // no default
        }

    }


    onSelectChange = (event, fieldName, menuItemText) => {

        let selected = event.target.value;
        let relatedTempObj = null;
        let tempFieldNameObj = fieldName + "Obj";

        let tempTab0 = this.state.tab0;
        let tempTab1 = this.state.tab1;
        let tempTab2 = this.state.tab2;
        let tempTab3 = this.state.tab3;
        let tempTab4 = this.state.tab4;

        let tempMetadataField = "";
        let tempMetadataName = "";
        let relatedMetadataTagsToDelete = [];

        let tempDisplayObj = metadata.getDefaultMetadataObj();
        let tempDisplayField = "dummyDisplay";

        switch(fieldName) {

            case "appHeader":

                tempTab0 = tabLabels.appHeader[selected === "no" ? 0 : 1];
                tempMetadataField = "appHeader";
                tempMetadataName = portalShared.CUSTOM_QNR_APP_HEADER_OBJ;
                relatedTempObj = (selected === "no") ? null : portalShared.getCustomQuestionnaireAppHeaderObj();
                if (selected === "no") {
                    relatedMetadataTagsToDelete = [portalShared.HEADER_APPBAR_IMAGE_LEFT, portalShared.HEADER_APPBAR_IMAGE_CENTER, portalShared.HEADER_APPBAR_IMAGE_RIGHT];
                }
                break;

            case "startPage":

                tempMetadataName = portalShared.CUSTOM_QNR_START_PAGE_OBJ;

                if (selected === "no") {
                    tempTab1 = tabLabels.startPage[0];
                    relatedTempObj = null;
                    relatedMetadataTagsToDelete = [];
                    relatedMetadataTagsToDelete.push(portalShared.QNR_START_PAGE_SCRIPT);
                    relatedMetadataTagsToDelete.push(portalShared.QNR_START_PAGE_SCRIPT_FORMAT_OBJ);
                    tempDisplayField = "startPageScriptDisplay"; // Reset Portal Display back to default
                } else {
                    tempTab1 = tabLabels.startPage[1];
                    relatedTempObj = portalShared.getCustomQuestionnaireStartPageObj();
                }
                break;

            case "mainPages":

                tempTab2 = tabLabels.mainPages[selected === "default" ? 0 : 1];
                tempMetadataField = "mainPages";
                tempMetadataName = portalShared.CUSTOM_QNR_MAIN_PAGES_OBJ;
                relatedTempObj = (selected === "default") ? null : portalShared.getCustomQuestionnaireMainPagesObj();
                break;

            case "closePage":

                tempMetadataName = portalShared.CUSTOM_QNR_CLOSE_PAGE_OBJ;

                if (selected === "no") {
                    tempTab1 = tabLabels.closePage[0];
                    relatedTempObj = null;
                    relatedMetadataTagsToDelete = [];
                    relatedMetadataTagsToDelete.push(portalShared.QNR_CLOSE_PAGE_SCRIPT);
                    relatedMetadataTagsToDelete.push(portalShared.QNR_CLOSE_PAGE_SCRIPT_FORMAT_OBJ);
                    tempDisplayField = "closePageScriptDisplay"; // Reset Portal Display back to default
                } else {
                    tempTab1 = tabLabels.closePage[1];
                    relatedTempObj = portalShared.getCustomQuestionnaireClosePageObj();
                }
                break;

            case "system":

                tempTab4 = tabLabels.system[selected === "default" ? 0 : 1];
                tempMetadataField = "system";
                tempMetadataName = portalShared.CUSTOM_QNR_SYSTEM_OBJ;
                relatedTempObj = (selected === "default") ? null : portalShared.getCustomQuestionnaireSystemObj();
                break;
            // no default
        }

        this.setState({
            tab0: tempTab0,
            tab1: tempTab1,
            tab2: tempTab2,
            tab3: tempTab3,
            tab4: tempTab4,
            [fieldName]: selected,
            [tempDisplayField]: tempDisplayObj,
            [tempFieldNameObj]: relatedTempObj
        }, () => this.setQuestionnaireCustomMetadata(tempFieldNameObj, fieldName, selected, tempMetadataName, relatedTempObj, relatedMetadataTagsToDelete));

    };

    // #################################################################################################################
    // -----------------------------------------------------------------------------------------------------------------
    // APP TITLE BAR - LEFT, CENTER & RIGHT - HEADER FUNCTIONS
    // -----------------------------------------------------------------------------------------------------------------
    // #################################################################################################################

    onSelectChangeTitle = (event, fieldName, menuItemText) => {

        let selected = event.target.value;

        //let adjustedFieldName = fieldName.includes("Type") ? fieldName.replace("Type","") : fieldName;

        let tempMetadataObj = null;
        let stateObjName = titleType[fieldName][TITLE_TYPE_STATE_OBJ];
        let textEntryType = titleType[fieldName][TITLE_TYPE_TEXT_ENTRY_TYPE];
        let metadataObjTag = titleType[fieldName][TITLE_TYPE_METADATA_OBJ_TAG];
        let metadataImageTag = titleType[fieldName][TITLE_TYPE_METADATA_IMAGE_TAG];
        let textObjName = fieldName + "TextObj";
        let removeMetadataTag = null;

        if (selected !== "image") {
            removeMetadataTag = metadataImageTag;
        }

        tempMetadataObj = portalShared.getMetadataObj(this.props.template.metadata, metadataObjTag);
        tempMetadataObj[fieldName + "Type"] = selected;
        if (selected === "text") {
            tempMetadataObj[textObjName] = portalShared.getCustomTextObj(TEMPLATE.TYPE.QUESTIONNAIRE, textEntryType);
        } else {
            tempMetadataObj[textObjName] = null; // none selected so remove custom text object
        }

        this.setState({[stateObjName]: tempMetadataObj}, () => this.handleChangeMetadataValue(metadataObjTag, JSON.stringify(tempMetadataObj), removeMetadataTag));
    }

    onTextChangeAppHeaderTitle = (event, fieldName) => {
        let tempMetadataObj = null;
        let stateObjName = titleType[fieldName][TITLE_TYPE_STATE_OBJ];
        let metadataObjTag = titleType[fieldName][TITLE_TYPE_METADATA_OBJ_TAG];

        tempMetadataObj = portalShared.getMetadataObj(this.props.template.metadata, metadataObjTag);
        tempMetadataObj[fieldName + "TextObj"].title = event.target.value;

        this.setState({[stateObjName]: tempMetadataObj}, () => this.handleChangeMetadataValue(metadataObjTag, JSON.stringify(tempMetadataObj)));
    };

    onSelectChangeTitleTextFormat = (event, fieldName, menuItemText) => {
        let tempMetadataObj = null;
        let stateObjName = titleType[fieldName][TITLE_TYPE_STATE_OBJ];
        let metadataObjTag = titleType[fieldName][TITLE_TYPE_METADATA_OBJ_TAG];

        tempMetadataObj = portalShared.getMetadataObj(this.props.template.metadata, metadataObjTag);
        tempMetadataObj[fieldName + "TextObj"].format = event.target.value;

        this.setState({[stateObjName]: tempMetadataObj}, () => this.handleChangeMetadataValue(metadataObjTag, JSON.stringify(tempMetadataObj)));
    }

    updateCustomTitleObject = (fieldNameObj, fieldName, selected, metadataTag, metadataValueObj) => {

        let updatedTemplate = {...this.props.template};
        let tempMetadata = {...updatedTemplate.metadata};

        let tempMetadataObj = JSON.parse(tempMetadata[metadataTag]);

        tempMetadataObj[fieldName] = metadataValueObj;

        this.setState({[fieldNameObj]: tempMetadataObj});

        this.handleChangeMetadataValue(metadataTag, JSON.stringify(tempMetadataObj));
    };

    // -----------------------------------------------------------------------------------------------------------------
    // SCRIPT FUNCTIONS
    // -----------------------------------------------------------------------------------------------------------------

    onClosePageScriptChange = (event, fieldName) => {
        let value = event.target.value;
        this.setState({closePageScript: value}, () => this.handleChangeMetadataValue(fieldName, value, value === "" ? fieldName : null));
    }

    onChangeObj = (event, fieldName) => {

        let obj = null;
        let tagName = "";

        if (fieldName.startsWith("startPageObj")) {

            let obj = JSON.parse(this.props.template.metadata[portalShared.CUSTOM_QNR_START_PAGE_OBJ]);
            let tagName = portalShared.CUSTOM_QNR_START_PAGE_OBJ;

            switch(fieldName) {
                case "startPageObj.text":
                    obj.text = this.event.value;
                    break;
                case "startPageObj.textFormat":
                    obj.textFormat = this.event.value;
                    break;
            }
        }

    }

    onChangeMainPagesObj = (event, fieldName) => {

        let tempStateObj = portalShared.cloneObj(this.state.mainPagesObj);

        tempStateObj[fieldName] = event.target.value;

        this.setState({mainPagesObj: tempStateObj});

        let updatedTemplate = portalShared.cloneObj(this.props.template); // Prevent mutation errors

        updatedTemplate.metadata[portalShared.CUSTOM_QNR_MAIN_PAGES_OBJ] = JSON.stringify(tempStateObj);

        this.props.updateQuestionnaireTemplate(updatedTemplate);
    };

    // -----------------------------------------------------------------------------------------------------------------
    // IMAGE UPLOAD FROM ArgoMetadataEditor  ---------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    handleLogoUploadOnChange = (e, metadataTag) => {
        console.log("file to upload:", e.target.files[0]);
        let file = e.target.files[0];

        console.log("file type:", typeof file);

        if (file) {
            const reader = new FileReader();
            reader.onload = this._handleReaderLoader.bind(this, metadataTag);

            reader.readAsBinaryString(file);
        }

    };

    _handleReaderLoader = (metadataTag, readerEvt) => {
        let binaryString = readerEvt.target.result;

        let base64String = btoa(binaryString);

        this.setState({
            base64TextString: base64String
        });

        this.setImageMetadata(metadataTag, base64String);
        //this.handleOnChange("value", this.state.currentEntryIndex, null, base64String);
    };

    onChangeIsThisAnExistingCategory = (event, fieldName, index) => {

        let value = event.target.value;
        let newState = {
            isThisAnExistingCategory: value,
            categoryTemplateSearch: (value)? this.state.categoryTemplateSearch: ""
        };

        this.setState(newState);
    }

    handleTabChange = (event, selectedTabNumber) => {
        this.setState({ selectedTabNumber: selectedTabNumber });
    };

    getTab0_Header(propsObj) {

        return (
            <div id="QuestionnaireEditor-HeaderTab-container-div" style={{paddingLeft: "20px", paddingBottom: "20px"}}>

                <div>&nbsp;</div>

                <ArgoTextFieldSelectHook
                    fileName="QuestionnaireEditor"
                    fieldName="appHeader"
                    label="Application Header"
                    value={this.state.appHeader}
                    onChange={this.onSelectChange}
                    width="170px"
                    menuItems={arrayNoOrYes}
                />

                <div style={{width: "20px"}}></div>

                {this.state.appHeader === "yes" ?
                    <React.Fragment>
                        <div>
                            {this.getTitleDiv("Left", "appHeaderLeft", propsObj)}
                        </div>
                        <div>
                            {this.getTitleDiv("Center", "appHeaderCenter", propsObj)}
                        </div>
                        <div>
                            {this.getTitleDiv("Right", "appHeaderRight", propsObj)}
                        </div>
                    </React.Fragment> : ""
                }

            </div>
        );
    }

    getTitleDiv(titleLabel, titlePrefix, propsObj) {

        let tempTitleType = titlePrefix + "Type";
        let tempTitleTextObj = titlePrefix + "TextObj";

        let stateObjName = titleType[titlePrefix][TITLE_TYPE_STATE_OBJ];
        let metadataObjTag = titleType[titlePrefix][TITLE_TYPE_METADATA_OBJ_TAG];
        let metadataImageTag = titleType[titlePrefix][TITLE_TYPE_METADATA_IMAGE_TAG];

        let image = null;

        let metadata = {...this.props.template.metadata};
        if (metadata.hasOwnProperty(metadataImageTag)) {
            image = metadata[metadataImageTag];
        }

        return (
            <div id={"Qnr-Title-" + titlePrefix} style={{display: "flex", alignItems: "left", width: '99%', backgroundColor: 'white'}}>
                <div style={{width: "20px"}}></div>

                <div style={{padding: '10px 10px 10px 0'}}>
                    <ArgoTextFieldSelectHook
                        fileName="QuestionnaireEditor"
                        fieldName={titlePrefix}
                        label={titleLabel}
                        value={this.state[stateObjName][tempTitleType]}
                        onChange={this.onSelectChangeTitle}
                        width="80px"
                        menuItems={arrayNoneOrTextOrImage}
                    /></div>

                <div style={{width: "20px"}}></div>

                {this.state[stateObjName][tempTitleType] === "none" ?
                    <div style={{
                        paddingTop: "20px",
                        display: "flex",
                        alignItems: "left",
                        width: '99%',
                        backgroundColor: 'white'
                    }}>
                    </div> : ""
                }

                {this.state[stateObjName][tempTitleType] === "image" ?
                    <div style={{
                        paddingTop: "30px",
                        display: "flex",
                        alignItems: "left",
                        width: '99%',
                        backgroundColor: 'white'
                    }}>

                        <form onChange={(e) => this.handleLogoUploadOnChange(e, metadataImageTag)}>
                            <input
                                type="file"
                                name="image"
                                id="file"
                                accept=".jpeg, .png, .jpg"
                            />
                        </form>
                        {image === null ? "" :
                            <img src={`data:image/png;base64,${image}`}
                                 alt="" style={{
                                verticalAlign: 'middle',
                                height: '60px'
                            }}/>
                        }

                    </div> : ""
                }

                {this.state[stateObjName][tempTitleType] === "text" ?
                    <div style={{
                        display: "flex",
                        alignItems: "left",
                        width: '99%',
                        backgroundColor: 'white',
                        paddingTop: '10px'
                    }}>

                        <ArgoTextFieldHook
                            fileName="QuestionnaireEditor"
                            fieldName={titlePrefix}
                            label={titleLabel + " Text"}
                            value={this.state[stateObjName][tempTitleTextObj].title}
                            onChange={this.onTextChangeAppHeaderTitle}
                            width="200px"
                            maxLength={40}
                        />

                        <div style={{width: "15px"}}></div>

                        <ArgoTextFieldSelectHook
                            fileName="QuestionnaireEditor"
                            fieldName={titlePrefix}
                            label="Format"
                            value={this.state[stateObjName][tempTitleTextObj].format}
                            onChange={this.onSelectChangeTitleTextFormat}
                            width="90px"
                            menuItems={arrayDefaultOrCustom}
                        />

                        <div style={{width: '99%'}}>
                            {(this.state[stateObjName][tempTitleTextObj].format === 'custom') ?
                                <ArgoTextDisplayCustom
                                    fieldName={tempTitleTextObj}
                                    fieldTextObjName={stateObjName}
                                    initToObj={this.state[stateObjName][tempTitleTextObj]}
                                    metadataTag={metadataObjTag}
                                    showDisplay={false}
                                    backgroundColorOverride={"white"}
                                    metadataPropsObj={Object.assign({metadataTag: metadataObjTag}, propsObj)}
                                    useMetadataPropsObjAltFunction={true}
                                    largeHexEntry={false}
                                    paddingTop={'0'}
                                    setCustomMetadata={this.updateCustomTitleObject}
                                />
                                : ""
                            }
                        </div>
                    </div> : ""
                }
            </div>
        );
    }

    // -----------------------------------------------------------------------------------------------------------------
    // START PAGE SECTION & CLOSE PAGE SECTION
    // -----------------------------------------------------------------------------------------------------------------

    onCheckboxChange = (event, fieldName) => {

        let updatedTemplate = {...this.props.template};  // Copy template
        let metadata = {...updatedTemplate.metadata};  // Copy metadata from template

        let metadataObj = JSON.parse(metadata[portalShared.CUSTOM_QNR_START_PAGE_OBJ]);

        metadataObj.hideName = event.target.checked;

        metadata[portalShared.CUSTOM_QNR_START_PAGE_OBJ] = JSON.stringify(metadataObj);

        updatedTemplate.metadata = metadata; // Update template with new metadata
        this.props.updateQuestionnaireTemplate(updatedTemplate); // Update questionnaire with new metadata

        this.setState({hideNameOnStartPage: event.target.checked});
    };

    onStartOrClosePageScriptChange = (event, fieldName) => {

        let stateFieldName = (fieldName === portalShared.QNR_START_PAGE_SCRIPT) ? "startPageScript" : "closePageScript";

        let value = event.target.value;
        this.setState({[stateFieldName]: value}, () => this.handleChangeMetadataValue(fieldName, value, value === "" ? fieldName : null));
    }

    setStateStartOrClosePage = (stateObj) => {

        let prefix = Object.keys(stateObj)[0].substring(0,9); // Will get "startPage or closePage

        let metadataTag = (prefix === "startPage") ? portalShared.CUSTOM_QNR_START_PAGE_OBJ : portalShared.CUSTOM_QNR_CLOSE_PAGE_OBJ;

        if (stateObj.hasOwnProperty(prefix + "ScriptDisplay")) {
            this.setState({[prefix + "ScriptDisplay"]: stateObj[prefix + "ScriptDisplay"]});
        } else if (stateObj.hasOwnProperty(prefix + "TextDisplay")) {

            let updatedTemplate = {...this.props.template};  // Copy template
            let metadata = {...updatedTemplate.metadata};  // Copy metadata from template

            let metadataObj = JSON.parse(metadata[metadataTag]);

            if (stateObj[prefix + "TextDisplay"].selected === "custom") {
                metadataObj.defaultTextFormat = "custom";
                metadataObj.defaultTextCustomObj = stateObj[prefix + "TextDisplay"].metadataValueObj;
            } else {
                metadataObj.defaultTextFormat = "default";
                metadataObj.defaultTextCustomObj = null;
            }

            metadata[metadataTag] = JSON.stringify(metadataObj);

            this.setState({[prefix + "TextDisplay"]: stateObj[prefix + "TextDisplay"]});

            updatedTemplate.metadata = metadata; // Update template with new metadata
            this.props.updateQuestionnaireTemplate(updatedTemplate); // Update questionnaire with new metadata
        }
    }

    onTextChangeStartOrClosePageDefaultText = (event, fieldName) => {

        let metadataTag = (fieldName === "startPageText") ? portalShared.CUSTOM_QNR_START_PAGE_OBJ : portalShared.CUSTOM_QNR_CLOSE_PAGE_OBJ;

        let updatedTemplate = portalShared.cloneObj(this.props.template);  // Clone template
        let metadata = {...updatedTemplate.metadata};  // Copy metadata from template

        let metadataObj = JSON.parse(metadata[metadataTag]);
        metadataObj.defaultText = event.target.value;

        metadata[metadataTag] = JSON.stringify(metadataObj);

        updatedTemplate.metadata = metadata; // Update template with new metadata
        this.props.updateQuestionnaireTemplate(updatedTemplate); // Update questionnaire with new metadata

        this.setState({[fieldName]: event.target.value});
    }

    getTab_StartOrClosePage(tabStr, pageType, propsObj) {

        let label = "Start"
        let pageMetadataTag = portalShared.CUSTOM_QNR_START_PAGE_OBJ;
        let scriptType = TEMPLATE.TEXT_TYPE.QUESTIONNAIRE.START_PAGE_SCRIPT
        let scriptMetadataTag = portalShared.QNR_START_PAGE_SCRIPT;
        let scriptMetadataFormatTag = portalShared.QNR_START_PAGE_SCRIPT_FORMAT_OBJ;
        let textType = TEMPLATE.TEXT_TYPE.QUESTIONNAIRE.START_PAGE_TEXT;

        if (pageType === "closePage") {
            label = "Close"
            pageMetadataTag = portalShared.CUSTOM_QNR_CLOSE_PAGE_OBJ;
            scriptType = TEMPLATE.TEXT_TYPE.QUESTIONNAIRE.CLOSE_PAGE_SCRIPT
            scriptMetadataTag = portalShared.QNR_CLOSE_PAGE_SCRIPT;
            scriptMetadataFormatTag = portalShared.QNR_CLOSE_PAGE_SCRIPT_FORMAT_OBJ;
            textType = TEMPLATE.TEXT_TYPE.QUESTIONNAIRE.CLOSE_PAGE_TEXT;
        }

        propsObj.onTextChange = this.onStartOrClosePageScriptChange;
        propsObj.setStateCallbackFunction = this.setStateStartOrClosePage;

        let metadataLoaded = this.props.template.hasOwnProperty("metadata");

        return (
            <div id={"QuestionnaireEditor-" + pageType + "Tab-container-div"}
                 style={{paddingLeft: "20px", paddingBottom: "20px"}}>

                <div>&nbsp;</div>

                <div style={{display: "flex", alignItems: "left", width: '99%'}}>

                    <ArgoTextFieldSelectHook
                        fileName="QuestionnaireEditor"
                        fieldName={pageType}
                        label={label + " Page"}
                        value={this.state[pageType]}
                        onChange={this.onSelectChange}
                        width="170px"
                        menuItems={arrayNoOrYes}
                    />

                </div>

                {(this.state[pageType] === "yes") && metadataLoaded ?
                    <React.Fragment>
                        <div style={{width: '95%', padding: "20px"}}>

                            {pageType === "startPage" ?
                                <div style={{
                                    display: "flex",
                                    alignItems: "left",
                                    backgroundColor: 'white',
                                    paddingLeft: "20px"
                                }}>
                                    <ArgoCheckboxHook
                                        fileName="QuestionnaireEditor"
                                        fieldName="hideNameOnStartPage"
                                        label="Do not display questionnaire name"
                                        onChange={this.onCheckboxChange}
                                        checked={this.state.hideNameOnStartPage}
                                        width="300px"
                                    />
                                </div>
                                :
                                <div style={{
                                    display: "flex",
                                    alignItems: "left",
                                    backgroundColor: 'white',
                                    paddingLeft: "20px"
                                }}>
                                    <ArgoCheckboxHook
                                        fileName="QuestionnaireEditor"
                                        fieldName="noGreenCheck"
                                        label="No green check mark"
                                        onChange={this.onCheckboxChange}
                                        checked={this.state.noGreenCheck}
                                        width="300px"
                                    />
                                </div>
                            }

                            {pageType === "startPage" ? <div style={{height: "20px"}}>&nbsp;</div> : ""}

                            <div style={{display: "flex", alignItems: "left", backgroundColor: 'white'}}>

                                <div style={{width: "20px"}}></div>

                                <div style={{display: "flex", alignItems: "left", width: '99%', padding: '10px 0'}}>

                                    <ArgoTextEntryWithCustomOverride
                                        templateType={TEMPLATE.TYPE.QUESTIONNAIRE}
                                        textEntryType={scriptType}
                                        fieldName={scriptMetadataTag}
                                        fieldTextObjName={pageType + "ScriptDisplay"}
                                        label={label + " Page Script"}
                                        labelCustom={"Display (" + label + " Script)"}
                                        value={this.props.template.metadata[scriptMetadataTag]}
                                        maxlength={500}
                                        hideOption={false}
                                        initTo={this.state[pageType + "ScriptDisplay"].selected}
                                        initToObj={this.state[pageType + "ScriptDisplay"].metadataValueObj}
                                        propsObj={Object.assign({metadataTag: scriptMetadataFormatTag}, propsObj)}
                                    />

                                </div>
                            </div>

                        </div>

                        <div style={{width: '95%', padding: "20px"}}>

                            <div style={{display: "flex", alignItems: "left", backgroundColor: 'white'}}>

                                <div style={{width: "20px"}}></div>

                                <div style={{display: "flex", alignItems: "left", width: '99%', padding: '10px 0'}}>
                                    <ArgoTextFieldHook
                                        fileName={'QuestionnaireEditor'}
                                        fieldName={pageType + "Text"}
                                        label="Default Text"
                                        variant={'standard'}
                                        maxLength={'500'}
                                        multiline={true}
                                        onChange={this.onTextChangeStartOrClosePageDefaultText}
                                        value={this.state[pageType + "Text"]}
                                        width='100%'
                                    />

                                    <ArgoSelectPortalTextDisplayType
                                        templateType={TEMPLATE.TYPE.QUESTIONNAIRE}
                                        textEntryType={textType}
                                        fieldName={pageType + "TextDisplay"}
                                        fieldTextObjName={pageType + "TextDisplay"}
                                        hideOption={false}
                                        initTo={this.state[pageType + "TextDisplay"].selected}
                                        metadataPropsObj={Object.assign({metadataTag: pageMetadataTag}, propsObj)}
                                    />
                                </div>

                            </div>

                            {(this.state[pageType + "TextDisplay"].selected === 'custom') ?
                                <div style={{paddingLeft: "18px"}}>
                                    <ArgoTextDisplayCustom
                                        fieldName={pageType + "TextDisplay"}
                                        fieldTextObjName={pageType + "TextDisplay"}
                                        initToObj={this.state[pageType + "TextDisplay"].metadataValueObj}
                                        metadataTag={pageMetadataTag}
                                        showDisplay={true}
                                        displayLabel={"Display (" + label + " Page Text)"}
                                        metadataPropsObj={Object.assign({metadataTag: pageMetadataTag}, propsObj)}
                                    />
                                </div>
                                :
                                ""
                            }

                        </div>
                    </React.Fragment> : ""
                }

            </div>
        );

    }

    // -----------------------------------------------------------------------------------------------------------------
    // MAIN PAGES SECTION
    // -----------------------------------------------------------------------------------------------------------------

    getTab2_MainPages(propsObj) {

        return (
            <div id="QuestionnaireEditor-MainPagesTab-container-div"
                 style={{paddingLeft: "20px", paddingBottom: "20px"}}>

                <div>&nbsp;</div>

                <ArgoTextFieldSelectHook
                    fileName="QuestionnaireEditor"
                    fieldName="mainPages"
                    label="Main Pages Format"
                    value={this.state.mainPages}
                    onChange={this.onSelectChange}
                    width="170px"
                    menuItems={arrayDefaultOrCustom}
                />

                <div style={{width: "20px"}}></div>

                {this.state.mainPages === "custom" ?
                    <React.Fragment>
                        <div style={{width: '95%', padding: "20px 20px 0 20px"}}>
                            <div style={{display: "flex", alignItems: "left", width: '99%', backgroundColor: 'white'}}>
                                <div style={{width: "20px"}}></div>

                                <div style={{
                                    display: "flex",
                                    alignItems: "left",
                                    width: '99%',
                                    backgroundColor: 'white'
                                }}>
                                    <div style={{padding: '10px 10px 10px 0'}}>
                                        <ArgoTextFieldSelectHook
                                            fileName="QuestionnaireEditor"
                                            fieldName="pagingMode"
                                            label="Paging"
                                            value={this.state.mainPagesObj.pagingMode}
                                            onChange={this.onChangeMainPagesObj}
                                            width="220px"
                                            menuItems={[
                                                {value: "full", text: "Full Page"},
                                                {value: "one", text: "One Question Per Page"}
                                            ]}/>
                                    </div>

                                    <div style={{width: '20px'}}></div>

                                    <div style={{paddingTop: '10px'}}>
                                        <ArgoTextFieldSelectHook
                                            fileName="QuestionnaireEditor"
                                            fieldName="answeredFirstQuestion"
                                            label="Answered First Question"
                                            value={this.state.mainPagesObj.answeredFirstQuestion}
                                            onChange={this.onChangeMainPagesObj}
                                            width="220px"
                                            menuItems={[
                                                {value: "show", text: "Show"},
                                                {value: "hide", text: "Hide"}
                                            ]}/>
                                    </div>

                                    <div style={{width: '20px'}}></div>

                                    <div style={{paddingTop: '10px'}}>
                                        <ArgoTextFieldSelectHook
                                            fileName="QuestionnaireEditor"
                                            fieldName="questionNumbering"
                                            label="Question Numbering"
                                            value={this.state.mainPagesObj.questionNumbering}
                                            onChange={this.onChangeMainPagesObj}
                                            width="200px"
                                            menuItems={[
                                                {value: "none", text: "None"},
                                                {value: "all", text: "All Pages"},
                                                {value: "page", text: "Reset Each Page"}
                                            ]}/>
                                    </div>

                                    <div style={{width: '20px'}}></div>

                                    {this.state.mainPagesObj.questionNumbering !== "none" ?
                                        <React.Fragment>

                                            <div style={{paddingTop: '10px'}}>
                                                <ArgoTextFieldSelectHook
                                                    fileName="QuestionnaireEditor"
                                                    fieldName="questionNums"
                                                    label="Question Numbers"
                                                    value={this.state.mainPagesObj.questionNums}
                                                    onChange={this.onChangeMainPagesObj}
                                                    width="200px"
                                                    menuItems={[
                                                        {value: "#", text: "#"},
                                                        {value: "#.", text: "#."},
                                                        {value: "#)", text: "#)"}
                                                    ]}/>
                                            </div>

                                            <div style={{width: '15px'}}></div>

                                            <div style={{paddingTop: '10px'}}>
                                                <ArgoTextFieldSelectHook
                                                    fileName="QuestionnaireEditor"
                                                    fieldName="questionNumsLikert"
                                                    label="Likert Sub-Numbers"
                                                    value={this.state.mainPagesObj.questionNumsLikert}
                                                    onChange={this.onChangeMainPagesObj}
                                                    width="200px"
                                                    menuItems={arrayQuestionSubNumberFormats}/>
                                            </div>

                                            <div style={{width: '15px'}}></div>

                                            <div style={{paddingTop: '10px'}}>
                                                <ArgoTextFieldSelectHook
                                                    fileName="QuestionnaireEditor"
                                                    fieldName="questionNumsShowGroup"
                                                    label="Condition Sub-Numbers"
                                                    value={this.state.mainPagesObj.questionNumsShowGroup}
                                                    onChange={this.onChangeMainPagesObj}
                                                    width="200px"
                                                    menuItems={arrayQuestionSubNumberFormats}/>
                                            </div>
                                        </React.Fragment> : ""
                                    }
                                </div>
                            </div>

                            {this.getMainPagesTitles("Header", "pageHeader", propsObj)}

                            {this.getMainPagesTitles("Footer 1", "pageFooter1", propsObj)}

                            {this.getMainPagesTitles("Footer 2", "pageFooter2", propsObj)}

                        </div>
                    </React.Fragment> : ""
                }

            </div>
        );
    }

    getMainPagesTitles = (label, titlePrefix, propsObj) => {

        return (
            <div style={{display: "flex", alignItems: "left", width: '99%', backgroundColor: 'white'}}>
                <div style={{width: "20px"}}></div>

                <div style={{padding: '10px 10px 10px 0'}}>
                    <ArgoTextFieldSelectHook
                        fileName="QuestionnaireEditor"
                        fieldName={titlePrefix}
                        label={label}
                        value={this.state.mainPagesObj[titlePrefix]}
                        onChange={this.onChangeMainPagesObj}
                        width="120px"
                        menuItems={arrayNoOrYes}/>
                </div>

                <div style={{width: "20px"}}></div>

                <div style={{width: '99%'}}>

                    {this.state.mainPagesObj[titlePrefix] === "yes" ?
                        <React.Fragment>
                            <div>
                                {this.getTitleDiv("Left", titlePrefix + "Left", propsObj)}
                            </div>
                            <div>
                                {this.getTitleDiv("Center", titlePrefix + "Center", propsObj)}
                            </div>
                            <div>
                                {this.getTitleDiv("Right", titlePrefix + "Right", propsObj)}
                            </div>
                        </React.Fragment> : ""
                    }
                </div>

            </div>);
    }

    // -----------------------------------------------------------------------------------------------------------------
    // SYSTEM SECTION
    // -----------------------------------------------------------------------------------------------------------------

    getTab4_System(propsObj) {

        return (
            <div id="QuestionnaireEditor-SystemTab-container-div" style={{paddingLeft: "20px", paddingBottom: "20px"}}>

                <div>&nbsp;</div>

                <ArgoTextFieldSelectHook
                    fileName="QuestionnaireEditor"
                    fieldName="system"
                    label="System"
                    value={this.state.system}
                    onChange={this.onSelectChange}
                    width="170px"
                    menuItems={arrayDefaultOrCustom}
                />

                <div style={{width: "20px"}}></div>

                {this.state.system === "custom" ?
                    <React.Fragment>
                        <div>
                            TEST
                        </div>
                    </React.Fragment> : ""
                }

            </div>
        );
    }

    // -----------------------------------------------------------------------------------------------------------------
    //
    // -----------------------------------------------------------------------------------------------------------------

    addSelectedCategory = (categoryTemplate) => {
        if (!this.props.template.categoryKeys.includes(categoryTemplate.templateKey)) {
            this.setState({categoryTemplateSearch: ""});
            this.props.addQuestionnaireCategory(categoryTemplate.templateKey);
            this.props.getCategoryTemplate(categoryTemplate.templateKey);
        }
    };

    saveNewCategory = (template) => {
        this.props.createCategoryTemplate(template, true);
        this.setState({isThisAnExistingCategory: "0", categoryTemplateSearch: ""});
    }

    closeNewCategory = () => {
        this.setState({isThisAnExistingCategory: "0", categoryTemplateSearch: ""});
    }

    handleToolbarBackButtonClick = (event, fieldName, buttonTitle) => {
        if (buttonTitle === 'BACK') {
            this.setState({openCategoryReview: false, openGroupReview: false, openQuestionReview: false});
        }
    }

    // -----------------------------------------------------------------------------------------------------------------
    // EDITOR FUNCTIONS ------------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    categoryEditorSave = () => {
        this.props.saveCategoryTemplate(this.state.categoryTemplate);
        this.setState({openCategoryEditor: false});
    };

    categoryEditorCancel = () => {
        this.setState({openCategoryEditor: false});
    };

    groupEditorSave = () => {
        this.props.saveGroupTemplate(this.state.groupTemplate);
        this.setState({openGroupEditor: false, openGroupEditorComplete: false});
    };

    groupEditorCancel = () => {
        this.setState({openGroupEditor: false, openGroupEditorComplete: false});
    };

    questionEditorSave = () => {
        this.props.saveQuestionTemplate(this.state.questionTemplate);
        this.setState({openQuestionEditor: false});
    };

    questionEditorCancel = () => {
        this.setState({openQuestionEditor: false});
    };

    // -----------------------------------------------------------------------------------------------------------------
    // RENDER ----------------------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    render() {

        if (this.state.isLoading) {
            // TODO place a loading screen here
            return null;
        }

        const metadataObj = (this.props.template && this.props.template.metadata) ? this.props.template.metadata : {};

        let propsObj = {
            fileName: "QuestionnaireEditor",
            onTextChange: this.onTextChange,
            templateType: TEMPLATE.TYPE.QUESTIONNAIRE,
            template: this.props.template,
            metadata: metadataObj,
            templateUpdateFunction: this.props.updateQuestionnaireTemplate,
            setStateCallbackFunction: this.setStateCallback,
            setCustomMetadataFunction: metadataUtils.setCustomMetadata,
            setCustomMetadataFunctionAlt: this.setCustomMetadataLayered,
            depth: 0
        }

        //const {palette} = this.context;  // From blueTheme.js

        // -------------------------------------------------------------------------------------------------------------
        // REVIEW SCREENS ----------------------------------------------------------------------------------------------
        // -------------------------------------------------------------------------------------------------------------

        if (this.state.openCategoryReview) {
            return <ArgoReviewTemplate
                templateType={TEMPLATE.TYPE.CATEGORY}
                templateKey={this.state.selectedCategoryInfoKey}
                handleToolbarButtonClickFunction={this.handleToolbarButtonClick}
                width={this.props.width}
            />;
        }
        if (this.state.openGroupReview) {
            return <ArgoReviewTemplate
                templateType={TEMPLATE.TYPE.GROUP}
                templateKey={this.state.selectedGroupInfoKey}
                handleToolbarButtonClickFunction={this.handleToolbarButtonClick}
                width={this.props.width}
            />;
        }
        if (this.state.openQuestionReview) {
            return <ArgoReviewTemplate
                templateType={TEMPLATE.TYPE.QUESTION}
                templateKey={this.state.selectedQuestionInfoKey}
                handleToolbarButtonClickFunction={this.handleToolbarButtonClick}
                width={this.props.width}
            />;
        }

        // -------------------------------------------------------------------------------------------------------------
        // EDITOR SCREENS ----------------------------------------------------------------------------------------------
        // -------------------------------------------------------------------------------------------------------------

        if (this.state.openCategoryEditor) {
            return <CategoryEditor
                title="Edit Category"
                save={this.categoryEditorSave}
                cancel={this.categoryEditorCancel}
                templateKey={this.state.categoryTemplate.templateKey}
                templateId={this.state.categoryTemplate.id}
                filter={this.props.filter}
                templateMode={TEMPLATE.MODE.CURRENT}
                categoryExpanderEdit={true}
                categoryExpanderSetStateCallback={this.setStateCallback}
                width={this.props.width}
                scrollTo="QuestionnaireEditor-default-container-div"
            />;
        }

        if (this.state.openGroupEditor) {

            //if (!this.state.openGroupEditorComplete) {

                return <GroupEditor
                    title="Edit Group"
                    save={this.groupEditorSave}
                    cancel={this.groupEditorCancel}
                    templateKey={this.state.groupTemplate.templateKey}
                    templateId={this.state.groupTemplate.id}
                    filter={this.props.filter}
                    templateMode={TEMPLATE.MODE.CURRENT}
                    groupExpanderEdit={true}
                    groupExpanderSetStateCallback={this.setStateCallback}
                    width={this.props.width}
                    scrollTo="QuestionnaireEditor-default-container-div"
                />;
            //}
        }

        if (this.state.openQuestionEditor) {
            return <QuestionEditor
                title="Edit Question"
                save={this.questionEditorSave}
                cancel={this.questionEditorCancel}
                templateKey={this.state.questionTemplate.templateKey}
                templateId={this.state.questionTemplate.id}
                filter={this.props.filter}
                templateMode={TEMPLATE.MODE.CURRENT}
                questionDepth={QUESTION_DEPTH}
                questionExpanderEdit={true}
                questionExpanderSetStateCallback={this.setStateCallback}
                width={this.props.width}
                scrollTo="QuestionnaireEditor-default-container-div"
            />;
        }

        // -------------------------------------------------------------------------------------------------------------
        // -------------------------------------------------------------------------------------------------------------
        // -------------------------------------------------------------------------------------------------------------

        if (this.state.isThisAnExistingCategory === "1") {
            let template = {
                name: this.state.categoryTemplateSearch
            };

            return <CategoryEditor title="Create Category"
                                   createMode={true}
                                   save={this.saveNewCategory.bind(this)}
                                   cancel={this.closeNewCategory.bind(this)}
                                   createInitialTemplate={template}
                                   width={this.props.width}
            />;
        }

        let portalTabTitle = "";
        if (this.props.template?.metadata) {
            portalTabTitle = this.props.template.metadata.hasOwnProperty(portalShared.PORTAL_TAB_TITLE) ? this.props.template.metadata[portalShared.PORTAL_TAB_TITLE] : ""
        }

        let questionnaireTemplateClone = portalShared.cloneObj(this.props.template);

        questionnaireTemplateClone.categoryKeys = this.props.categoryKeys; // Pick up category changes for PortalPreview

        return (
            (this.props.cloneMode) ?
                <div id="QuestionnaireEditor-clone-container-div">
                    <ArgoToolbarHook
                        fileName="QuestionnaireEditor"
                        fieldName="save-or-cancel"
                        toolbarErrorText={this.state.toolbarErrorText}
                        onClick={this.props.handleToolbarButtonClick}
                        width={this.props.width}
                    />
                    <div id="QuestionnaireEditor-height-div" style={{height: "50px"}}></div>

                    <div style={{justifyContent: "space-between"}}>
                        <div style={{flexGrow: "2", paddingLeft: "32px", paddingRight: "32px", marginTop: "30px"}}>

                            <ArgoTextFieldHook
                                fileName="QuestionnaireEditor"
                                fieldName="nameQualifier"
                                label="Questionnaire Qualifier"
                                value={this.props.template.nameQualifier}
                                maxLength={40}
                                onChange={this.onTextChange}
                                errorText={this.state.nameQualifierErrorText}
                            />

                        </div>
                    </div>

                    {(this.props.template.templateKey !== "") ?

                        <div style={{height: "100%"}}>
                            <div style={{height: '20px'}}>

                            </div>
                            {(this.props.categoryKeys && this.props.questionKeys) ?
                                <QuestionnaireReview template={this.props.template}
                                                     categories={this.props.categoryKeys}
                                                     questionKeys={this.props.questionKeys}
                                                     width={this.props.width}
                                                     cloneMode={true}/>
                                : ""
                            }

                        </div>
                        :
                        ""}
                </div>
                :
                <div id="QuestionnaireEditor-default-container-div" className="layout vertical" style={{height: "100%", width: "100%"}}>
                    {/*<div style={{justifyContent: "space-between"}}>*/}

                    <ArgoToolbarHook
                        fileName="QuestionnaireEditor"
                        fieldName="save-or-cancel"
                        toolbarErrorText={this.state.toolbarErrorText}
                        onClick={this.props.handleToolbarButtonClick}
                        width={this.props.width}
                    />
                    <div id="QuestionnaireEditor-height-div" style={{height: "50px"}}></div>

                    <div style={{flexGrow: "2", paddingLeft: "32px", paddingRight: "32px", marginTop: '30px'}}>

                        <ArgoTextEntryWithCustomOverride
                            templateType={TEMPLATE.TYPE.QUESTIONNAIRE}
                            textEntryType={TEMPLATE.TEXT_TYPE.QUESTIONNAIRE.NAME}
                            fieldName="name"
                            fieldTextObjName="nameDisplay"
                            label="Questionnaire Name"
                            labelCustom="Display (Name)"
                            value={this.props.template.name}
                            maxlength={255}
                            errorText={this.state.nameErrorText}
                            initTo={this.state.nameDisplay.selected}
                            initToObj={this.state.nameDisplay.metadataValueObj}
                            propsObj={Object.assign({metadataTag: portalShared.CUSTOM_DDO_NAME}, propsObj)}
                        />

                        <ArgoTextFieldHook
                            fileName="QuestionnaireEditor"
                            fieldName="nameQualifier"
                            label="Name Qualifier"
                            value={this.props.template.nameQualifier}
                            maxlength={255}
                            onChange={this.onTextChange}
                        />

                        <div>&nbsp;</div>
                        <div>&nbsp;</div>

                        <ArgoTextEntryWithCustomOverride
                            templateType={TEMPLATE.TYPE.QUESTIONNAIRE}
                            textEntryType={TEMPLATE.TEXT_TYPE.QUESTIONNAIRE.DESC}
                            fieldName="description"
                            label="Description/Instructions"
                            fieldTextObjName="descDisplay"
                            labelCustom="Display (Description)"
                            value={this.props.template.description}
                            maxlength={255}
                            initTo={this.state.descDisplay.selected}
                            initToObj={this.state.descDisplay.metadataValueObj}
                            propsObj={Object.assign({metadataTag: portalShared.CUSTOM_DDO_DESC}, propsObj)}
                        />

                        <ArgoTextFieldHook
                            fileName="QuestionnaireEditor"
                            fieldName="portalTabTitle"
                            label="Portal Tab Title"
                            value={this.state.portalTabTitle}
                            maxlength={40}
                            width="300px"
                            onChange={this.onTextChangePortalTabTitle}
                        />

                        {/*// ===========================================================================================================*/}
                        {/*// KEY METRIC CATEGORY */}
                        {/*// ===========================================================================================================*/}

                        <ArgoTextFieldSelectHook
                            fileName="QuestionnaireEditor"
                            fieldName="keyMetricCategory"
                            label="Key Metric Category"
                            value={this.state.keyMetricCategory}
                            onChange={this.onSelectChangeKeyMetricCategory}
                            width="300px"
                            menuItems={this.state.keyMetricCategories}
                        />

                        <div style={{width: "20px"}}>&nbsp;</div>

                        {/*// ===========================================================================================================*/}
                        {/*// PORTAL UI TABS */}
                        {/*// ===========================================================================================================*/}

                        {/*<div>Link to tab Styling Tutorial: https://smartdevpreneur.com/the-best-mui-tabs-tutorial-hover-active-focus-and-onclick/</div>*/}
                        {/*<div>Link to tab Styling Tutorial: https://www.youtube.com/watch?v=0IPnrdnagBM</div>*/}

                        <div id="QuestionnaireEditor-tabs-container-div" style={{width: "100%"}}>
                            <Tabs
                                TabIndicatorProps={{
                                    style: {
                                        backgroundColor: "white",
                                        borderColor: "black"
                                    }
                                }}
                                style={{width: "100%"}}
                                sx={{
                                    "& button": {color: "gray", border: "solid", borderWidth: "thin", width: "20%", maxWidth: "none"},
                                    "& button:hover": {
                                        backgroundColor: "#E8E8E8",
                                        color: "black",
                                        borderColor: "black"
                                    },
                                    "& button:active": {backgroundColor: "white", color: "black"},
                                    "& button:Mui-selected": {backgroundColor: "white"},
                                }}
                                value={this.state.selectedTabNumber}
                                onChange={this.handleTabChange.bind(this)}
                            >
                                <Tab value={0} sx={{
                                    textTransform: "none",
                                    backgroundColor: (this.state.selectedTabNumber === 0 ? "white" : "#E8E8E8"),
                                    color: (this.state.selectedTabNumber === 0 ? "black" : "#989898"),
                                }} label={this.state.tab0}/>

                                <Tab value={1} sx={{
                                    textTransform: "none",
                                    backgroundColor: (this.state.selectedTabNumber === 1 ? "white" : "#E8E8E8"),
                                    color: (this.state.selectedTabNumber === 1 ? "black" : "#989898"),
                                }} label={this.state.tab1}/>

                                <Tab value={2} sx={{
                                    textTransform: "none",
                                    backgroundColor: (this.state.selectedTabNumber === 2 ? "white" : "#E8E8E8"),
                                    color: (this.state.selectedTabNumber === 2 ? "black" : "#989898"),
                                }} label={this.state.tab2}/>

                                <Tab value={3} sx={{
                                    textTransform: "none",
                                    backgroundColor: (this.state.selectedTabNumber === 3 ? "white" : "#E8E8E8"),
                                    color: (this.state.selectedTabNumber === 3 ? "black" : "#989898"),
                                }} label={this.state.tab3}/>

                                <Tab value={4} sx={{
                                    textTransform: "none",
                                    backgroundColor: (this.state.selectedTabNumber === 4 ? "white" : "#E8E8E8"),
                                    color: (this.state.selectedTabNumber === 4 ? "black" : "#989898"),
                                }} label={this.state.tab4}/>

                            </Tabs>

                            {/*// ===========================================================================================================*/}
                            {/*// Tab Sections */}
                            {/*// ===========================================================================================================*/}

                            <div id="QuestionnaireEditor-portal-tab-contents-container-div" style={{
                                paddingBotton: "20px",
                                borderLeft: "solid",
                                borderBottom: "solid",
                                borderRight: "solid",
                                borderWidth: "thin",
                                borderColor: "#1976d2"
                            }}>
                                <div style={{display: this.state.selectedTabNumber === 0 ? "inline" : "none"}}
                                     id="QuestionnaireEditor-Tab0-container-div">
                                    {this.getTab0_Header(propsObj)}
                                </div>
                                <div style={{display: this.state.selectedTabNumber === 1 ? "inline" : "none"}}
                                     id="QuestionnaireEditor-Tab1-container-div">
                                    {this.getTab_StartOrClosePage("Tab1", "startPage", propsObj)}
                                </div>
                                <div style={{display: this.state.selectedTabNumber === 2 ? "inline" : "none"}}
                                     id="QuestionnaireEditor-Tab2-container-div">
                                    {this.getTab2_MainPages(propsObj)}
                                </div>
                                <div style={{display: this.state.selectedTabNumber === 3 ? "inline" : "none"}}
                                     id="QuestionnaireEditor-Tab3-container-div">
                                    {this.getTab_StartOrClosePage("Tab3", "closePage", propsObj)}
                                </div>
                                <div style={{display: this.state.selectedTabNumber === 4 ? "inline" : "none"}}
                                     id="QuestionnaireEditor-Tab4-container-div">
                                    {this.getTab4_System(propsObj)}
                                </div>
                            </div>
                        </div>

                        {/*// ===========================================================================================================*/}
                        {/*// METADATA */}
                        {/*// ===========================================================================================================*/}

                        <div>&nbsp;</div>

                        <div style={{width: "100%", paddingBottom: "40px"}}>
                            <ArgoMetadataEditor
                                entityType={metadataTemplateTypes.QuestionnaireTemplate}
                                template={this.props.template}
                                // onChange={this.props.updateQuestionnaireTemplate}
                                onChangeCallback={this.metadataChanged}
                                metadata={this.props.template.metadata}
                                setMetadataErrorText={this.setMetadataErrorText}
                            />
                        </div>

                        {/*// ===========================================================================================================*/}
                        {/*// ADD CATEGORY TO QUESTIONNAIRE */}
                        {/*// ===========================================================================================================*/}

                        <div style={{width: "100%"}}>

                            <ArgoRadioGroupHook
                                fileName="QuestionnaireEditor"
                                fieldName="createMode"
                                label="Is this an existing category?"
                                value={this.state.isThisAnExistingCategory}
                                onChange={this.onChangeIsThisAnExistingCategory}
                                checked={true}
                            />

                            <ArgoSelectExisting
                                mode={TEMPLATE.TYPE.CATEGORY}
                                paddingRight="0px"
                                onAddTemplate={this.addSelectedCategory}
                            />

                            <div>&nbsp;</div>

                        </div>

                        {/*// ===========================================================================================================*/}
                        {/*// ASSIGNED CATEGORIES LIST */}
                        {/*// ===========================================================================================================*/}

                        <CategoryViewerExpandable
                            parentName="QuestionnaireEditor"
                            questionnaireTemplate={this.props.template}
                            categoryKeys={this.props.categoryKeys}
                            setStateCallback={this.setStateCallback}
                            hidePortalPreview={true}
                        />

                        {/*// ===========================================================================================================*/}
                        {/*// PORTAL PREVIEW */}
                        {/*// ===========================================================================================================*/}

                        <div id="CategoryEditor-space-above-portal-preview" style={{height: "20px"}}>&nbsp;</div>

                        <ArgoPortalPreview
                            calledBy="QuestionnaireEditor"
                            createMode={this.props.createMode}
                            templateType={TEMPLATE.TYPE.QUESTIONNAIRE}
                            template={questionnaireTemplateClone}
                            hidePortalPreview={this.props.cloneMode}
                        />

                    </div>
                </div>
        );
    }
}

QuestionnaireEditor.defaultProps = {
    createMode: false,
    cloneMode: false,
    width: "100%"
};

QuestionnaireEditor.propTypes = {
    createMode: PropTypes.bool,
    templateKey: PropTypes.string,
    createCategoryTemplate: PropTypes.func,
    handleToolbarButtonClick: PropTypes.func,
    cloneMode: PropTypes.bool,
    width: PropTypes.string
};

function mapStateToProps(state, props) {
    return {
        questionnaireType: state.questionnaireEditor.questionnaireType,
        options: state.questionnaireEditor.options,
        template: state.questionnaireEditor.template,
        templateOriginal: state.questionnaireTemplate[props.templateKey],
        questionnaireTemplate: state.questionnaireTemplate,
        categoryKeys: state.questionnaireEditor.categoryKeys, // Added category keys are returned here
        categoryTemplate: state.categoryTemplate,
        groupTemplate: state.groupTemplate,
        questionTemplate: state.questionTemplate,
        metadataDefinition: state.metadataDefinition[metadataTemplateTypes.QuestionnaireTemplate]
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getQuestionnaireTemplate,
        initializeQuestionnaireEditorUpdate,
        initializeQuestionnaireEditorCreate,
        clearQuestionnaireEditor,
        updateQuestionnaireTemplate,
        checkQuestionnaireValue,
        checkNameQualifier,
        validateQuestionnaireNameAndQualifierUnique,
        incrementPortalCount,
        getMetadataDefinitions,
        addQuestionnaireCategory,
        createCategoryTemplate,
        getCategoryTemplate,
        saveCategoryTemplate,
        saveGroupTemplate,
        saveQuestionTemplate,
        expanderClear
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionnaireEditor);
