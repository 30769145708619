import * as types from "../constants/actionConstants";

const initialState = [];

export default function questionnaireActionTypesReducer(state = initialState, action) {
    switch (action.type) {
        case types.ACTION_TYPES_GET_LIST_SUCCESS:
            return action.list;
        default:
            return state;
    }
}