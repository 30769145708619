import * as types from "../constants/actionConstants";

const initialState = {
    status: "",
    items: []
};

function uploadedFilesReducer(state = initialState, action) {
    let items = state.items;
    let newItems = [];
    let response;
    switch (action.type) {
        case types.BATCH_UPLOAD_SUCCESS:
            newItems = items.slice();
            response = action.response.data;
            for (let key in response) {
                if (response.hasOwnProperty(key)) {
                    newItems.push(response[key]);
                }
            }
            return {
                ...state,
                items: newItems
            };
        case types.BATCH_UPLOAD_FAIL:
            newItems = items.slice();
            if (action.response) {
                response = action.response.data;
                for (let key in response) {
                    if (response.hasOwnProperty(key)) {
                        newItems.push(response[key]);
                    }
                }
            }

            return {
                ...state,
                status: action.message,
                items: newItems,
            };
        case types.BATCH_UPLOAD_CLEAR:
            return {
                ...state,
                status: action.message,
                items: [],
            };
        default:
            return state;
    }
}

export default uploadedFilesReducer;