import * as types from "../constants/actionConstants";

const initialState = {
    open: false,
    message: ""
};

export default function notificationsReducer(state = initialState, action) {
    switch (action.type) {
        case types.NOTIFICATION_OPEN:
            return {
                ...state,
                open: true,
                message: action.message
            };
        case types.NOTIFICATION_CLOSED:
            return initialState;
        default:
            return state;
    }
}