import TemplateApi from "../api/TemplateApi";
import * as types from "../constants/actionConstants";
import {initGetTemplateList} from "./templateListActions";
import {closeDrawer} from "./drawerActions";
import * as TEMPLATE from "../constants/templateConstants";
import TemplateAPI from "../api/TemplateApi";
import * as portalTypes from "../portal/constants/actionConstants";


export function getQuestionnaireTemplate(questionKey, id, after = ()=>{}) {
    return (dispatch)=>{
        if (id) {
            TemplateApi.getQuestionnaireById(id).then((questionnaireTemplate) => {
                dispatch({type: types.QUESTIONNAIRE_TEMPLATE_GET_SUCCESS, questionnaireTemplate});
                after(questionnaireTemplate);
            });
        }
        else {
            TemplateApi.getCurrentQuestionnaire(questionKey).then((questionnaireTemplate) =>{
                dispatch({type: types.QUESTIONNAIRE_TEMPLATE_GET_SUCCESS , questionnaireTemplate});
                after(questionnaireTemplate);
            });
        }
    };
}

export function saveQuestionnaireTemplate(questionnaireTemplateObject) {
    return (dispatch) => {
        TemplateApi.updateQuestionnaireTemplate(questionnaireTemplateObject).then((questionnaireTemplate) =>{
            dispatch({type: types.QUESTIONNAIRE_TEMPLATE_UPDATE_SUCCESS , questionnaireTemplate});
        });
    };
}

export function clearQuestionnaireReference() {
    return (dispatch) => {
        dispatch({type: types.QUESTIONNAIRE_REFERENCE_CLEAR});
    };
}


export function saveQuestionnaireTemplateAndCloseDrawer(questionnaireTemplate, filter, versionsFilter, templateMode, onSuccess = ()=>{}) {
    return (dispatch) => {
        TemplateApi.updateQuestionnaireTemplate(questionnaireTemplate).then((questionnaireTemplate) =>{
            dispatch({type: types.QUESTIONNAIRE_TEMPLATE_UPDATE_SUCCESS , questionnaireTemplate: questionnaireTemplate});
            onSuccess();
            dispatch(closeDrawer());

            // Best to load question list page in all cases
            dispatch(initGetTemplateList(filter, TEMPLATE.TYPE.QUESTIONNAIRE, TEMPLATE.MODE.CURRENT));
            if (templateMode === TEMPLATE.MODE.VERSIONS) {
                let tempVersionsFilter = {...versionsFilter};
                tempVersionsFilter.templateName = questionnaireTemplate.name;
                tempVersionsFilter.templateKey = questionnaireTemplate.templateKey;
                dispatch(initGetTemplateList(tempVersionsFilter, TEMPLATE.TYPE.QUESTIONNAIRE, TEMPLATE.MODE.VERSIONS));
            }

        });
    };
}

export function createQuestionnaireTemplate(questionnaireTemplate, onSuccess = ()=>{}) {
    return (dispatch) => {
        TemplateApi.createQuestionnaireTemplate(questionnaireTemplate).then((template) => {
            dispatch({type: types.QUESTIONNAIRE_TEMPLATE_CREATE_SUCCESS, questionnaireTemplate: template});
            onSuccess();
            dispatch(closeDrawer());
        });
    };
}

export function createQuestionnaireTemplateAndCloseDrawer(questionnaireTemplate, filter, onSuccess = ()=>{}) {
    return (dispatch) => {
        TemplateApi.createQuestionnaireTemplate(questionnaireTemplate).then((questionnaireTemplate) => {
            dispatch({type: types.QUESTIONNAIRE_TEMPLATE_CREATE_SUCCESS, questionnaireTemplate: questionnaireTemplate});
            onSuccess();
            dispatch(closeDrawer());
            dispatch(initGetTemplateList(filter, TEMPLATE.TYPE.QUESTIONNAIRE, TEMPLATE.MODE.CURRENT));
        });
    };
}

export function cloneQuestionnaireTemplateAndCloseDrawer(questionnaireTemplate, filter, versionsFilter, templateMode, onSuccess = ()=>{}) {
        return (dispatch) => {
            TemplateApi.cloneQuestionnaireTemplate(questionnaireTemplate).then((questionnaireTemplate) =>{
                dispatch({type: types.QUESTIONNAIRE_TEMPLATE_CLONE_SUCCESS , questionnaireTemplate});
                onSuccess();
                dispatch(closeDrawer());

                // Forcing clears to keep clone of a clone for generating errors
                dispatch({type: types.QUESTIONNAIRE_EDITOR_CLEAR});
                dispatch({type: types.QUESTIONNAIRE_TEMPLATE_CLEAR});

                // Best to load question list page in all cases
                dispatch(initGetTemplateList(filter, TEMPLATE.TYPE.QUESTIONNAIRE, TEMPLATE.MODE.CURRENT));

                // For now we do not allow clone from the versions list so no need to re-load it
                // if (templateMode === TEMPLATE.MODE.VERSIONS) {
                //     dispatch(initGetTemplateList(versionsFilter, TEMPLATE.TYPE.QUESTIONNAIRE, TEMPLATE.MODE.VERSIONS));
                // }
            });
        };
}


// // name, updatedBy, status, outstanding, page, size, sort ? sort.replace(/\s+/g, '') : sort , direction
// export function getQuestionnaireTemplateList(pageable, filter) {
//     return (dispatch)=>{
//         TemplateApi.questionnaireListFilter(pageable, filter).then((data) =>{
//             dispatch({type: types.QUESTIONNAIRE_TEMPLATE_LIST_GET_SUCCESS,
//                 list: data.collection,
//                 totalRecords: data.page.totalElements
//             });
//         });
//     };
// }

//  Questionnaire Version List
// export function filterVersionList(key, pageable, filter) {
//     return (dispatch)=>{
//         TemplateApi.versionsListFilter(key, pageable, filter).then((data) =>{
//             dispatch({
//                 type: types.QUESTIONNAIRE_VERSIONS_GET_SUCCESS,
//                 list: data.collection,
//                 totalRecords: data.page.totalElements
//             });
//         });
//     };
// }

export function getQuestionnaireNotes(key) {
    return (dispatch)=>{
        TemplateApi.getTemplateNotes(key).then((data) =>{
            dispatch({
                type: types.QUESTIONNAIRE_NOTES_GET_SUCCESS,
                list: data.collection,
            });
        });
    };
}


export function getQuestionnaireStatistics(id) {
    // Get survey statistics for a specific published version
    return (dispatch)=>{
        TemplateApi.getQuestionnaireStatistics(id).then((data) =>{
            dispatch({
                type: types.QUESTIONNAIRE_STATISTICS_GET_SUCCESS,
                list: data,
            });
        });
    };
}


export function getQuestionnaireStatisticsAll(termplateKey) {
    // Get survey statistics for all, an array of stats, for each published version
    return (dispatch)=>{
        TemplateApi.getQuestionnaireStatisticsAll(termplateKey).then((data) =>{
            dispatch({
                type: types.QUESTIONNAIRE_STATISTICS_GET_ALL_SUCCESS,
                list: data,
            });
        });
    };
}


export function getQuestionnaireExport(template) {
    TemplateApi.getTemplateExport(template);
    return {
        type: ""//this is a non action
    };
}


export function questionnaireTemplateInit() {
    return (dispatch) => {
        dispatch({type: types.QUESTIONNAIRE_TEMPLATE_INIT});
    };
}


export function questionnaireTemplateInitClear() {
    return (dispatch) => {
        dispatch({type: types.QUESTIONNAIRE_TEMPLATE_INIT_CLEAR});
    };
}


export function questionnaireTemplateClone() {
    return (dispatch) => {
        dispatch({type: types.QUESTIONNAIRE_TEMPLATE_CLONE});
    };
}


export function questionnaireTemplateCloneClear() {
    return (dispatch) => {
        dispatch({type: types.QUESTIONNAIRE_TEMPLATE_CLONE_CLEAR});
    };
}


export function getQuestionnaireReference(jsonObj) {
    return (dispatch)=>{
        TemplateApi.getQuestionnaireReference(jsonObj).then((data) =>{
            dispatch({
                type: types.QUESTIONNAIRE_REFERENCE_GET_SUCCESS,
                referenceObj: data,
            });
        });
    };
}

export function getMetadataRequiredTokens(templateID) {
    return (dispatch) => {
        TemplateApi.getMetadataRequiredTokens(templateID).then(data => {
            dispatch({
                type: types.QUESTIONNAIRE_REQUIRED_TOKENS_GET_SUCCESS,
                requiredTokens: data
            });
        })
    }
}

//</editor-fold>
// -----------------------------------------------------------------------------------------------------------------

// -----------------------------------------------------------------------------------------------------------------
// Delete !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! <editor-fold>
// -----------------------------------------------------------------------------------------------------------------

export function deleteQuestionnaire(id, retireDTO, key, filter, versionsFilter) {

    return (dispatch) => {
        TemplateApi.deleteQuestionnaire(id, retireDTO)
            .then((response) => {
                return dispatch(deleteSuccess(key, filter, versionsFilter));
            })
            .catch((error) => {
                dispatch(deleteFailure());
                console.log(error.message);// eslint-disable-line
            });
    };

}

const deleteSuccess = (key, filter, versionsFilter) => {
    return (dispatch) => {
        if (key !== undefined) {
            dispatch(initGetTemplateList(versionsFilter, TEMPLATE.TYPE.QUESTIONNAIRE, TEMPLATE.MODE.VERSIONS, key));
        }
        dispatch(initGetTemplateList(filter, TEMPLATE.TYPE.QUESTIONNAIRE, TEMPLATE.MODE.CURRENT));
        dispatch(getOutstandingQuestionnaireTemplateCount());
        dispatch(closeDrawer());
    };
};

const deleteFailure = () => {
    return {
        type: types.DELETE_FAIL
    };
};

//</editor-fold>
// -----------------------------------------------------------------------------------------------------------------

// -----------------------------------------------------------------------------------------------------------------
// Retire !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! <editor-fold>
// -----------------------------------------------------------------------------------------------------------------

export function retireQuestionnaire(id, retireDTO, key, filter, versionsFilter) {

    return (dispatch) => {
        TemplateAPI.retireQuestionnaire(id, retireDTO)
            .then((response) => {
                return dispatch(retireQuestionnaireSuccess(key, response, filter, versionsFilter));
            })
            .catch((error) => {
                dispatch(retireQuestionnaireFailure());
                console.log(error.message);// eslint-disable-line
            });
    };
};

// Retire actions: Because retire can be triggered from Main and Versions List pages init both.
const retireQuestionnaireSuccess = (key, response, filter, versionsFilter) => {
    return (dispatch) => {
        if (key !== undefined) {
            dispatch(initGetTemplateList(versionsFilter, TEMPLATE.TYPE.QUESTIONNAIRE, TEMPLATE.MODE.VERSIONS, key)); // Version page reguires a key
        }
        dispatch(initGetTemplateList(filter, TEMPLATE.TYPE.QUESTIONNAIRE, TEMPLATE.MODE.CURRENT));
        dispatch(closeDrawer());
    };
};

const retireQuestionnaireFailure = () => {
    return {
        type: types.RETIRE_FAIL
    };
};

//</editor-fold>
// -----------------------------------------------------------------------------------------------------------------

// -----------------------------------------------------------------------------------------------------------------
// Scoring !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! <editor-fold>
// -----------------------------------------------------------------------------------------------------------------

export function getQuestionnaireGraph(id) {
    return (dispatch) => {
        TemplateApi.getQuestionnaireGraph(id)
            .then((response) => {
                return dispatch({type: types.GET_QUESTIONNAIRE_GRAPH_SUCCESS, questionnaireTemplateGraph: response});
            })
            .catch((error) => {
                dispatch({type: types.GET_QUESTIONNAIRE_GRAPH_FAILURE});
                console.log(error.message);// eslint-disable-line
            });
        dispatch({type: portalTypes.INCREMENT_PORTAL_COUNT});
    };

}

export function clearQuestionnaireGraph(id) {
    return (dispatch) => {
        dispatch({type: types.CLEAR_QUESTIONNAIRE_GRAPH});
    };

}


//</editor-fold>
// -----------------------------------------------------------------------------------------------------------------

export function getOutstandingQuestionnaireTemplateCount() {
    return (dispatch) => {
        TemplateApi.getOutstandingQuestionnaireTemplateCount().then((data) =>{
            dispatch({type: types.QUESTIONNAIRE_OUTSTANDING_GET_SUCCESS,
                count: data,
            });
        });
    };
}

