import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {closeDrawer} from "../../actions/drawerActions";
import TagReview from "../qd/tags/TagReview";
import ArgoAppBar from "../common/ArgoAppBar";
import ClickAwayListener from '@mui/material/ClickAwayListener';


class TagReviewDrawer extends Component {

    render() {

        return (
            <ClickAwayListener onClickAway={this.props.closeDrawer}>
                <div>
                    <ArgoAppBar
                        title="Review Tag"
                        showMenuArrowButton="LEFT"
                        onLeftIconButtonTouchTap={this.props.closeDrawer}
                        isDrawer={true}
                        width={tagReviewDrawerProps.width}
                    />

                    <div style={{height: '64px'}}></div>

                    <div>
                        <TagReview id={this.props.id} width={tagReviewDrawerProps.width}/>
                    </div>
                </div>
            </ClickAwayListener>
        );
    }
}

TagReviewDrawer.propTypes = {
    templateKey: PropTypes.string,
    id: PropTypes.string
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({closeDrawer}, dispatch);
}

const tagReviewDrawerProps = {
    opensecondary: true,
    width: "90%",
    docked: false,
    anchor: "right"
};

export default connect(null, mapDispatchToProps)(TagReviewDrawer);
export {tagReviewDrawerProps};
