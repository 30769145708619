import * as types from "../constants/actionConstants";

const initialState = {};

function categoryTemplateReducer(state = initialState, action) {
    switch (action.type) {
        case types.CATEGORY_TEMPLATE_GET_SUCCESS:
        case types.CATEGORY_TEMPLATE_CREATE_SUCCESS:
        case types.CATEGORY_TEMPLATE_UPDATE_SUCCESS:
            return {
                ...state,
                [action.categoryTemplate.templateKey]: {
                    ...action.categoryTemplate
                }
            };

        default:
            return state;
    }
}

export default categoryTemplateReducer;
