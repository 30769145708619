import * as types from "../constants/actionConstants";
import * as questionTypes from "../constants/questionTypeConstants";
import * as portalShared from "../portal/shared/metadataConstantsAndUtilities";


const questionDefault = {
    template: {
        templateKey: "",
        name: "",
        nameQualifier: null,
        description: "",
        questionType: questionTypes.RADIO,
        optional: true,
        preserveAnswer: true,
        metadata: {},
        searchTags: []
    },
    answerOptionTemplates: [],
    options: {
        validate: [],
        isValidating: false,
        hasVersion: null,
        hasReference: null
    }};

const initialState = [
    questionDefault,
    questionDefault
];

export default function questionEditorReducer(state = initialState, action) {
    let answerTemplates;
    let newState;
    switch (action.type) {
        case types.QUESTION_EDITOR_INITIALIZE:
            newState = [
                ...state
            ];

            newState[action.depth] = {
                ...newState[action.depth],
                ...action.payload
            };

            return newState;

        case types.QUESTION_EDITOR_UPDATE_TEMPLATE:
            newState = [...state];
            newState[action.depth] = {
                ...newState[action.depth],
                template: {
                    ...state[action.depth].template,
                    ...action.payload.template,
                },
                options: {
                    ...state[action.depth].options,
                    ...action.payload.options
                }
            };
            return newState;

        case types.QUESTION_EDITOR_CLEAR:
            newState = [...state];
            newState[action.depth] = {...questionDefault};
            return newState;

        case types.QUESTION_EDITOR_UPDATE_EDITOR:
            newState = [...state];
            newState[action.depth] = {...newState[action.depth]};
            newState[action.depth].options = {
                ...newState[action.depth].options,
                ...action.options
            };
            return newState;

        case types.ANSWER_EDITOR_ADD_QUESTION_ANSWER:// eslint-disable-line no-case-declarations
            newState = [...state];
            newState[action.depth] = {...newState[action.depth]};

            let newAnswerOptions = [
                ...state[action.depth].answerOptionTemplates,
                action.answer
            ];
            newState[action.depth].answerOptionTemplates = newAnswerOptions;

            return newState;

        case types.QUESTION_EDITOR_VALUE_EXISTS:
            newState = [...state];
            newState[action.depth] = {...newState[action.depth]};
            newState[action.depth].options = {
                ...newState[action.depth].options,
                validate: action.list,
                isValidating: false
            };
            return newState;

        case types.QUESTION_EDITOR_CHECK_REFERENCE:
            newState = [...state];
            newState[action.depth] = {...newState[action.depth]};
            newState[action.depth].options = {
                ...newState[action.depth].options,
                hasReference: action.data.collection,
            };
            return newState;

        case types.QUESTION_EDITOR_CHECK_VERSION:
            newState = [...state];
            newState[action.depth] = {...newState[action.depth]};
            newState[action.depth].options = {
                ...newState[action.depth].options,
                hasVersion: action.data.collection,
            };
            return newState;

        case types.QUESTION_EDITOR_VALUE_VALIDATING:
            newState = [...state];

            newState[action.depth] = {...newState[action.depth]};
            newState[action.depth].options = {
                ...newState[action.depth].options,
                isValidating: true
            };
            return newState;

        case types.QUESTION_EDITOR_ADD_GROUP:
            if (!action.answerIndex && !(action.answerIndex === 0)) {
                return state;
            }
            newState = [...state];
            newState[action.depth] = {...newState[action.depth]};

            answerTemplates = [...state[action.depth].answerOptionTemplates];

            answerTemplates[action.answerIndex] = {...state[action.depth].answerOptionTemplates[action.answerIndex]};
            answerTemplates[action.answerIndex].importGroupKeys = [
                ...answerTemplates[action.answerIndex].importGroupKeys,
                action.groupTemplate.templateKey
            ];
            newState[action.depth].answerOptionTemplates = answerTemplates;

            return newState;

        case types.QUESTION_EDITOR_ADD_ACTION:
            if (!action.answerIndex && !(action.answerIndex === 0)){
                return state;
            }
            newState = [...state];
            newState[action.depth] = {...newState[action.depth]};

            answerTemplates = [...state[action.depth].answerOptionTemplates];

            answerTemplates[action.answerIndex] = {...state[action.depth].answerOptionTemplates[action.answerIndex]};
            answerTemplates[action.answerIndex].importActionKeys = [
                ...answerTemplates[action.answerIndex].importActionKeys,
                action.actionTemplate.templateKey
            ];
            newState[action.depth].answerOptionTemplates = answerTemplates;

            return newState;

        case types.QUESTION_EDITOR_CLEAR_SEARCH_TAGS_ARRAY:// eslint-disable-line no-case-declarations
            newState = [...state];
            newState[action.depth] = {
                ...newState[action.depth],
                template: {
                    ...state[action.depth].template
                }
            };
            newState[action.depth].template.searchTags = [];
            return newState;

        case types.ANSWER_EDITOR_UPDATE_QUESTION_ANSWER:// eslint-disable-line no-case-declarations
            newState = [...state];
            newState[action.depth] = {...newState[action.depth]};
            let answerOptions = [
                ...state[action.depth].answerOptionTemplates
            ];
            answerOptions[action.index] = {...action.answer};
            newState[action.depth].answerOptionTemplates = answerOptions;
            return newState;

        case types.ANSWER_EDITOR_REMOVE_QUESTION_ANSWER:// eslint-disable-line no-case-declarations
            newState = [...state];
            newState[action.depth] = {...newState[action.depth]};
            let removedAnswer = state[action.depth].answerOptionTemplates.filter((element, index) => {
                return index !== action.index;
            });
            let newAnswers = portalShared.cloneObj(removedAnswer);
            newAnswers.forEach((answer, index) => {
                answer.index = index
            })
            newState[action.depth].answerOptionTemplates = newAnswers;
            return newState;

        case types.ANSWER_EDITOR_REORDER_QUESTION_ANSWER:// eslint-disable-line no-case-declarations
            newState = [...state];
            newState[action.depth] = {...newState[action.depth]};

            let answerOptionTemplates = [...state[action.depth].answerOptionTemplates];
            let target = (action.isUp)? -1 : 1;
            target = target + action.currentIndex;

            if (target < 0) { target=0;}
            else if (target > answerOptionTemplates.length -1) {target = answerOptionTemplates.length -1;}

            let removed = answerOptionTemplates.splice(action.currentIndex, 1);
            answerOptionTemplates.splice(target, 0, removed[0]);

            newState[action.depth].answerOptionTemplates = answerOptionTemplates;
            return newState;

        default:
            return state;
    }
}
