import { notificationOpen } from "../actions/notificationActions";
import { clearToken } from "../actions/responseActions";
import * as system from "../constants/systemConstants";
import * as portalShared from "../shared/metadataConstantsAndUtilities"


export const errorResponse = async function(error) {
    let message = error.message;
    let loginMessage = "";


    if (error.status === 404) {
        loginMessage = portalShared.ALERT_MESSAGE_QUESTIONNAIRE_INVALID_KEY; // ref key invalid
    }
    else if (error.status === 403) {
        loginMessage = portalShared.ALERT_MESSAGE_QUESTIONNAIRE_DEACTIVATED; // ref deactivated (admin action), can set using deactivatedDate: "2020-08-01T20:59:07.748Z"
    }
    else if (error.status === 409) {
        loginMessage = portalShared.ALERT_MESSAGE_QUESTIONNAIRE_COMPLETED; // questionnaire completed
    }
    else if (error.status === 410) {
        loginMessage = portalShared.ALERT_MESSAGE_QUESTIONNAIRE_EXPIRED; // questionnaire expired, can set using expirationDate: "2020-08-01T20:59:07.748Z"
    }
    else if (error.status === 423) {
        loginMessage = portalShared.ALERT_MESSAGE_QUESTIONNAIRE_UNAVAILABLE;
    }
    else {
        system.systemConstants.storeDispatch(notificationOpen(message, error));
    }
    system.systemConstants.storeDispatch(clearToken(loginMessage));
};
