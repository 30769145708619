import React from 'react';
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

export default function ArgoTemplateListDividerHook(props) {

    const fileName = props.fileName;

    const parentsLabel = props.parentsLabel;

    const arrayLength = props.arrayLength;

    const childLabel = arrayLength === 1 ? props.childLabel[0] : props.childLabel[1];

    return (
        <div>
            <Divider id={fileName + "-divider-ArgoTemplateListDividerHook"}>
                    <Typography variant="subtitle1">
                        {parentsLabel + " assigned (" + arrayLength + ") " + childLabel}
                    </Typography>
            </Divider>
            <div>&nbsp;</div>
        </div>
    );
}


