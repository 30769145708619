import React from 'react';
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";


export default function ArgoDividerWithLabelHook(props) {

    const fileName = props.fileName;

    const label = props.label;

    return (<Divider id={fileName + "-divider-ArgoDividerWithLabelHook"}>
        <Typography variant="subtitle1">
            {label}
        </Typography>
    </Divider>);
}


