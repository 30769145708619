import React from "react";
import {NumericFormat} from 'react-number-format';
import { PatternFormat } from 'react-number-format';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';
import {styled} from "@mui/material/styles";
import {getLabelWidth} from "../utilities/getLabelWidth"; // Used for state selection DDLB in Address Component
//import * as mode from "../utilities/displayOneQuestionPerPage";
import * as font from "../utilities/fontUtilities";
import * as ddo from "../constants/customObjConstants";
import * as tag from "../constants/customMetadataConstants";
import * as portalShared from "../shared/metadataConstantsAndUtilities"
import {saveAnswerTypeSingleSelect, singleSelectDropDown} from "./SingleSelect";
import * as ssType from "../constants/singleSelectTypes";
import {CUSTOM_DDO_QUE_LABEL} from "../shared/metadataConstantsAndUtilities";


// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
// ---------------------------------------------------------------------------------------------------------------------
//<editor-fold desc="[CODE SECTION] Text Question Save Answer Routines ----------------------------------------------------------------------">


function saveAnswerTypeText(callerRefStr, savedObjs, event) {
    const {
        question,
        surveyEditMode,
        displayMode
    } = savedObjs.props;
    let enteredValue
    if(callerRefStr.includes("date")) {
        if (isNaN(event)) return
        enteredValue = event.toISOString()
    } else enteredValue = event.target.value;

    let answer = savedObjs.question.answerText;

    // -----------------------------------------------------------------------------------------------------------------
    // Special handeling for From/To date stored as a comma delimited From/To
    if ((callerRefStr === "dateRangeEntryFrom-date-range")||(callerRefStr === "dateRangeEntryTo-date-range")) {
        answer = (answer) ? answer.split(",") : ["", ""];
        enteredValue = (callerRefStr === "dateRangeEntryFrom-date-range") ? enteredValue + "," + answer[1] : answer[0]  + "," + enteredValue;
    }
    // -----------------------------------------------------------------------------------------------------------------
    // Special Handeling for Address (Compplex QUestion Type)
    let addressAnswer = savedObjs.question.addressAnswer !== undefined ? {...savedObjs.question.addressAnswer} : {address: "", extended: "", city: "", zipPostalCode: ""};
    let addressAnswerSave = {...addressAnswer};
    if (callerRefStr !== "") {
        if (callerRefStr.substring(0, 8) === "address-") {

            addressAnswer[callerRefStr.substring(8)] = enteredValue;
        }
    }

    // -----------------------------------------------------------------------------------------------------------------

    if (surveyEditMode === portalShared.EDITABLE_SURVEY) {

        if (callerRefStr.substring(0, 8) === "address-") {
            if ((addressAnswer.address !== addressAnswerSave.address) ||
                (addressAnswer.extended !== addressAnswerSave.extended) ||
                (addressAnswer.city !== addressAnswerSave.city) ||
                (addressAnswer.zipPostalCode !== addressAnswerSave.zipPostalCode))
            {
                if (displayMode === portalShared.INSTANT_MODE) {
                    savedObjs.props.answerQuestionText(question, {addressAnswer: addressAnswer});
                }
                else {
                    savedObjs.props.answerQuestionTemplateText(question, {addressAnswer: addressAnswer});
                }
            }
        }
        else {

            if (question.answerText === enteredValue) {
                return; //If the answer didn't change, don't update it
            }

            // See if template or instance
            if (displayMode === portalShared.INSTANT_MODE) {
                savedObjs.props.answerQuestionText(question, {answerText: enteredValue});
            } else {
                savedObjs.props.answerQuestionTemplateText(question, {answerText: enteredValue});
            }
        }

    }
}


//</editor-fold>
// ---------------------------------------------------------------------------------------------------------------------
// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||


// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
// ---------------------------------------------------------------------------------------------------------------------
//<editor-fold desc="[CODE SECTION] Text Entry Fields (QUESTION) -------------------------------------------------------------------------">


export function textEntryQuestion(textEntryType, inputType, itemObj, props) {
    //const self = this;
    const {questionnaire, question, questionNumber, surveyEditMode, displayMode, answerQuestionOptions, answerQuestionTemplateOptions} = this.props;

    let textObj = ddo.getQuestionNameText(question, questionNumber);

    // Used for state DDLB in address  ---------------------------------------------------------------------------------
    let answers = [];
    let selectedAnswer = null;

    if (inputType === "address") {
        answers = ddo.getAnswerOptions(question);
        selectedAnswer = answers.find((answerOption) => {
            return answerOption.selected === true;
        });

        if (!selectedAnswer) {
            selectedAnswer = {
                id: ""
            };
        }
    }
    // -----------------------------------------------------------------------------------------------------------------

    let savedObjs = {
        itemObj: itemObj,
        inputType: inputType,
        inputModifier: "",
        questionnaire: questionnaire,
        question: question,
        questionNameHidden: (tag.metadataValue(question, portalShared.CUSTOM_DDO_NAME) === "hide"),
        questionNumber: questionNumber === "0" ? "" : questionNumber,
        questionTextObj: textObj,
        answerText: "",
        answers: answers, // Used for states DDLB in address
        answerQuestionOptions: answerQuestionOptions,
        answerQuestionTemplateOptions: answerQuestionTemplateOptions,
        selectedAnswer: selectedAnswer, // Used for states DDLB in address
        displayMode: displayMode,
        surveyEditMode: surveyEditMode,
        readonlyMode: (surveyEditMode === portalShared.READONLY_SURVEY),
        //indent: tag.metadataValue(activeTemplate, portalShared.INDENT_NAME, "", "0px"),
        props: this.props
    }

    if (tag.metadataExists(question, portalShared.CUSTOM_DDO_QUE_FORMAT)) {
        savedObjs.customTemplateObj = JSON.parse(tag.metadataValue(question, portalShared.CUSTOM_DDO_QUE_FORMAT))
    }

    let htmlObj = "";

    switch(textEntryType) {
        case portalShared.FREE_TEXT:
            htmlObj = freeTextEntry(savedObjs);
            break;
        case portalShared.EMAIL:
            htmlObj = emailTextEntry(savedObjs);
            break;
        case portalShared.ZIPPOSTAL_ONLY:
        case portalShared.PHONE:
        case portalShared.NUMBER:
        case portalShared.CURRENCY:
            htmlObj = numericTextEntry(savedObjs, textEntryType);
            break;
        case portalShared.DATE:
            htmlObj = singleDateEntry(savedObjs, "singleDateEntry-");
            break;
        case portalShared.DATE_RANGE:
            htmlObj = dateRangeEntry(savedObjs);
            break;
        case portalShared.ADDRESS_FULL:
            htmlObj = addressAnswerEntry(savedObjs, props);
            break;
        // no default
    }

    return htmlObj;
}


function getTextField(self, savedObjs, entryType, textPropsObj, textStyleObj, styledObj, callerRefStrOverride = "", formatFunction = null) {

    let styleObj = {
        fontSize: textStyleObj.fontObj.fontSize,
        fontStyle: textStyleObj.fontObj.fontStyle,
        fontWeight: textStyleObj.fontObj.fontWeight,
        fontFamily: textStyleObj.fontObj.fontFamily,
    }
    let error = textPropsObj.errorFunction(savedObjs.question.answerText);

    let callerRefStr = (callerRefStrOverride !== "") ? callerRefStrOverride : ("textEntry-" + textPropsObj.type)

    let answerText = savedObjs.question.answerText !== null ? savedObjs.question.answerText : "";

    // If an address field then just pull the required field out
    if ((savedObjs?.question?.addressAnswer)) {
        answerText = savedObjs.question.addressAnswer[callerRefStrOverride.substring(8)];
    }

    let autoComplete = "off";
    if (callerRefStrOverride !== "") {
        if (callerRefStrOverride.substring(0,8) === "address-") {
            autoComplete = "new-pasword";
        }
    }

    let placeholderColor = portalShared.DEFAULT_TEXT_FIELD_PLACEHOLDER_COLOR;
    let placeholderOpacity = portalShared.DEFAULT_TEXT_FIELD_PLACEHOLDER_OPACITY;
    if (savedObjs?.customTemplateObj) {
        placeholderColor = colorNameToHex(savedObjs?.customTemplateObj?.placeHolder?.color);  // Some of the theme props wont take a color string
        placeholderOpacity = colorNameToHex(savedObjs?.customTemplateObj?.placeHolder?.opacity);  // Some of the theme props wont take a color string
    }

    return {
        type: savedObjs.inputType,
        defaultValue: answerText,
        id: entryType + "-" + savedObjs.question.id,
        label: textPropsObj.floatingLabel,
        placeholder: textPropsObj.placeholder,
        multiline: textPropsObj.multiline,
        maxRows: textPropsObj.maxrows,
        variant: textPropsObj.variant,
        size: textPropsObj.size,
        onBlur: saveAnswerTypeText.bind(self, callerRefStr, savedObjs),
        error: error.flag,
        helperText: error.message,
        disabled: savedObjs.readonlyMode,
        inputProps: {maxLength: textPropsObj.maxLength, autoComplete: autoComplete, style: {...styleObj}},
        InputLabelProps: {
            style: {
                backgroundColor: textPropsObj.variant === "outlined" ? "white" : "",
                autoComplete: "off", ...styleObj
            }
        },
        sx: {
            input: {
                "&::placeholder": {
                    color: placeholderColor,
                    opacity: placeholderOpacity
                }
            }
        }
    }
}

//</editor-fold>
// ---------------------------------------------------------------------------------------------------------------------
// |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||


// ---------------------------------------------------------------------------------------------------------------------
// Free Text Entry
// ---------------------------------------------------------------------------------------------------------------------


function freeTextEntry(savedObjs, callerRefStrOverride = "") {
    let self = this;

    let textPropsObj = getTextProperties(savedObjs);

    // textPropsObj.multiline = tag.metadataExists(savedObjs.question, portalShared.TEXT_MULTILINE);
    // textPropsObj.maxrows = tag.metadataValue(savedObjs.question, portalShared.TEXT_MAXROW, "", "1");

    let textStyleObj = font.getDefaultOrCustomTextObj(savedObjs.question, ddo.OBJ_TYPE.TEXT, ddo.OBJ_TEXT_TYPE.NAME);

    let styledObj = getCustomStyledTextField(savedObjs, textPropsObj, textStyleObj);

    let additionalProps = getTextField(self, savedObjs, "freetext", textPropsObj, textStyleObj, styledObj, callerRefStrOverride);

    return (
        //<form>
        <FormControl key={savedObjs.question.id} style={{width: textPropsObj.width}}>
            {savedObjs?.customTemplateObj?.labelPosition === "above" ? addLabel(savedObjs) : ""}
            <styledObj.styledTextField
                label={savedObjs?.customTemplateObj?.unselectedFloatingLabel?.text ?? "Free Text"}
                name={textPropsObj.placeholder.text}
                variant={textPropsObj.variant}
                size={textPropsObj.size}
                {...{...additionalProps, label: textPropsObj.floatingLabel !== "" ? textPropsObj.floatingLabel : "Free Text"}}
            />
            {savedObjs?.customTemplateObj?.labelPosition === "below" ? addLabel(savedObjs) : ""}
        </FormControl>
        //</form>
    );
}


// ---------------------------------------------------------------------------------------------------------------------
// eMail Text Entry
// ---------------------------------------------------------------------------------------------------------------------


function validateEmail(answerText) {
    // regex checks for one and only 1 '@'
    // no white space in the email
    // at least one character before the '@', between the '@' and the '.'
    // and one or more characters after the '.'
    let tempEmail = (answerText === null || answerText === undefined) ? "" : answerText;

    let errorFlag = !!(tempEmail.match(/^[^@\s]+@[^@\s]+\.[^@\s]+$/) || tempEmail.length === 0);

    let errorMessage = !errorFlag ? "Invalid email entry" : "";

    return {flag: !errorFlag, message: errorMessage};
}


function emailTextEntry(savedObjs)  {
    let self = this;

    let textPropsObj = getTextProperties(savedObjs);

    // textPropsObj.multiline = tag.metadataExists(savedObjs.question, portalShared.TEXT_MULTILINE);
    // textPropsObj.maxrows = tag.metadataValue(savedObjs.question, portalShared.TEXT_MAXROW, "", "1");

    textPropsObj.errorFunction = validateEmail;

    let textStyleObj = font.getDefaultOrCustomTextObj(savedObjs.question, ddo.OBJ_TYPE.TEXT, ddo.OBJ_TEXT_TYPE.NAME);

    let styledObj = getCustomStyledTextField(savedObjs);

    let additionalProps = getTextField(self, savedObjs, "email", textPropsObj, textStyleObj, styledObj, "")

    return (
        <FormControl key={savedObjs.question.id} style={{width: textPropsObj.width}}>
            {addLabel(savedObjs)}
            <styledObj.styledTextField
                name={textPropsObj.placeholder}
                variant={textPropsObj.variant}
                size={textPropsObj.size}
               {...{...additionalProps, label: textPropsObj.floatingLabel !== "" ? textPropsObj.floatingLabel : "Email"}}
            />
            {addLabel(savedObjs)}
        </FormControl>
    );
}


// ---------------------------------------------------------------------------------------------------------------------
// Number Entry
// ---------------------------------------------------------------------------------------------------------------------


function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    //Field name is used to pass a formatted object because it was the only string I could attach too
    let parsedName = JSON.parse(other.name);

    if (parsedName.format === "") {
        return (<NumericFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value
                    }
                });
            }}
            type={parsedName.type}
            decimalScale={parsedName.decimalScale}
            fixedDecimalScale
            thousandSeparator={parsedName.thousandSeparator}
            allowNegative={parsedName.allowNegative}
            prefix={parsedName.prefix}
            suffix={parsedName.suffix}
        />);
    }
    else
    {
        return (<PatternFormat
            placeholder="Zip Code"
            {...other}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        value: values.value
                    }
                });
            }}
            type={parsedName.type}
            format={parsedName.format}
            allowEmptyFormatting mask={parsedName.mask}
            decimalScale={parsedName.decimalScale}
            thousandSeparator={parsedName.thousandSeparator}
            allowNegative={parsedName.allowNegative}
            prefix={parsedName.prefix}
            suffix={parsedName.suffix}
        />);
    }
}


function numericTextEntry(savedObjs, numericType, callerRefStrOverride = "") {
    let self = this;

    let textPropsObj = getTextProperties(savedObjs);

//     textPropsObj.allowNegative = tag.metadataExists(savedObjs.question, portalShared.ALLOW_NEGATIVES);
//     textPropsObj.isPercentage = tag.metadataExists(savedObjs.question, portalShared.IS_PERCENTAGE);
//     textPropsObj.noDecimal = tag.metadataExists(savedObjs.question, portalShared.NO_DECIMAL);
//     textPropsObj.useDecimal = tag.metadataExists(savedObjs.question, portalShared.ALLOW_DECIMAL);
//     if (textPropsObj.useDecimal) {
//         textPropsObj.decimalScale = tag.metadataValue(savedObjs.question, portalShared.ALLOW_DECIMAL, "", 2);
//     }

    // NOTE: since name property is passed to the formatter i use it to pass properties in an object
    let name = {thousandSeparator: true, decimalScale: 0, allowNegative: false, prefix: "", suffix: "", type: "text", format: "", mask: ""};

    switch(numericType) {
        case portalShared.NUMBER:
            name.suffix = textPropsObj.isPercentage ? "%" : "";
            name.decimalScale = textPropsObj.isDecimal ? textPropsObj.decimalScale : 0;
            name.thousandSeparator = textPropsObj.thousandSeparator;
            name.allowNegative = textPropsObj.allowNegative
            // name.format = tag.metadataValue(savedObjs.question, portalShared.CUSTOM_MASK, "", "")
            break;
        case portalShared.CURRENCY:
            name.decimalScale = textPropsObj.isDecimal ? textPropsObj.decimalScale : 0;
            name.prefix = "$";
            break;
        case portalShared.ZIPPOSTAL_ONLY:
            name.format = textPropsObj.zipFormat;
            name.mask = ""
            break;
        case portalShared.PHONE:
            name.type = "tel";
            name.format = "(###) ###-####";
            name.mask = ""
            break;
        // no default
    }

    let textStyleObj = font.getDefaultOrCustomTextObj(savedObjs.question, ddo.OBJ_TYPE.TEXT, ddo.OBJ_TEXT_TYPE.NAME);

    let styleObj = {
        fontSize: textStyleObj.fontObj.fontSize,
        fontStyle: textStyleObj.fontObj.fontStyle,
        fontWeight: textStyleObj.fontObj.fontWeight,
        fontFamily: textStyleObj.fontObj.fontFamily,
    }

    let styledObj = getCustomStyledTextField(savedObjs, textPropsObj, textStyleObj, callerRefStrOverride);

    let callerRefStr = callerRefStrOverride !== "" ? callerRefStrOverride : ("numericEntry-" + textPropsObj.type);

    let answerText = savedObjs.question.answerText !== null ? savedObjs.question.answerText : "";

    // If an address field then ust pull the required field out
    if ((savedObjs.question.addressAnswerDTO !== undefined)&&(savedObjs.question.addressAnswerDTO !== null))  {
        answerText = savedObjs.question.addressAnswerDTO[callerRefStrOverride.substring(8)];
    }

    return (
        <FormControl key={savedObjs.question.id} style={{width: textPropsObj.width}}>
            {addLabel(savedObjs)}
            <styledObj.styledTextField
                id={numericType + "-" + savedObjs.question.id}
                name={JSON.stringify(name)}
                defaultValue={answerText}
                label={textPropsObj.floatingLabel !== "" ? textPropsObj.floatingLabel :
                    portalShared.getLabel[numericType].charAt(0).toUpperCase() + portalShared.getLabel[numericType].slice(1).toLowerCase()}
                placeholder={textPropsObj.placeholder}
                variant={textPropsObj.variant}
                size={textPropsObj.size}
                onBlur={saveAnswerTypeText.bind(self, callerRefStr, savedObjs)}
                min={"0"}
                max={"100"}
                disabled={savedObjs.readonlyMode}
                //inputProps={{autoComplete: "off"}}

                InputProps={{
                    inputComponent: NumberFormatCustom,
                    autoComplete: callerRefStrOverride === "address-zipPostalCode" ? "new-password" : "off",

                }}
                InputLabelProps={{style: {backgroundColor: textPropsObj.variant === "filled" ? "#E8E8E8" : "white", ...styleObj}}}

            />
            {addLabel(savedObjs)}
        </FormControl>
    );
}


// ---------------------------------------------------------------------------------------------------------------------
// Date Entry
// ---------------------------------------------------------------------------------------------------------------------


function singleDateEntry(savedObjs, refStr, tagsArray = []) {
    let self = this;

    let textPropsObj = getTextProperties(savedObjs);

    let textStyleObj = font.getDefaultOrCustomTextObj(savedObjs.question, ddo.OBJ_TYPE.TEXT, ddo.OBJ_TEXT_TYPE.NAME);

    let styleObj = {
        fontSize: textStyleObj.fontObj.fontSize,
        fontStyle: textStyleObj.fontObj.fontStyle,
        fontWeight: textStyleObj.fontObj.fontWeight,
        fontFamily: textStyleObj.fontObj.fontFamily,
    }

    let styledObj = getCustomStyledTextField(savedObjs, textPropsObj, textStyleObj, "DATE");

    // Note: Date range value are storedas a comma delimited string
    let answer = savedObjs.question.answerText;
    let dateRangeToFlag = (refStr === "dateRangeEntryTo-");
    if (refStr !== "singleDateEntry-") {
        answer = (answer) ? answer.split(",") : ["", ""];
        answer = (dateRangeToFlag) ? answer[1] : answer[0];
    }

    return (
        <FormControl key={refStr + savedObjs.question.id} style={{width: textPropsObj.width}}>
            {addLabel(savedObjs, dateRangeToFlag ? portalShared.TEXT_LABEL_ABOVE2 : portalShared.TEXT_LABEL_ABOVE)}
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                {/*<styledObj.styledDateField label={styledObj.floatingLabel} name={textPropsObj.placeholder} variant={textPropsObj.variant} size={textPropsObj.size}>*/}

                <DatePicker id={refStr + savedObjs.question.id}
                            label={textPropsObj.floatingLabel}
                            variant={textPropsObj.variant}
                            size={textPropsObj.size}
                            defaultValue={(answer) ? answer : ""}
                            value={(answer) ? answer : ""}
                            onBlur={saveAnswerTypeText.bind(self, refStr + textPropsObj.type, savedObjs)}
                            onChange={saveAnswerTypeText.bind(self, refStr + textPropsObj.type, savedObjs)}
                            disabled={savedObjs.readonlyMode}
                            renderInput={(params) => <TextField {...params}  variant="standard" error={false} sx={{width: "350px", marginBottom: "22px"}}/>}
                    // InputLabelProps={{shrink: true, ...styleObj}}
                    // inputProps={{autoComplete: "off"}}  // eslint-disable-line
                    // InputProps={{style: {...styleObj}}} // eslint-disable-line
                    //inputprops={{max: "2999-12-31"}}
                />

                {/*</styledObj.styledDateField>*/}
            </LocalizationProvider>
            {addLabel(savedObjs, dateRangeToFlag ? portalShared.TEXT_LABEL_BELOW2 : portalShared.TEXT_LABEL_BELOW)}
        </FormControl>
    );
}


// ---------------------------------------------------------------------------------------------------------------------
// Date Range Entry
// ---------------------------------------------------------------------------------------------------------------------


function dateRangeEntry(savedObjs) {

    let arrangeSideBySide = tag.metadataExists(savedObjs.question, portalShared.DISPLAY_DATE_RANGE_AS_SIDE_BY_SIDE);

    let tempStyle = arrangeSideBySide ? {display: 'flex', width: '100%'} : {};

    let width1 = tag.metadataValue(savedObjs.question, portalShared.TEXT_WIDTH, "", "100%")
    let width2 = tag.metadataValue(savedObjs.question, portalShared.TEXT_WIDTH2, "", "100%")

    return (
        <div style={tempStyle}>
            <div style={{float: "left", width: width1}}>
                {singleDateEntry(savedObjs, "dateRangeEntryFrom-")}
            </div>
            <div style={{float: "left", width: "20px"}}>{portalShared.NON_BREAKING_SPACE}</div>
            <div style={{float: "left", width: width2}}>
                {singleDateEntry(savedObjs, "dateRangeEntryTo-",  [[portalShared.TEXT_FLOATING_LABEL2, ""], [portalShared.TEXT_PLACEHOLDER2, ""], [portalShared.TEXT_VARIANT2, "outlined"], [portalShared.TEXT_SIZE2, "small"], [portalShared.TEXT_WIDTH2, "100%"], [portalShared.TEXT_MAXLENGTH2, "80"]])}
            </div>
        </div>
    );
}


// ---------------------------------------------------------------------------------------------------------------------
// Address Entry
// ---------------------------------------------------------------------------------------------------------------------


export function addressAnswerEntry(savedObjs, props){

    let portalPreviewMode = (savedObjs.displayMode === "AdminModeUseTemplateQnr");

    let textObjs = {
        answerText: savedObjs.answerText,
        answers: savedObjs.answers,
        displayMode: savedObjs.displayMode,
        inputModifier: savedObjs.inputModifier,
        inputType: savedObjs.inputType,
        itemObj: savedObjs.itemObj,
        props: savedObjs.props,
        question: savedObjs.question,
        questionNameHidden: savedObjs.questionNameHidden,
        questionNumber: savedObjs.questionNumber,
        questionTextObj: savedObjs.questionTextObj,
        questionnaire: savedObjs.questionnaire,
        readonlyMode: savedObjs.readonlyMode,
        selectedAnswer: savedObjs.selectedAnswer,
        surveyEditMode: savedObjs.surveyEditMode,
        customTemplateObj: {
            questionType: savedObjs?.customTemplateObj?.questionType,
            selected: savedObjs?.customTemplateObj?.selected,
            color: savedObjs?.customTemplateObj?.color,
            style: savedObjs?.customTemplateObj?.style,
            size: savedObjs?.customTemplateObj?.size,
            labelPosition: savedObjs?.customTemplateObj?.labelPosition,
            multiline: savedObjs?.customTemplateObj?.multiline,
            maxRows: savedObjs?.customTemplateObj?.maxRows,
            hoverFloatingLabel: savedObjs?.customTemplateObj?.hoverFloatingLabel,
            selectedFloatingLabel: savedObjs?.customTemplateObj?.selectedFloatingLabel,
            text: savedObjs?.customTemplateObj?.text,
            unselectedBorder: savedObjs?.customTemplateObj?.unselectedBorder,
            hoverBorder:savedObjs?.customTemplateObj?.hoverBorder,
            selectedBorder: savedObjs?.customTemplateObj?.selectedBorder,
            unselectedBackground: savedObjs?.customTemplateObj?.unselectedBackground,
            hoverBackground: savedObjs?.customTemplateObj?.hoverBackground,
            selectedBackground: savedObjs?.customTemplateObj?.selectedBackground
        }
    }

    let ddlbObjs = {
        answerText: savedObjs.answerText,
        answers: savedObjs.question.answerOptionTemplates,
        displayMode: savedObjs.displayMode,
        inputModifier: savedObjs.inputModifier,
        inputType: savedObjs.inputType,
        itemObj: savedObjs.itemObj,
        props: savedObjs.props,
        question: savedObjs.question,
        questionNameHidden: savedObjs.questionNameHidden,
        questionNumber: savedObjs.questionNumber,
        questionTextObj: savedObjs.questionTextObj,
        questionnaire: savedObjs.questionnaire,
        readonlyMode: savedObjs.readonlyMode,
        selectedAnswer: savedObjs.selectedAnswer,
        surveyEditMode: savedObjs.surveyEditMode,
        customTemplateObj: {
            selectedText: savedObjs?.customTemplateObj?.text,
            unselectedBorder: savedObjs?.customTemplateObj?.unselectedBorder,
            hoverBorder:savedObjs?.customTemplateObj?.hoverBorder,
            selectedBorder: savedObjs?.customTemplateObj?.selectedBorder,
            ddlbBorderColorAfterSelection: savedObjs?.customTemplateObj?.selectedBorder
        }
    }

    const defaultLabels = ["Street 1", "Street 2", "City", "State", "Zip"]

    const addressObjects = [
        JSON.parse(JSON.stringify(textObjs)),
        JSON.parse(JSON.stringify(textObjs)),
        JSON.parse(JSON.stringify(textObjs)),
        JSON.parse(JSON.stringify(ddlbObjs)),
        JSON.parse(JSON.stringify(textObjs))
    ]

    addressObjects.forEach((obj, index) => {
        if(index !== 3) {
            obj.props.answerQuestionOptions = savedObjs.props.answerQuestionOptions
            obj.props.answerQuestionTemplateOptions = savedObjs.props.answerQuestionTemplateOptions
            obj.props.answerQuestionTemplateText = savedObjs.props.answerQuestionTemplateText
            obj.props.answerQuestionText = savedObjs.props.answerQuestionText
        }
        else {
            obj.answerQuestionOptions = savedObjs.props.answerQuestionOptions
            obj.answerQuestionTemplateOptions = savedObjs.props.answerQuestionTemplateOptions
            obj.answerQuestionTemplateText = savedObjs.props.answerQuestionTemplateText
            obj.answerQuestionText = savedObjs.props.answerQuestionText
        }
    })

    if(savedObjs?.customTemplateObj) {
        addressObjects.forEach((obj, index) => {
            if(index !== 3) {
                obj.customTemplateObj[`width`] = savedObjs.customTemplateObj[`width${index}`]
                obj.customTemplateObj[`widthEntry`] = savedObjs.customTemplateObj[`widthEntry${index}`]
                obj.customTemplateObj[`widthType`] = savedObjs.customTemplateObj[`widthType${index}`]
                obj.customTemplateObj[`max`] = savedObjs.customTemplateObj[`max${index}`]
                obj.customTemplateObj[`label`] = savedObjs.customTemplateObj[`label${index}`]
                obj.customTemplateObj[`unselectedFloatingLabel`] = {
                    text: savedObjs.customTemplateObj[`floatingLabel${index}`],
                    ...savedObjs.customTemplateObj.unselectedFloatingLabel
                }
                obj.customTemplateObj[`placeHolder`] = {
                    text: savedObjs.customTemplateObj[`placeHolder${index}`],
                    ...savedObjs.customTemplateObj.placeHolderColor
                }
            }
            else {
                obj.customTemplateObj.floatingLabel = {
                    text: savedObjs.customTemplateObj[`floatingLabel${index}`],
                    ...savedObjs.customTemplateObj.unselectedFloatingLabel
                }
            }
        })
    }
    else {
        addressObjects.forEach((obj, index) => {
            if(index !== 3)  obj.customTemplateObj.unselectedFloatingLabel = {text: defaultLabels[index]}
            else obj.customTemplateObj.floatingLabel = {text: defaultLabels[index]}
        })
    }

    return (
        <div>
            <div>
                {freeTextEntry(addressObjects[0], "address-address")}
            </div>

            <div>{portalShared.NON_BREAKING_SPACE}</div>

            <div>
                {freeTextEntry(addressObjects[1], "address-extended")}
            </div>

            <div>{portalShared.NON_BREAKING_SPACE}</div>

            <div style={{width: "100%", justifyContent: "space-between"}}>
                <div style={{float: "left"}}>
                    {freeTextEntry(addressObjects[2], "address-city")}
                </div>

                <div style={{float: "left", width: portalPreviewMode ? "11px" : "20px"}}>{portalShared.NON_BREAKING_SPACE}</div>

                <div style={{float: 'left', width: "200px"}}>
                    {addLabel(savedObjs)}
                    {/*{addressDropDownStates(savedObjs, statesTagsArray)}*/}
                    {singleSelectDropDown(addressObjects[3])}
                    <div style={{marginTop: "-20px"}}>{addLabel(savedObjs)}</div>
                </div>

                <div style={{float: "left", width: portalPreviewMode ? "11px" : "20px"}}>{portalShared.NON_BREAKING_SPACE}</div>

                <div style={{float: "left"}}>
                    {numericTextEntry(addressObjects[4], portalShared.ZIPPOSTAL_ONLY, "address-zipPostalCode")}
                </div>
            </div>

        </div>
    );
}


// ---------------------------------------------------------------------------------------------------------------------
// singleSelectDropDownStates !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! <editor-fold>
// ---------------------------------------------------------------------------------------------------------------------

export function addressDropDownStates(savedObjs, tagsArray) {
    let self = this;

    let textPropsObj = getTextProperties(savedObjs);

    textPropsObj.errorFunction = validateEmail;

    let textStyleObj = font.getDefaultOrCustomTextObj(savedObjs.question, ddo.OBJ_TYPE.TEXT, ddo.OBJ_TEXT_TYPE.NAME);

    let styledObj = getCustomStyledTextField(savedObjs, textPropsObj, textStyleObj, "");

    let question = savedObjs.question;

    let selectedFlag = (savedObjs.selectedAnswer === null) ? false : (savedObjs.selectedAnswer.id !== "");
    let inputLabelAdjPx = "0px";
    let leftAdjPx = "0px";
    let formAdjPx = "0px";
    let backgroundColor = "white";
    let backgroundColorLabel = "white";
    let smallSize = "40px";
    let largeSize = "56px";
    let paddingLeft = "0px";
    let paddingTop = "0px";
    let paddingTopSelect = "0px";
    let fontColor = styledObj.borderColor;

    if (textPropsObj.variant === "outlined") {
        formAdjPx = "0px";

        if (textPropsObj.size === "small") {
            inputLabelAdjPx = (selectedFlag) ? "0px" : "-8px";
            paddingTop = (selectedFlag) ? "0px" : "-8px";
            paddingTopSelect = (selectedFlag) ? "0px" : "-8px";
        } else {
            inputLabelAdjPx = "0px";
        }

    } else if (textPropsObj.variant === "standard") {

        if (textPropsObj.size === "small") {
            smallSize = (selectedFlag) ? "35px" : "40px";
            inputLabelAdjPx = (selectedFlag) ? "16px" : "12px";  // padding above floating text
            formAdjPx = (selectedFlag) ? "-6px" : "-11px";
            leftAdjPx = "-14px";
            paddingTopSelect = "4px";  // padding above answer
        } else {
            inputLabelAdjPx = (selectedFlag) ? "16px" : "12px";  // padding above floating text
            formAdjPx = (selectedFlag) ? "-7px" : "-8px";  // Adjust bottom line position
            leftAdjPx = "-14px";
            largeSize = "40px";
            paddingTopSelect = "6px";  // padding above answer
        }

    } else {
        // Filled entry
        backgroundColor = "#E8E8E8";
        if (selectedFlag) {
            backgroundColorLabel = "transparent";
            paddingTopSelect = "16px";
            paddingTop = "20px";
        } else {
            backgroundColorLabel = "transparent";
            paddingTopSelect = "0px";
            paddingTop = "0px";
        }
        paddingLeft = "12px";

        if (textPropsObj.size === "small") {
            inputLabelAdjPx = (selectedFlag) ? "14px" : "12px";  // position of floating label
            formAdjPx = "-16px";
            leftAdjPx = (selectedFlag) ? "-2px" : "0px";
            smallSize = "48px";
        } else {
            inputLabelAdjPx = (selectedFlag) ? "16px" : "16px"; // position of floating label
            formAdjPx = "-16px";
            leftAdjPx = (selectedFlag) ? "-2px" : "0px";
        }
    }

    savedObjs.answers = savedObjs.displayMode === portalShared.INSTANT_MODE ? savedObjs.question.answerOptions : savedObjs.question.answerOptionTemplates;

    let selectedId = "";
    for (let i = 0; i < savedObjs.answers.length; i++) {
        if (savedObjs.answers[i].selected) {
            selectedId = savedObjs.answers[i].id.toString();
        }
    }


    return (
        <FormControl
            variant="outlined"
            style={{width: "200px", marginTop: formAdjPx}}
            //className={savedObjs.classes.formControl}
        >
            {textPropsObj.floatingLabel === "" ? null : <InputLabel style={{color: fontColor, backgroundColor: backgroundColorLabel, paddingTop: paddingTop, marginLeft: leftAdjPx, marginTop: inputLabelAdjPx}}>{textPropsObj.floatingLabel + portalShared.NON_BREAKING_SPACE2}</InputLabel>}
            <styledObj.styledSelect label={styledObj.floatingLabel} name={textPropsObj.placeholder} variant={textPropsObj.variant} size={textPropsObj.size}>

                    labelId={question.id.toString()}
                    id={question.id.toString()}
                    value={selectedId}
                    onChange={saveAnswerTypeSingleSelect.bind(self, "addressStateDDLB", savedObjs)}
                    disabled={savedObjs.readonlyMode}
                    label={textPropsObj.floatingLabel}
                    {/*style={{*/}
                    {/*    height: textPropsObj.size === "small" ? smallSize : largeSize,*/}
                    {/*    backgroundColor: backgroundColor,*/}
                    {/*    paddingLeft: paddingLeft,*/}
                    {/*    paddingTop: paddingTopSelect*/}
                    {/*}}*/}
                    input={textPropsObj.variant === "outlined" ?
                        <OutlinedInput
                            labelWidth={getLabelWidth(textPropsObj.floatingLabel)}
                            id={"addressStates-" + question.id.toString()}
                        />
                        :
                        null
                    }
                >
                    {savedObjs.answers.map((answerOption) => {
                        return (
                            <MenuItem
                                value={answerOption.id.toString()}
                                key={answerOption.id.toString()}>
                                {answerOption.optionText}
                            </MenuItem>);
                    })}

            </styledObj.styledSelect>
        </FormControl>
    );

}


// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------
// Text Entry Utilities
// ---------------------------------------------------------------------------------------------------------------------
// ---------------------------------------------------------------------------------------------------------------------


function addLabel(savedObjs) {

    let question = savedObjs.question;

    if (savedObjs.questionNameHidden) {
        return <div></div>;
    }

    if (savedObjs?.customTemplateObj?.labelPosition !== undefined && savedObjs?.customTemplateObj?.labelPosition !== "none") {

        let value = savedObjs.customTemplateObj.labelPosition;

        if (tag.metadataExists(question, portalShared.CUSTOM_DDO_QUE_LABEL)) {

            let customObj = JSON.parse(tag.metadataValue(question, portalShared.CUSTOM_DDO_QUE_LABEL));

            let CustomTypography = styled(Typography)({
                fontFamily: customObj.fontFamily,
                fontWeight: customObj.fontWeight,
                color: customObj.color,
                fontSize: customObj.fontSize,  // 2em, 18pt
                fontStyle: customObj.fontStyle,
                textAlign: customObj.textAlign,  // left center right
                opacity: customObj.opacity
            });

            return <CustomTypography>{value}</CustomTypography>;
        }
        else {

            let valueArray = value.split("|");  //  text | style | wieght | color | size

            let label = savedObjs.customTemplateObj.label;
            let fontStyle = valueArray.length > 1 ? valueArray[1] : "normal"; // normal or italic, default: normal
            let fontWeight = valueArray.length > 2 ? valueArray[2] : "normal";  // normal or bold,  default: normal
            let color = valueArray.length > 3 ? valueArray[3] : "black"; // any color, default: black
            let fontSize = valueArray.length > 4 ? valueArray[4] : "10px";  // any ##px, default: 10px
            let lineHeight = '20px';

            return <div style={{fontSize: fontSize, fontWeight: fontWeight, lineHeight: lineHeight, color: color, fontStyle: fontStyle}}>{label}</div>;
        }

    }

    return null;
}


function getTextProperties(savedObjs, defaultWidth = "100%", defaultMaxlength = "80") {

    let textDefObj = {};

    textDefObj.type = savedObjs.inputType;
    textDefObj.floatingLabel = savedObjs?.customTemplateObj?.unselectedFloatingLabel?.text ?? "";
    textDefObj.placeholder = savedObjs?.customTemplateObj?.placeHolder?.text ?? "";
    textDefObj.variant = savedObjs?.customTemplateObj?.style ?? "outlined";
    textDefObj.size = savedObjs?.customTemplateObj?.size ?? "small";
    textDefObj.width = savedObjs?.customTemplateObj?.width ?? defaultWidth;
    textDefObj.maxLength = savedObjs?.customTemplateObj?.max ?? defaultMaxlength;
    textDefObj.multiline = savedObjs?.customTemplateObj?.multiline ?? false;
    textDefObj.rowsMax = savedObjs?.customTemplateObj?.maxRows ?? 1;
    textDefObj.isPercentage = savedObjs?.customTemplateObj?.isPercentage ?? false;
    textDefObj.allowNegative = savedObjs?.customTemplateObj?.isNegative ?? false;
    textDefObj.isDecimal = savedObjs?.customTemplateObj?.isDecimal ?? false;
    textDefObj.decimalScale = savedObjs?.customTemplateObj?.decimalScale ?? 2;
    textDefObj.thousandSeparator = savedObjs?.customTemplateObj?.thousandSeparator ?? true;
    textDefObj.zipFormat = savedObjs?.customTemplateObj?.zipFormat ?? "#####";
    textDefObj.customFormat = "";

    // LEGACY FIX for these 3 metadata tags
    if (tag.metadataExists(savedObjs.question, portalShared.TEXT_FLOATING_LABEL, "", true)) {
        textDefObj.floatingLabel = tag.metadataValue(savedObjs.question, portalShared.TEXT_FLOATING_LABEL, "", savedObjs.floatingLabel);
    }

    if (tag.metadataExists(savedObjs.question, portalShared.TEXT_PLACEHOLDER, "", true)) {
        textDefObj.placeholder = tag.metadataValue(savedObjs.question, portalShared.TEXT_PLACEHOLDER, "", savedObjs.placeholder);
    }

    if (tag.metadataExists(savedObjs.question, portalShared.TEXT_MULTILINE, "", true)) {
        textDefObj.multiline = tag.metadataValue(savedObjs.question, portalShared.TEXT_PLACEHOLDER, "", savedObjs.multiline) !== false;
    }

    textDefObj.errorFunction = function (answerText) {return {flag: false, message: ""};};

    return textDefObj;
}



function colorNameToHex(color)
{
    let colorString = (typeof color === "string") ? color : "black" ;
    var colors = {"aliceblue":"#f0f8ff","antiquewhite":"#faebd7","aqua":"#00ffff","aquamarine":"#7fffd4","azure":"#f0ffff",
        "beige":"#f5f5dc","bisque":"#ffe4c4","black":"#000000","blanchedalmond":"#ffebcd","blue":"#0000ff","blueviolet":"#8a2be2","brown":"#a52a2a","burlywood":"#deb887",
        "cadetblue":"#5f9ea0","chartreuse":"#7fff00","chocolate":"#d2691e","coral":"#ff7f50","cornflowerblue":"#6495ed","cornsilk":"#fff8dc","crimson":"#dc143c","cyan":"#00ffff",
        "darkblue":"#00008b","darkcyan":"#008b8b","darkgoldenrod":"#b8860b","darkgray":"#a9a9a9","darkgreen":"#006400","darkkhaki":"#bdb76b","darkmagenta":"#8b008b","darkolivegreen":"#556b2f",
        "darkorange":"#ff8c00","darkorchid":"#9932cc","darkred":"#8b0000","darksalmon":"#e9967a","darkseagreen":"#8fbc8f","darkslateblue":"#483d8b","darkslategray":"#2f4f4f","darkturquoise":"#00ced1",
        "darkviolet":"#9400d3","deeppink":"#ff1493","deepskyblue":"#00bfff","dimgray":"#696969","dodgerblue":"#1e90ff",
        "firebrick":"#b22222","floralwhite":"#fffaf0","forestgreen":"#228b22","fuchsia":"#ff00ff",
        "gainsboro":"#dcdcdc","ghostwhite":"#f8f8ff","gold":"#ffd700","goldenrod":"#daa520","gray":"#808080","green":"#008000","greenyellow":"#adff2f",
        "honeydew":"#f0fff0","hotpink":"#ff69b4",
        "indianred ":"#cd5c5c","indigo":"#4b0082","ivory":"#fffff0","khaki":"#f0e68c",
        "lavender":"#e6e6fa","lavenderblush":"#fff0f5","lawngreen":"#7cfc00","lemonchiffon":"#fffacd","lightblue":"#add8e6","lightcoral":"#f08080","lightcyan":"#e0ffff","lightgoldenrodyellow":"#fafad2",
        "lightgrey":"#d3d3d3","lightgreen":"#90ee90","lightpink":"#ffb6c1","lightsalmon":"#ffa07a","lightseagreen":"#20b2aa","lightskyblue":"#87cefa","lightslategray":"#778899","lightsteelblue":"#b0c4de",
        "lightyellow":"#ffffe0","lime":"#00ff00","limegreen":"#32cd32","linen":"#faf0e6",
        "magenta":"#ff00ff","maroon":"#800000","mediumaquamarine":"#66cdaa","mediumblue":"#0000cd","mediumorchid":"#ba55d3","mediumpurple":"#9370d8","mediumseagreen":"#3cb371","mediumslateblue":"#7b68ee",
        "mediumspringgreen":"#00fa9a","mediumturquoise":"#48d1cc","mediumvioletred":"#c71585","midnightblue":"#191970","mintcream":"#f5fffa","mistyrose":"#ffe4e1","moccasin":"#ffe4b5",
        "navajowhite":"#ffdead","navy":"#000080",
        "oldlace":"#fdf5e6","olive":"#808000","olivedrab":"#6b8e23","orange":"#ffa500","orangered":"#ff4500","orchid":"#da70d6",
        "palegoldenrod":"#eee8aa","palegreen":"#98fb98","paleturquoise":"#afeeee","palevioletred":"#d87093","papayawhip":"#ffefd5","peachpuff":"#ffdab9","peru":"#cd853f","pink":"#ffc0cb","plum":"#dda0dd","powderblue":"#b0e0e6","purple":"#800080",
        "rebeccapurple":"#663399","red":"#ff0000","rosybrown":"#bc8f8f","royalblue":"#4169e1",
        "saddlebrown":"#8b4513","salmon":"#fa8072","sandybrown":"#f4a460","seagreen":"#2e8b57","seashell":"#fff5ee","sienna":"#a0522d","silver":"#c0c0c0","skyblue":"#87ceeb","slateblue":"#6a5acd","slategray":"#708090","snow":"#fffafa","springgreen":"#00ff7f","steelblue":"#4682b4",
        "tan":"#d2b48c","teal":"#008080","thistle":"#d8bfd8","tomato":"#ff6347","turquoise":"#40e0d0",
        "violet":"#ee82ee",
        "wheat":"#f5deb3","white":"#ffffff","whitesmoke":"#f5f5f5",
        "yellow":"#ffff00","yellowgreen":"#9acd32"};

    if (typeof colors[colorString.toLowerCase()] !== 'undefined') {
        return colors[colorString.toLowerCase()];
    }

    return color;
}


export function getCustomStyledTextField(savedObjs) {

    let defaultGray = "#808080";
    // Set styledObj defaults
    let styledObj = {
        styledTextField: null,
        styledDateField: null,
        styledSelect: null,
        textColor: savedObjs?.customTemplateObj?.text?.colorObj?.colorType !== "default" ?
            savedObjs?.customTemplateObj?.text?.colorObj?.color : undefined,
        // textColorHovered: textStyleObj.type === "default" ? portalShared.DEFAULT_TEXT_FIELD_TEXT_COLOR_HOVERED : textStyleObj.fontObj.color,
        // textColorFocused: textStyleObj.type === "default" ? portalShared.DEFAULT_TEXT_FIELD_TEXT_COLOR_FOCUSED : textStyleObj.fontObj.color,
        floatingLabel: savedObjs?.customTemplateObj?.unselectedFloatingLabel?.text,
        floatingLabelColor: savedObjs?.customTemplateObj?.unselectedFloatingLabel?.colorType !== "default" ?
            savedObjs?.customTemplateObj?.unselectedFloatingLabel?.color : undefined,
        floatingLabelColorHovered: savedObjs?.customTemplateObj?.hoverFloatingLabel?.colorType !== "default" ?
            savedObjs?.customTemplateObj?.hoverFloatingLabel?.color : undefined,
        floatingLabelColorFocused: savedObjs?.customTemplateObj?.selectedFloatingLabel?.colorType !== "default" ?
            savedObjs?.customTemplateObj?.selectedFloatingLabel?.color : undefined,

        placeHolderColor: savedObjs?.customTemplateObj?.placeHolder?.colorType !== "default" ?
            savedObjs?.customTemplateObj?.placeHolder?.color : undefined,
        placeHolderOpacity: savedObjs?.customTemplateObj?.placeHolder?.opacity,

        colorTextAnsweredBorderColor: savedObjs?.customTemplateObj?.selectedBorder?.colorType !== "default" ?
            savedObjs?.customTemplateObj?.selectedBorder?.color : undefined,

        backgroundColor: savedObjs?.customTemplateObj?.unselectedBackground?.colorType !== "default" ?
            savedObjs?.customTemplateObj?.unselectedBackground?.color : undefined,
        backgroundColorHovered: savedObjs?.customTemplateObj?.hoverBackground?.colorType !== "default" ?
            savedObjs?.customTemplateObj?.hoverBackground?.color : undefined,
        backgroundColorFocused: savedObjs?.customTemplateObj?.selectedBackground?.colorType !== "default" ?
            savedObjs?.customTemplateObj?.selectedBackground?.color : undefined,

        backgroundFilledColor: savedObjs?.customTemplateObj?.unselectedBackground?.colorType !== "default" ?
            savedObjs?.customTemplateObj?.unselectedBackground?.color : undefined,
        backgroundFilledColorHovered: savedObjs?.customTemplateObj?.hoverBackground?.colorType !== "default" ?
            savedObjs?.customTemplateObj?.hoverBackground?.color : undefined,
        backgroundFilledColorFocused: savedObjs?.customTemplateObj?.selectedBackground?.colorType !== "default" ?
            savedObjs?.customTemplateObj?.selectedBackground?.color : undefined,

        borderColor: savedObjs?.customTemplateObj?.unselectedBorder?.colorType !== "default" ?
            savedObjs?.customTemplateObj?.unselectedBorder?.color : undefined,
        borderColorHovered: savedObjs?.customTemplateObj?.hoverBorder?.colorType !== "default" ?
            savedObjs?.customTemplateObj?.hoverBorder?.color : undefined,
        borderColorFocused: savedObjs?.customTemplateObj?.selectedBorder?.colorType !== "default" ?
            savedObjs?.customTemplateObj?.selectedBorder?.color : undefined
    }

    let tempStyledObj = {
        //Properties for all text fields
        '& .MuiInputBase-root': {
            color: styledObj.textColor //Text color for all text field
        },
        '& .MuiInputLabel-root': {
            color: styledObj.floatingLabelColor // MUI 5 floating label color all variants NOT focused or hovered
        },
        '&:hover label': {
            color: styledObj.floatingLabelColorHovered // MUI 5 floating label color all variants hovered
        },
        '& label.Mui-focused': {
            color: styledObj.floatingLabelColorFocused // MUI 5 floating label color all variants focused
        },

        //All outlined text field properties
        '& .MuiOutlinedInput-root': {
            backgroundColor: styledObj.backgroundColor,
            '& fieldset': {
                borderColor: styledObj.borderColor // MUI 5 outlined border color NOT focused or hovered
            },
            '&:hover fieldset': {
                borderColor: styledObj.borderColorHovered // MUI 5 outlined border color hovered
            },
            '&.Mui-focused fieldset': {
                borderColor: styledObj.borderColorFocused // MUI 5 outlined border color focused
            },
            "&:hover": {
                backgroundColor: styledObj.backgroundColorHovered,
            },
            "&.Mui-focused": {
                backgroundColor: styledObj.backgroundColorFocused,
            }
        },
        "& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder": {
            color: styledObj.placeHolderColor,
            opacity: styledObj.placeHolderOpacity
        },

        //All standard text field properties
        '&& .MuiInput-root::before': {
            borderColor: styledObj.borderColor, // MUI 5 standard underline NOT hovered or focused color
        },
        '&& .MuiInput-root:hover::before': {
            borderColor: styledObj.borderColorHovered // MUI 5 standard underline hover color
        },
        '&& .MuiInput-root::after': {
            borderColor: styledObj.borderColorFocused // MUI 5 standard underline focused color
        },
        "& .MuiInputBase-root.MuiInput-root ::placeholder": {
            color: styledObj.placeHolderColor,
            opacity: styledObj.placeHolderOpacity
        },

        //Background color for standard text field
        //On standard text field the background color blocks floating label
        "& .MuiInput-root": {
            backgroundColor: styledObj.backgroundColor,
            "&:hover": {
                backgroundColor: styledObj.backgroundColorHovered,
            },
            "&.Mui-focused": {
                backgroundColor: styledObj.backgroundColorFocused,
            },
        },

        //All filled text field properties
        '&& .MuiFilledInput-root': {
            backgroundColor: styledObj.backgroundFilledColor,
        },
        '&& .MuiFilledInput-root::before': {
            borderColor: styledObj.borderColor // MUI 5 filled underline NOT hovered or focused color
        },
        '&& .MuiFilledInput-root:hover::before': {
            borderColor: styledObj.borderColorHovered // MUI 5 filled underline hover color
        },
        '&& .MuiFilledInput-root::after': {
            borderColor: styledObj.borderColorFocused // MUI 5 filled underline focused color
        },
        "& .MuiInputBase-root.MuiFilledInput-root ::placeholder": {
            color: styledObj.placeHolderColor,
            opacity: styledObj.placeHolderOpacity
        },

        //Background colors for filled text field
        "& .MuiFilledInput-root": {
            backgroundColor: styledObj.backgroundFilledColor,
            "&:hover": {
                backgroundColor:styledObj.backgroundFilledColorHovered,
            },
            "&.Mui-focused": {
                backgroundColor: styledObj.backgroundFilledColorFocused,
            }
        },
    };

    styledObj.styledTextField = styled(TextField)(tempStyledObj);

    //styledObj.styledDateField = styled(DateField)(tempStyledObj);

    styledObj.styledSelect = styled(Select)(tempStyledObj);

    // Get any styledObj metadata overrides
    // if (tag.metadataExists(savedObjs.question, portalShared.DISPLAY_DEF_FLOATING_LABEL_COLOR)) {
    //     styledObj.floatingLabelColor = colorNameToHex(tag.metadataValue(savedObjs.question, portalShared.DISPLAY_DEF_FLOATING_LABEL_COLOR, "", styledObj.floatingLabelColor));  // Some of the theme props wont take a color string
    // }
    // if (tag.metadataExists(savedObjs.question, portalShared.DISPLAY_DEF_FLOATING_LABEL_COLOR_HOVERED)) {
    //     styledObj.floatingLabelColorHovered = colorNameToHex(tag.metadataValue(savedObjs.question, portalShared.DISPLAY_DEF_FLOATING_LABEL_COLOR_HOVERED, "", styledObj.floatingLabelColorHovered));  // Some of the theme props wont take a color string
    // }
    // if (tag.metadataExists(savedObjs.question, portalShared.DISPLAY_DEF_FLOATING_LABEL_COLOR_FOCUSED)) {
    //     styledObj.floatingLabelColorFocused = colorNameToHex(tag.metadataValue(savedObjs.question, portalShared.DISPLAY_DEF_FLOATING_LABEL_COLOR_FOCUSED, "", styledObj.floatingLabelColorFocused));  // Some of the theme props wont take a color string
    // }
    //
    // // Placeholder always seems to be the input base color at 50% opacity
    // if (tag.metadataExists(savedObjs.question, portalShared.DISPLAY_DEF_PLACEHOLDER_COLOR)) {
    //     styledObj.placeholderColor = colorNameToHex(tag.metadataValue(savedObjs.question, portalShared.DISPLAY_DEF_PLACEHOLDER_COLOR, "", styledObj.placeholderColor));  // Some of the theme props wont take a color string
    // }
    // if (tag.metadataExists(savedObjs.question, portalShared.DISPLAY_DEF_PLACEHOLDER_OPACITY)) {
    //     styledObj.placeholderOpacity = tag.metadataValue(savedObjs.question, portalShared.DISPLAY_DEF_PLACEHOLDER_OPACITY, "", styledObj.placeholderOpacity);  // Some of the theme props wont take a color string
    // }
    //
    // if (tag.metadataExists(savedObjs.question, portalShared.DISPLAY_DEF_TEXT_COLOR)) {
    //     styledObj.textColor = colorNameToHex(tag.metadataValue(savedObjs.question, portalShared.DISPLAY_DEF_TEXT_COLOR, "", styledObj.textColor));  // Some of the theme props wont take a color string
    // }
    // if (tag.metadataExists(savedObjs.question, portalShared.DISPLAY_DEF_TEXT_COLOR_HOVERED)) {
    //     styledObj.textColorHovered = colorNameToHex(tag.metadataValue(savedObjs.question, portalShared.DISPLAY_DEF_TEXT_COLOR_HOVERED, "", styledObj.textColorHovered));  // Some of the theme props wont take a color string
    // }
    // if (tag.metadataExists(savedObjs.question, portalShared.DISPLAY_DEF_TEXT_COLOR_FOCUSED)) {
    //     styledObj.textColorFocused = colorNameToHex(tag.metadataValue(savedObjs.question, portalShared.DISPLAY_DEF_TEXT_COLOR_FOCUSED, "", styledObj.textColorFocused));  // Some of the theme props wont take a color string
    // }
    //
    //
    // if (tag.metadataExists(savedObjs.question, portalShared.DISPLAY_DEF_BACKGROUND_COLOR)) {
    //     styledObj.backgroundColor = colorNameToHex(tag.metadataValue(savedObjs.question, portalShared.DISPLAY_DEF_BACKGROUND_COLOR, "",  styledObj.backgroundColor));  // Some of the theme props wont take a color string
    // }
    // if (tag.metadataExists(savedObjs.question, portalShared.DISPLAY_DEF_BACKGROUND_COLOR_HOVERED)) {
    //     styledObj.backgroundColorHovered = colorNameToHex(tag.metadataValue(savedObjs.question, portalShared.DISPLAY_DEF_BACKGROUND_COLOR_HOVERED, "",  styledObj.backgroundColorHovered));  // Some of the theme props wont take a color string
    // }
    // if (tag.metadataExists(savedObjs.question, portalShared.DISPLAY_DEF_BACKGROUND_COLOR_FOCUSED)) {
    //     styledObj.backgroundColorFocused = colorNameToHex(tag.metadataValue(savedObjs.question, portalShared.DISPLAY_DEF_BACKGROUND_COLOR_FOCUSED,  "", styledObj.backgroundColorFocused));  // Some of the theme props wont take a color string
    // }
    // if (tag.metadataExists(savedObjs.question, portalShared.DISPLAY_DEF_FILLED_BACKGROUND_COLOR)) {
    //     styledObj.backgroundFilledColor = colorNameToHex(tag.metadataValue(savedObjs.question, portalShared.DISPLAY_DEF_FILLED_BACKGROUND_COLOR, "",  styledObj.backgroundFilledColor));  // Some of the theme props wont take a color string
    // }
    // if (tag.metadataExists(savedObjs.question, portalShared.DISPLAY_DEF_FILLED_BACKGROUND_COLOR_HOVERED)) {
    //     styledObj.backgroundFilledColorHovered = colorNameToHex(tag.metadataValue(savedObjs.question, portalShared.DISPLAY_DEF_FILLED_BACKGROUND_COLOR_HOVERED, "",  styledObj.backgroundFilledColorHovered));  // Some of the theme props wont take a color string
    // }
    // if (tag.metadataExists(savedObjs.question, portalShared.DISPLAY_DEF_FILLED_BACKGROUND_COLOR_FOCUSED)) {
    //     styledObj.backgroundFilledColorFocused = colorNameToHex(tag.metadataValue(savedObjs.question, portalShared.DISPLAY_DEF_FILLED_BACKGROUND_COLOR_FOCUSED,  "", styledObj.backgroundFilledColorFocused));  // Some of the theme props wont take a color string
    // }
    //
    // if (tag.metadataExists(savedObjs.question, portalShared.DISPLAY_DEF_BORDER_COLOR)) {
    //     styledObj.borderColor = colorNameToHex(tag.metadataValue(savedObjs.question, portalShared.DISPLAY_DEF_BORDER_COLOR, "",  styledObj.borderColor));  // Some of the theme props wont take a color string
    // }
    // if (tag.metadataExists(savedObjs.question, portalShared.DISPLAY_DEF_BORDER_COLOR_HOVERED)) {
    //     styledObj.borderColorHovered = colorNameToHex(tag.metadataValue(savedObjs.question, portalShared.DISPLAY_DEF_BORDER_COLOR_HOVERED, "",  styledObj.borderColorHovered));  // Some of the theme props wont take a color string
    // }
    // if (tag.metadataExists(savedObjs.question, portalShared.DISPLAY_DEF_BORDER_COLOR_FOCUSED)) {
    //     styledObj.borderColorFocused = colorNameToHex(tag.metadataValue(savedObjs.question, portalShared.DISPLAY_DEF_BORDER_COLOR_FOCUSED,  "", styledObj.borderColorFocused));  // Some of the theme props wont take a color string
    // }
    //
    //
    // // NOT sure this is a thing?????
    // if (tag.metadataExists(savedObjs.question, portalShared.DISPLAY_DEF_COLOR_ANSWERED_BORDER)) {
    //     styledObj.colorTextAnsweredBorderColor = colorNameToHex(tag.metadataValue(savedObjs.question, portalShared.DISPLAY_DEF_COLOR_ANSWERED_BORDER, "", "gray"));  // Some of the theme props wont take a color string
    // }


    // let callerRefStr = (callerRefStrOverride !== "") ? callerRefStrOverride : ("textEntry-" + textPropsObj.type)

    // let answerText = savedObjs.question.answerText !== null ? savedObjs.question.answerText : "";
    //
    // // If an address field then just pull the required field out
    // if ((savedObjs.question.addressAnswerDTO !== undefined)&&(savedObjs.question.addressAnswerDTO !== null)) {
    //     answerText = savedObjs.question.addressAnswerDTO[callerRefStrOverride.substring(8)];
    // }

    return styledObj;
}


