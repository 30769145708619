import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import Router from "./Router";
import ReactDOM from "react-dom/client";
import store from "./store/store";
import { register } from 'register-service-worker'
import Login from "./components/common/Login";
import './index.css';
import blueTheme from "./blueTheme";
import UserContext,{UserProvider} from './UserContext'
import {QD_URI_PREFIX, REACT_APP_PORTAL_MODE} from "./constants/systemConstants";
import {initSystemConstants} from "./portal/constants/systemConstants";
import { authProvider,msalInstance } from './authProvider';
import { AzureAD, AuthenticationState  } from 'react-aad-msal';
import { MsalProvider } from '@azure/msal-react';
initSystemConstants(QD_URI_PREFIX, null, null);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <UserProvider value={blueTheme}>
    <Provider store={store}>
        {REACT_APP_PORTAL_MODE === "true" ?
            <BrowserRouter>
                <Router/>
            </BrowserRouter>
            :
            <MsalProvider instance={msalInstance}>          
            <Login style={{fontFamily:UserContext.fontFamily}}>
                <BrowserRouter>
                    <Router/>
                </BrowserRouter>
            </Login>            
           </MsalProvider>
        }
        </Provider>
    </UserProvider>
);

register();

















// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
//
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
