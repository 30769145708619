import React, { useState, useEffect } from 'react';
import TextField from "@mui/material/TextField";


export default function ArgoTextFieldHook(props) {
    const [error, setError] = useState(null);

    const fileName = props.fileName ?? "NoFileName";
    const fieldName = props.fieldName ?? "NoFieldName";

    const index = props.index ?? ""; // If field is built in a loop index is required to know which field to match up with

    const uniqueIdentifier = fileName + "-ArgoTextFieldHook-" + fieldName + index;

    const variant = props.variant ?? "standard";
    const type = props.type ?? "text";

    const label = props.label ?? "" ;
    const placeholder = props.placeholder ?? "";

    const value = props.value ?? "";
    const maxLength = props.maxLength ?? 255;

    const width = props.width ?? 0;
    const fullWidth = props.fullWidth === undefined ? (width === 0) : false;

    const autoFocus = props.autoFocus ?? false;


    const onChange = props.onChange ?? function () {};
    const onKeyDown = props.onKeyDown ?? function () {};
    const onBlur = props.onBlur ?? function () {};

    //const display = props.display === undefined ? "inline" : "none";
    const multiline = !(props.multiline === undefined);
    const paddingBottom = props.style ?? "-5px";

    const divContainerHeight = props.divContainerHeight ?? "auto";

    const errorText = props.errorText ?? " ";

    const validationType = props.validationType ?? "";

    const disabled = props.disabled ?? false;

    //const style = props.style ?? {};

    const handleOnChange = (event) => {
        onChange(event, fieldName, index, validationType);
    };

    const handleOnKeyDown = (event) => {
        onKeyDown(event, fieldName, index, validationType);
    };

    const handleOnBlur = (event) => {
        onBlur(event, fieldName, index, validationType);
    };

    useEffect(() => {
        setError(errorText !== " ");
    }, [value, errorText, error])

    return (
        <div id={uniqueIdentifier + "-container-div"} style={{paddingBottom: paddingBottom, height: divContainerHeight, width: fullWidth ? "100%" : width}}>
            <TextField
                id={uniqueIdentifier}
                name={uniqueIdentifier}
                variant={variant}
                type={type}
                label={label}
                value={value === null ? "" : value}
                placeholder={placeholder}
                autoFocus={autoFocus}
                onChange={handleOnChange}
                onKeyDown={handleOnKeyDown}
                onBlur={handleOnBlur}
                inputProps={{autoComplete: "off", maxLength: maxLength}}
                fullWidth={fullWidth}
                sx={{width: fullWidth ? "100%" : width, paddingBottom: paddingBottom}}
                multiline={multiline}
                error={error}
                helperText={errorText}
                disabled={disabled}
            />
        </div>
    );
}

// sx={{ marginBottom: 4}}

// onKeyDown={handleOnKeyDown}
// sx={{display: display}}

// const textfieldStyling = {
//     "& label": {
//         //display: "none",
//         //visibility: "hidden",
//         //whiteSpace: "normal",
//         color: "secondary.main",
//         marginLeft: "65%",
//         width: 100,
//         "&.Mui-focused": {
//             marginLeft: 0
//             //display: "none"
//         }
//     }
//     // "& legend": {
//     //   display: "none"
//     // }
// };


// const handleOnKeyDown = (event) => {
//     onChange(event, props.fieldName)
// };
