import * as types from "../constants/actionConstants";

const initialState = [];

function integrationListReducer(state = initialState, action) {
    switch (action.type) {

        case types.INTEGRATION_LIST_CLEAR:
            return initialState;

        case types.INTEGRATION_LIST_GET_SUCCESS:
            return action.list;

        default:
            return state;
    }
}

export default integrationListReducer;
