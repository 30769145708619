import * as types from "../constants/actionConstants";
import * as questionnaireType from "../constants/questionnaireType";

const initialState = {
    questionnaireType: questionnaireType.QUESTIONNAIRE_COMPLEX, // NOTE: LEGACY, Complex is the only type supported now, SIMPLE and DYNAMIC have been removed
    template: {
        templateKey: "",
        name: "",
        nameQualifier: null,
        description: "",
        script: "",
    },
    categoryKeys: [],
    //questionKeys:[], No longer used, was for when there was a SIMPLE qnr type that could have questions tied directly to it
    options: {
        hasScript: false,
        hasDescription: false,
        edited: false,
        validate: []
    }
};

export default function questionnaireEditorReducer(state = initialState, action) {
    let updatedList, target, removed, updatedTemplate;
    switch (action.type) {
        case types.QUESTIONNAIRE_EDITOR_INITIALIZE:
            return {
                ...state,
                ...action.payload
            };

        case types.QUESTIONNAIRE_EDITOR_UPDATE:
            return {
                ...state,
                template: {
                    ...state.template,
                    ...action.payload.template
                },
                options: {
                    ...state.options,
                    ...action.payload.options
                }
            };

        case types.QUESTIONNAIRE_EDITOR_CLEAR:
            return {
                ...initialState
            };

        case types.QUESTIONNAIRE_EDITOR_ADD_CATEGORY:// eslint-disable-line no-case-declarations
            let addCategory = [
                ...state.categoryKeys,
                action.category
            ];
            return {
                ...state,
                categoryKeys: addCategory,
                options: {
                    ...state.options,
                    edited: true
                }
            };

        case types.CATEGORY_TEMPLATE_CREATE_SUCCESS:// eslint-disable-line no-case-declarations
            updatedList = [
                ...state.categoryKeys,
                action.categoryTemplate.templateKey
            ];
            return {
                ...state,
                categoryKeys: updatedList,
                options: {
                    ...state.options,
                    edited: true
                }
            };

        // case types.QUESTION_TEMPLATE_CREATE_SUCCESS:
        //     if (action.depth > 0) {
        //         return state;
        //     }
        //     updatedList = [
        //         ...state.questionKeys,
        //         action.questionTemplate.templateKey
        //     ];
        //     return {
        //         ...state,
        //         questionKeys: updatedList,
        //         options: {
        //             ...state.options,
        //             edited: true
        //         }
        //     };

        case types.QUESTIONNAIRE_EDITOR_REMOVE_CATEGORY:// eslint-disable-line no-case-declarations
            let removeCategory = state.categoryKeys[action.index];
            updatedTemplate = {...state.template};
            if (removeCategory === state.template.defaultCategory) {
                updatedTemplate.defaultCategory = "";
            }

            updatedList = state.categoryKeys.filter((element, index) => {
                return index !== action.index;
            });
            return {
                ...state,
                template: updatedTemplate,
                categoryKeys: updatedList,
                options: {
                    ...state.options,
                    edited: true
                }
            };

        case types.QUESTIONNAIRE_EDITOR_REORDER:// eslint-disable-line no-case-declarations
            let categoryKeys = [...state.categoryKeys];
            target = (action.isUp)? -1 : 1;
            target = target + action.currentIndex;

            if (target < 0) { target=0;}
            else if (target > categoryKeys.length -1) {target = categoryKeys.length -1;}

            removed = categoryKeys.splice(action.currentIndex, 1);
            categoryKeys.splice(target, 0, removed[0]);
            return {
                ...state,
                categoryKeys,
                options: {
                    ...state.options,
                    edited: true
                }
            };

        case types.QUESTIONNAIRE_EDITOR_SET_DEFAULT_CATEGORY:
            return {
                ...state,
                template: {
                    ...state.template,
                    defaultCategory: action.defaultCategory
                },
                options: {
                    ...state.options,
                    edited: true
                }
            };

        case types.QUESTIONNAIRE_EDITOR_ADD_QUESTION:// eslint-disable-line no-case-declarations
            let addQuestion = [
                ...state.questionKeys,
                action.questionKey
            ];
            return {
                ...state,
                questionKeys: addQuestion,
                options: {
                    ...state.options,
                    edited: true
                }
            };

        case types.QUESTIONNAIRE_EDITOR_REMOVE_QUESTION:
            updatedTemplate = {...state.template};
            updatedList = state.questionKeys.filter((element, index) => {
                return index !== action.index;
            });

            return {
                ...state,
                template: updatedTemplate,
                questionKeys: updatedList,
                options: {
                    ...state.options,
                    edited: true
                }
            };

        case types.QUESTIONNAIRE_EDITOR_REORDER_QUESTION:// eslint-disable-line no-case-declarations
            let questionKeys = [...state.questionKeys];
            target = (action.isUp)? -1 : 1;
            target = target + action.currentIndex;

            if (target < 0) { target=0;}
            else if (target > questionKeys.length -1) {target = questionKeys.length -1;}

            removed = questionKeys.splice(action.currentIndex, 1);
            questionKeys.splice(target, 0, removed[0]);
            return {
                ...state,
                questionKeys,
                options: {
                    ...state.options,
                    edited: true
                }
            };

        case types.QUESTIONNAIRE_EDITOR_VALUE_EXISTS:
        case types.QUESTIONNAIRE_NAME_QUALIFIER_EXISTS:
            return {
                ...state,
                options: {
                    ...state.options,
                    validate: action.list
                }
            };

        case types.QUESTIONNAIRE_EDITOR_SET_TYPE:
            return {
                ...state,
                questionnaireType: action.questionnaireType
            };

        default:
            return state;
    }
}
