import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {closeDrawer} from "../../actions/drawerActions";
import GroupEditor from "../qd/groups/GroupEditor";
import {createGroupTemplatAndCloseDrawer, saveGroupTemplateAndCloseDrawer} from "../../actions/groupTemplateActions";
import {QD_URL} from "../../constants/systemConstants";
import * as TEMPLATE from "../../constants/templateConstants";
import ArgoAppBar from "../common/ArgoAppBar";
import {withRouter} from "../../utilities/withRouter";


class GroupCreateEditDrawer extends Component {

    closeDrawer = () => {
        this.props.closeDrawer();
    };

    handleRequestSave = (groupTemplate) => {
        if ((typeof this.props.setLoadingState === "function") && (this.props.templateMode !== "versions")) {
            this.props.setLoadingState(this.save, groupTemplate);
        }
        else {
            this.save(groupTemplate);
        }
    };

    save = (groupTemplate) => {
        if (this.props.createMode) {
            this.props.createGroupTemplatAndCloseDrawer(groupTemplate, this.props.versionsFilter, this.onCreateSuccess);
        }
        else {
            this.props.saveGroupTemplateAndCloseDrawer(groupTemplate, this.props.filter, this.props.versionsFilter, this.props.templateMode);
        }
    };

    onCreateSuccess = () => {
        <withRouter navigate={QD_URL + "/groups"}/>;
    };

    render() {

        return (
            <div id="GroupCreateEditDrawer-container-div" style={{height: "100%"}}>
                <ArgoAppBar
                    title={this.props.createMode ? "Create Group" : "Edit Group"}
                    showMenuIconButton={false}
                    noIcon={true}
                    isDrawer={true}
                    width={groupCreateEditDrawerProps.width}
                />

                <div style={{height: '64px'}}></div>

                <GroupEditor
                    createMode={this.props.createMode}
                    cancel={this.closeDrawer}
                    save={this.handleRequestSave}
                    templateKey={this.props.templateKey}
                    templateId={this.props.templateId}
                    templateMode={this.props.templateMode}
                    width={groupCreateEditDrawerProps.width}
                    scrollTo="GroupCreateEditDrawer-container-div"
                />
            </div>
        );
    }
}

// <GroupEditor cancel={this.close} save={this.handleRequestSave.bind(this)} templateKey={this.props.templateKey} templateId={this.props.templateId} editMode={true}/>

GroupCreateEditDrawer.defaultProps = {
    createMode: false,
    templateKey: "",
    templateId: null,
    templateMode: ""
};

GroupCreateEditDrawer.propTypes = {
    createMode: PropTypes.bool,
    setLoadingState: PropTypes.func,
    templateKey: PropTypes.string,
    templateId: PropTypes.number,
    templateMode: PropTypes.string,
    filter: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({closeDrawer, createGroupTemplatAndCloseDrawer, saveGroupTemplateAndCloseDrawer},dispatch);
}

function mapStateToProps(state) {
    return {versionsFilter: state.filter[TEMPLATE.FILTER.current.group]};
}

const groupCreateEditDrawerProps = {
    opensecondary: true,
    width: "90%",
    docked: false,
    anchor: "right"
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupCreateEditDrawer);
export {groupCreateEditDrawerProps};
