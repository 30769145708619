import * as types from "../constants/actionConstants";

const expanderUpdate = (parentName, expanderName, expanderObj, templateKey) => {
    return {
        type: types.EXPANDER_UPDATE,
        payload: {parentName: parentName, expanderName: expanderName, expanderObj: expanderObj, templateKey: templateKey}
    };
};

const expanderClear = (parentName) => {
    return {
        type: types.EXPANDER_CLEAR,
        payload: {parentName: parentName}
    };
}

export {
    expanderUpdate,
    expanderClear
};
